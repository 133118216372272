import { createContext, useEffect } from 'react';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';

// project-imports
import Loader from 'components/Loader';
import { userActions } from 'store/actions';
import { useDispatch, useSelector } from 'store';
import config from 'aws-config';
import { Auth } from 'aws-amplify';
// types
//import { AWSCognitoContextType } from 'types/auth';

// constant
const awsConfig = {
  ...config
};
export const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.Auth.userPoolId,
  ClientId: awsConfig.Auth.userPoolWebClientId
});
// ==============================|| AWS COGNITO - CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  const state = useSelector((state) => state.sessionReducer);
  const { isInitialized } = state;
  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
          dispatch(userActions.loginSuccess(serviceToken));
        } else {
          dispatch(userActions.logout());
        }
        if (!state.user) {
          const session = await Auth.currentAuthenticatedUser();
          dispatch(userActions.setUserSession(session));
        }
      } catch (err) {
        console.error(err);
        dispatch(userActions.logout());
      }
    };
    init();
  }, []);

  const login = async (email, password) => {
    dispatch(userActions.login(email, password, '/'));
  };

  const register = (email, password, firstName, lastName) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        password,
        [new CognitoUserAttribute({ Name: 'email', Value: email }), new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const logout = () => {
    dispatch(userActions.logout());
    localStorage.removeItem('DefaultRoute');
  };

  const forgotPassword = async (email) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    user.forgotPassword({
      onSuccess: function () {},
      onFailure: function () {}
    });
  };

  const resetPassword = async (verificationCode) => {
    const email = localStorage.getItem('email');
    const user = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, '', {
        onSuccess: function (data) {
          localStorage.removeItem('email');
          resolve(data);
        },
        onFailure: function (error) {
          reject(error.message);
        }
      });
    });
  };
  const updateProfile = () => {};
  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider value={{ ...state, login, logout, register, forgotPassword, resetPassword, updateProfile }}>
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
