/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { stockConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  recordID: '',
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  pageSize: 5,
  page: 0,
  total: null,
  tripRecords: [],
  filters: null,
  tripData: null,
  external: null,
  conditional_operator: null,
  statusLoading: false,
  tripCounts: {},
  deleteSuccess: false,
  imageLoader: false,
  options: [],
  subRecords: {},
  subLoading: false,
  groups: [],
  adjacementRecords: []
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, groups, adjacementRecords, recordsMetaData = {}, error, message, page, total, tripRecords, filters,groupId } = props;

  return produce(state, (draft) => {
    switch (type) {
      case stockConstants.REQUST_VALID_CACHE:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_RECORDS:
      case stockConstants.LOAD_RECORD:
      case stockConstants.LOAD_GROUPS_RECORD:
      case stockConstants.LOAD_ADJUSTMENTS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_STOCK_BYGROUPID:
      case stockConstants.LOAD_ADJUSTMENT_BYID:
        draft.subLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.subRecords[groupId] = [];
        break;
      case stockConstants.UPLOAD_ADJUSTMENTS_FILE:
        draft.subLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;

      case stockConstants.LOAD_STOCK_BYGROUPID_SUCCESS:
        draft.subRecords[groupId] = records;
        draft.subLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case stockConstants.LOAD_ADJUSTMENT_BYID_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case stockConstants.LOAD_STOCK_BYGROUPID_ERROR:
        draft.subLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.subRecords[groupId] = [];
        break;
      case stockConstants.LOAD_ADJUSTMENT_BYID_ERROR:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_OPTIONS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_OPTIONS_SUCCESS:
        draft.options = records;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.ID === v.ID) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case stockConstants.LOAD_ADJUSTMENTS_SUCCESS:
        let alladjacementRecords = '';
        page === 0 ? (alladjacementRecords = adjacementRecords) : (alladjacementRecords = state.adjacementRecords.concat(adjacementRecords));
        draft.adjacementRecords = alladjacementRecords.filter((v, i, a) => a.findIndex((v2) => v2.ID === v.ID) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case stockConstants.LOAD_GROUPS_RECORD_SUCCESS:
        let allRecord = '';
        page === 0 ? (allRecord = groups) : (allRecord = state.groups.concat(groups));
        draft.groups = allRecord.filter((v, i, a) => a.findIndex((v2) => v2.ID === v.ID) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case stockConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.filters = filters;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.filters = filters;
        break;
      case stockConstants.VIEW_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.VIEW_RECORDS_SUCCESS:
        draft.tripRecords = tripRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case stockConstants.VIEW_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.filters = filters;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.CREATE_RECORD_SUCCESS:
      case stockConstants.CREATE_GROUPS_SUCCESS:
      case stockConstants.CREATE_ADJUSTMENTS_SUCCESS:
        draft.records = record.concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case stockConstants.UPLOAD_ADJUSTMENTS_FILE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.UPDATE_RECORD_SUCCESS:
        const ID = record.ID;
        draft.records = state.records.find((r) => ID === r.ID)
          ? state.records.map((r) => (ID === r.ID ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.success = false;
        break;
      case stockConstants.UPDATE_GROUPS_SUCCESS:
        const groupID = record.ID;
        draft.groups = state.groups.find((r) => groupID === r.ID)
          ? state.groups.map((r) => (groupID === r.ID ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.groups.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.success = false;
        break;

      case stockConstants.UPDATE_ADJUSTMENTS_SUCCESS:
        const adjustmentID = record.ID;
        draft.adjacementRecords = state.adjacementRecords.find((r) => adjustmentID === r.ID)
          ? state.adjacementRecords.map((r) => (adjustmentID === r.ID ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.adjacementRecords.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.success = false;
        break;
      case stockConstants.DELETE_RECORD_SUCCESS:
      case stockConstants.DELETE_GROUPS_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.ID.toString() !== record.stockId.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case stockConstants.DELETE_ADJUSTMENT_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.ID.toString() !== record.adjustmentId.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case stockConstants.LOAD_RECORDS_ERROR:
      case stockConstants.LOAD_RECORD_ERROR:
      case stockConstants.LOAD_ADJUSTMENTS_ERROR:
      case stockConstants.LOAD_GROUPS_RECORD_ERROR:
      case stockConstants.LOAD_OPTIONS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.CREATE_RECORD_ERROR:
      case stockConstants.CREATE_GROUPS_ERROR:
      case stockConstants.CREATE_ADJUSTMENTS_ERROR:
      case stockConstants.UPLOAD_ADJUSTMENTS_FILE_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.UPDATE_RECORD_ERROR:
      case stockConstants.UPDATE_GROUPS_ERROR:
      case stockConstants.UPDATE_ADJUSTMENTS_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case stockConstants.DELETE_RECORD_ERROR:
      case stockConstants.DELETE_GROUPS_ERROR:
      case stockConstants.DELETE_ADJUSTMENT_ERROR:
      case stockConstants.DELETE_ADJUSTMENTSTOCK_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case stockConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case stockConstants.DELETE_ADJUSTMENT_FILES:
      case stockConstants.DELETE_ADJUSTMENTSTOCK:
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case stockConstants.DELETE_ADJUSTMENT_FILES_SUCCESS:
      case stockConstants.DELETE_ADJUSTMENTSTOCK_SUCCESS:
        draft.error = error;
        draft.success = true;
        draft.message = message;
        break;
      case stockConstants.DELETE_ADJUSTMENT_FILES_ERROR:
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;

      default:
        break;
    }
  });
}
