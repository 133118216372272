import { Box, Card, Grid, Paper, Stack, Table, TableContainer, TableHead, TableRow, Typography, TableBody, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router';
import { dispatch, useSelector } from 'store';
import { invoiceActions } from 'store/actions';
import config from "aws-config"

const QrInvoice = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [gstTax, setGstTax] = useState(0);
  const { records } = useSelector((state) => state.invoice);
  const { id } = useParams();
  useEffect(() => {
    dispatch(invoiceActions.loadQrRecord(id));
  }, [id]);
  useEffect(() => {
    setInvoiceData(records);
  }, [records]);
  const selectedTrips = invoiceData?.tripId?.split(',') || [];
  const selectedVehicle = invoiceData?.vehicles?.split(',') || [];

  useEffect(() => {
    let productsArr = invoiceData?.products;
    try {
      let prods = JSON.parse(invoiceData?.products);
      productsArr = prods;
      setInvoiceProducts(prods);
    } catch {
      setInvoiceProducts(invoiceData?.products);
    }

    if (productsArr && productsArr.length > 0 && (productsArr.length === 1 || productsArr.every((product) => product?.tax === product[0]?.tax))) {
      setGstTax(productsArr[0].tax);
    } else {
      setGstTax(0);
    }
  }, [invoiceData?.products]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1D3F71',
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  return (
    <Box>
      <Grid container spacing={2} root spacing-xs={12}>
        <Grid
          item
          xs={12}
          xl={12}
          md={12}
          display="flex"
          justifyContent="center"
          id="Invoice"
          sx={{
            m: 2,
            flexGrow: 1,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <Card
            sx={{
              p: 2,
              width: '1240px',
              maxWidth: '100%',
              borderRadius: 0,
              position: 'relative',
              margin: 0
            }}
          >
            <Grid display="flex" justifyContent="center" sx={{ pb: 1 }}>
              <Typography color="inherit" variant="h4">
                Tax Invoice
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5} lg={5} sm={5} md={5}>
                <Paper sx={{ m: 2 }} elevation={0}>
                  <Grid container spacing={1}>
                    <Grid xs={12} lg={12} sm={12} md={12} sx={{ pb: 0 }}>
                      <Box display="flex">
                        {invoiceData && invoiceData.logo ? (
                          <img
                            alt="Logo"
                            src={invoiceData?.logo ? invoiceData?.logo : ''}
                            style={{
                              width: '110px',
                              padding: '8px',
                              marginLeft: '-10px',
                              objectFit: 'contain'
                            }}
                          />
                        ) : (
                          ''
                        )}

                        <Stack sx={{ m: 1 }}>
                          <Typography variant="h4" mr={1}>
                            {invoiceData?.companyName}
                          </Typography>
                          <Typography mr={1}>{invoiceData?.tagLine}</Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>Supplier Address: {invoiceData?.headOfficeAddress}</Typography>
                      <Typography>GSTIN Number: {invoiceData?.gstIN}</Typography>
                      <Typography>
                        Mobile:{' '}
                        {`${invoiceData?.phone ? invoiceData?.phone : invoiceData?.mobile ? invoiceData?.mobile : ''}, ${
                          invoiceData?.alternativePhone1 ? invoiceData?.alternativePhone1 : invoiceData?.mobile1 ? invoiceData?.mobile1 : ''
                        }`}
                      </Typography>
                      <Typography>{` ${
                        invoiceData?.alternativePhone2 ? invoiceData?.alternativePhone2 : invoiceData?.mobile2 ? invoiceData?.mobile2 : ''
                      }`}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={5} lg={5} sm={5} md={5}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Date:</Typography>
                  </Grid>
                  <Grid item xs={8} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.issueDate}</Typography>
                  </Grid>
                  {invoiceData?.invoiceId && (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Invoice No:</Typography>
                      </Grid>
                      <Grid item xs={8} display="flex" justifyContent="end">
                        <Typography>{invoiceData?.invoiceId}</Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Order No:</Typography>
                  </Grid>
                  <Grid item xs={8} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.orderId || invoiceData?.selectedOrder}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Trip No:</Typography>
                  </Grid>
                  <Grid item xs={8} display="flex" justifyContent="end">
                    <Typography>{selectedTrips.length > 1 ? `${selectedTrips[0]} + ${selectedTrips.length - 1}` : selectedTrips[0]}</Typography>
                  </Grid>
                  {invoiceData?.vehicles && (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1">Vehicle No:</Typography>
                      </Grid>
                      <Grid item xs={8} display="flex" justifyContent="end">
                        <Typography>{selectedVehicle.length > 1 ? `${selectedVehicle[0]} + ${selectedVehicle.length - 1}` : selectedVehicle[0]}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={2} lg={2} sm={2} md={2} display="flex" justifyContent="center">
                <Typography>
                  <QRCode size={80} value={config?.QR_CODE_URL ? `${config.QR_CODE_URL}QR/${id}` : ''} />
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5" mr={1} mb={1} backgroundColor="#1D3F71" color="white" pl={1}>
                  Bill To
                </Typography>
                <Typography>{invoiceData?.contractorName}</Typography>
                <Typography>{invoiceData?.company}</Typography>
                <Typography>{invoiceData?.fullAddress}</Typography>
                <Typography>{invoiceData?.mobile}</Typography>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5" mr={1} mb={1} backgroundColor="#1D3F71" color="white" pl={1}>
                  Ship To
                </Typography>
                <Typography>{invoiceData?.contractorName}</Typography>
                <Typography>{invoiceData?.company}</Typography>
                <Typography>{invoiceData?.deliveryAddress}</Typography>
                <Typography>{invoiceData?.mobile}</Typography>
              </Grid>
            </Grid>
            <Paper sx={{ mt: 2, mb: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ITEM</StyledTableCell>
                      <StyledTableCell align="right">RATE</StyledTableCell>
                      <StyledTableCell align="right">QTY</StyledTableCell>
                      <StyledTableCell align="right">TAX</StyledTableCell>
                      <StyledTableCell align="right">AMOUNT</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceProducts &&
                      invoiceProducts?.map((row) => (
                        <StyledTableRow
                          key={row.product}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.product} - {row.grade}
                          </TableCell>
                          <TableCell align="right">{row.price ? `₹ ${Number(row.price).toLocaleString('en-IN')}` : '-'}</TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{row.tax}%</TableCell>
                          <TableCell align="right">{row.totalAmount ? `₹ ${Number(row.totalAmount).toLocaleString('en-IN')}` : '-'}</TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5">BANK DETAILS</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{invoiceData?.notes}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.invoiceSubTotal ? `₹ ${Number(invoiceData?.invoiceSubTotal).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Discount</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.discount ? `-₹ ${Number(invoiceData?.discount).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  {invoiceData?.gstType === 'inter-state' ? (
                    <>
                      <Grid item xs={6}>
                        <Typography>{`CGST ${(gstTax > 0 && `@ ${gstTax / 2} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>₹ {Number(invoiceData?.taxAmount / 2).toFixed(2)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{`SGST ${(gstTax > 0 && `@ ${gstTax / 2} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>₹ {Number(invoiceData?.taxAmount / 2).toFixed(2)}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <Typography>{`IGST ${(gstTax > 0 && `@ ${gstTax} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>{invoiceData?.taxAmount ? `₹ ${Number(invoiceData?.taxAmount).toLocaleString('en-IN')}` : '-'}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <hr />
                <Grid container sx={{ pb: 2 }}>
                  <Grid item xs={6}>
                    <Typography>Invoice Total</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.invoiceTotal ? `₹ ${Number(invoiceData?.invoiceTotal).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Round off</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.roundOff ? `₹ ${Number(invoiceData?.roundOff).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Rounded Total</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceData?.roundedTotal ? `₹ ${Number(invoiceData?.roundedTotal).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2} display="flex">
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5">Terms & Conditions</Typography>
                <Grid item xs={6}>
                  <Typography sx={{ whiteSpace: 'pre-line' }}>{invoiceData?.paymentTerms}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6} justifyContent="flex-end">
                <Typography variant="h5" sx={{ marginBottom: '50px' }}>
                  for {invoiceData?.companyName}
                </Typography>
                <Typography variant="p">Authorised Signatory</Typography>
              </Grid>
            </Grid>
            <hr />
            <Stack spacing={1} direction="row" justifyContent={'space-between'} sx={{ margin: '5px' }}>
              <Typography variant="p">This is a computer generated invoice</Typography>
              <Typography display={'flex'} variant="subtitle2" variant="p" fontWeight={600} component="span">
                © 2023 All rights reserved |{' '}
                <img navigate="" alt="icon" isIcon={true} height={25} src={`${config.S3.poweredBy}`} />
              </Typography>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QrInvoice;
