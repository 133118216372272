import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';
import config from 'aws-config';
//constant key
const constantName = 'product';

//Load product records
function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
async function loadProducts() {
  const response = await fetch(config.PRODUCTS_URL);
  const products = await response.json();
  return products.Product;
}

function deleteRecord(id) {
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}
function updateRecord(record) {
  const { productName, spec, publicId, HSN_SAC, removedGrade } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${record.productId}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign({}, { publicId, productName, spec, HSN_SAC, removedGrade })
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateProductStatus(record) {
  let { productId, ...filterRecord } = record;
  return API.put('tennat-service', `${constantName}/status/${productId}`, getDefaultParamswithoutlimitkey({ body: filterRecord }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
export const productService = {
  loadRecords,
  loadRecord,
  loadProducts,
  deleteRecord,
  createRecord,
  updateRecord,
  updateProductStatus
};
