import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'einvoice';

function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get(`${constantName}`,`${constantName}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
// create einvoice form invoice list
function createRecord(record) {
  return API.post(`${constantName}`, `${constantName}`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
// from via prefrence update einvoice details
function updateRecord(record) {
  return API.put('einvoice', `${constantName}`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}


function loadHistorybyId(id) {
  return API.get('einvoice', `${constantName}/history/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(record) {
  return API.post('einvoice', `${constantName}/delete`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
export const einvoiceService = {
  loadRecords,
  createRecord,
  updateRecord,
  loadHistorybyId,
  deleteRecord
};
