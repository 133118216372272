import { useContext } from 'react';

// table provider 
import TableColumnContext from 'contexts/TableColumnContext'; 

// ==============================|| HOOKS - AUTH ||============================== //

const useTableColumnContext = () => {
  const context = useContext(TableColumnContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useTableColumnContext;
