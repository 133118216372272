/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { qcConstants } from '../_constants';

const initialState = {
    qcRecord: {},
    record: {},
    prodRecord: {},
    loading: false
};

export default function Reducer(state = initialState, props) {
    const { type, payload, records,grade } = props;

    return produce(state, (draft) => {
        switch (type) {
            case qcConstants.LOAD_QC_ORDER_DATA:
                draft.error = false;
                draft.success = false;
                draft.loading = true;
                break;
            case qcConstants.LOAD_QC_ORDER_DATA_SUCCESS:
                draft.record = records;
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                break;
            case qcConstants.LOAD_QC_ORDER_DATA_ERROR:
                draft.record = records;
                draft.error = true;
                draft.success = false;
                draft.loading = false;
                break;
            case qcConstants.LOAD_QC_QUOTE_DATA:
                draft.error = false;
                draft.loading = true;
                break;
            case qcConstants.LOAD_QC_QUOTE_DATA_SUCCESS:
                draft.record = records;
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                break;
            case qcConstants.LOAD_QC_QUOTE_DATA_ERROR:
                draft.record = records;
                draft.error = true;
                draft.success = false;
                draft.loading = false;
                break;
            case qcConstants.LOAD_QC_TRIP_DATA:
                draft.error = false;
                draft.loading = true;
                break;
            case qcConstants.LOAD_QC_TRIP_DATA_SUCCESS:
                draft.record = records;
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                break;
            case qcConstants.LOAD_QC_TRIP_DATA_ERROR:
                draft.record = records;
                draft.error = true;
                draft.success = false;
                draft.loading = false;
                break;
            case qcConstants.CREATE_QC_DATA:
                draft.error = false;
                draft.success = false;
                draft.loading = true;
                break;
            case qcConstants.CREATE_QC_DATA_SUCCESS:
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                draft.qcMetrics = {};
                break;
            case qcConstants.UPDATE_QC_DATA_SUCCESS:
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                draft.qcMetrics = {};
                break;
            case qcConstants.CREATE_QC_DATA_ERROR:
                draft.error = true;
                draft.success = false;
                draft.loading = false;
                break;
            case qcConstants.GET_QC_DATA:
                draft.error = false;
                draft.success = false;
                draft.loading = true;
                break;
            case qcConstants.GET_QC_DATA_SUCCESS:
                draft.qcRecord = records;
                const preMetrics = {...records}
                Object.keys(preMetrics).find((key)=>{preMetrics[key] = preMetrics[key].filter((_v)=>_v.stockId)})
                draft.qcMetrics = preMetrics
                draft.error = false;
                draft.success = true;
                draft.loading = false;
                break;
            case qcConstants.GET_QC_DATA_ERROR:
                draft.error = true;
                draft.success = false;
                draft.loading = false;
                break;
            case qcConstants.STORE_PRODUCT_QC_RECORD:
                draft.qcRecord = {...state.qcRecord,...{[grade]:payload}};
                draft.qcMetrics = {...state.qcMetrics,...{[grade]:payload}};
                break;
            case qcConstants.STORE_DEFULT_PRODUCT_QC_RECORD:
                draft.qcRecord = payload
                // const defaultPayload = {...payload}
                // Object.keys(defaultPayload).find((key)=>{defaultPayload[key] = defaultPayload[key].filter((_v)=>_v.stockId)})
                draft.qcMetrics = payload
                break;
            default:
                break;
        }
    });
}