import { preferencesService } from '../services';
import { preferencesConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
// import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
function loadRecords(pageSize = 5, page = 0) {
  return (dispatch) => {
    dispatch(request());
    preferencesService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: preferencesConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.UPDATE_RECORD };
  }
  function success(generalValue, message, error) {
    return { type: preferencesConstants.UPDATE_RECORD_SUCCESS, generalValue, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    preferencesService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: preferencesConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: preferencesConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.CREATE_RECORD_ERROR, error, message };
  }
}

//general setting

function loadGeneralSettingRecords(params) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadGeneralSettingRecords(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_GENERALSETTING_RECORDS };
  }
  function success(generalValue) {
    return { type: preferencesConstants.LOAD_GENERALSETTING_RECORDS_SUCCESS, generalValue };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_GENERALSETTING_RECORDS_ERROR, error, message };
  }
}

//module Setting

function loadModuleSettingRecords(params) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadModuleSettingRecords(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_MODULESETTING_RECORDS };
  }
  function success(moduleRecords) {
    return { type: preferencesConstants.LOAD_MODULESETTING_RECORDS_SUCCESS, moduleRecords };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_MODULESETTING_RECORDS_ERROR, error, message };
  }
}

//user Setting

function loadUserSettingRecords(params) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadUserSettingRecords(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_USERSETTING_RECORDS };
  }
  function success(userRecords) {
    return { type: preferencesConstants.LOAD_USERSETTING_RECORDS_SUCCESS, userRecords };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_USERSETTING_RECORDS_ERROR, error, message };
  }
}

//custom field get

function loadCustomFieldRecords(params) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadCustomFieldRecords(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS };
  }
  function success(customFieldRecords) {
    return { type: preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS_SUCCESS, customFieldRecords };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS_ERROR, error, message };
  }
}


//put general setting
function updateGeneralSettingRecords(record,navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    preferencesService.updateGeneralSettingRecords(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: preferencesConstants.UPDATE_GENERALSETTING_RECORDS,record };
  }
  function success(record, message, error) {
    return { type: preferencesConstants.UPDATE_GENERALSETTING_RECORDS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.UPDATE_GENERALSETTING_RECORDS_ERROR, error, message };
  }
}


//Update custom fields
function updateCustomFields(field) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.updateCustomFields(field).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(field, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(field) {
    return { type: preferencesConstants.UPDATE_CUSTOM_FIELDS ,field};
  }
  function success(field, message, error) {
    return { type: preferencesConstants.UPDATE_CUSTOM_FIELDS_SUCCESS, field, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.UPDATE_CUSTOM_FIELDS_ERROR, error, message };
  }
}

//Update module setting
function updateModuleSetting(modulePayload,queryParams) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.updateModuleSetting(modulePayload,queryParams).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(modulePayload, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.UPDATE_MODULE_SETTING };
  }
  function success(modulePayload, message, error) {
    return { type: preferencesConstants.UPDATE_MODULE_SETTING_SUCCESS, modulePayload, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.UPDATE_MODULE_SETTING_ERROR, error, message };
  }
}

//Update user setting
function updateUserSetting(userPayload,pathParams) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.updateUserSetting(userPayload,pathParams).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(userPayload, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.UPDATE_USER_SETTING };
  }
  function success(userRecords, message, error) {
    return { type: preferencesConstants.UPDATE_USER_SETTING_SUCCESS, userRecords, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.UPDATE_USER_SETTING_ERROR, error, message };
  }
}
function loadAllModuleSettingRecords() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadAllModuleSettingRecords().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_PREFIX_RECORDS };
  }
  function success(prefixRecords) {
    return { type: preferencesConstants.LOAD_PREFIX_RECORDS_SUCCESS, prefixRecords };
  }
  function failure(error, message) {
    return { type: preferencesConstants.LOAD_PREFIX_RECORDS_ERROR, error, message };
  }
}


function connectTally(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.connectTally(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.CONNECT_TALLY };
  }
  function success(records, message, error) {
    return { type: preferencesConstants.CONNECT_TALLY_SUCCESS, records, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.CONNECT_TALLY_ERROR, error, message };
  }
}
function loadConnectTally() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.loadConnectTally().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message, response[0]));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0], message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.LOAD_TALLY_CONNECT };
  }
  function success(records, message, error) {
    return { type: preferencesConstants.LOAD_TALLY_CONNECT_SUCCESS, records, message, error };
  }
  function failure(error, message,records) {
    return { type: preferencesConstants.LOAD_TALLY_CONNECT_ERROR, error, message,records };
  }
}

function testTallyConnection(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    preferencesService.testTallyConnection(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: preferencesConstants.TALLY_TEST_CONNECTION };
  }
  function success(records, message, error) {
    return { type: preferencesConstants.TALLY_TEST_CONNECTION_SUCCESS, records, message, error };
  }
  function failure(error, message) {
    return { type: preferencesConstants.TALLY_TEST_CONNECTION_ERROR, error, message };
  }
}

export const preferencesActions = {
  loadRecords,
  updateRecord,
  createRecord,
  loadGeneralSettingRecords,
  loadModuleSettingRecords,
  loadUserSettingRecords,
  loadCustomFieldRecords,
  updateGeneralSettingRecords,
  updateCustomFields,
  updateModuleSetting,
  updateUserSetting,
  loadAllModuleSettingRecords,
  connectTally,
  loadConnectTally,
  testTallyConnection
};
