import React, { useState, useEffect, useRef, useMemo } from 'react';
//import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, Card, Button, Paper, Box, Stack } from '@mui/material';
//import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import { styled } from '@mui/material/styles';
import { ArrowLeft, DocumentDownload } from 'iconsax-react';
import PlantViewSkeleton from 'components/Skeleton/plant/view';
import { PreviewInvoice } from './viewInvoice';
import CustomTable from 'components/CommonTable';
export const ConsoliDateInvoice = ({ invoiceActions, dispatch, useSelector }) => {
  const { consolidatedInvoice, loading } = useSelector((store) => store.invoice);
  const [record, setRecord] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();
  const rowSelections = location?.state || null;
  const container = useRef();
  const handleDownloadPDF = useReactToPrint({
    content: () => container.current,
    documentTitle: `consolidate`
  });
  const [totalPending, setTotalPending] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    if (rowSelections) {
      let data = {
        invoice_ids: rowSelections
      };
      dispatch(invoiceActions.createConsolidatedInvoice(data));
    }
  }, [rowSelections]);
  useEffect(() => {
    setRecord(consolidatedInvoice);
  }, [consolidatedInvoice]);

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice Id ',
        accessor: 'invoiceId',
        disableFilters: false,
        disableGroupBy: true,
        dataType: 'text'
      },
      {
        Header: 'Order Id',
        accessor: 'orderId',
        disableFilters: false,
        className: 'cell-left',
        disableGroupBy: true,
        dataType: 'text'
      },
      {
        Header: 'Invoice Date',
        accessor: 'issueDate',
        className: 'cell-center',
        disableFilters: false,
        disableGroupBy: true,
        dataType: 'date'
      },
      {
        Header: 'Due Date',
        accessor: 'dateDue',
        className: 'cell-center',
        disableFilters: false,
        disableGroupBy: true,
        dataType: 'date'
      },
      {
        Header: 'Amount',
        accessor: 'roundedTotal',
        className: 'cell-center',
        disableFilters: false,
        disableGroupBy: true,
        dataType: 'text',
        Cell: ({ row }) => {
          const { original } = row;
          return <Typography>{original?.roundedTotal ? `₹ ${Number(original?.roundedTotal).toLocaleString('en-IN')}` : '-'} </Typography>;
        }
      },
      {
        Header: 'Balance Due',
        accessor: 'pending',
        className: 'cell-center',
        disableFilters: true,
        disableGroupBy: true,
        dataType: 'text',
        Cell: ({ row }) => {
          const { original } = row;
          return <Typography>{original?.pending ? `₹ ${Number(original?.pending).toLocaleString('en-IN')}` : '-'} </Typography>;
        }
      }
    ],
    [record, loading]
  );
  useEffect(() => {
    if (record) {
      const updatedTotalData = record.map((val) => {
        return Number(val.invoiceTotal);
      });
      const updatedPendingData = record.map((val) => {
        return Number(val.pending);
      });
      const totalPending = updatedPendingData.reduce((acc, curr) => acc + curr, 0);
      setTotalPending(totalPending);
      const totalAmount = updatedTotalData.reduce((acc, curr) => acc + curr, 0);
      setTotalAmount(totalAmount);
    }
  }, [record]);
  return (
    <>
      {record && record.length ? (
        <>
          <Card ref={container} sx={{ px: 4 }}>
            <Card sx={{ p: 1, m: 1, height: '100vh' }}>
              <Typography color="inherit" variant="h4" sx={{ textAlign: 'center', mb: 1, mt: 2 }}>
                Consolidated Invoices
              </Typography>

              <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                <Grid item xs={5} lg={6} sm={6} md={6}>
                  <Paper sx={{ m: 2 }} elevation={0}>
                    <Grid container spacing={1}>
                      <Grid xs={12} lg={12} sm={12} md={12} sx={{ pb: 0 }}>
                        <Box display="flex">
                          {record[0]?.logoImage ? (
                            <img
                              alt="Logo"
                              src={record[0]?.logoImage}
                              style={{
                                width: '110px',
                                padding: '8px',
                                marginLeft: '-10px',
                                objectFit: 'contain'
                              }}
                            />
                          ) : (
                            ''
                          )}
                          <Stack sx={{ m: 1 }}>
                            <Typography variant="h4" mr={1}>
                              {record[0]?.companyName}
                            </Typography>
                            <Typography mr={1}>{record[0]?.tagLine}</Typography>
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>Supplier Address: {record[0]?.headOfficeAddress}</Typography>
                        <Typography>GSTIN Number: {record[0]?.gstIN}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                  <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                    <div>
                      <Typography variant="h5" mr={1} mb={1}>
                        Billing Address
                      </Typography>
                      <Typography>{record[0]?.contractorName}</Typography>
                      <Typography>{record[0]?.company}</Typography>
                      <Typography>{record[0]?.fullAddress}</Typography>
                      <Typography>{record[0]?.mobile}</Typography>
                      <Typography>{record[0]?.gstNumber}</Typography>
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <CustomTable headcells={columns} rows={record} />
              <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                <div>
                  <Grid item xs={12}>
                    <Typography>
                      {`Total Amount`} {`₹ ${Number(totalAmount).toLocaleString('en-IN')}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Total Pending {`₹ ${totalPending}`}</Typography>
                  </Grid>
                </div>
              </Box>
            </Card>
            {record.map((val) => (
              <Stack>
                <Typography color="inherit" variant="h4" sx={{ textAlign: 'center', mb: 1, mt: 2 }}>
                  Invoice - {val.invoiceId}
                </Typography>
                <PreviewInvoice record={val} isReportView={true} />
                <Box sx={{ height: '12vh' }}></Box>
              </Stack>
            ))}
          </Card>
          <Grid spacing={2} sx={{ mt: 3 }} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              color="inherit"
              sx={{ ml: 2 }}
              startIcon={<ArrowLeft />}
              onClick={() => {
                navigation(location.hash ? `${location.hash.slice(1)}/#consolidate` : -1);
              }}
            >
              Back
            </Button>
            <Button variant="contained" onClick={handleDownloadPDF}>
              <DocumentDownload size={22} />{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle1">
                Download Invoice
              </Typography>
            </Button>
          </Grid>
        </>
      ) : (
        <PlantViewSkeleton />
      )}
    </>
  );
};