import { Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { encrypt, decrypt } from 'n-krypta';
import { dispatch } from 'store';
import { openSnackbar, closeSnackbar, openSlientSnackbar } from 'store/reducers/snackbar';
/**
 *
 * @param {function} columns
 * @param {object} record
 * @param {object} locationState
 */
export function resolveColumns(columns, record, locationState, metaData) {
  const schema = typeof columns === 'function' ? columns(record, metaData) : columns;
  const section = (schema && typeof schema === 'object' && schema.section) || false;
  const sectionColumns = (section && locationState && locationState.schemaId && section.find((_) => _.schemaId === locationState.schemaId)) || false;

  return (sectionColumns && sectionColumns.columns) || (schema && schema.columns) || schema;
}

export const dummyData = (fields) =>
  Array.from(Array(5), (x, index) => {
    const columns = fields && fields.length > 0 && fields.reduce((k, el) => Object.assign({}, k, { [el.value]: '' }), {});
    return Object.assign({}, columns, { _id: index + 1, loading: true });
  });

export const getDefaultParams = (payload, queryString, isAuthRequired = true) => {
  let param = Object.assign({}, payload, queryString);
  if (isAuthRequired) {
    param.headers = {
      limitKey: `${localStorage.getItem('limitKey')}`
    };
  }

  return param;
};
export const getDefaultParamswithoutlimitkey = (payload, queryString) => {
  return Object.assign({}, payload, queryString);
};
export const getParams = (payload, isAuthRequired) => {
  let params = Object.assign({}, payload);
  if (isAuthRequired) {
    params.headers = {
      authorization: `Bearer ${localStorage.getItem('serviceToken')}`
    };
  }
  return;
};
export function getLimitKey() {
  return API.get('tennat-service', `limit/tenantId`, {})
    .then((res) => localStorage.setItem('limitKey', JSON.stringify(res.response.planLimits).replace(/['"]+/g, '')))
    .catch((error) => Promise.reject(error));
}

export function encryption(data) {
  const secret = 'e44694aba9db61bf';
  const encryptedData = encrypt(data, secret);
  localStorage.setItem('Roles', JSON.stringify(encryptedData).replace(/['"]+/g, ''));
}

export function decryption(data) {
  const secret = 'e44694aba9db61bf';
  const decryptedData = decrypt(data ? data : '', secret);
  return decryptedData;
}

export function get_all_authorization() {
  const roles_permissions = localStorage.getItem('Roles');
  const authorizationByRoles = decryption(roles_permissions);
  return authorizationByRoles;
}
export function get_module_authorization(premissions, module) {
  if (premissions) {
    return premissions[module] ? premissions[module] : {};
  }
  return {};
}

export function snackbarActions(error, message, userInteraction = false) {
  return (dispatch) => {
    if (error) {
      if (userInteraction) {
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'userInteraction',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true
          })
        );
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            severity: 'error',
            close: true
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          severity: 'success',
          close: true
        })
      );
    }
  };
}

export function snackbarClose() {
  return (dispatch) => {
    dispatch(closeSnackbar());
  };
}

export function slientSnackbar(message) {
  return (dispatch) => {
    dispatch(openSlientSnackbar({ message }));
  };
}

export function isEmpty(object = {}) {
  if (object && Object.keys(object).length) return false;
  return true;
}

export const buildQuery = (params) => {
  const { pageSize, page, delivery_date, queryParams, customField } = params;
  let { filters, columnFilters } = params;
  let conditional_operator = 'or';
  if (columnFilters && !isEmpty(columnFilters)) {
    filters = columnFilters;
    conditional_operator = 'and';
  }

  const queryObject = { pageSize, page, delivery_date };
  if (filters && !isEmpty(filters)) {
    if (typeof filters == 'string') {
      queryObject.filters = filters;
    } else {
      let arr = [];
      let keys = Object.keys(filters);
      let customArr = [];
      keys.forEach((key) => {
        if (filters[key]) {
          if (customField && customField?.includes(key)) {
            customArr.push(`${key}=${filters[key]}`);
          } else {
            arr.push(`${key}=${filters[key]}`);
          }
        }
      });
      if (arr.length) {
        queryObject.filters = arr.join(',');
        queryObject.conditional_operator = conditional_operator;
      }
      if (customArr?.length) {
        queryObject.customFields = customArr.join(',');
      }
    }
  }
  if (queryParams && !isEmpty(queryParams)) {
    let keys = Object.keys(queryParams);
    keys.forEach((key) => {
      if (queryParams[key]) {
        queryObject[key] = queryParams[key];
      }
    });
  }
  return JSON.parse(JSON.stringify(queryObject));
};

export const CREATE_SUCCESS = 'createSuccess';

export const newlineText = (props) => {
  const text = props;
  const newText = text.split('\n').map((str) => <Typography>{str}</Typography>);
  return newText;
};

export const getCurrentDateTime = (DateISO) => {
  const now = new Date(DateISO);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const parseCsv = (csvData, actions, module) => {
  let parsedata = [];
  let newLinebrk = csvData.split('\n');
  for (let i = 0; i < newLinebrk.length; i++) {
    if (newLinebrk[i] !== '') {
      const newRecords = newLinebrk[i].replaceAll('\r', '');
      parsedata.push(newRecords.split(','));
    }
  }
  constructData(parsedata, actions, module);
};

export const constructData = (parsedata, actions, module) => {
  let result = [];
  const headers = parsedata[0];
  for (var i = 1; i < parsedata.length; i++) {
    let datas = {
      contractorStatus: module === 'contractor' ? 'true' : ''
    };
    // let data ={}
    let countryCode = '';
    headers.forEach((header, index) => {
      if (header !== 'countryCode') {
        if (countryCode === '91' && header === 'mobile') {
          parsedata[i][index] = '+' + countryCode + parsedata[i][index];
        }
        datas[header.trim()] = parsedata[i][index];
      } else {
        countryCode = parsedata[i][index];
      }
    });
    result.push(datas);
  }
  validData(result, actions);
};

export const validData = (datas, actions) => {
  const csvData = JSON.parse(JSON.stringify(datas));
  csvData.forEach(function check(data) {
    for (let key in data) {
      if (data[key] === '') {
        delete data[key];
      }
    }
  });
  dispatch(actions.uploadData(csvData));
};

export const getGstType = (address, state) => {
  const selectedStateArr = address.split(',');
  const selectedState = selectedStateArr[selectedStateArr.length - 3].split('-')[0].trim().split(' ').join('').toLowerCase();
  const formattedState = state && state?.split(' ').join('').toLowerCase();
  if (selectedState !== formattedState) {
    return 'inter-state';
  } else {
    return 'intra-state';
  }
};
