import { driverService } from '../services';
import { driverConstants } from '../_constants';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/logistics/driver';
function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    driverService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: driverConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: driverConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: driverConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  let cloneRecord = { ...record };
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    driverService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          if (cloneRecord.image) {
            dispatch(uploadProfilePhoto(cloneRecord));
          }
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: driverConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: driverConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: driverConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function updateDriverStatus(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.driverId));
    driverService.updateDriverStatus(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: driverConstants.UPDATE_AVAILABILITY_RECORD };
  }
  function success(record, message, error) {
    return { type: driverConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: driverConstants.UPDATE_AVAILABILITY_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  let cloneRecord = { ...record };
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    driverService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          cloneRecord = { ...response, ...cloneRecord };
          if (cloneRecord.image) {
            dispatch(uploadProfilePhoto(cloneRecord));
          }
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: driverConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: driverConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: driverConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    driverService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: driverConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: driverConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: driverConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    driverService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: driverConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: driverConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: driverConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function tripsByDriver(id, status) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    driverService.tripsByDriver(id, status).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: driverConstants.VIEW_RECORDS };
  }
  function success(tripRecords) {
    return { type: driverConstants.VIEW_RECORDS_SUCCESS, tripRecords };
  }
  function failure(error, message) {
    return { type: driverConstants.VIEW_RECORDS_ERROR, error, message };
  }
}

function tripsCountByDriver(id) {
  return (dispatch) => {
    dispatch(request());
    driverService.tripsCountByDriver(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: driverConstants.TRIPS_COUNT_BY_DRIVER };
  }
  function success(tripCounts) {
    return { type: driverConstants.TRIPS_COUNT_BY_DRIVER_SUCCESS, tripCounts };
  }
  function failure(error, message) {
    return { type: driverConstants.TRIPS_COUNT_BY_DRIVER_ERROR, error, message };
  }
}
function resendMsg(id) {
  return (dispatch) => {
    dispatch(request());
    driverService.resendMsg(id).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(message));
          dispatch(snackbarActions(false, message));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: driverConstants.RESEND_MSG };
  }
  function success(message) {
    return { type: driverConstants.RESEND_MSG_SUCCESS, message };
  }
  function failure(error, message) {
    return { type: driverConstants.RESEND_MSG_ERROR, error, message };
  }
}

function driverUpdateByTrip(tripData, external) {
  return (dispatch) => {
    dispatch(request(tripData, external));
  };
  function request(tripData, external) {
    return { type: driverConstants.DRIVER_UPDATE_BYTRIP, tripData, external };
  }
}

function uploadProfilePhoto(record) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    driverService.uploadProfilePhoto(record).then(
      (res) => {
        let { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          //dispatch(snackbarActions(false, message));
          if (response && response['driver']) {
            response = response['driver'];
          }
          dispatch(success(record, response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: driverConstants.UPLOAD_IMAGE, record };
  }
  function success(record, message, error) {
    return { type: driverConstants.UPLOAD_IMAGE_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: driverConstants.UPLOAD_IMAGE_ERROR, error, message };
  }
}

export const driverActions = {
  loadRecords,
  updateRecord,
  updateDriverStatus,
  createRecord,
  loadRecord,
  deleteRecord,
  tripsByDriver,
  tripsCountByDriver,
  driverUpdateByTrip,
  uploadProfilePhoto,
  resendMsg
};
