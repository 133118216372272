import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
//const constantName = 'tally';

function syncContractorTally(record,action = "Create") {
    return API.post('tally-instance', `ledger`, getDefaultParamswithoutlimitkey({ body: record,queryStringParameters:{action:action} }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function fetchTallyCompanies() {
    return API.get('tally-instance', `ledger/all`)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateTallyCompanies() {
    return API.post('tally-instance', `ledger/all`)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function syncInvoiceTally(record) {
    return API.post('tally-instance', `ledger`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function fetchTallInvoice() {
    return API.get('tally-instance', `ledger/all`)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateTallInvoice() {
    return API.post('tally-instance', `ledger/all`)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function importTallInvoice() {
    return API.post('tally-instance', `ledger/all`)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

export const tallyService = {
    syncContractorTally,
    fetchTallyCompanies,
    updateTallyCompanies,
    syncInvoiceTally,
    fetchTallInvoice,
    updateTallInvoice,
    importTallInvoice,
};
