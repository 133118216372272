/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/stock`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_GROUPS_RECORD = `${url}/LOAD_GROUPS_RECORD`;
const LOAD_GROUPS_RECORD_SUCCESS = `${url}/LOAD_GROUPS_RECORD_SUCCESS`;
const LOAD_GROUPS_RECORD_ERROR = `${url}/LOAD_GROUPS_RECORD_ERROR`;
const CREATE_GROUPS = `${url}/CREATE_GROUPS`;
const CREATE_GROUPS_SUCCESS = `${url}/CREATE_GROUPS_SUCCESS`;
const CREATE_GROUPS_ERROR = `${url}/CREATE_GROUPS_ERROR`;
const UPDATE_GROUPS = `${url}/UPDATE_GROUPS`;
const UPDATE_GROUPS_SUCCESS = `${url}/UPDATE_GROUPS_SUCCESS`;
const UPDATE_GROUPS_ERROR = `${url}/UPDATE_GROUPS_ERROR`;
const DELETE_GROUPS = `${url}/DELETE_GROUPS`;
const DELETE_GROUPS_SUCCESS = `${url}/DELETE_GROUPS_SUCCESS`;
const DELETE_GROUPS_ERROR = `${url}/DELETE_GROUPS_ERROR`;
const CREATE_ADJUSTMENTS = `${url}/CREATE_ADJUSTMENTS,`;
const CREATE_ADJUSTMENTS_SUCCESS = `${url}/CREATE_ADJUSTMENTS_SUCCESS,`;
const CREATE_ADJUSTMENTS_ERROR = `${url}/CREATE_ADJUSTMENTS_ERROR,`;
const UPLOAD_ADJUSTMENTS_FILE = `${url}/UPLOAD_ADJUSTMENTS_FILE,`;
const UPLOAD_ADJUSTMENTS_FILE_SUCCESS = `${url}/UPLOAD_ADJUSTMENTS_FILE_SUCCESS,`;
const UPLOAD_ADJUSTMENTS_FILE_ERROR = `${url}/UPLOAD_ADJUSTMENTS_FILE_ERROR,`;
const UPDATE_ADJUSTMENTS = `${url}/UPDATE_ADJUSTMENTS`;
const UPDATE_ADJUSTMENTS_SUCCESS = `${url}/UPDATE_ADJUSTMENTS_SUCCESS`;
const UPDATE_ADJUSTMENTS_ERROR = `${url}/UPDATE_ADJUSTMENTS_ERROR`;
const LOAD_ADJUSTMENTS = `${url}/LOAD_ADJUSTMENTS`;
const LOAD_ADJUSTMENTS_SUCCESS = `${url}/LOAD_ADJUSTMENTS_SUCCESS`;
const LOAD_ADJUSTMENTS_ERROR = `${url}/LOAD_ADJUSTMENTS_ERROR`;
const DELETE_ADJUSTMENT = `${url}/DELETE_ADJUSTMENT`;
const DELETE_ADJUSTMENT_SUCCESS = `${url}/DELETE_ADJUSTMENT_SUCCESS`;
const DELETE_ADJUSTMENT_ERROR = `${url}/DELETE_ADJUSTMENT_ERROR`;
const LOAD_ADJUSTMENT_BYID = `${url}/LOAD_ADJUSTMENT_BYID`;
const LOAD_ADJUSTMENT_BYID_SUCCESS = `${url}/LOAD_ADJUSTMENT_BYID_SUCCESS`;
const LOAD_ADJUSTMENT_BYID_ERROR = `${url}/LOAD_ADJUSTMENT_BYID_ERROR`;
const LOAD_OPTIONS = `${url}/LOAD_OPTIONS`;
const LOAD_OPTIONS_SUCCESS = `${url}/LOAD_OPTIONS_SUCCESS`;
const LOAD_OPTIONS_ERROR = `${url}/LOAD_OPTIONS_ERROR`;
const LOAD_STOCK_BYGROUPID = `${url}/LOAD_STOCK_BYGROUPID`;
const LOAD_STOCK_BYGROUPID_SUCCESS = `${url}/LOAD_STOCK_BYGROUPID_SUCCESS`;
const LOAD_STOCK_BYGROUPID_ERROR = `${url}/LOAD_STOCK_BYGROUPID_ERROR`;
const DELETE_ADJUSTMENT_FILES = `${url}/DELETE_ADJUSTMENT_FILES`;
const DELETE_ADJUSTMENT_FILES_SUCCESS = `${url}/DELETE_ADJUSTMENT_FILES_SUCCESS`;
const DELETE_ADJUSTMENT_FILES_ERROR = `${url}/DELETE_ADJUSTMENT_FILES_ERROR`;
const DELETE_ADJUSTMENTSTOCK = `${url}/DELETE_ADJUSTMENTSTOCK`;
const DELETE_ADJUSTMENTSTOCK_SUCCESS = `${url}/DELETE_ADJUSTMENTSTOCK_SUCCESS`;
const DELETE_ADJUSTMENTSTOCK_ERROR = `${url}/DELETE_ADJUSTMENTSTOCK_ERROR`;

export const stockConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPLOAD_ADJUSTMENTS_FILE,
  UPLOAD_ADJUSTMENTS_FILE_SUCCESS,
  UPLOAD_ADJUSTMENTS_FILE_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_GROUPS_RECORD,
  LOAD_GROUPS_RECORD_SUCCESS,
  LOAD_GROUPS_RECORD_ERROR,
  CREATE_GROUPS,
  CREATE_GROUPS_SUCCESS,
  CREATE_GROUPS_ERROR,
  UPDATE_GROUPS,
  UPDATE_GROUPS_SUCCESS,
  UPDATE_GROUPS_ERROR,
  DELETE_GROUPS,
  DELETE_GROUPS_SUCCESS,
  DELETE_GROUPS_ERROR,
  CREATE_ADJUSTMENTS,
  CREATE_ADJUSTMENTS_SUCCESS,
  CREATE_ADJUSTMENTS_ERROR,
  LOAD_ADJUSTMENTS,
  LOAD_ADJUSTMENTS_SUCCESS,
  LOAD_ADJUSTMENTS_ERROR,
  DELETE_ADJUSTMENT,
  DELETE_ADJUSTMENT_SUCCESS,
  DELETE_ADJUSTMENT_ERROR,
  LOAD_ADJUSTMENT_BYID,
  LOAD_ADJUSTMENT_BYID_SUCCESS,
  LOAD_ADJUSTMENT_BYID_ERROR,
  LOAD_OPTIONS,
  LOAD_OPTIONS_SUCCESS,
  LOAD_OPTIONS_ERROR,
  LOAD_STOCK_BYGROUPID,
  LOAD_STOCK_BYGROUPID_SUCCESS,
  LOAD_STOCK_BYGROUPID_ERROR,
  UPDATE_ADJUSTMENTS,
  UPDATE_ADJUSTMENTS_SUCCESS,
  UPDATE_ADJUSTMENTS_ERROR,
  DELETE_ADJUSTMENT_FILES,
  DELETE_ADJUSTMENT_FILES_SUCCESS,
  DELETE_ADJUSTMENT_FILES_ERROR,
  DELETE_ADJUSTMENTSTOCK,
  DELETE_ADJUSTMENTSTOCK_SUCCESS,
  DELETE_ADJUSTMENTSTOCK_ERROR
};
