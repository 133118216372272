/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/companyprofile`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const LOAD_PREFIX_RECORDS = `${url}/LOAD_PREFIX_RECORDS`;
const LOAD_PREFIX_RECORDS_SUCCESS = `${url}/LOAD_PREFIX_RECORDS_SUCCESS`;
const LOAD_PREFIX_RECORDS_ERROR = `${url}/LOAD_PREFIX_RECORDS_ERROR`;
const UPDATE_PREFIX_RECORDS = `${url}/UPDATE_PREFIX_RECORDS`;
const UPDATE_PREFIX_RECORDS_SUCCESS = `${url}/UPDATE_PREFIX_RECORDS_SUCCESS`;
const UPDATE_PREFIX_RECORDS_ERROR = `${url}/UPDATE_PREFIX_RECORDS_ERROR`;
const RESET = `${url}/RESET`;
const LOAD_PROFILE_RECORDS = `${url}/LOAD_PROFILE_RECORDS`;
const LOAD_PROFILE_RECORDS_SUCCESS = `${url}/LOAD_PROFILE_RECORDS_SUCCESS`;
const LOAD_PROFILE_RECORDS_ERROR = `${url}/LOAD_PROFILE_RECORDS_ERROR`;
const UPDATE_ADVANCED_RECORD = `${url}/UPDATE_ADVANCED_RECORD`;
const UPDATE_ADVANCED_RECORD_SUCCESS = `${url}/UPDATE_ADVANCED_RECORD_SUCCESS`;
const UPDATE_ADVANCED_RECORD_ERROR = `${url}/UPDATE_ADVANCED_RECORD_ERROR`;

const UPDATE_LOGO = `${url}/UPDATE_LOGO`;
const UPDATE_LOGO_SUCCESS = `${url}/UPDATE_LOGO_SUCCESS`;
const UPDATE_LOGO_ERROR = `${url}/UPDATE_LOGO_ERROR`;

const UPDATE_BANNER = `${url}/UPDATE_BANNER`;
const UPDATE_BANNER_SUCCESS = `${url}/UPDATE_BANNER_SUCCESS`;
const UPDATE_BANNER_ERROR = `${url}/UPDATE_BANNER_ERROR`;

const UPLOAD_IMAGE = `${url}/UPLOAD_IMAGE`;
const UPLOAD_IMAGE_SUCCESS = `${url}/UPLOAD_IMAGE_SUCCESS`;
const UPLOAD_IMAGE_ERROR = `${url}/UPLOAD_IMAGE_ERROR`;

const REMOVE_IMAGE = `${url}/REMOVE_IMAGE`;
const REMOVE_IMAGE_SUCCESS = `${url}/REMOVE_IMAGE_SUCCESS`;
const REMOVE_IMAGE_ERROR = `${url}/REMOVE_IMAGE_ERROR`;

const UPLOAD_ADMIN_IMAGE = `${url}/UPLOAD_ADMIN_IMAGE`;
const UPLOAD_ADMIN_IMAGE_SUCCESS = `${url}/UPLOAD_ADMIN_IMAGE_SUCCESS`;
const UPLOAD_ADMIN_IMAGE_ERROR = `${url}/UPLOAD_ADMIN_IMAGE_ERROR`;
const DISPLAY_RECORD = `${url}/DISPLAY_RECORD`;
const DISPLAY_RECORD_SUCCESS = `${url}/DISPLAY_RECORD_SUCCESS`;
const DISPLAY_RECORD_ERROR = `${url}/DISPLAY_RECORD_ERROR`;

export const companyProfileConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  LOAD_PREFIX_RECORDS,
  LOAD_PREFIX_RECORDS_SUCCESS,
  LOAD_PREFIX_RECORDS_ERROR,
  UPDATE_PREFIX_RECORDS,
  UPDATE_PREFIX_RECORDS_SUCCESS,
  UPDATE_PREFIX_RECORDS_ERROR,
  RESET,
  LOAD_PROFILE_RECORDS,
  LOAD_PROFILE_RECORDS_SUCCESS,
  LOAD_PROFILE_RECORDS_ERROR,
  UPDATE_ADVANCED_RECORD,
  UPDATE_ADVANCED_RECORD_SUCCESS,
  UPDATE_ADVANCED_RECORD_ERROR,
  UPDATE_LOGO,
  UPDATE_LOGO_SUCCESS,
  UPDATE_LOGO_ERROR,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  REMOVE_IMAGE,
  REMOVE_IMAGE_SUCCESS,
  REMOVE_IMAGE_ERROR,
  UPLOAD_ADMIN_IMAGE,
  UPLOAD_ADMIN_IMAGE_SUCCESS,
  UPLOAD_ADMIN_IMAGE_ERROR,
  DISPLAY_RECORD,
  DISPLAY_RECORD_SUCCESS,
  DISPLAY_RECORD_ERROR
};
