/*
 *
 *   reducer
 *
 */
import produce from 'immer';
import { usersConstants } from 'store/_constants/users.constants';

const initialState = {
  userRecords: [],
  record: {},
  userRecordsMetaData: {},
  userLoading: false,
  error: false,
  page: 0,
  updateError: false,
  success: false,
  message: null,
  lastUpdate: null,
  triggerApi: false
};

export default function Reducer(state = initialState, props) {
  const { type, record, userRecords, userRecordsMetaData = {}, error, message, page, total, imageUrl } = props;

  return produce(state, (draft) => {
    switch (type) {
      case usersConstants.LOAD_RECORDS_VALID_CACHE:
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case usersConstants.LOAD_RECORDS:
      case usersConstants.UPLOAD_USERS:
      case usersConstants.LOAD_RECORD:
        draft.userLoading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case usersConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = [];
        draft.userRecords = userRecords;
        page === 0 ? (allRecords = userRecords) : (allRecords = state.userRecords.concat(userRecords));
        draft.userRecords = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.userName === v.userName) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.total = total;
        draft.page = page;
        draft.message = '';
        break;
      case usersConstants.LOAD_RECORD_SUCCESS:
        draft.userRecords = userRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case usersConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.userRecordsMetaData = Object.assign({}, state.userRecordsMetaData, userRecordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case usersConstants.CREATE_RECORD_SUCCESS:
        draft.userRecords = [record].concat(state.userRecords);
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.UPDATE_RECORD_SUCCESS:
        draft.userRecords = state.userRecords.find((r) => record.userName === r.userName)
          ? state.userRecords.map((r) => (record.userName === r.userName ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.userRecords.concat([Object.assign({}, record)]);
        draft.userLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.UPLOAD_USERS_SUCCESS:
        let allRecord = state.userRecords.concat(record);
        draft.userRecords = allRecord.filter((v, i, a) => a.findIndex((v2) => v2.userId === v.userId) === i);
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.triggerApi = true;
        draft.total = state.total + record.length;
        break;
      case usersConstants.UPLOAD_USERS_ERROR:
        draft.userLoading = false;
        draft.error = true;
        draft.success = false;
        draft.triggerApi = false;
        draft.message = message;
        break;
      case usersConstants.DELETE_RECORD_SUCCESS:
        draft.userRecords = error ? state.userRecords : state.userRecords.filter((r) => r.userName.toString() !== record.userName.toString());
        draft.userLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total - 1;
        break;
      case usersConstants.LOAD_RECORDS_ERROR:
      case usersConstants.LOAD_RECORD_ERROR:
        draft.userLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.CREATE_RECORD_ERROR:
        draft.userLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.UPDATE_RECORD_ERROR:
      case usersConstants.DELETE_RECORD_ERROR:
        draft.userLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        break;
      case usersConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case usersConstants.RESEND_MSG:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case usersConstants.RESEND_MSG_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.RESEND_MSG_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case usersConstants.UPLOAD_IMAGE:
        draft.imageLoader = true;
        draft.loading = true;
        const loadingItem = { ...record, ...{ imageLoader: true } };
        const unique_identifier = loadingItem.userName;
        draft.userRecords = state.userRecords.find((r) => unique_identifier === r.userName)
          ? state.userRecords.map((r) => (unique_identifier === r.userName ? Object.assign({}, r, loadingItem) : Object.assign({}, r)))
          : state.userRecords.concat([Object.assign({}, loadingItem)]);
        break;
      case usersConstants.UPLOAD_IMAGE_SUCCESS:
        draft.imageLoader = false;
        draft.loading = false;
        console.log(imageUrl);
        const item = { ...record, ...{ imageLoader: false } };
        const identifier = item.userName;
        draft.userRecords = state.userRecords.find((r) => identifier === r.userName)
          ? state.userRecords.map((r) => (identifier === r.userName ? Object.assign({}, r, item) : Object.assign({}, r)))
          : state.userRecords.concat([Object.assign({}, item)]);
        break;
      case usersConstants.UPLOAD_IMAGE_ERROR:
        draft.imageLoader = false;
        draft.loading = false;

        break;
      default:
        break;
    }
  });
}
