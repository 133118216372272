/*
 *
 *   reducer
 *
 */
import produce from "immer";

import {
   noticeBoardConstants
} from "../_constants";

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},  
  loading: false,
  error: false, 
  success: false,
  lastUpdate: null,
  message: null, 
  pageSize: null,
  page: 0,
  total: null,
  filters: null,
  notify:null
};

export default function Reducer(state = initialState, props) {
  const {   
    type,
    records,  
    record,
    recordsMetaData = {},
    error,
    message, 
    total,
    ID
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case noticeBoardConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = "";
        break;
      case noticeBoardConstants.DELETE_RECORD:
      case noticeBoardConstants.LOAD_RECORDS:  
        draft.loading = true;
        draft.error = false; 
        draft.success = false;
        draft.message = "";
        break; 
      case noticeBoardConstants.LOAD_RECORDS_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = ""; 
        draft.total = total;
        break; 
      case noticeBoardConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign(
          {},
          state.recordsMetaData,
          recordsMetaData
        );
        draft.error = false;
        draft.success = false;
        draft.message = "";
        break; 
      case noticeBoardConstants.CREATE_RECORD:  
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = "";
        break; 
      case noticeBoardConstants.CREATE_RECORD_SUCCESS:
        let allRecords = [...state.records]
        if(allRecords && allRecords.length){
            let records  = allRecords[0]
            if(records.messages && records.messages.length){
               let clone_messages = [...records.messages] 
               clone_messages.splice(0, 0,record);
                allRecords[0] = {
                  day:records.day,
                  count:clone_messages.length,
                  messages:clone_messages
                }
            }else{
              allRecords[0] = {
                  day:records.day,
                  count:records.messages.length,
                  messages:records.messages.concat([record])
                }
              
            }
            
        }
        draft.records = allRecords;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = (state.total)+1
        break;  
      case noticeBoardConstants.UPDATE_RECORD_SUCCESS:
        let editRecrods = [...state.records]
        if(editRecrods && editRecrods.length){ 
            editRecrods.forEach((_record,index)=>{
              let messages = [..._record.messages]
              if(messages && messages.length){
                messages.forEach((_message,messageIndex)=>{
                  if(record.ID === _message.ID){
                      messages.splice(messageIndex,1,record)
                  }
                })
                editRecrods[index] = {
                  day:_record.day,
                  count:messages.length,
                  messages:messages
                }
              }
            }) 
        }
        draft.records = editRecrods;
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.success = false;
        break;
      case noticeBoardConstants.UPDATE_RECORD_ERROR:
          draft.loading = false;
          draft.error = error;
          draft.success = false;
          draft.message = message;
          break;
      case noticeBoardConstants.UPDATE_RECORD:
          draft.loading = true;
          draft.error = false;
          draft.success = false;
          draft.message = '';
          break;
      case noticeBoardConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;  
      case noticeBoardConstants.CLEAR:
        draft.error = false;
        draft.message = "";
        draft.notify = false
        break
      case noticeBoardConstants.DELETE_RECORD_SUCCESS:
        let deleteReords = [...state.records]
        if(deleteReords && deleteReords.length){ 
            deleteReords.forEach((_record,index)=>{
              let messages = [..._record.messages]
              if(messages && messages.length){
                messages.forEach((_message,messageIndex)=>{
                  if(ID === _message.ID){
                      messages.splice(messageIndex,1)
                  }
                })
                deleteReords[index] = {
                  day:_record.day,
                  messages:messages,
                  count:messages.length
                }
              }
            }) 
        }
        
        draft.records = deleteReords;
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case noticeBoardConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      default:
        break;
    }
  });
}
