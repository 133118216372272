import {prod,dev,demo,stage} from "./aws-config"

let config = {}; 
if(process.env.REACT_APP_STAGE === "prod"){
  config = {  
    ...prod
  };
}else if(process.env.REACT_APP_STAGE === "dev"){
  config = {  
    ...dev
  };
}else if(process.env.REACT_APP_STAGE === "demo"){
  config = {  
    ...demo
  };
}else if(process.env.REACT_APP_STAGE === "stage"){
  config = {  
    ...stage
  };
}else{
  config = {  
    ...dev
  };
}

export default config