import { vehicleService } from '../services';
import { vehicleConstants } from '../_constants';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/logistics/vehicle';

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    vehicleService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: vehicleConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: vehicleConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: vehicleConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    vehicleService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot') || message.includes("Can't")) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: vehicleConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: vehicleConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: vehicleConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    vehicleService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: vehicleConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: vehicleConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: vehicleConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    vehicleService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          dispatch(loaderActions.end());
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: vehicleConstants.DELETE_RECORD };
  }
  function success(vehicleId, message, error) {
    return { type: vehicleConstants.DELETE_RECORD_SUCCESS, vehicleId, message, error };
  }
  function failure(error, message) {
    return { type: vehicleConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    vehicleService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: vehicleConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: vehicleConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: vehicleConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function tripsByVehicle(id, status) {
  return (dispatch) => {
    dispatch(request());
    vehicleService.tripsByVehicle(id, status).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: vehicleConstants.VIEW_RECORDS };
  }
  function success(tripRecords) {
    return { type: vehicleConstants.VIEW_RECORDS_SUCCESS, tripRecords };
  }
  function failure(error, message) {
    return { type: vehicleConstants.VIEW_RECORDS_ERROR, error, message };
  }
}

function updateVehicleStatus(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.vehicleId));
    vehicleService.updateVehicleStatus(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: vehicleConstants.UPDATE_AVAILABILITY_RECORD };
  }
  function success(record, message, error) {
    return { type: vehicleConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: vehicleConstants.UPDATE_AVAILABILITY_RECORD_ERROR, error, message };
  }
}

function tripsCountByVehicle(id) {
  return (dispatch) => {
    dispatch(request());
    vehicleService.tripsCountByVehicle(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: vehicleConstants.TRIPS_COUNT_BY_VEHICLE };
  }
  function success(tripCounts) {
    return { type: vehicleConstants.TRIPS_COUNT_BY_VEHICLE_SUCCESS, tripCounts };
  }
  function failure(error, message) {
    return { type: vehicleConstants.TRIPS_COUNT_BY_VEHICLE_ERROR, error, message };
  }
}

function vehicleUpdateByTrip(tripData, external) {
  return (dispatch) => {
    dispatch(request(tripData, external));
  };
  function request(tripData, external) {
    return { type: vehicleConstants.VEHICLE_UPDATE_BYTRIP, tripData, external };
  }
}

export const vehicleActions = {
  loadRecords,
  updateRecord,
  loadRecord,
  deleteRecord,
  createRecord,
  tripsByVehicle,
  updateVehicleStatus,
  tripsCountByVehicle,
  vehicleUpdateByTrip
};
