/*
 *
 *   reducer
 *
 */
import produce from 'immer';
import { einvoiceConstants } from '../_constants';

const initialState = {
  records: [],
  pageSize: null,
  page: 0,
  total: null,
  message: null,
  loading: false,
  error: false,
  subRecords: {},
  subLoading: false
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    record,
    records,

    error,
    message,
    page,
    total,
    id
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case einvoiceConstants.LOAD_RECORDS:
      case einvoiceConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;

      case einvoiceConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));

        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.invoice_uuid === v.invoice_uuid) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.page = page;
        // draft.message = '';
        draft.deleteSuccess = false;
        draft.total = total;
        break;
      case einvoiceConstants.LOAD_RECORD_SUCCESS:
        draft.record = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case einvoiceConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case einvoiceConstants.CREATE_RECORD:
        draft.loading = true;
        draft.message = '';
        break;
      case einvoiceConstants.LOAD_RECORDS_ERROR:
      case einvoiceConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case einvoiceConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID:
        draft.subLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.subRecords[id] = [];
        break;
      case einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID_SUCCESS:
        draft.subRecords[id] = records;
        draft.subLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID_ERROR:
        draft.subLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.subRecords[id] = [];
        break;
      case einvoiceConstants.UPDATE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case einvoiceConstants.UPDATE_RECORD_SUCCESS:
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = message;
        break;
      case einvoiceConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case einvoiceConstants.LOAD_UPDATE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case einvoiceConstants.LOAD_UPDATE_RECORD_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = '';
        break;
      case einvoiceConstants.LOAD_UPDATE_RECORD_ERROR:
        draft.records = records;
        draft.error = error;
        draft.loading = false;
        draft.message = message;
        break;
      default:
        break;
      case einvoiceConstants.DELETE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case einvoiceConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.irn.toString() !== record.Irn.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case einvoiceConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
    }
  });
}
