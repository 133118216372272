/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { tallyConstants } from '../_constants';

const initialState = {
    tallyCompanyRecords: [],
    tallyCompanyLoading: false,
    tallyCompanies: [],
    fetchingTally: false,
    error: false,
    message: ''
};

export default function Reducer(state = initialState, props) {
    const { type, record, message, tallyCompanies } = props;

    return produce(state, (draft) => {
        switch (type) {
            case tallyConstants.SYNC_CONTRACTOR_TALLY:
                draft.tallyCompanyLoading = true;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.SYNC_CONTRACTOR_TALLY_SUCCESS:
                draft.tallyCompanyRecords = record;
                draft.tallyCompanyLoading = false;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.SYNC_CONTRACTOR_TALLY_ERROR:
                draft.tallyCompanyLoading = false;
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.FETCH_TALLY_COMPANIES:
                draft.tallyCompanyLoading = true;
                draft.fetchingTally = true;
                draft.error = false;
                break;
            case tallyConstants.FETCH_TALLY_COMPANIES_SUCCESS:
                draft.tallyCompanyLoading = false;
                draft.tallyCompanies = tallyCompanies;
                draft.fetchingTally = false;
                draft.error = false;
                draft.message = message;
                break;
            case tallyConstants.FETCH_TALLY_COMPANIES_ERROR:
                draft.tallyCompanyLoading = false;
                draft.tallyCompanies = [];
                draft.fetchingTally = false;
                draft.error = true;
                draft.message = message;
                break;
            case tallyConstants.IMPORT_CONTRACTOR_TALLY:
                draft.tallyCompanyLoading = true;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.IMPORT_CONTRACTOR_TALLY_SUCCESS:
                draft.tallyCompanyRecords = record;
                draft.tallyCompanyLoading = false;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.IMPORT_CONTRACTOR_TALLY_ERROR:
                draft.tallyCompanyLoading = false;
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.UPDATE_CONTRACTOR_TALLY:
                draft.tallyCompanyLoading = true;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.UPDATE_CONTRACTOR_TALLY_SUCCESS:
                draft.tallyCompanyRecords = record;
                draft.tallyCompanyLoading = false;
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.UPDATE_CONTRACTOR_TALLY_ERROR:
                draft.tallyCompanyLoading = false;
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.CLEAR_TALLY_RECORD:
                draft.tallyCompanyRecords = [];
                draft.tallyCompanyLoading = false;
                break;
            case tallyConstants.SYNC_INVOICE_TALLY:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.SYNC_INVOICE_TALLY_SUCCESS:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.SYNC_INVOICE_TALLY_ERROR:
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.FETCH_TALLY_INVOICE:
                draft.fetchingTally = true;
                draft.error = false;
                break;
            case tallyConstants.FETCH_TALLY_INVOICE_SUCCESS:
                draft.fetchingTally = false;
                draft.error = false;
                draft.message = message;
                break;
            case tallyConstants.FETCH_TALLY_INVOICE_ERROR:
                draft.fetchingTally = false;
                draft.error = true;
                draft.message = message;
                break;
            case tallyConstants.IMPORT_INVOICE_TALLY:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.IMPORT_INVOICE_TALLY_SUCCESS:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.IMPORT_INVOICE_TALLY_ERROR:
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.UPDATE_INVOICE_TALLY:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                draft.total = state.total + 1;
                break;
            case tallyConstants.UPDATE_INVOICE_TALLY_SUCCESS:
                draft.error = false;
                draft.success = false;
                draft.message = message;
                break;
            case tallyConstants.UPDATE_INVOICE_TALLY_ERROR:
                draft.error = true;
                draft.success = false;
                draft.message = message;
                break;
            default:
                break;
        }
    });
}
