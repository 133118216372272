import { mqttConstants } from '../_constants';

   
function createMqttConnection(mqttConnectionMessage,mqttConnectorError) {
    return {
      type: mqttConstants.MQTTCONNECTING,
      mqttConnectorError,
      mqttConnectionMessage
    }
  }
  function createMqttConnectionSuccess(isMqttConnected) { 
    return {
      type: mqttConstants.MQTTCONNECTING_SUCCESS,
      isMqttConnected
    }
  }
  function createMqttConnectionError(mqttConnectionMessage,mqttConnectorError) {
    return {
      type: mqttConstants.MQTTCONNECTING_ERROR,
      mqttConnectorError,
      mqttConnectionMessage
    }
  }
  
  function getMqttMessage(mqttMessage,mqttMessageError) {
    return {
      type: mqttConstants.GET_MESSAGE,
      mqttMessageError,
      mqttMessage
    }
  }
  function getMqttMessageSuccess(mqttResponse) {
    return {
      type: mqttConstants.GET_MESSAGE_SUCCESS,
      mqttResponse
    }
  }
  function getMqttMessageError(mqttMessage,mqttMessageError) {
    return {
      type: mqttConstants.GET_MESSAGE_ERROR,
      mqttMessageError,
      mqttMessage
    }
  }
  
  function loadMqttTopic(message,error) {
    return {
      type: mqttConstants.LOAD_TOPIC,
      error,
      message
    }
  }
  function loadMqttTopicSuccess(topic) {
    return {
      type: mqttConstants.LOAD_TOPIC_SUCCESS,
      topic
    }
  }
  function loadMqttTopicError(message,error) {
    return {
      type: mqttConstants.LOAD_TOPIC_ERROR,
      error,
      message
    }
  }
  function updateMarkePostion(markerPosition) {
    return {
      type: mqttConstants.UPDATE_MARKER_POSITION,
      markerPosition
    }
  }
  
  function clear() {
    return {
      type: mqttConstants.CLEAR
    }
  }

  function removeCompletedMarkerPosition(mqttId) {
    return {
      type: mqttConstants.REMOVE_COMPLETED_MARKER,
      mqttId
    }
  }
  function updateTrasistionStatus(transitionStatus) {
    return {
      type: mqttConstants.TRASISTION_STATUS,
      transitionStatus
    }
  }

export const mqttActions = {
  clear,
  createMqttConnection,
  createMqttConnectionSuccess,
  createMqttConnectionError,
  getMqttMessageError,
  getMqttMessageSuccess,
  getMqttMessage,
  loadMqttTopic,
  loadMqttTopicSuccess,
  loadMqttTopicError,
  updateMarkePostion,
  removeCompletedMarkerPosition,
  updateTrasistionStatus
};