import { useState } from 'react';
// material-ui
import { Grid, Stack, Typography, Chip, Menu, ListItemButton, IconButton } from '@mui/material';
import Dot from 'components/@extended/Dot';
import { More } from 'iconsax-react';
// project-imports
import CustomDialog from 'components/CustomDialog';
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import avatar1 from 'assets/images/users/avatar-10.png';
// ==============================|| HEADER - MESSAGE CARD ||============================== //

import { dispatch, useSelector } from 'store';
import { noticeBoardActions, dialogActions } from 'store/actions';

const NoticeBoardCard = ({
  category,
  status,
  time,
  title,
  message,
  src,
  actions,
  openAddDialog,
  selectedRecord,
  keyValue,
  setNoticeboardID,
  noticeBoardID
}) => {
  const { user } = useSelector((state) => state.sessionReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open] = useState('sample');
  const opened = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const removeNoticeBoard = () => {
    dispatch(noticeBoardActions.deleteRecord(noticeBoardID));
  };
  return (
    <MainCard sx={{ mb: 1 }} key={`${keyValue}-maincard`}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip label={category} color={status ? status : 'default'} size="small" variant="light" />
              <Typography variant="caption" color="secondary">
                {time}
              </Typography>
              <Dot size={5} color="warning" />
            </Stack>
            {user && (user.username === selectedRecord.createdBy || user.attributes['custom:userRole'] === 'Admin') && (
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <IconButton
                  color="secondary"
                  id="wallet-button"
                  aria-controls={open ? 'wallet-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <More />
                </IconButton>
                <Menu
                  id="wallet-menu"
                  anchorEl={anchorEl}
                  open={opened}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'wallet-button',
                    sx: { p: 1.25, minWidth: 150 }
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  {user && user.username === selectedRecord.createdBy && (
                    <ListItemButton
                      onClick={(e) => {
                        openAddDialog('edit', selectedRecord);
                      }}
                    >
                      Edit
                    </ListItemButton>
                  )}
                  <ListItemButton
                    id={selectedRecord.ID}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(dialogActions.request(true));
                      setNoticeboardID(e.target.id);
                    }}
                  >
                    Delete
                  </ListItemButton>
                </Menu>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt="profile user" src={avatar1} />
            <Typography variant="subtitle1">{title}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography>{message}</Typography>
        </Grid>
      </Grid>
      <CustomDialog
        callback={removeNoticeBoard}
        title="Notice Board"
        sx={{
          zIndex: 77777,
          '& .MuiBackdrop-root': {
            opacity: '0 !important'
          }
        }}
      ></CustomDialog>
    </MainCard>
  );
};

export default NoticeBoardCard;
