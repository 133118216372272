
import { productService } from '../services';
import { productContants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
import { qcActions } from './qualityControl.actions';
import { userActions } from 'store/actions';
const listUrl = '/product/list';

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    productService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: productContants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: productContants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: productContants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    productService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: productContants.LOAD_RECORD };
  }
  function success(record) {
    return { type: productContants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: productContants.LOAD_RECORD_ERROR, error, message };
  }
}

function loadProducts() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    productService.loadProducts().then(
      (res) => {
        const { error } = res;
        if (error) {
          dispatch(failure(true));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(res));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: productContants.LOAD_PRODUCTS };
  }
  function success(products) {
    return { type: productContants.LOAD_PRODUCTS_SUCCESS, products };
  }
  function failure(error, message) {
    return { type: productContants.LOAD_PRODUCTS_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    productService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: productContants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: productContants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: productContants.DELETE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, isFirstTimeUpload = false, navigate) {
  let { qcValue, ...filterRecord } = record;
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    productService.createRecord(filterRecord).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          const updatedRecord = {
            ...qcValue,
            productId: response.productId
          };
          dispatch(success(response, message, error));
          dispatch(qcActions.createQcData(updatedRecord))
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (isFirstTimeUpload) {
            dispatch(userActions.updateBoardingStatus(navigate));
          } else {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          if (isFirstTimeUpload) {
            dispatch(userActions.updateBoardingStatus(navigate));
          } else {
            navigate(listUrl);
          }
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: productContants.CREATE_RECORD };
  }
  function success(record, message, error) {
    return { type: productContants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: productContants.CREATE_RECORD_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    const recordWithoutQcValue = { ...record };
    delete recordWithoutQcValue.qcValue;
    productService.updateRecord(recordWithoutQcValue).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(qcActions.updateQcData(record.qcValue, record.productId));
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: productContants.UPDATE_RECORD };
  }
  function success(record, message, error) {
    return { type: productContants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: productContants.UPDATE_RECORD_ERROR, error, message };
  }
}

function updateProductStatus(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.productId));
    productService.updateProductStatus(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: productContants.UPDATE_AVAILABILITY_RECORD };
  }
  function success(record, message, error) {
    return { type: productContants.UPDATE_AVAILABILITY_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: productContants.UPDATE_AVAILABILITY_RECORD_ERROR, error, message };
  }
}
function addRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    productService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: productContants.Add_RECORD };
  }
  function success(record, message, error) {
    return { type: productContants.Add_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: productContants.Add_RECORD_ERROR, error, message };
  }
}
export const productActions = {
  updateProductStatus,
  loadRecords,
  loadRecord,
  loadProducts,
  deleteRecord,
  createRecord,
  updateRecord,
  addRecord
};
