// material-ui 
import {  
  Grid,  
  TablePagination,
  Typography
} from '@mui/material'; 
import useTableContenxt from 'hooks/useTableContext'

const TablePaginationComponent = ({actions, gotoPage, rows, setPageSize, pageSize, pageIndex, total,queryParameters={},filters={},contextKey='quote',handleTableContext}) => {
    const {tableConfig} = useTableContenxt()  
    const handleChangePagination = (event, value) => { 
      gotoPage(value)
      let params = {
        pageSize:pageSize,
        page:value,
        queryParams:queryParameters,
        filters:filters
      }
      if(value>0){
          //dispatch(actions.loadSearchRecords(params))
      }
      handleTableContext(params)
    };

    const handleChange = (event) => {
      setPageSize(parseInt(event.target.value));
      gotoPage(0)
      let params = {
        pageSize:parseInt(event.target.value),
        page:0,
        queryParams:queryParameters,
        filters:filters
      }
      handleTableContext(params)
    }; 
    return (
      <Grid container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}> 
        <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
           <Typography variant="caption" color="secondary">
              Total {total ? total: Math.ceil(rows.length / pageSize)}
            </Typography>
        </Grid>
        <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
          <TablePagination
             sx={{ '& .MuiToolbar-root': { p: 0, minHeight: 0 },'& .MuiTablePagination-selectLabel': { m: 0 },'& .MuiTablePagination-displayedRows': { m: 0 }}}
            component="div"
            count={total ? total: Math.ceil(rows.length / tableConfig[contextKey]?.pageSize)}
            page={tableConfig[contextKey]?.page}
            onPageChange={handleChangePagination}
            rowsPerPage={tableConfig[contextKey]?.pageSize}
            onRowsPerPageChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  };

  export default TablePaginationComponent