/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { companyProfileConstants } from '../_constants';
import logoImg from 'assets/images/logo.png';
const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  prefixRecords: [],
  profileLoading: false,
  logoImage: logoImg,
  bannerImage: '',
  domainURL: '',
  userprofiles: [],
  loader: false,
  images: [],
  imageLoader: false,
  recordsDisplay: []
};

export default function Reducer(state = initialState, props) {
  const { type, id, record, recordsDisplay, records, recordsMetaData = {}, error, message, userprofiles, prefixRecords } = props;

  return produce(state, (draft) => {
    switch (type) {
      case companyProfileConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_RECORDS:
      case companyProfileConstants.LOAD_PREFIX_RECORDS:
      case companyProfileConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_RECORDS_SUCCESS:
        draft.records = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        if (draft.records && draft.records.length) {
          draft.logoImage = draft.records[0]?.logo;
          draft.bannerImage = draft.records[0]?.banner;
          draft.domainURL = draft.records[0]?.domainURL;
        }
        break;
      case companyProfileConstants.LOAD_PREFIX_RECORDS_SUCCESS:
        draft.prefixRecords = prefixRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_RECORD_SUCCESS:
        draft.records = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.UPDATE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        break;
      case companyProfileConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.map((r) => (record.userName === r.userName ? Object.assign({}, r, record) : Object.assign({}, r)));
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.logoImage = draft.records[0]?.logo;
        draft.bannerImage = draft.records[0]?.banner;
        draft.domainURL = draft.records[0]?.domainURL;
        break;
      case companyProfileConstants.UPDATE_PREFIX_RECORDS_SUCCESS:
        draft.prefixRecords = record;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.contractorId.toString() !== id.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.LOAD_RECORDS_ERROR:
      case companyProfileConstants.LOAD_PREFIX_RECORDS_ERROR:
      case companyProfileConstants.LOAD_RECORD_ERROR:
      case companyProfileConstants.UPDATE_LOGO_ERROR:
      case companyProfileConstants.UPDATE_BANNER_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.UPDATE_RECORD_ERROR:
      case companyProfileConstants.UPDATE_PREFIX_RECORDS_ERROR:
      case companyProfileConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case companyProfileConstants.RESET:
        draft.error = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_PROFILE_RECORDS:
        draft.profileLoading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_PROFILE_RECORDS_SUCCESS:
        draft.profileLoading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.userprofiles = userprofiles;
        draft.message = '';
        break;
      case companyProfileConstants.LOAD_PROFILE_RECORDS_ERROR:
        draft.profileLoading = false;
        draft.error = true;
        draft.updateError = false;
        draft.success = false;
        draft.message = message;
        break;
      case companyProfileConstants.UPDATE_LOGO_SUCCESS:
        draft.logoImage = record?.logo;
        break;
      case companyProfileConstants.UPDATE_BANNER_SUCCESS:
        draft.bannerImage = record?.banner;
        break;
      case companyProfileConstants.UPLOAD_IMAGE:
        draft.loader = true;
        break;
      case companyProfileConstants.UPLOAD_IMAGE_SUCCESS:
        let profileRecord = { ...record, ...{ [record.module]: record[record.module] } };
        draft.records = state.records.map((r) => (profileRecord.tenantId === r.ID ? Object.assign({}, r, profileRecord) : Object.assign({}, r)));
        if (record.module === 'logo') {
          draft.logoImage = record[record.module];
        } else {
          draft.bannerImage = record[record.module];
        }
        draft.loader = false;
        break;
      case companyProfileConstants.UPLOAD_IMAGE_ERROR:
        draft.loader = false;
        break;
      case companyProfileConstants.REMOVE_IMAGE:
        draft.loader = true;
        break;
      case companyProfileConstants.REMOVE_IMAGE_SUCCESS:
        // let profileRecordRemove = { ...record, ...{ [record.module]: record[record.module] } };
        draft.records = state.records.filter((r) => record.tenantId !== r.ID);
        if (record.module === 'logo') {
          draft.logoImage = '';
        } else {
          draft.bannerImage = '';
        }
        draft.loader = false;
        break;
      case companyProfileConstants.REMOVE_IMAGE_ERROR:
        draft.loader = false;
        break;

      case companyProfileConstants.UPLOAD_ADMIN_IMAGE:
        draft.imageLoader = true;
        draft.loading = true;
        break;
      case companyProfileConstants.UPLOAD_ADMIN_IMAGE_SUCCESS:
        draft.imageLoader = false;
        draft.loading = false;
        break;
      case companyProfileConstants.UPLOAD_ADMIN_IMAGE_ERROR:
        draft.imageLoader = false;
        draft.loading = false;
      case companyProfileConstants.DISPLAY_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case companyProfileConstants.DISPLAY_RECORD_SUCCESS:
        draft.recordsDisplay = recordsDisplay;
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = message;
        break;

      case companyProfileConstants.DISPLAY_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.recordsDisplay = [];

        break;
      default:
        break;
    }
  });
}
