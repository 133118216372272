// material-ui
import { Theme } from '@mui/material/styles';
// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            WebkitTextFillColor: "#A9A9A9"
          }
        }
      }
    }
  };
}
