import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'trip';

function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

// function loadRecords(pageSize = 10, page = 0, delivery_date,filters) {
//   let queryParams = { pageSize: pageSize, page: page, delivery_date: delivery_date,filters, conditional_operator: 'or' };
//   let querywithoutDeliveryDate = { pageSize: pageSize, page: page,filters, conditional_operator: 'or' };
//   return API.get(
//     'tennat-service',
//     `${constantName}s`,
//     getDefaultParams(null, { queryStringParameters: delivery_date === undefined ? querywithoutDeliveryDate : queryParams })
//   )
//     .then((res) => res)
//     .catch((error) => Promise.reject(error));
// }
function deleteRecord(id) {
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  let datas = record;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { viaorder, ...filterRecord } = datas;
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: filterRecord }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadTripsByOrderRecords(id) {
  return API.get('tennat-service', `trip/status/orderId/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: {} }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function viewTriprecordsByOrder(id) {
  return API.get('tennat-service', `trips/order/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: {} }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function updateRecord(record) {
  const {
    orderId,
    tripId,
    tripStatus,
    // driverName,
    // mobile,
    vehicleId,
    // vehicleCapacity,
    deliveryDate,
    contractorId,
    deliveryAddress,
    tripUnit,
    tripCapacity,
    plantId,
    // driverTripStatus,
    // vehicleTripStatus,
    // vehicleUnit,
    // vehicleNumber,
    driverId,
    tripStatusChanged,
    tripStatusUpdate,
    driverMobileNumber,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viaorder,
    customFields
  } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${tripId}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign(
        {},
        {
          orderId,
          tripStatus,
          driverMobileNumber,
          // driverName,
          // mobile,
          vehicleId,
          plantId,
          contractorId,
          tripUnit,
          // driverTripStatus,
          // vehicleTripStatus,
          tripCapacity,
          deliveryAddress,
          // vehicleCapacity,
          deliveryDate,
          // vehicleUnit,
          // vehicleNumber,
          driverId,
          tripStatusChanged,
          tripStatusUpdate,
          customFields
        }
      )
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createTripSheet(id) {
  return API.get('tennat-service', `trip/createtripsheet/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: {} }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function loadRecordsCount(params) {
  return API.get('tennat-service', `${constantName}s/counts`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function loadStatusTimelineRecords(id) {
  return API.get('tennat-service', `trip/timeline/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadTodayRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadUpcomingRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadLiveRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadCompletedRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export const tripService = {
  loadRecords,
  deleteRecord,
  loadRecord,
  createRecord,
  loadTripsByOrderRecords,
  updateRecord,
  loadRecordsCount,
  createTripSheet,
  loadStatusTimelineRecords,
  loadTodayRecords,
  loadUpcomingRecords,
  loadLiveRecords,
  loadCompletedRecords,
  viewTriprecordsByOrder
};
