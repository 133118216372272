import { tripService } from '../services';
import { tripConstants } from '../_constants';
import { dialogActions } from './dialog.actions';
import { snackbarActions,  CREATE_SUCCESS } from 'helpers/tools';
import { loaderActions } from './loader.actions';
const listUrl = '/trip/list';

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    tripService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (record?.viaorder) {
            navigate(`/order/view/${response[0].orderId}`);
          } else {
            navigate(listUrl, { state: { status: CREATE_SUCCESS } });
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: tripConstants.CREATE_RECORD };
  }
  function success(record, message, error) {
    return { type: tripConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: tripConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function createTripSheet(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    tripService.createTripSheet(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
          //navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: tripConstants.CREATE_TRIPSHEET };
  }
  function success(records, message, error) {
    return { type: tripConstants.CREATE_TRIPSHEET_SUCCESS, records, message, error };
  }
  function failure(error, message) {
    return { type: tripConstants.CREATE_TRIPSHEET_ERROR, error, message };
  }
}

function loadStatusTimelineRecords(id) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadStatusTimelineRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_STATUS_TIMELINE_RECORDS };
  }
  function success(statusTimeline) {
    return { type: tripConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS, statusTimeline };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR, error, message };
  }
}

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    tripService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: tripConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  let { tripId, orderId } = record;
  return (dispatch) => {
    dispatch(request());
    tripService.deleteRecord(tripId ? tripId : record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(tripId ? tripId : record, message, error));
          dispatch(dialogActions.success(false));
          if (orderId) {
            navigate(`/order/view/${orderId}`);
          } else {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error.toString()));
      }
    );
  };
  function request() {
    return { type: tripConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: tripConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: tripConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    tripService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: tripConstants.LOAD_RECORD, id };
  }
  function success(record) {
    return { type: tripConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function loadTripsByOrderRecords(id) {
  return (dispatch) => {
    dispatch(request(id));
    tripService.loadTripsByOrderRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, message));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: tripConstants.LOAD_TRIPSBYORDER_RECORDS, id };
  }
  function success(records, message) {
    return { type: tripConstants.LOAD_TRIPSBYORDER_RECORDS_SUCCESS, records, message };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_TRIPSBYORDER_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    tripService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (response[0].tripStatusUpdate && !record?.viaorder) {
            navigate(`/trip/view/${response[0].tripId}`);
          } else if (record?.viaorder) {
            navigate(`/trip/view/${response[0].tripId}/#id`);
          } else {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: tripConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: tripConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: tripConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadRecordsCount(params) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadRecordsCount(params).then(
      (res) => {
        const { error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_RECORDS_COUNT };
  }
  function success(counts) {
    return { type: tripConstants.LOAD_RECORDS_COUNT_SUCCESS, counts };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_RECORDS_COUNT_ERROR, error, message };
  }
}

function loadTodayRecords(params) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadTodayRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_TODAY_RECORDS };
  }
  function success(records, total) {
    return { type: tripConstants.LOAD_TODAY_RECORDS_SUCCESS, records, total };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_TODAY_RECORDS_ERROR, error, message };
  }
}

function loadUpcomingRecords(params) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadUpcomingRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_UPCOMING_RECORDS };
  }
  function success(records, total) {
    return { type: tripConstants.LOAD_UPCOMING_RECORDS_SUCCESS, records, total };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_UPCOMING_RECORDS_ERROR, error, message };
  }
}

function loadLiveRecords(params) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadLiveRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_LIVE_RECORDS };
  }
  function success(records, total) {
    return { type: tripConstants.LOAD_LIVE_RECORDS_SUCCESS, records, total };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_LIVE_RECORDS_ERROR, error, message };
  }
}

function loadCompletedRecords(params) {
  return (dispatch) => {
    dispatch(request());
    tripService.loadCompletedRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tripConstants.LOAD_COMPLETED_RECORDS };
  }
  function success(records, total) {
    return { type: tripConstants.LOAD_COMPLETED_RECORDS_SUCCESS, records, total };
  }
  function failure(error, message) {
    return { type: tripConstants.LOAD_COMPLETED_RECORDS_ERROR, error, message };
  }
}

function viewTriprecordsByOrder(id) {
  return (dispatch) => {
    dispatch(request(id));
    tripService.viewTriprecordsByOrder(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, message));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: tripConstants.VIEW_TRIPSBYORDER, id };
  }
  function success(records) {
    return { type: tripConstants.VIEW_TRIPSBYORDER_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: tripConstants.VIEW_TRIPSBYORDER_ERROR, error, message };
  }
}

export const tripActions = {
  loadRecords,
  deleteRecord,
  loadRecord,
  createRecord,
  loadTripsByOrderRecords,
  updateRecord,
  loadRecordsCount,
  createTripSheet,
  loadStatusTimelineRecords,
  loadTodayRecords,
  loadUpcomingRecords,
  loadLiveRecords,
  loadCompletedRecords,
  viewTriprecordsByOrder
};
