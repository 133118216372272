import { getDefaultParamswithoutlimitkey, } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = "noticeboard";

//Load driver records
function loadRecords(pageSize = 10, page = 0,days) {
    let queryParams = { pageSize: pageSize, page: page,days:days }
    return API.get("tennat-service", `${constantName}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).then((res) => res).catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {object} record 
 * @returns 
 */
function updateRecord(record) {
    const { label,message,ID,status } = record || {}
    return API.put("tennat-service", `${constantName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { label,message,status }) },{queryStringParameters: {id:ID}})).then((response) => response).catch((error) => Promise.reject(error))
}

function createRecord(record) {
    return API.post("tennat-service", constantName, getDefaultParamswithoutlimitkey({body: record}))
    .then((response) => {
        if (response.error) {
            let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
            throw new Error(err_msg);
        }
        return response;
    })
    .catch((error) => Promise.reject(error))
}
 
function deleteRecord(ID) {
    let queryParams = { id: ID}
    return API.del("tennat-service", `${constantName}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).then((response) => response).catch((error) => Promise.reject(error))
} 

export const noticeBoardService = {
    loadRecords,
    updateRecord,
    createRecord, 
    deleteRecord
};