import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'vehicle';
function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  const {
    bodyType,
    vehicleCapacity,
    vehicleNumber,
    vehicleName,
    insuranceId,
    insuranceExpiryDate,
    vehicleUnit,
    pollutionExpiryDate,
    fcExpiryDate,
    toPermitExpiryDate,
    permitState,
    permitState_0,
    permitState_1,
    toPermitExpiryDate_0,
    toPermitExpiryDate_1,
    VehicleStatus,
    vehicleTripStatus
  } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${record.vehicleId ? record.vehicleId : record.ID}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign(
        {},
        {
          bodyType,
          vehicleCapacity,
          vehicleNumber,
          vehicleName,
          insuranceId,
          insuranceExpiryDate,
          vehicleUnit,
          pollutionExpiryDate,
          fcExpiryDate,
          toPermitExpiryDate,
          permitState,
          permitState_0,
          permitState_1,
          toPermitExpiryDate_0,
          toPermitExpiryDate_1,
          VehicleStatus,
          vehicleTripStatus
        }
      )
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
/**
 *
 * @param {integer} id
 * @returns
 */
function deleteRecord(vehicleId) {
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${vehicleId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function tripsByVehicle(id, status) {
  let queryParam = { status: status };
  return API.get('tennat-service', `trips/vehicle/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParam }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateVehicleStatus(record) {
  const { VehicleStatus, vehicleTripStatus } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/status/${record.vehicleId}`,
    getDefaultParamswithoutlimitkey({ body: Object.assign({}, { VehicleStatus: String(VehicleStatus), vehicleTripStatus }) })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function tripsCountByVehicle(id) {
  return API.get('tennat-service', `vehicle/counts/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const vehicleService = {
  loadRecords,
  updateRecord,
  loadRecord,
  deleteRecord,
  createRecord,
  tripsByVehicle,
  updateVehicleStatus,
  tripsCountByVehicle
};
