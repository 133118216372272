import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
//const constantName = "plans";

//Load plans records
function loadRecords() { 
    return API.get("tennat-service-no-auth", 'public/test/plans',getDefaultParamswithoutlimitkey(null)).then((res) => res).catch((error) => Promise.reject(error))
}

function loadRecord(params) { 
    return API.get("tennat-service-no-auth", 'public/test/plans',getDefaultParamswithoutlimitkey(null, { queryStringParameters: params })).then((res) => res).catch((error) => Promise.reject(error))
}

export const plansService = {
    loadRecords,
    loadRecord
};