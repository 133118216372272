import { useContext } from 'react';

// table provider 
import PermissionContext from 'contexts/PermissionContext'; 

// ==============================|| HOOKS - AUTH ||============================== //

const usePermissionContext = () => {
  const context = useContext(PermissionContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default usePermissionContext;
