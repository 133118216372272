import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';
const constantName = "quality-control";


function loadQcOrderData(params) {
    return API.get('tennat-service', `${constantName}/order`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateQcOrderData(record, id) {
    return API.put('tennat-service', `${constantName}/order/${id}`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function loadQcQuoteData(params) {
    return API.get('tennat-service', `${constantName}/quote`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateQcQuoteData(record, id) {
    return API.put('tennat-service', `${constantName}/quote/${id}`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function loadQcTripData(params) {
    return API.get('tennat-service', `${constantName}/trip`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateQcTripData(record, id) {
    return API.put('tennat-service', `${constantName}/trip/${id}`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function createQcData(record) {
    return API.post('tennat-service', `${constantName}/product`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function getQcData(params) {
    return API.get('tennat-service', `${constantName}/product`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function updateQcData(record,path) {
    return API.put('tennat-service', `${constantName}/product/${path}`, getDefaultParamswithoutlimitkey({ body: record }))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

function deleteQcData(params) {
    console.log(params)
    return API.del('tennat-service', `${constantName}/${params}`, getDefaultParamswithoutlimitkey(null))
        .then((res) => res)
        .catch((error) => Promise.reject(error));
}

export const qcService = {
    loadQcOrderData,
    updateQcOrderData,
    loadQcQuoteData,
    updateQcQuoteData,
    loadQcTripData,
    updateQcTripData,
    createQcData,
    updateQcData,
    deleteQcData,
    getQcData
};