/*
 *
 *   reducer
 *
 */
import produce from 'immer'

import {
    plansConstants
} from '../_constants'

const initialState = {
    records: [],
    record: {},
    recordsMetaData: {},
    loading: false,
    error: false,
    updateError: false,
    success: false,
    lastUpdate: null,
    message: null,
    loadRecord:false
}

export default function Reducer(state = initialState, props) {

    const { type, record, records, recordsMetaData = {}, error,message } = props
    return produce(state, draft => {
        switch (type) {
            case plansConstants.LOAD_RECORDS_VALID_CACHE:
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.LOAD_RECORD:
                draft.loadRecord = true
                draft.error = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.LOAD_RECORDS:            
                draft.loading = true
                draft.error = false
                draft.updateError = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.LOAD_RECORDS_SUCCESS:
                draft.records = records
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.LOAD_RECORD_SUCCESS: 
                draft.record = record
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loadRecord = false
                draft.error = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.LOAD_RECORDS_META_DATA_SUCCESS:
                draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData)
                draft.error = false
                draft.success = false
                draft.message = "";
                break
            case plansConstants.CREATE_RECORD_SUCCESS:
                draft.records = state.records
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = message
                break
            case plansConstants.CREATE_PAYMENT_RECORD_SUCCESS:
                draft.records = [record].concat(state.records)
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case plansConstants.LOAD_RECORDS_ERROR:
            case plansConstants.LOAD_RECORD_ERROR:
                draft.loading = false
                draft.loadRecord = false
                draft.error = error
                draft.success = false
                break
            case plansConstants.CREATE_RECORD_ERROR:
                draft.loading = false
                draft.updateError = error
                draft.success = false
                draft.message = message
                draft.error = error
                break
            case plansConstants.CREATE_PAYMENT_RECORD_ERROR:
                draft.loading = false
                draft.updateError = error
                draft.success = false
                break
            case plansConstants.LOAD_RECORDS_META_DATA_ERROR:
                draft.error = error
                break
            default:
                break    
        }
    })
}

