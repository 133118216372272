import { qcService } from '../services';
import { qcConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';


function loadQcOrderData(params) {
    return (dispatch) => {
        dispatch(request());
        qcService.loadQcOrderData(params).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.LOAD_QC_ORDER_DATA };
    }
    function success(records, message, error) {
        return { type: qcConstants.LOAD_QC_ORDER_DATA_SUCCESS, records, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.LOAD_QC_ORDER_DATA_ERROR, error, message };
    }
};

function updateQcOrderData(record,id) {
    return (dispatch) => {
        dispatch(request());
        qcService.updateQcOrderData(record,id).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(qcActions.loadQcOrderData({ orderId: id }))
                    dispatch(snackbarActions(false, message));
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.UPDATE_QC_ORDER_DATA };
    }
    function success(message, error) {
        return { type: qcConstants.UPDATE_QC_ORDER_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.UPDATE_QC_ORDER_DATA_ERROR, error, message };
    }
};

function loadQcQuoteData(params) {
    return (dispatch) => {
        dispatch(request());
        qcService.loadQcQuoteData(params).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.LOAD_QC_QUOTE_DATA };
    }
    function success(records, message, error) {
        return { type: qcConstants.LOAD_QC_QUOTE_DATA_SUCCESS, records, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.LOAD_QC_QUOTE_DATA_ERROR, error, message };
    }
};

function updateQcQuoteData(record,id) {
    return (dispatch) => {
        dispatch(request());
        qcService.updateQcQuoteData(record,id).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(qcActions.loadQcQuoteData({ quoteId: id }))
                    dispatch(snackbarActions(false, message));
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.UPDATE_QC_QUOTE_DATA };
    }
    function success(message, error) {
        return { type: qcConstants.UPDATE_QC_QUOTE_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.UPDATE_QC_QUOTE_DATA_ERROR, error, message };
    }
};

function loadQcTripData(params) {
    return (dispatch) => {
        dispatch(request());
        qcService.loadQcTripData(params).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.LOAD_QC_TRIP_DATA };
    }
    function success(records, message, error) {
        return { type: qcConstants.LOAD_QC_TRIP_DATA_SUCCESS, records, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.LOAD_QC_TRIP_DATA_ERROR, error, message };
    }
};

function updateQcTripData(record,id) {
    return (dispatch) => {
        dispatch(request());
        qcService.updateQcTripData(record,id).then(
            (res) => {
                const { response, error, message, total } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(qcActions.loadQcTripData({ tripId: id }))
                    dispatch(snackbarActions(false, message));
                    dispatch(success(response, total));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
            }
        );
    };
    function request() {
        return { type: qcConstants.UPDATE_QC_TRIP_DATA };
    }
    function success(message, error) {
        return { type: qcConstants.UPDATE_QC_TRIP_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.UPDATE_QC_TRIP_DATA_ERROR, error, message };
    }
};

function createQcData(record) {
    return (dispatch) => {
        dispatch(request());
        qcService.createQcData(record).then(
            (res) => {
                const { response, error, message } = res;
                if (error) {
                    dispatch(failure(true, message));
                    dispatch(snackbarActions(true, error));
                } else {
                    dispatch(success(response, message, error));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
                dispatch(snackbarActions(true, error));
            }
        );
    };
    function request() {
        return { type: qcConstants.CREATE_QC_DATA };
    }
    function success( message, error) {
        return { type: qcConstants.CREATE_QC_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.CREATE_QC_DATA_ERROR, error, message };
    }
};

function getQcData(params) {
    return (dispatch) => {
        dispatch(request());
        qcService.getQcData(params).then(
            (res) => {
                const { response, error, message } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    const formattingResponse = {}
                    response.map((_v)=>{
                        formattingResponse[_v.grade] = _v.qcMetrics.length?_v.qcMetrics:[{ id: 0, item: '', unit: '', quantity: '', stockId: '' }]
                    })
                    dispatch(success(formattingResponse, message, error));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
                dispatch(snackbarActions(true, error));
            }
        );
    };
    function request() {
        return { type: qcConstants.GET_QC_DATA };
    }
    function success(records, message, error) {
        return { type: qcConstants.GET_QC_DATA_SUCCESS, message, error, records };
    }
    function failure(error, message) {
        return { type: qcConstants.GET_QC_DATA_ERROR, error, message };
    }
};

function updateQcData(record,path) {
    return (dispatch) => {
        dispatch(request());
        qcService.updateQcData(record,path).then(
            (res) => {
                const { response, error, message } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(success(response, message, error));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
                dispatch(snackbarActions(true, error));
            }
        );
    };
    function request() {
        return { type: qcConstants.UPDATE_QC_DATA };
    }
    function success(message, error) {
        return { type: qcConstants.UPDATE_QC_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.UPDATE_QC_DATA_ERROR, error, message };
    }
};

function deleteQcData(param) {
    return (dispatch) => {
        dispatch(request());
        qcService.deleteQcData(param).then(
            (res) => {
                const { response, error, message } = res;
                if (error) {
                    dispatch(failure(true, message));
                } else {
                    dispatch(success(response, message, error));
                }
            },
            (error) => {
                if (error && error.message) {
                    error = error.message;
                }
                dispatch(failure(true, error.toString()));
                dispatch(snackbarActions(true, error));
            }
        );
    };
    function request() {
        return { type: qcConstants.DELETE_QC_DATA };
    }
    function success(message, error) {
        return { type: qcConstants.DELETE_QC_DATA_SUCCESS, message, error };
    }
    function failure(error, message) {
        return { type: qcConstants.DELETE_QC_DATA_ERROR, error, message };
    }
};

function storeQcData(grade,payload) {
    return (dispatch) => {
        dispatch(success(grade,payload));
    };
    function success(grade,payload) {
        return { type: qcConstants.STORE_PRODUCT_QC_RECORD, grade,payload };
    }
}

function storeDefaultQCRecord(payload) {
    return (dispatch) => {
        dispatch(success(payload));
    };
    function success(payload) {
        return { type: qcConstants.STORE_DEFULT_PRODUCT_QC_RECORD, payload };
    }
}

export const qcActions = {
    loadQcOrderData,
    updateQcOrderData,
    loadQcQuoteData,
    updateQcQuoteData,
    loadQcTripData,
    updateQcTripData,
    createQcData,
    getQcData,
    updateQcData,
    deleteQcData,
    storeQcData,
    storeDefaultQCRecord
};