import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'order';

function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function updateRecord(record) {
  const {
    productId,
    // client_company,
    quantity,
    deliveryDate,
    contractorId,
    // plant,
    // plantAddress,
    deliveryAddress,
    orderStatus,
    // plant_latitude,
    // plant_longitude,
    deliveryLatitude,
    deliveryLongitude,
    productName,
    productImg,
    contractorName,
    publicProductId,
    grade,
    productUnit,
    price,
    discount,
    tax,
    taxType,
    netAmount,
    taxAmount,
    totalAmount,
    plantId,
    marketingPersonName,
    orderStatusChanged,
    statusReverted,
    orderStatusUpdate,
    aliasName,
    comments,
    reasonClose,
    customFields
  } = record || {};
  const payload = {
    productId,
    // client_company,
    quantity,
    deliveryDate,
    contractorId,
    aliasName,
    // plant,
    // plantAddress,
    deliveryAddress,
    orderStatus,
    // plant_latitude: String(plant_latitude),
    // plant_longitude: String(plant_longitude),
    deliveryLatitude: String(deliveryLatitude),
    deliveryLongitude: String(deliveryLongitude),
    // statusChanged: true,
    contractorName,
    publicProductId,
    grade,
    productUnit,
    price,
    discount,
    tax,
    taxType,
    netAmount,
    taxAmount,
    totalAmount,
    plantId,
    marketingPersonName,
    orderStatusChanged,
    productName,
    productImg,
    orderStatusUpdate,
    statusReverted,
    comments,
    reasonClose,
    customFields
  };
  return API.put('tennat-service', `${constantName}/${record.orderId}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...payload }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function deleteRecord(id) {
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

// Load the marketingPersonnames List
function loadMarketingPersonnames() {
  return API.get('tennat-service', `orders/marketingperson`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: {} }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  let { ...filterRecords } = record;
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: filterRecords }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadRecordsCount(params) {
  return API.get('tennat-service', `${constantName}s/counts`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadStatusTimelineRecords(id) {
  return API.get('tennat-service', `order/timeline/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadRecordsProductsCount(params) {
  return API.get('tennat-service', `${constantName}s/products`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadOrdersCountbyContractors(params) {
  return API.get('tennat-service', `${constantName}s/contractors`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadTodayRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadUpcomingRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadPendingRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s/pending`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadRevenueDays(params) {
  return API.get('tennat-service', `invoices/revenue`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function loadQuantityCounts(params) {
  return API.get('tennat-service', `orders/products/quantities`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
export const orderService = {
  loadRecords,
  updateRecord,
  loadRecord,
  deleteRecord,
  loadMarketingPersonnames,
  createRecord,
  loadRecordsCount,
  loadStatusTimelineRecords,
  loadRecordsProductsCount,
  loadOrdersCountbyContractors,
  loadTodayRecords,
  loadUpcomingRecords,
  loadPendingRecords,
  loadRevenueDays,
  loadQuantityCounts
};
