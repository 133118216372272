import { permissionsService } from '../services';
import { permissionsConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = "/users/permissions/list"
function loadRecords(pageSize = 5, page = 0) {
  return (dispatch) => {
    dispatch(request());
    permissionsService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message,total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response,total,page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: permissionsConstants.LOAD_RECORDS };
  }
  function success(records,total,page) {
    return { type: permissionsConstants.LOAD_RECORDS_SUCCESS, records,total,page };
  }
  function failure(error, message) {
    return { type: permissionsConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record,navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start())
    permissionsService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end())
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end())
          navigate(listUrl)
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end())
      }
    );
  };
  function request(record) {
    return { type: permissionsConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: permissionsConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: permissionsConstants.UPDATE_RECORD_ERROR, error, message };
  }
}
 
function createRecord(record,navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start())
    dispatch(request());
    permissionsService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message)); 
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end())
        } else {
          dispatch(success(response, message, error)); 
          dispatch(snackbarActions(false, message));          
          dispatch(loaderActions.end())
          navigate(listUrl)
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end())
      }
    );
  };
  function request(record) {
    return { type: permissionsConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: permissionsConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: permissionsConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    permissionsService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: permissionsConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: permissionsConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: permissionsConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record,navigate) {
  return (dispatch) => {
    dispatch(request());
    permissionsService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false))
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false))
          if(navigate){
            navigate(listUrl)
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: permissionsConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: permissionsConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: permissionsConstants.DELETE_RECORD_ERROR, error, message };
  }
} 

export const permissionsActions = {
  loadRecords,
  updateRecord, 
  createRecord,
  loadRecord,
  deleteRecord
};
