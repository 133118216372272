// project-imports
import applications from './applications'; 
import applicationEmpty from './applicationEmpty';  

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [applicationEmpty,applications]
};

export default menuItems;
