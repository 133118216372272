/**
 *
 * @param {string} name
 * @returns
 */
const url = `app/contractor`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const LOAD_SEARCH = `${url}/LOAD_SEARCH`;
const LOAD_SEARCH_SUCCESS = `${url}/LOAD_SEARCH_SUCCESS`;
const LOAD_SEARCH_ERROR = `${url}/LOAD_SEARCH_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const CREATE_ADDRESS = `${url}/CREATE_ADDRESS`;
const CREATE_ADDRESS_SUCCESS = `${url}/CREATE_ADDRESS_SUCCESS`;
const CREATE_ADDRESS_ERROR = `${url}/CREATE_ADDRESS_ERROR`;
const GET_ADDRESS = `${url}/GET_ADDRESS`;
const GET_ADDRESS_SUCCESS = `${url}/GET_ADDRESS_SUCCESS`;
const GET_ADDRESS_ERROR = `${url}/GET_ADDRESS_ERROR`;
const UPDATE_ADDRESS = `${url}/UPDATE_ADDRESS`;
const UPDATE_ADDRESS_SUCCESS = `${url}/UPDATE_ADDRESS_SUCCESS`;
const UPDATE_ADDRESS_ERROR = `${url}/UPDATE_ADDRESS_ERROR`;
const DELETE_ADDRESS = `${url}/DELETE_ADDRESS`;
const DELETE_ADDRESS_SUCCESS = `${url}/DELETE_ADDRESS_SUCCESS`;
const DELETE_ADDRESS_ERROR = `${url}/DELETE_ADDRESS_ERROR`;
const GET_ALL_ADDRESS = `${url}/GET_ALL_ADDRESS`;
const GET_ALL_ADDRESS_SUCCESS = `${url}/GET_ALL_ADDRESS_SUCCESS`;
const GET_ALL_ADDRESS_ERROR = `${url}/GET_ALL_ADDRESS_ERROR`;
const GET_ADDRESS_BY_CONTRACTOR = `${url}/GET_ADDRESS_BY_CONTRACTOR`;
const GET_ADDRESS_BY_CONTRACTOR_SUCCESS = `${url}/GET_ADDRESS_BY_CONTRACTOR_SUCCESS`;
const GET_ADDRESS_BY_CONTRACTOR_ERROR = `${url}/GET_ADDRESS_BY_CONTRACTOR_ERROR`;
const CLEAR = `${url}/CLEAR`;
const UPLOAD_CONTRACTORS = `${url}/UPLOAD_CONTRACTORS`;
const UPLOAD_CONTRACTORS_SUCCESS = `${url}/UPLOAD_CONTRACTORS_SUCCESS`;
const UPLOAD_CONTRACTORS_ERROR = `${url}/UPLOAD_CONTRACTORS_ERROR`;
const LOAD_RECORDS_COUNT = `${url}/LOAD_RECORDS_COUNT`;
const LOAD_RECORDS_COUNT_SUCCESS = `${url}/LOAD_RECORDS_COUNT_SUCCESS`;
const LOAD_RECORDS_COUNT_ERROR = `${url}/LOAD_RECORDS_COUNT_ERROR`;
const LOAD_TRANSACTION_RECORDS = `${url}/LOAD_TRANSACTION_RECORDS`;
const LOAD_TRANSACTION_RECORDS_SUCCESS = `${url}/LOAD_TRANSACTION_RECORDS_SUCCESS`;
const LOAD_TRANSACTION_RECORDS_ERROR = `${url}/LOAD_TRANSACTION_RECORDS_ERROR`;
const LOAD_PAYMENT_RECORDS = `${url}/LOAD_PAYMENT_RECORDS`;
const LOAD_PAYMENT_RECORDS_SUCCESS = `${url}/LOAD_PAYMENT_RECORDS_SUCCESS`;
const LOAD_PAYMENT_RECORDS_ERROR = `${url}/LOAD_PAYMENT_RECORDS_ERROR`;
const CREATE_PAYMENT_RECORDS = `${url}/CREATE_PAYMENT_RECORDS`;
const CREATE_PAYMENT_RECORDS_SUCCESS = `${url}/CREATE_PAYMENT_RECORDS_SUCCESS`;
const CREATE_PAYMENT_RECORDS_ERROR = `${url}/CREATE_PAYMENT_RECORDS_ERROR`;
const ORDERS_COUNT_BY_CONTRACTOR = `${url}/ORDERS_COUNT_BY_CONTRACTOR`;
const ORDERS_COUNT_BY_CONTRACTOR_SUCCESS = `${url}/ORDERS_COUNT_BY_CONTRACTOR_SUCCESS`;
const ORDERS_COUNT_BY_CONTRACTOR_ERROR = `${url}/ORDERS_COUNT_BY_CONTRACTOR_ERROR`;
const SYNC_CONTRACTOR_TALLY = `${url}/SYNC_CONTRACTOR_TALLY`;
const SYNC_CONTRACTOR_TALLY_SUCCESS = `${url}/SYNC_CONTRACTOR_TALLY_SUCCESS`;
const SYNC_CONTRACTOR_TALLY_ERROR = `${url}/SYNC_CONTRACTOR_TALLY_ERROR`;
const UPDATE_AVAILABILITY_RECORD = `${url}/UPDATE_AVAILABILITY_RECORD`;
const UPDATE_AVAILABILITY_RECORD_SUCCESS = `${url}/UPDATE_AVAILABILITY_RECORD_SUCCESS`;
const UPDATE_AVAILABILITY_RECORD_ERROR = `${url}/UPDATE_AVAILABILITY_RECORD_ERROR`;
const LOAD_AUTOFILL_ADDRESS = `${url}/LOAD_AUTOFILL_ADDRESS`;
const LOAD_AUTOFILL_ADDRESS_SUCCESS = `${url}/LOAD_AUTOFILL_ADDRESS_SUCCESS`;
const LOAD_AUTOFILL_ADDRESS_ERROR = `${url}/LOAD_AUTOFILL_ADDRESS_ERROR`;
const LOAD_TOP_PENDING_CONTRACTORS_RECORDS = `${url}/LOAD_TOP_PENDING_CONTRACTORS_RECORDS`
const LOAD_TOP_PENDING_CONTRACTORS_RECORDS_SUCCESS = `${url}/LOAD_TOP_PENDING_CONTRACTORS_RECORDS_SUCCESS`
const LOAD_TOP_PENDING_CONTRACTORS_RECORDS_ERROR = `${url}/LOAD_TOP_PENDING_CONTRACTORS_RECORDS_ERROR`
const RESET_MAP_LOCATION = `${url}/RESET_MAP_LOCATION`;
const UPDATE_PAYMENT_RECORDS = `${url}/UPDATE_PAYMENT_RECORDS`;
const UPDATE_PAYMENT_RECORDS_SUCCESS = `${url}/UPDATE_PAYMENT_RECORDS_SUCCESS`;
const UPDATE_PAYMENT_RECORDS_ERROR = `${url}/UPDATE_PAYMENT_RECORDS_ERROR`;
const DELETE_PAYMENT_RECORDS = `${url}/DELETE_PAYMENT_RECORDS`;
const DELETE_PAYMENT_RECORDS_SUCCESS = `${url}/DELETE_PAYMENT_RECORDS_SUCCESS`;
const DELETE_PAYMENT_RECORDS_ERROR = `${url}/DELETE_PAYMENT_RECORDS_ERROR`;
const RESET_DELETE_PAYMENT_RECORD  =`${url}/RESET_DELETE_PAYMENT_RECORD`
const CLEAR_TALLY_RECORD = `${url}/CLEAR_TALLY_RECORD`;
const SET_FILTERMONTH = `${url}/SET_FILTERMONTH`;
export const contractorConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  LOAD_SEARCH,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  CREATE_ADDRESS,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERROR,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_ERROR,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERROR,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  GET_ALL_ADDRESS,
  GET_ALL_ADDRESS_SUCCESS,
  GET_ALL_ADDRESS_ERROR,
  GET_ADDRESS_BY_CONTRACTOR,
  GET_ADDRESS_BY_CONTRACTOR_SUCCESS,
  GET_ADDRESS_BY_CONTRACTOR_ERROR,
  CLEAR,
  UPLOAD_CONTRACTORS,
  UPLOAD_CONTRACTORS_SUCCESS,
  UPLOAD_CONTRACTORS_ERROR,
  LOAD_RECORDS_COUNT,
  LOAD_RECORDS_COUNT_SUCCESS,
  LOAD_RECORDS_COUNT_ERROR,
  LOAD_TRANSACTION_RECORDS,
  LOAD_TRANSACTION_RECORDS_SUCCESS,
  LOAD_TRANSACTION_RECORDS_ERROR,
  LOAD_PAYMENT_RECORDS,
  LOAD_PAYMENT_RECORDS_SUCCESS,
  LOAD_PAYMENT_RECORDS_ERROR,
  CREATE_PAYMENT_RECORDS,
  CREATE_PAYMENT_RECORDS_SUCCESS,
  CREATE_PAYMENT_RECORDS_ERROR,
  ORDERS_COUNT_BY_CONTRACTOR,
  ORDERS_COUNT_BY_CONTRACTOR_SUCCESS,
  ORDERS_COUNT_BY_CONTRACTOR_ERROR,
  SYNC_CONTRACTOR_TALLY,
  SYNC_CONTRACTOR_TALLY_SUCCESS,
  SYNC_CONTRACTOR_TALLY_ERROR,
  UPDATE_AVAILABILITY_RECORD,
  UPDATE_AVAILABILITY_RECORD_SUCCESS,
  UPDATE_AVAILABILITY_RECORD_ERROR,
  LOAD_AUTOFILL_ADDRESS,
  LOAD_AUTOFILL_ADDRESS_SUCCESS,
  LOAD_AUTOFILL_ADDRESS_ERROR,
  LOAD_TOP_PENDING_CONTRACTORS_RECORDS,
  LOAD_TOP_PENDING_CONTRACTORS_RECORDS_SUCCESS,
  LOAD_TOP_PENDING_CONTRACTORS_RECORDS_ERROR,
  RESET_MAP_LOCATION,
  UPDATE_PAYMENT_RECORDS,
  UPDATE_PAYMENT_RECORDS_SUCCESS,
  UPDATE_PAYMENT_RECORDS_ERROR,
  DELETE_PAYMENT_RECORDS,
  DELETE_PAYMENT_RECORDS_SUCCESS,
  DELETE_PAYMENT_RECORDS_ERROR,
  RESET_DELETE_PAYMENT_RECORD,
  CLEAR_TALLY_RECORD,
  SET_FILTERMONTH
};
