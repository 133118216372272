import { usersService } from 'store/services/users.service';
import { usersConstants } from 'store/_constants/users.constants';
import { alertActions } from '.';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/users/list';

function loadRecords(pageSize = 10, page = 0) {
  return (dispatch) => {
    dispatch(request());
    usersService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: usersConstants.LOAD_RECORDS };
  }
  function success(userRecords, total, page) {
    return { type: usersConstants.LOAD_RECORDS_SUCCESS, userRecords, total, page };
  }
  function failure(error, message) {
    return { type: usersConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    usersService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: usersConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: usersConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: usersConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  let cloneRecord = { ...record };
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    usersService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          if (cloneRecord.image) {
            dispatch(uploadProfilePhoto(cloneRecord));
          }
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: usersConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: usersConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: usersConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    usersService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          dispatch(loaderActions.end());
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: usersConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: usersConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: usersConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  let cloneRecord = { ...record };
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    usersService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          cloneRecord = { ...response, ...cloneRecord };
          if (cloneRecord.image) {
            dispatch(uploadProfilePhoto(cloneRecord));
          }
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: usersConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: usersConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: usersConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function uploadData(records, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    usersService.uploadUsers(records).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else if (message.includes('already exists')) {
          dispatch(snackbarActions(true, message));
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: usersConstants.UPLOAD_USERS };
  }
  function success(record, message, error) {
    return { type: usersConstants.UPLOAD_USERS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: usersConstants.UPLOAD_USERS_ERROR, error, message };
  }
}

function uploadProfilePhoto(record) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    usersService.uploadProfilePhoto(record).then(
      (res) => {
        let { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          //dispatch(snackbarActions(false, message));
          if (response && response['user']) {
            response = response['user'];
          }
          dispatch(success(record, response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: usersConstants.UPLOAD_IMAGE, record };
  }
  function success(record, imageUrl, message, error) {
    return { type: usersConstants.UPLOAD_IMAGE_SUCCESS, record, imageUrl, message, error };
  }
  function failure(error, message) {
    return { type: usersConstants.UPLOAD_IMAGE_ERROR, error, message };
  }
}

function resendMsg(id) {
  return (dispatch) => {
    dispatch(request());
    usersService.resendMsg(id).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(message));
          dispatch(snackbarActions(false, message));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: usersConstants.RESEND_MSG };
  }
  function success(message) {
    return { type: usersConstants.RESEND_MSG_SUCCESS, message };
  }
  function failure(error, message) {
    return { type: usersConstants.RESEND_MSG_ERROR, error, message };
  }
}

export const usersActions = {
  loadRecords,
  loadRecord,
  updateRecord,
  deleteRecord,
  createRecord,
  uploadData,
  uploadProfilePhoto,
  resendMsg
};
