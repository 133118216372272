/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { plantConstants } from 'store/_constants/plant.constants';

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, recordsMetaData = {}, error, message } = props;

  return produce(state, (draft) => {
    switch (type) {
      case plantConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.LOAD_RECORDS:
      case plantConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.LOAD_RECORDS_SUCCESS:
        draft.records = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.UPDATE_AVAILABILITY_RECORD:
        draft.statusLoading = true;
        break;
      case plantConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.plantId === r.plantId)
          ? state.records.map((r) => (record.plantId === r.plantId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.statusLoading = false;
        break;
      case plantConstants.UPDATE_AVAILABILITY_RECORD_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.ID === r.ID)
          ? state.records.map((r) => (record.ID === r.ID ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.ID.toString() !== record.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.DELETE_RECORD:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case plantConstants.LOAD_RECORDS_ERROR:
      case plantConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case plantConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        break;
      case plantConstants.CLEAR:
        draft.error = '';
        draft.message = '';
        break;
      default:
        break;
    }
  });
}
