import { tallyService } from '../services';
import { tallyConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
// import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
// const listUrl = '/contractor/list';
// const transactionUrl = '/contractor/transactions';



function syncContractorTally(record,action) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    tallyService.syncContractorTally(record,action).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.SYNC_CONTRACTOR_TALLY };
  }
  function success(record, message, error) {
    return { type: tallyConstants.SYNC_CONTRACTOR_TALLY_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.SYNC_CONTRACTOR_TALLY_ERROR, error, message };
  }
}

function fetchTallyCompanies(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.fetchTallyCompanies().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.FETCH_TALLY_COMPANIES };
  }
  function success(tallyCompanies, message, error) {
    return { type: tallyConstants.FETCH_TALLY_COMPANIES_SUCCESS, tallyCompanies, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.FETCH_TALLY_COMPANIES_ERROR, error, message };
  }
}

function importTallyCompanies(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.importTallyCompanies().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.IMPORT_CONTRACTOR_TALLY };
  }
  function success(message, error) {
    return { type: tallyConstants.IMPORT_CONTRACTOR_TALLY_SUCCESS, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.IMPORT_CONTRACTOR_TALLY_ERROR, error, message };
  }
}

function updateTallyCompanies(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.updateTallyCompanies().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.UPDATE_CONTRACTOR_TALLY };
  }
  function success(message, error) {
    return { type: tallyConstants.UPDATE_CONTRACTOR_TALLY_SUCCESS, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.UPDATE_CONTRACTOR_TALLY_ERROR, error, message };
  }
}

function clearTallyCompanyData() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: tallyConstants.CLEAR_TALLY_RECORD };
  }
}

function syncInvoiceTally(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    tallyService.syncInvoiceTally(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.SYNC_INVOICE_TALLY };
  }
  function success(record, message, error) {
    return { type: tallyConstants.SYNC_INVOICE_TALLY_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.SYNC_INVOICE_TALLY_ERROR, error, message };
  }
}

function importTallInvoice(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.importTallInvoice().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.IMPORT_INVOICE_TALLY };
  }
  function success(message, error) {
    return { type: tallyConstants.IMPORT_INVOICE_TALLY_SUCCESS, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.UPDATE_INVOICE_TALLY_ERROR, error, message };
  }
}

function updateTallInvoice(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.updateTallInvoice().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.UPDATE_INVOICE_TALLY };
  }
  function success(message, error) {
    return { type: tallyConstants.UPDATE_INVOICE_TALLY_SUCCESS, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.UPDATE_INVOICE_TALLY_ERROR, error, message };
  }
}

function fetchTallInvoice(record) {
  return (dispatch) => {
    dispatch(request());
    tallyService.fetchTallInvoice().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));  
        } else {
          dispatch(success(response, message, error)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: tallyConstants.FETCH_TALLY_INVOICE };
  }
  function success(tallyCompanies, message, error) {
    return { type: tallyConstants.FETCH_TALLY_INVOICE_SUCCESS, tallyCompanies, message, error };
  }
  function failure(error, message) {
    return { type: tallyConstants.FETCH_TALLY_INVOICE_ERROR, error, message };
  }
}

export const tallyActions = {
  syncContractorTally,
  importTallyCompanies,
  updateTallyCompanies,
  fetchTallyCompanies,
  clearTallyCompanyData,
  syncInvoiceTally,
  importTallInvoice,
  updateTallInvoice,
  fetchTallInvoice,
};
