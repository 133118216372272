/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { vehicleConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  pageSize: null,
  page: 0,
  total: null,
  tripRecords: [],
  search: null,
  tripData: null,
  external: null,
  conditional_operator: null,
  statusLoading: false,
  tripCounts: {},
  deleteSuccess: false
};

export default function Reducer(state = initialState, props) {
  const { type, vehicleId, record, records, recordsMetaData = {}, error, message, total, search, tripRecords, page, tripData, external, tripCounts } = props;

  return produce(state, (draft) => {
    switch (type) {
      case vehicleConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.LOAD_RECORDS:
      case vehicleConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.vehicleId === v.vehicleId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.page = page;
        draft.deleteSuccess = false;
        draft.total = total;
        break;
      case vehicleConstants.VIEW_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.VIEW_RECORDS_SUCCESS:
        draft.tripRecords = tripRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.VIEW_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.search = search;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.deleteSuccess = false;
        draft.search = search;
        break;
      case vehicleConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case vehicleConstants.UPDATE_RECORD_SUCCESS:
        const ID = record.vehicle || record.ID;
        draft.records = state.records.find((r) => ID === r.vehicleId)
          ? state.records.map((r) => (ID === r.vehicleId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.vehicleId.toString() !== vehicleId.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case vehicleConstants.LOAD_RECORDS_ERROR:
      case vehicleConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.search = search;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.UPDATE_RECORD_ERROR:
      case vehicleConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case vehicleConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case vehicleConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case vehicleConstants.VEHICLE_UPDATE_BYTRIP:
        draft.records = state.records.map((r) => {
          return tripData.vehicleId === r.vehicleId ? { ...r, vehicleTripStatus: external } : r;
        });
        break;
      case vehicleConstants.UPDATE_AVAILABILITY_RECORD:
        draft.statusLoading = true;
        break;
      case vehicleConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.vehicleId === r.vehicleId)
          ? state.records.map((r) => (record.vehicleId === r.vehicleId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.statusLoading = false;
        break;
      case vehicleConstants.UPDATE_AVAILABILITY_RECORD_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case vehicleConstants.TRIPS_COUNT_BY_VEHICLE:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        draft.tripCounts = {};
        break;
      case vehicleConstants.TRIPS_COUNT_BY_VEHICLE_SUCCESS:
        draft.tripCounts = tripCounts;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case vehicleConstants.TRIPS_COUNT_BY_VEHICLE_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      default:
        break;
    }
  });
}
