import React, { useState } from 'react';
import { ListItemButton, Menu, IconButton } from '@mui/material';
import { Add } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import usePermissionContext from 'hooks/usePermissionContext';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';

const QuickAdd = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();
  const { permissions } = usePermissionContext();
  const orderPermission = permissions['order'];
  const tripPermission = permissions['trip'];
  const invoicePermission = permissions['invoice'];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddOrder = () => {
    setAnchorEl(false);
    if (orderPermission && orderPermission['post']) {
      navigate('/order/add');
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'You do not have access for this action',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  const handleAddTrip = () => {
    setAnchorEl(false);
    if (tripPermission && tripPermission['post']) {
      navigate('/trip/add');
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'You do not have access for this action',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };

  const handleAddInvoice = () => {
    setAnchorEl(false);
    if (invoicePermission && invoicePermission['post']) {
      navigate('/invoice/add/auto');
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: 'You do not have access for this action',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
<IconButton onClick={handleClick} style={{ fontSize: '34px' }}>
  <Add variant="Bold" color="#4780FB" />
</IconButton>

      <Menu
        id="wallet-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        MenuListProps={{
          'aria-labelledby': 'wallet-button',
          sx: { p: 1.25, minWidth: 150 }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        <ListItemButton onClick={handleAddOrder}>Add Order</ListItemButton>
        <ListItemButton onClick={handleAddTrip}>Add Trip</ListItemButton>
        <ListItemButton onClick={handleAddInvoice}>Add Invoice</ListItemButton>
      </Menu>
    </>
  );
};

export default QuickAdd;
