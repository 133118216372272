/**
 * 
 * @param {string} name 
 * @returns 
 */ 

const url = `app/userprofile`

const LOAD_RECORDS = `${url}/LOAD_RECORDS`
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`
const LOAD_RECORD = `${url}/LOAD_RECORD`
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`
const CREATE_RECORD = `${url}/CREATE_RECORD`
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`
const UPDATE_RECORD = `${url}/UPDATE_RECORD`
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`
const DELETE_RECORD = `${url}/DELETE_RECORD`
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`


export const userProfileConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,

}
