/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/notice`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`; 
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`; 
const NOTIFY_RECORDS = `${url}/NOTIFY_RECORDS`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const CLEAR = `${url}/CLEAR`

export const noticeBoardConstants = {
    LOAD_RECORDS,
    LOAD_RECORDS_SUCCESS,
    LOAD_RECORDS_ERROR,
    LOAD_RECORDS_VALID_CACHE,
    LOAD_RECORDS_META_DATA,
    LOAD_RECORDS_META_DATA_SUCCESS,
    LOAD_RECORDS_META_DATA_ERROR, 
    NOTIFY_RECORDS,
    CREATE_RECORD,
    CREATE_RECORD_SUCCESS,
    CREATE_RECORD_ERROR,
    UPDATE_RECORD,
    UPDATE_RECORD_SUCCESS,
    UPDATE_RECORD_ERROR,
    DELETE_RECORD,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_ERROR,
    CLEAR
  }; 