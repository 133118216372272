import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
// assets
import { MessageQuestion, } from 'iconsax-react';

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Questions = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
    const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';
    return (
        <Box sx={{ flexShrink: 0, ml: 0.5 }}>
            <IconButton
                color="secondary"
                variant="light"
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="large"
                sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
            >
                <MessageQuestion variant="Bold" color="#5B6B79" />
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                borderRadius: 1.5,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 460,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <MainCard elevation={0} border={false}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h5">FAQ (Frequently Asked Questions)</Typography>
                                    <IconButton
                                        color="secondary"
                                        variant="light"
                                        aria-label="open profile"
                                        aria-haspopup="true"
                                        onClick={handleClose}
                                        size="small"
                                        sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
                                    >
                                        X
                                    </IconButton>
                                </Stack>
                                <List
                                    component="nav"
                                    sx={{
                                        height: 'auto',
                                        mt: 1,
                                        pr: 0.5,
                                        overflowY: 'auto',
                                        '& .MuiListItemButton-root': {
                                            p: 1.5,
                                            mb: 0.5,
                                            border: `1px solid ${theme.palette.divider}`,
                                            '&:hover': {
                                                bgcolor: 'primary.lighter',
                                                borderColor: theme.palette.primary.light
                                            },
                                            '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                        }
                                    }}
                                >
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="1. How to clear the cache"
                                            secondary={
                                                <>
                                                    {" ~ Press Ctrl+Shift+Delete (Windows) or Command+Shift+Delete (Mac) to open the Clear Browsing Data menu.Select Cached images and files or similar option, then click Clear or Delete."}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="2. Remove local storage "
                                            secondary={
                                                <>
                                                    {" ~ Press Ctrl+Shift+J (Windows/Linux) or Command+Option+J (Mac).In the Application tab, find Local Storage, right-click on the relevant domain, and choose Clear or Delete."}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="3. Connectivity check"
                                            secondary={
                                                <>
                                                    {" ~ Press Ctrl+Shift+J (Windows/Linux) or Command+Option+J (Mac).Navigate to the Network tab to check for successful requests and confirm connectivity."}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="4. Find error log"
                                            secondary={
                                                <>
                                                    {" ~ Press Ctrl+Shift+J (Windows/Linux) or Command+Option+J (Mac).Navigate to the Console tab to view error messages and logs."}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="5. Find browser versions "
                                            secondary={
                                                <>
                                                    {" ~ In Chrome, Edge, or Firefox, press Alt+F (Windows) or Option+Command+I (Mac) to open the menu. Click Help and select About to view the version."}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary="6. Find system resolution"
                                            secondary={
                                                <>
                                                    {" ~ On Windows: Right-click on the desktop, select Display settings, and look for the Resolution drop-down menu."}
                                                    {"On macOS: Click on the Apple logo in the top-left corner, choose About This Mac, then click Displays to view the resolution."}
                                                </>
                                            }
                                        />
                                    </ListItem>

                                </List>
                            </MainCard>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box >
    );
};

export default Questions;