/*
 *
 *   reducer
 *
 */
import produce from 'immer'

import { userProfileConstants } from '../_constants';

const initialState = {
    records: [],
    record: {},
    recordsMetaData: {},
    loading: false,
    error: false,
    updateError: false,
    success: false,
    lastUpdate: null,
    message:null
}

export default function Reducer(state = initialState, props) {

    const { type, id, record, records, recordsMetaData = {}, error, message } = props

    return produce(state, draft => {
        switch (type) {
            case userProfileConstants.LOAD_RECORDS_VALID_CACHE:
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = ""
                break
            case userProfileConstants.LOAD_RECORDS:
            case userProfileConstants.LOAD_RECORD:
                draft.loading = true
                draft.error = false
                draft.updateError = false
                draft.success = false
                draft.message = ""
                break
            case userProfileConstants.LOAD_RECORDS_SUCCESS:
                draft.records = records
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = ""
                break
            case userProfileConstants.LOAD_RECORD_SUCCESS:
                draft.record = record
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = ""
                break
            case userProfileConstants.LOAD_RECORDS_META_DATA_SUCCESS:
                draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData)
                draft.error = false
                draft.success = false
                draft.message = ""
                break
            case userProfileConstants.CREATE_RECORD_SUCCESS:
                draft.records = [record].concat(state.records)
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message = message
                break
            case userProfileConstants.UPDATE_RECORD_SUCCESS:
                draft.records = state.records.map((r) => record.userName === r.userName ? Object.assign({}, r, record) : Object.assign({}, r))
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message=message
                break
            case userProfileConstants.DELETE_RECORD_SUCCESS:
                draft.records = state.records.filter((r) => r.contractorId.toString() !== id.toString())
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message=message
                break
            case userProfileConstants.LOAD_RECORDS_ERROR:
            case userProfileConstants.LOAD_RECORD_ERROR:
                draft.loading = false
                draft.error = error
                draft.success = false
                draft.message = message
                break
            case userProfileConstants.CREATE_RECORD_ERROR:
                draft.loading = false
                draft.error = error
                draft.success = false
                draft.message=message
                break
            case userProfileConstants.UPDATE_RECORD_ERROR:
            case userProfileConstants.DELETE_RECORD_ERROR:
                draft.loading = false
                draft.error = error               
                draft.success = false
                draft.message=message
                break
            case userProfileConstants.LOAD_RECORDS_META_DATA_ERROR:
                draft.error = error
                draft.message=message
                break
            case userProfileConstants.RESET:
                draft.error=false
                draft.message=""
                break
            default:
                break    
        }
    })
}

