import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormControl, TextField, FormLabel, ClickAwayListener, IconButton, Grid } from '@mui/material';
import { Calendar2 } from 'iconsax-react';
const DateField = ({ fieldValue, label, setFieldValue, disablePast, disabled, disableFuture, required, error, onClear = {}, clearable = false }) => {
  const [open, setOpen] = useState(false);

  const handlePickerOpen = () => {
    setOpen(true);
  };

  const handlePickerClose = () => {
    setOpen(false);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <ClickAwayListener onClickAway={handlePickerClose}>
      <FormControl fullWidth>
        <FormLabel
          sx={{
            mb: 1
          }}
          htmlFor={label}
        >
          {label} {required && <span style={{ color: '#e46a76' }}>*</span>}
        </FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            open={open}
            inputFormat="yyyy-MM-dd"
            value={fieldValue || null}
            disableFuture={disableFuture}
            disabled={disabled}
            onChange={(newValue) => {
              setFieldValue(newValue.toLocaleDateString('en-CA'));
              setOpen(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                onKeyDown={onKeyDown}
                onClick={disabled || (clearable && params.inputProps.value) ? () => {} : handlePickerOpen}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      {params.inputProps.value && clearable && !disabled ? (
                        <IconButton
                          onClick={() => {
                            onClear();
                          }}
                        >
                          X
                        </IconButton>
                      ) : (
                        ''
                      )}
                      <IconButton onClick={disabled ? () => {} : handlePickerOpen}>
                        <Calendar2 />
                      </IconButton>
                    </Grid>
                  )

                  //  params.inputProps.value ? (
                  //   <IconButton onClick={() => onClear()}>X</IconButton>
                  // ) : (
                  //   <IconButton>
                  //     <Calendar2 />
                  //   </IconButton>
                  // )
                }}
              />
            )}
            disablePast={disablePast}
          />
        </LocalizationProvider>
      </FormControl>
    </ClickAwayListener>
  );
};

export default DateField;
