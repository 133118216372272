/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/user`;

const UPLOAD_USERS = `${url}/UPLOAD_USERS`;
const UPLOAD_USERS_SUCCESS = `${url}/UPLOAD_USERS_SUCCESS`;
const UPLOAD_USERS_ERROR = `${url}/UPLOAD_USERS_ERROR`;
const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const UPLOAD_IMAGE = `${url}/UPLOAD_IMAGE`;
const UPLOAD_IMAGE_SUCCESS = `${url}/UPLOAD_IMAGE_SUCCESS`;
const UPLOAD_IMAGE_ERROR = `${url}/UPLOAD_IMAGE_ERROR`;
const RESEND_MSG = `${url}/RESEND_MSG`;
const RESEND_MSG_SUCCESS = `${url}/RESEND_MSG_SUCCESS`;
const RESEND_MSG_ERROR = `${url}/RESEND_MSG_ERROR`;
const CLEAR = `${url}/CLEAR`;

export const usersConstants = {
  UPLOAD_USERS,
  UPLOAD_USERS_SUCCESS,
  UPLOAD_USERS_ERROR,
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  RESEND_MSG,
  RESEND_MSG_SUCCESS,
  RESEND_MSG_ERROR,
  CLEAR
};
