/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { tripConstants } from '../_constants';

const initialState = {
  records: [],
  orderbyTripRecords: [],
  orderTripRecords: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  tripsheetLoad: false,
  error: false,
  message: null,
  updateError: false,
  success: false,
  lastUpdate: null,
  pageSize: null,
  page: 0,
  total: null,
  filters: null,
  fields: null,
  delivery_date: null,
  counts: null,
  todayRecords: [],
  completedRecords: [],
  liveRecords: [],
  tripQuantity: [],
  tripSheet: null,
  conditional_operator: null,
  statusTimeline: [],
  statusError:false
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, counts, recordsMetaData = {}, error, message, total, filters, page, delivery_date, statusTimeline, tripQuantity } = props;
  return produce(state, (draft) => {
    switch (type) {
      case tripConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORDS:
      case tripConstants.LOAD_TODAY_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_TRIP_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORDS_SUCCESS:
        let alRecords = '';
        page === 0 ? (alRecords = records) : (alRecords = state.records.concat(records));
        draft.records = alRecords.filter((v, i, a) => a.findIndex((v2) => v2.tripId === v.tripId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.deleteSuccess = false;
        draft.delivery_date = delivery_date;
        draft.orderTripRecords = [];
        break;
      case tripConstants.LOAD_TRIP_RECORDS_SUCCESS:
        draft.allRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_TODAY_RECORDS_SUCCESS:
        draft.todayRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case tripConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.filters = filters;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = records.length;
        draft.deleteSuccess = false;
        draft.filters = filters;
        break;
      case tripConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.filters = filters;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.CREATE_RECORD_SUCCESS:
        draft.records = record.concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case tripConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record[0].tripId === r.tripId)
          ? state.records.map((r) => (record.tripId === r.tripId ? Object.assign({}, r, record[0]) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record[0])]);
        draft.searchRecords = state.searchRecords
          ? state.searchRecords.find((r) => record[0].tripId === r.tripId)
            ? state.searchRecords.map((r) => (record.tripId === r.tripId ? Object.assign({}, r, record[0]) : Object.assign({}, r)))
            : state.searchRecords.concat([Object.assign({}, record[0])])
          : state.searchRecords;
        draft.loading = false;
        draft.error = error;
        draft.statusError = error;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.UPDATE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.statusError = false;
        draft.success = false;
        draft.message = message;
        draft.status_changed = state.records.find((r) => record.tripId === r.tripId && record.tripStatus !== r.tripStatus) ? true : false;
        break;
      case tripConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.tripId.toString() !== record.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case tripConstants.CREATE_TRIPSHEET:
        draft.tripsheetLoad = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.CREATE_TRIPSHEET_SUCCESS:
        draft.tripSheet = Array.isArray(records) && records.length ? records[0] : null;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.tripsheetLoad = false;
        draft.error = false;
        draft.success = false;
        //draft.message = message;
        break;
      case tripConstants.CREATE_TRIPSHEET_ERROR:
        draft.tripsheetLoad = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.tripsheet = [];
        break;

      case tripConstants.LOAD_TRIPSBYORDER_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_TRIPSBYORDER_RECORDS_SUCCESS:
        draft.orderbyTripRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.LOAD_TRIPSBYORDER_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;

      case tripConstants.VIEW_TRIPSBYORDER:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.VIEW_TRIPSBYORDER_SUCCESS:
        draft.orderTripRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.VIEW_TRIPSBYORDER_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORDS_ERROR:
      case tripConstants.LOAD_TODAY_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.todayRecords = [];
        break;
      case tripConstants.LOAD_RECORD_ERROR:
      case tripConstants.LOAD_TRIP_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.statusError = error;
        draft.success = false;
        draft.message = message;
        break;
      case tripConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case tripConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case tripConstants.LOAD_RECORDS_COUNT:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_RECORDS_COUNT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.counts = counts;
        break;
      case tripConstants.LOAD_RECORDS_COUNT_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.counts = 0;
        break;
      case tripConstants.LOAD_COMPLETED_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_COMPLETED_RECORDS_SUCCESS:
        draft.completedRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case tripConstants.LOAD_COMPLETED_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.completedRecords = [];
        break;
      case tripConstants.LOAD_LIVE_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_LIVE_RECORDS_SUCCESS:
        draft.liveRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case tripConstants.LOAD_LIVE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.liveRecords = [];
        break;
      case tripConstants.LOAD_UPCOMING_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_UPCOMING_RECORDS_SUCCESS:
        draft.upcomingRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case tripConstants.LOAD_UPCOMING_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.upcomingRecords = [];
        break;
      case tripConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case tripConstants.ORDERTRIPCOUNTS:
        draft.orderbyTripRecords = state.orderbyTripRecords.concat(tripQuantity);
        draft.searchRecords = state.searchRecords ? state.searchRecords.concat(tripQuantity) : state.searchRecords;
        break;
      case tripConstants.LOAD_STATUS_TIMELINE_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS:
        draft.statusTimeline = statusTimeline;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case tripConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.statusTimeline = [];
        break;
      default:
        break;
    }
  });
}
