import {  buildQuery,getDefaultParams,getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'invoice';

function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadTotalTransactions(params) {
  return API.get('tennat-service', `${constantName}/transactions`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadExceedRecord(queryParams) {
  return API.get('tennat-service', `${constantName}/exceeded`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadDashboardInvoiceRecords(queryParams) {
  return API.get('tennat-service', `${constantName}s/dashboard`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(record) {
  const { invoiceId, ID } = record;
  let queryParams = { ID };
  return API.del('tennat-service', `${constantName}/${invoiceId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}
function loadStatusTimelineRecordsInvoice(id) {
  return API.get('tennat-service', `invoice/timeline/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function createInvoiceviaTrip(record) {
  return API.post('tennat-service', `${constantName}/tripId/${record}`, getDefaultParams(null))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}


function createInvoiceviaOrder(record) {
  return API.post('tennat-service', `${constantName}/orderId/${record}`, getDefaultParams(null))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadInvoicePaymentRecords(id) {
  return API.get('tennat-service', `${'invoice'}s/${id}`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function createInvoicePaymentRecords(record) {
  return API.post('tennat-service', `invoices/contractor`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  const { invoiceId, ID, ...payloadData } = record || {};
  let queryParams = { ID };
  return API.put(
    'tennat-service',
    `${constantName}/${invoiceId}`,
    getDefaultParamswithoutlimitkey(
      {
        body: Object.assign({}, payloadData)
      },
      { queryStringParameters: queryParams }
    )
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadLastTransactionRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `contractors/payments`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadQrRecord(id) {
  return API.get('tennat-service-no-auth', `${constantName}/QR/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function syncInvoiceTally(record) {
  let queryParams = { url: record };
  return API.get('tennat-service', `${constantName}/location/url`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function createConsolidatedInvoice(record) {
  return API.post('tennat-service', `${constantName}/consolidate`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

export const invoiceService = {
  loadRecords,
  loadRecord,
  loadTotalTransactions,
  loadExceedRecord,
  loadDashboardInvoiceRecords,
  deleteRecord,
  createRecord,
  updateRecord,
  loadInvoicePaymentRecords,
  createInvoicePaymentRecords,
  loadLastTransactionRecords,
  loadQrRecord,
  syncInvoiceTally,
  createInvoiceviaTrip,
  createInvoiceviaOrder,
  loadStatusTimelineRecordsInvoice,
  createConsolidatedInvoice
};
