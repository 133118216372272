import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
  TablePagination
} from '@mui/material';
import Sockette from 'sockette';
import { Auth } from 'aws-amplify';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import config from 'aws-config';
// assets
import { MessageText1, Notification } from 'iconsax-react';
import Avatar from 'components/@extended/Avatar';
import { notificationActions, userActions } from 'store/actions';
import { dispatch, useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { useNavigate } from 'react-router';

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const NotificationPage = () => {
  const theme = useTheme();
  const { rowsPage } = useConfig();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { records, total, page, notify } = useSelector((state) => state.notification);
  const { user, compressToken } = useSelector((state) => state.sessionReducer);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [websocket, setWebsocket] = useState('');
  useEffect(() => {
    dispatch(notificationActions.loadRecords(rowsPerPage));
  }, [rowsPerPage]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setRowsPerPage(rowsPage);
  }, [rowsPage]);

  useEffect(() => {
    if (open && notify) {
      dispatch(notificationActions.loadRecords(rowsPage, 0));
      dispatch(notificationActions.clear());
    }
  }, [open]);
  const handleChangePagination = (event, value) => {
    dispatch(notificationActions.loadRecords(rowsPerPage, value));
  };
  const handleChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    dispatch(notificationActions.loadRecords(parseInt(event.target.value), 0));
  };

  useEffect(() => {
    async function getCompressedAuthToken() {
      if (user?.signInUserSession?.idToken && !websocket) {
        const session = await Auth.currentSession();
        const token = `Bearer ${session.idToken.jwtToken}`;
        dispatch(userActions.getCompressedAuthToken({ payload: token }));
      }
    }
    getCompressedAuthToken();
  }, [user]);
  useEffect(() => {
    async function connectWebsocket() {
      if (user?.signInUserSession?.idToken && !websocket && compressToken) {
        // const session = await Auth.currentSession()
        // const token = `Bearer ${session.idToken.jwtToken}`
        const ws = new Sockette(`${config.WS_URL.NOTIFICATION}?Authorization=${compressToken?.token}`, {
          timeout: 2,
          maxAttempts: 1,
          onopen: (e) => {
            console.log('Connected: ', e);
            setWebsocket(ws);
          },
          onmessage: (e) => {
            const message = JSON.parse(e.data);
            if (message.notification_response && Array.isArray(message.notification_response) && message.notification_response.length) {
              dispatch(notificationActions.notifyRecords(message));
            } else {
              console.log('websocket message is empty');
            }
            if (message.app_config_response && Object.keys(message.app_config_response).length) {
              dispatch(notificationActions.generalNotification(message.app_config_response));
            }
          },
          onreconnect: (e) => {
            console.log('Reconnecting...', e);
          },
          onmaximum: (e) => {
            console.log('Stop Attempting!', e);
          },
          onclose: (e) => {
            console.log('Closed!', e);
          },
          onerror: (e) => {
            console.log('Error:', e);
            clearInterval(websocket.interval);
            setWebsocket('');
          }
        });
      }
    }
    connectWebsocket();
  }, [user, compressToken]);

  useEffect(() => {
    if (websocket) {
      websocket.interval = setInterval(() => {
        try {
          websocket.send(
            JSON.stringify({
              tenantId: user.signInUserSession.idToken.payload['custom:tenantId'].toString(),
              action: 'sendNotification',
              userRole: user.attributes['custom:userRole'].toString()
            })
          );
        } catch (e) {
          clearInterval(websocket.interval);
        }
      }, config.APP_CONFIG.WS_INTERVAL);
      return () => {
        websocket.close();
        clearInterval(websocket.interval);
      };
    }
  }, [websocket]);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';
  const navigate = useNavigate();
  const handleNavigate = (data) => {
    if (data?.module && data?.module_Id) {
      let path = `${data?.module === 'driver' || data?.module === 'vehicle' ? '/logistics' : 
                 data?.module === 'plant' ? '/settings' : ''}/${data?.module === 'payment' ? 
                 'contractor' : `${data?.module}`}/${data?.module === 'contractor' ? 
                 'details' : data?.module === 'payment' ? 'transactions' : 'view'}`;
  
      if (data.module === 'users&Permission') {
        path = `/users/view/${data.module_Id}`;
      } else {
        path = `${path}/${data.module_Id}`;
      }
  
      navigate(path);
    } else {
      navigate('/403');
    }
    setOpen(false);
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
        sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
      >
        <Badge badgeContent={total} max={200} color="success" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
          <Notification variant="Bold" color={notify ? '#FF8A65' : 'rgb(91, 107, 121)'} />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: '100%',
                minWidth: 285,
                maxWidth: 460,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <MainCard elevation={0} border={false}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="h5">Notifications</Typography>
                  <IconButton
                    color="secondary"
                    variant="light"
                    aria-label="open profile"
                    aria-haspopup="true"
                    onClick={handleClose}
                    size="small"
                    sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
                  >
                    X
                  </IconButton>
                </Stack>
                <List
                  component="nav"
                  sx={{
                    height: records.length ? '300px' : 'auto',
                    mt: 1,
                    pr: 0.5,
                    overflowY: 'auto',
                    '& .MuiListItemButton-root': {
                      p: 1.5,
                      mb: 0.5,
                      border: `1px solid ${theme.palette.divider}`,
                      '&:hover': {
                        bgcolor: 'primary.lighter',
                        borderColor: theme.palette.primary.light
                      },
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >
                  {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification, index) => (
                    <>
                      <ListItemButton sx={{ m: 0 }} key={`list-button-${index}`} onClick={() => handleNavigate(notification)}>
                        <ListItemAvatar>
                          <Avatar type="outlined">
                            <MessageText1 size={20} variant="Bold" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          key={`list-item-text-${index}`}
                          primary={
                            <Typography variant="h6">
                              <Typography component="span" variant="subtitle1" title={notification.notification}>
                                {notification.notification}
                              </Typography>
                            </Typography>
                          }
                          secondary={`Created By ${notification.createdBy}`}
                        />
                        <ListItemSecondaryAction key={`list-secondary-action-${index}`}>
                          <Typography variant="caption" noWrap>
                            {notification.creationDate}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </>
                  ))}
                  {records.length == 0 && (
                    <ListItemText
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      primary={
                        <Typography variant="h6">
                          <Typography component="span" variant="subtitle1">
                            No Records Found
                          </Typography>
                        </Typography>
                      }
                    />
                  )}
                </List>
                {/*<Stack direction="row" justifyContent="center">
                    <Link href="#" variant="h6" color="primary">
                      View all
                    </Link>
                  </Stack>*/}
                <TablePagination
                  colSpan={3}
                  sx={{ overflow: 'hidden' }}
                  count={total ? total : Math.ceil(records.length / rowsPerPage)}
                  page={page}
                  onPageChange={handleChangePagination}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChange}
                />
              </MainCard>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default NotificationPage;
