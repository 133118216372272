
const url = `app/plans`

const LOAD_RECORDS = `${url}/LOAD_RECORDS`
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`
const LOAD_RECORD = `${url}/LOAD_RECORD`
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`
const CREATE_RECORD = `${url}/CREATE_RECORD`
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR` 
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`
const CREATE_PAYMENT_RECORD = `${url}/CREATE_PAYMENT_RECORD`
const CREATE_PAYMENT_RECORD_SUCCESS = `${url}/CREATE_PAYMENT_RECORD_SUCCESS`
const CREATE_PAYMENT_RECORD_ERROR = `${url}/CREATE_PAYMENT_RECORD_ERROR` 


export const plansConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR, 
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  CREATE_PAYMENT_RECORD,
  CREATE_PAYMENT_RECORD_SUCCESS,
  CREATE_PAYMENT_RECORD_ERROR
}


