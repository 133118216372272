import { einvoiceService } from '../services';
import { einvoiceConstants } from '../_constants';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/invoice/list/einvoiceList';
function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    einvoiceService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions.error(message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: einvoiceConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: einvoiceConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function createRecord(record, navigation) {
  return (dispatch) => {
    dispatch(request());
    einvoiceService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (!message.map) {
            dispatch(snackbarActions(true, message));
          }
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          navigation(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: einvoiceConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: einvoiceConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    einvoiceService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions.error(message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions.error(error.toString()));
      }
    );
  };
  function request(id) {
    return { type: einvoiceConstants.LOAD_RECORD, id };
  }
  function success(records) {
    return { type: einvoiceConstants.LOAD_RECORD_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function updateRecord(data) {
  return (dispatch) => {
    dispatch(request(data));
    dispatch(loaderActions.start());
    einvoiceService.updateRecord(data).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(data) {
    return { type: einvoiceConstants.UPDATE_RECORD, data };
  }
  function success(records, message, error) {
    return { type: einvoiceConstants.UPDATE_RECORD_SUCCESS, records, message, error };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadUpdateRecord() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    einvoiceService.loadUpdateRecord().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message, response[0]));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0], message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: einvoiceConstants.LOAD_UPDATE_RECORD };
  }
  function success(records, message, error) {
    return { type: einvoiceConstants.LOAD_UPDATE_RECORD_SUCCESS, records, message, error };
  }
  function failure(error, message, records) {
    return { type: einvoiceConstants.LOAD_UPDATE_RECORD_ERROR, error, message, records };
  }
}

function loadHistorybyId(id) {
  return (dispatch) => {
    dispatch(request(id));
    einvoiceService.loadHistorybyId(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          // dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, id));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID, id };
  }
  function success(records, id) {
    return { type: einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID_SUCCESS, records, id };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.LOAD_EINVOICE_HISTOTY_BYID_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    einvoiceService.deleteRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(success(response, record));
          dispatch(snackbarActions(false, message));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request(record) {
    return { type: einvoiceConstants.DELETE_RECORD, record };
  }
  function success(records, record) {
    return { type: einvoiceConstants.DELETE_RECORD_SUCCESS, records, record };
  }
  function failure(error, message) {
    return { type: einvoiceConstants.DELETE_RECORD_ERROR, error, message };
  }
}
export const einvoiceActions = {
  loadRecords,
  loadRecord,
  createRecord,
  updateRecord,
  loadUpdateRecord,
  loadHistorybyId,
  deleteRecord
};
