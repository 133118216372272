import { loaderConstants } from '../_constants';

export const loaderActions = {
    start,
    end
};
function start(ID) {
    return { type: loaderConstants.LOADING,ID };
}

function end() {
    return { type: loaderConstants.END };
}