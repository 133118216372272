/*
 *
 *   reducer
 *
 */
import produce from 'immer';
import {
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERROR,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  GET_ADDRESS_BY_CONTRACTOR,
  GET_ADDRESS_BY_CONTRACTOR_ERROR,
  GET_ADDRESS_BY_CONTRACTOR_SUCCESS,
  LOAD_TOTAL_PENDING,
  LOAD_TOTAL_PENDING_SUCCESS,
  LOAD_TOTAL_PENDING_ERROR,
  LOAD_TOTAL_PAID,
  LOAD_TOTAL_PAID_SUCCESS,
  LOAD_TOTAL_PAID_ERROR,
  LOAD_EXCEED_RECORD,
  LOAD_EXCEED_RECORD_SUCCESS,
  LOAD_EXCEED_RECORD_ERROR,
  LOAD_INVOICE_PAYMENT_RECORDS,
  LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS,
  LOAD_INVOICE_PAYMENT_RECORDS_ERROR,
  SYNC_INVOICE_TALLY,
  SYNC_INVOICE_TALLY_SUCCESS,
  SYNC_INVOICE_TALLY_ERROR,
  CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS,
  CREATE_INVOICE_PAYMENT_RECORDS_ERROR,
  LOAD_SEARCH_RECORDS,
  LOAD_SEARCH_RECORDS_SUCCESS,
  LOAD_SEARCH_RECORDS_ERROR,
  LOAD_INVOICE_DASHBOARD_RECORDS,
  LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS,
  LOAD_INVOICE_DASHBOARD_RECORDS_ERROR,
  LOAD_LAST_TRANSACTIONS_RECORDS,
  LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS,
  LOAD_LAST_TRANSACTIONS_RECORDS_ERROR,
  LOAD_QR_RECORD,
  LOAD_QR_RECORD_SUCCESS,
  LOAD_QR_RECORD_ERROR,
  CREATE_INVOICE_VIA_TRIP,
  CREATE_INVOICE_VIA_TRIP_SUCCESS,
  CREATE_INVOICE_VIA_TRIP_ERROR,
  CREATE_INVOICE_VIA_ORDER,
  CREATE_INVOICE_VIA_ORDER_SUCCESS,
  CREATE_INVOICE_VIA_ORDER_ERROR,
  CLEAR,
  CREATE_CONSOLIDATED_INVOICE,
  CREATE_CONSOLIDATED_INVOICE_SUCCESS,
  CREATE_CONSOLIDATED_INVOICE_ERROR,
  LOAD_STATUS_TIMELINE_RECORDS,
  LOAD_STATUS_TIMELINE_RECORDS_SUCCESS,
  LOAD_STATUS_TIMELINE_RECORDS_ERROR,
  UPDATE_INVOICE_RECORD,
  


} from '../_constants';

const countries = [
  { code: 'US', label: 'United States Dollar', currency: 'Dollar' },
  { code: 'GB', label: 'United Kingdom Pound', currency: 'Pound' },
  { code: 'IN', label: 'India Rupee', currency: 'Rupee' },
  { code: 'JP', label: 'Japan Yun', currency: 'Yun' }
];

const initialState = {
  records: [],
  consolidatedInvoice: [],
  record: {},
  recordsMetaData: {},
  address: [],
  selectedContractor: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  nextToken: null,
  pageSize: null,
  page: 0,
  total: null,
  totalPending: null,
  totalPaid: null,
  exceedRecord: null,
  filters: null,
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  country: countries[2],
  countries: countries,
  lists: [],
  list: null,
  alertPopup: false,
  deleteSuccess: false,
  quickOrderLoading: false,
  quickTripLoading: false,
  statusTimeline: [],
  invoiceRecord:[]
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    id,
    record,
    records,
    address,
    selectedContractor,
    recordsMetaData = {},
    error,
    message,
    page,
    total,
    nextToken,
    totalPending,
    totalPaid,
    exceedRecord,
    invoicePaymentRecords,
    filters,
    lastTransactions,
    statusTimeline,
    invoiceRecord
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case SYNC_INVOICE_TALLY:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case LOAD_RECORDS:
      case LOAD_RECORD:
      case GET_ADDRESS_BY_CONTRACTOR:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case 'GET_SELECTED_CONTRACTOR':
        draft.selectedContractor = selectedContractor;
        break;
      case LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.invoiceId === v.invoiceId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.page = page;
        draft.message = '';
        draft.nextToken = nextToken;
        draft.deleteSuccess = false;
        draft.total = total;
        break;
      case LOAD_RECORD_SUCCESS:
        draft.record = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case SYNC_INVOICE_TALLY_SUCCESS:
        let tallyRecord = state.records.concat(records);
        draft.records = tallyRecord.filter((v, i, a) => a.findIndex((v2) => v2.invoiceId === v.invoiceId) === i);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + records.length;
        break;
      case LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case CREATE_INVOICE_VIA_TRIP:
        draft.quickTripLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case CREATE_INVOICE_VIA_TRIP_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.quickTripLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case CREATE_INVOICE_VIA_ORDER:
        draft.quickOrderLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case CREATE_CONSOLIDATED_INVOICE:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case CREATE_CONSOLIDATED_INVOICE_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.consolidatedInvoice = record;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case CREATE_INVOICE_VIA_ORDER_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.quickOrderLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.invoiceId === r.invoiceId)
          ? state.records.map((r) => (record.invoiceId === r.invoiceId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r?.invoiceId?.toString() !== record.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case LOAD_RECORDS_ERROR:
      case LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case SYNC_INVOICE_TALLY_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        break;
      case CREATE_RECORD_ERROR:
      case CREATE_INVOICE_VIA_ORDER_ERROR:
        draft.quickOrderLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;
      case CREATE_INVOICE_VIA_TRIP_ERROR:
        draft.quickTripLoading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;
      case CREATE_ADDRESS_ERROR:
      case CREATE_CONSOLIDATED_INVOICE_ERROR:
      case GET_ADDRESS_BY_CONTRACTOR_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;
      case UPDATE_RECORD_ERROR:
      case DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case DELETE_ADDRESS_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case CREATE_ADDRESS_SUCCESS:
        draft.address = [address].concat(state.address);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case GET_ADDRESS_BY_CONTRACTOR_SUCCESS:
        draft.address = address;
        draft.openModal = true;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case DELETE_ADDRESS_SUCCESS:
        draft.address = state.address.filter((r) => r.addressId.toString() !== id.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case 'OPEN_MODAL':
        draft.isOpen = true;
        break;
      case 'CLOSE_MODAL':
        draft.isOpen = false;
        draft.open = false;
        break;
      case LOAD_TOTAL_PENDING:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_TOTAL_PENDING_SUCCESS:
        draft.totalPending = totalPending;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_TOTAL_PENDING_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.totalPending = 0;
        break;
      case LOAD_QR_RECORD:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_QR_RECORD_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_QR_RECORD_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.totalPending = 0;
        break;
      case LOAD_TOTAL_PAID:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_TOTAL_PAID_SUCCESS:
        draft.totalPaid = totalPaid;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_TOTAL_PAID_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.totalPaid = 0;
        break;
      case LOAD_EXCEED_RECORD:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_EXCEED_RECORD_SUCCESS:
        draft.exceedRecord = exceedRecord;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.total = total;
        draft.message = '';
        break;
      case LOAD_EXCEED_RECORD_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.exceedRecord = [];
        break;
      case LOAD_INVOICE_PAYMENT_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS:
        draft.invoicePaymentRecords = invoicePaymentRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.open = true;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_INVOICE_PAYMENT_RECORDS_ERROR:
        draft.error = true;
        draft.success = false;
        draft.message = '';
        break;
      case CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS:
        draft.invoicePaymentRecords = state.invoicePaymentRecords;
        const { invoiceId, pending, paid } = record.paidAmount[0];
        draft.records = state.records.map((r) => {
          if (r?.invoiceId?.toString() === invoiceId.toString()) {
            return {
              ...r,
              pending,
              paid
            };
          }
          return r;
        });
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case CREATE_INVOICE_PAYMENT_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_SEARCH_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.filters = filters;
        draft.success = false;
        draft.message = '';
        break;
      case LOAD_SEARCH_RECORDS_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        break;
      case LOAD_SEARCH_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.filters = filters;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_INVOICE_DASHBOARD_RECORDS:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS:
        draft.dashboardRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.filters = filters;
        draft.page = page;
        break;
      case LOAD_INVOICE_DASHBOARD_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.dashboardRecords = [];
        break;
      case LOAD_LAST_TRANSACTIONS_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS:
        draft.lastTransactions = lastTransactions;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.open = true;
        draft.loading = false;
        draft.transactionTotal = total;
        draft.error = false;
        draft.success = false;
        break;
      case LOAD_LAST_TRANSACTIONS_RECORDS_ERROR:
        draft.error = true;
        draft.success = false;
        draft.message = '';
        draft.loading = false;
        break;
      case CLEAR:
        draft.error = false;
        draft.message = '';
        draft.invoiceRecord = {}
        break;
      case LOAD_STATUS_TIMELINE_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case LOAD_STATUS_TIMELINE_RECORDS_SUCCESS:
        draft.statusTimeline = statusTimeline;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case LOAD_STATUS_TIMELINE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.statusTimeline = [];
        break;
      case UPDATE_INVOICE_RECORD:
        draft.invoiceRecord = invoiceRecord
        break;
      default:
        break;
    }
  });
}
