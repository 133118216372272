import { useState } from 'react';

// material-ui
import { Box, Grid, Stack, Typography, InputLabel, FormHelperText, TextField, Select, MenuItem, Button, OutlinedInput, InputAdornment } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
// project-imports
import IconButton from 'components/@extended/IconButton';

// assets
import { Add, Back } from 'iconsax-react';

import { dispatch, useSelector } from 'store';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const NoticeBoardAdd = ({ setAction, handleToggle, noticeBoardActions, categoryColor, categoryOptions }) => {
  const { user } = useSelector((state) => state.sessionReducer);
  const [showTextField, setShowTextField] = useState(false);
  return (
    <>
      <Box sx={{ p: 2.5 }}>
        <Formik
          initialValues={{ label: 'Delivery' }}
          onSubmit={(values) => {
            let colors = { status: categoryColor[values.label] ? categoryColor[values.label] : 'error' };
            let record = { ...values, ...colors, createdBy: user.username };
            dispatch(noticeBoardActions.createRecord(record));
            setAction('list');
          }}
          validationSchema={yup.object().shape({
            label: yup.mixed().required('Category is a required.'),
            message: yup.mixed().required('Message is a required.')
          })}
        >
          {({ values, handleSubmit, setFieldValue, touched, errors, handleChange }) => (
            <>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                <Typography variant="h5">Add new announcement</Typography>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                  <IconButton
                    color="secondary"
                    sx={{ p: 0 }}
                    onClick={() => {
                      setAction('list');
                    }}
                  >
                    <Back size="32" color="#FF8A65" />
                  </IconButton>
                  <IconButton color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
                    <Add size={28} style={{ transform: 'rotate(45deg)' }} />
                  </IconButton>
                </Stack>
              </Stack>
              <Grid container spacing={1.5} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} sx={{ p: 1 }}>
                  <Stack spacing={1}>
                    <InputLabel required>Category</InputLabel>
                    {showTextField ? (
                      <OutlinedInput
                        fullWidth
                        margin="dense"
                        name="label"
                        id="label"
                        sx={{ mt: 1 }}
                        placeholder="Enter custom category"
                        value={values.label}
                        error={errors.label}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => {
                                setShowTextField(false);
                                setFieldValue('label', 'Delivery', true);
                              }}
                            >
                              <Add size={28} style={{ transform: 'rotate(45deg)', cursor: 'pointer' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <Select
                        fullWidth
                        margin="dense"
                        name="label"
                        id="label"
                        value={values?.label}
                        sx={{ mt: 1 }}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          handleChange(e);
                          setShowTextField(selectedValue === 'Add new');
                          if (selectedValue === 'Add new') {
                            setFieldValue('label', '', true);
                          }
                        }}
                      >
                        {categoryOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                        <MenuItem value="Add new">
                          <Add size={20} style={{ marginRight: '8px' }} />
                          Add New
                        </MenuItem>
                      </Select>
                    )}
                    {touched.label && errors.label && (
                      <FormHelperText error id="noticeboard-category">
                        {errors.label}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} sx={{ p: 1 }}>
                  <Stack spacing={1}>
                    <InputLabel required>Message</InputLabel>
                    <TextField
                      required
                      multiline
                      rows={10}
                      id="message"
                      name="message"
                      placeholder="Enter announcement message"
                      sx={{ width: '100%' }}
                      type="textarea"
                      value={values?.message}
                      error={errors.message}
                      onChange={handleChange}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText error id="noticeboard-message">
                        {errors.message}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} sx={{ p: 1 }}>
                  <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      onClick={() => {
                        setAction('list');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit" fullWidth color="primary" onClick={handleSubmit}>
                      Create
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default NoticeBoardAdd;
