import { sessionService } from '../services';
import { sessionConstants } from '../_constants';
import { alertActions } from '.';
import { encryption } from 'helpers/tools';
import { snackbarActions, snackbarClose, slientSnackbar } from 'helpers/tools';
import { loaderActions } from './loader.actions';

function login(username, password, from) {
  return (dispatch) => {
    dispatch(request({ username }));
    sessionService.login(username, password).then(
      (response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(newPasswordRequired(response));
        } else {
          dispatch(success(response));
          setSession(response);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 3000);
      }
    );
  };

  function request(username) {
    return { type: sessionConstants.LOG_IN, username };
  }
  function success(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }
  function newPasswordRequired(user) {
    return { type: sessionConstants.NEW_PASSWORD_REQUIRED, user };
  }
  function failure(error, message) {
    return { type: sessionConstants.LOG_IN_ERROR, error, message };
  }
}

function logout() {
  return (dispatch) => {
    sessionService.logout();
    setSession(null);
    dispatch(sessionLogout());
  };
  function sessionLogout() {
    return { type: sessionConstants.LOG_OUT };
  }
}

function register(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    sessionService.register(record).then(
      (res) => {
        const { message, error } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
          // navigate('/login')
        } else {
          dispatch(success(message));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (navigate) {
            navigate('/login');
          }
        }
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };

  function request() {
    return { type: sessionConstants.REGISTER };
  }
  function success(message) {
    return { type: sessionConstants.REGISTER_SUCCESS, message };
  }
  function failure(error, message) {
    return { type: sessionConstants.REGISTER_ERROR, error, message };
  }
}

function loginSuccess(serviceToken) {
  return (dispatch) => {
    dispatch(success(serviceToken));
  };
  function success(serviceToken) {
    return { type: sessionConstants.LOG_IN_SUCCESS_EXIST, serviceToken };
  }
}

function isUserExists(values) {
  return async (dispatch) => {
    try {
      await sessionService.isUserExists(values);
    } catch (e) {
      if (e && e.code && e.code === 'NotAuthorizedException') {
        dispatch(snackbarActions(true, 'User exists already'));
      } else if (e && e.code && e.code === 'UserNotFoundException') {
        dispatch(slientSnackbar('User not found'));
      }
      setTimeout(() => {
        dispatch(snackbarClose());
      }, 2000);
    }
  };
}

const setSession = (response = null) => {
  if (response) {
    const serviceToken = response.signInUserSession.getIdToken().getJwtToken();
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('username', response.username);
    if (response.attributes) {
      localStorage.setItem('tennatId', response?.attributes['custom:tenantId']);
    } else {
      localStorage.setItem('tennatId', response?.signInUserSession?.idToken?.payload['custom:tenantId']);
    }
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('limitKey');
    localStorage.removeItem('Roles');
  }
};

// function loadLimitKey() {
//   return (dispatch) => {
//     sessionService.loadLimitKey().then(
//       (res) => {
//         const { response } = res;
//         const limitKey = JSON.stringify(response.planLimits).replace(/['"]+/g, '');
//         dispatch(success(limitKey));
//         localStorage.setItem('limitKey', limitKey);
//       },
//       (error) => {
//         if (error && error.message) {
//           error = error.message;
//         }
//         dispatch(failure(true, error.toString()));
//         dispatch(alertActions.error(error.toString()));
//       }
//     );
//   };
//   function success(res) {
//     return { type: sessionConstants.LOAD_LIMIT_KEY, res };
//   }
//   function failure(error, message) {
//     return { type: sessionConstants.LOAD_LIMIT_KEY_ERROR, error, message };
//   }
// }

function loadRolesPermission() {
  return (dispatch) => {
    dispatch(request());
    sessionService.loadRolesPermissions().then(
      (res) => {
        let { response } = res;
        if (response.length) {
          response = response[response.length - 1];
        }
        encryption(response);
        dispatch(success(response));
        dispatch(snackbarActions(false, 'Login successfully'));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: sessionConstants.LOAD_ROLES_PERMISSION };
  }
  function success(rolesinfo) {
    return { type: sessionConstants.LOAD_ROLES_PERMISSION_SUCCESS, rolesinfo };
  }
  function failure(error, message) {
    return { type: sessionConstants.LOAD_ROLES_PERMISSION_ERROR, error, message };
  }
}

function completeNewPassword(user, password) {
  return (dispatch) => {
    dispatch(request());
    sessionService.completeNewPassword(user, password).then(
      (response) => {
        dispatch(success(response));
        setSession(response);
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      }
    );
  };
  function request() {
    return { type: sessionConstants.COMPLETE_NEW_PASSWORD_INIT };
  }
  function success(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }
  function failure(error, message) {
    return { type: sessionConstants.LOG_IN_ERROR, error, message };
  }
}

function forgotPassword(username) {
  return (dispatch) => {
    dispatch(request());
    sessionService.forgotPassword(username).then(
      (res) => {
        dispatch(success(username));
        dispatch(snackbarActions(false, 'Code Sent Successfully'));
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
      }
    );
  };

  function request() {
    return { type: sessionConstants.FORGOT_PASSWORD };
  }
  function success(username) {
    return { type: sessionConstants.FORGOT_PASSWORD_SUCCESS, username };
  }
  function failure(error, message) {
    return { type: sessionConstants.FORGOT_PASSWORD_ERROR, error, message };
  }
}

function forgotPasswordSubmit(username, code, password, navigate) {
  return (dispatch) => {
    dispatch(request());
    sessionService.forgotPasswordSubmit(username, code, password).then(
      (res) => {
        dispatch(success(res));
        dispatch(snackbarActions(false, 'Successfully resetted'));
        setTimeout(() => {
          dispatch(snackbarClose());
        }, 2000);
        if (navigate) {
          navigate('/login');
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error, error.toString()));
      }
    );
  };

  function request() {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE };
  }
  function success(message) {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE_SUCCESS, message };
  }
  function failure(error, message) {
    return { type: sessionConstants.VERIFY_PASSWORD_CODE_ERROR, error, message };
  }
}

function setUserSession(user) {
  return (dispatch) => {
    dispatch(request(user));
  };

  function request(user) {
    return { type: sessionConstants.LOG_IN_SUCCESS, user };
  }
}

function resetLogin() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: sessionConstants.RESET_LOGIN };
  }
}

function requestInvite(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    sessionService.requestInvite(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          //dispatch(success(message));
          dispatch(snackbarActions(false, message));
          navigate('/login');
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: sessionConstants.REQUEST_INVITE };
  }
  // function success(counts) {
  //   return { type: sessionConstants.REQUEST_INVITE_SUCCESS, counts };
  // }
  function failure(error, message) {
    return { type: sessionConstants.REQUEST_INVITE_ERROR, error, message };
  }
}

function getCompressedAuthToken() {
  return (dispatch) => {
    dispatch(request());
    sessionService.getCompressedAuthToken().then(
      (res) => {
        let { response } = res;
        dispatch(success(response));
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: sessionConstants.GET_COMPRESS_AUTH };
  }
  function success(compressToken) {
    return { type: sessionConstants.GET_COMPRESS_AUTH_SUCCESS, compressToken };
  }
}
export const userActions = {
  login,
  logout,
  register,
  loginSuccess,
  // loadLimitKey,
  loadRolesPermission,
  isUserExists,
  completeNewPassword,
  forgotPasswordSubmit,
  forgotPassword,
  setUserSession,
  resetLogin,
  requestInvite,
  getCompressedAuthToken
};
