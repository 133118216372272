import { companyProfileService } from '../services';
import { companyProfileConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
function loadRecords(pageSize = 5, page = 0) {
  return (dispatch) => {
    dispatch(request());
    companyProfileService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: companyProfileConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record) {
  let cloneRecord = { ...record };
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          if (cloneRecord.image) {
            dispatch(uploadAdminProfilePhoto(cloneRecord));
          }
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPDATE_RECORD };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function uploadAdminProfilePhoto(record) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    companyProfileService.uploadAdminProfilePhoto(record).then(
      (res) => {
        let { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          //dispatch(snackbarActions(false, message));
          dispatch(success(record, response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: companyProfileConstants.UPLOAD_ADMIN_IMAGE, record };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.UPLOAD_ADMIN_IMAGE_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPLOAD_ADMIN_IMAGE_ERROR, error, message };
  }
}
function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    companyProfileService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: companyProfileConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    companyProfileService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: companyProfileConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record) {
  return (dispatch) => {
    dispatch(request());
    companyProfileService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: companyProfileConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function loadsProfile(username) {
  return (dispatch) => {
    dispatch(request());
    companyProfileService.loadsProfile(username).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          // dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.LOAD_PROFILE_RECORDS };
  }
  function success(userprofiles) {
    return { type: companyProfileConstants.LOAD_PROFILE_RECORDS_SUCCESS, userprofiles };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.LOAD_PROFILE_RECORDS_ERROR, error, message };
  }
}

function updateProfile(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.updateProfile(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPDATE_RECORD };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadPrefixRecords() {
  return (dispatch) => {
    dispatch(request());
    companyProfileService.loadPrefixRecords().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, message, error));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.LOAD_PREFIX_RECORDS };
  }
  function success(prefixRecords) {
    return { type: companyProfileConstants.LOAD_PREFIX_RECORDS_SUCCESS, prefixRecords };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.LOAD_PREFIX_RECORDS_ERROR, error, message };
  }
}

function updatePrefixRecords(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.updatePrefixRecords(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPDATE_PREFIX_RECORDS };
  }
  function success(record) {
    return { type: companyProfileConstants.UPDATE_PREFIX_RECORDS_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPDATE_PREFIX_RECORDS_ERROR, error, message };
  }
}

function uploadLogoImage(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.uploadLogoImage(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPDATE_LOGO };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.UPDATE_LOGO_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPDATE_LOGO_ERROR, error, message };
  }
}

function removeImage(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.removeImage(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.REMOVE_IMAGE };
  }
  function success(record) {
    return { type: companyProfileConstants.REMOVE_IMAGE_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.REMOVE_IMAGE_ERROR, error, message };
  }
}

function uploadBannerImage(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.uploadBannerImage(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPDATE_BANNER };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.UPDATE_BANNER_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPDATE_BANNER_ERROR, error, message };
  }
}

function uploadImage(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.uploadImage(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.UPLOAD_IMAGE };
  }
  function success(record, message, error) {
    return { type: companyProfileConstants.UPLOAD_IMAGE_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.UPLOAD_IMAGE_ERROR, error, message };
  }
}
function displayRecords() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    companyProfileService.displayRecords().then(
      (res) => {
        const { response, error, message } = res;
        console.log(res);
        if (error) {
          dispatch(failure(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(loaderActions.end());
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: companyProfileConstants.DISPLAY_RECORD };
  }
  function success(recordsDisplay) {
    return { type: companyProfileConstants.DISPLAY_RECORD_SUCCESS, recordsDisplay };
  }
  function failure(error, message) {
    return { type: companyProfileConstants.DISPLAY_RECORD_ERROR, error, message };
  }
}
export const companyProfileActions = {
  loadRecords,
  updateRecord,
  createRecord,
  loadRecord,
  deleteRecord,
  loadsProfile,
  updatePrefixRecords,
  loadPrefixRecords,
  updateProfile,
  uploadBannerImage,
  uploadLogoImage,
  uploadImage,
  removeImage,
  uploadAdminProfilePhoto,
  displayRecords
};
