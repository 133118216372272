import { Backdrop, Stack, Typography } from '@mui/material';
import Loader from "components/LoaderComponent"
const InvoiceAddSkeleton = ({ open, message = "" }) => {

  return (
    <>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >

        <Stack spacing={2}>
          <Loader />
          {message && <Typography variant="h5" color="primary">
            {message}
          </Typography>}
        </Stack>

      </Backdrop>

    </>
  );
};

export default InvoiceAddSkeleton;
