const url = `app/vehicle`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const LOAD_SEARCH = `${url}/LOAD_SEARCH`;
const LOAD_SEARCH_SUCCESS = `${url}/LOAD_SEARCH_SUCCESS`;
const LOAD_SEARCH_ERROR = `${url}/LOAD_SEARCH_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const VIEW_RECORDS = `${url}/VIEW_RECORDS`;
const VIEW_RECORDS_SUCCESS = `${url}/VIEW_RECORDS_SUCCESS`;
const VIEW_RECORDS_ERROR = `${url}/VIEW_RECORDS_ERROR`;
const CLEAR = `${url}/CLEAR`;
const VEHICLE_UPDATE_BYTRIP = `${url}/VEHICLE_UPDATE_BYTRIP`;
const UPDATE_AVAILABILITY_RECORD = `${url}/UPDATE_AVAILABILITY_RECORD`;
const UPDATE_AVAILABILITY_RECORD_SUCCESS = `${url}/UPDATE_AVAILABILITY_RECORD_SUCCESS`;
const UPDATE_AVAILABILITY_RECORD_ERROR = `${url}/UPDATE_AVAILABILITY_RECORD_ERROR`;
const TRIPS_COUNT_BY_VEHICLE = `${url}/TRIPS_COUNT_BY_VEHICLE`;
const TRIPS_COUNT_BY_VEHICLE_SUCCESS = `${url}/TRIPS_COUNT_BY_VEHICLE_SUCCESS`;
const TRIPS_COUNT_BY_VEHICLE_ERROR = `${url}/TRIPS_COUNT_BY_VEHICLE_ERROR`;

export const vehicleConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  LOAD_SEARCH,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  VIEW_RECORDS,
  VIEW_RECORDS_SUCCESS,
  VIEW_RECORDS_ERROR,
  VEHICLE_UPDATE_BYTRIP,
  CLEAR,
  UPDATE_AVAILABILITY_RECORD,
  UPDATE_AVAILABILITY_RECORD_SUCCESS,
  UPDATE_AVAILABILITY_RECORD_ERROR,
  TRIPS_COUNT_BY_VEHICLE,
  TRIPS_COUNT_BY_VEHICLE_SUCCESS,
  TRIPS_COUNT_BY_VEHICLE_ERROR
};
