import { invoiceService } from '../services';
import { invoiceConstants } from '../_constants';
import { snackbarActions,  CREATE_SUCCESS, getLimitKey } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
import { alertActions } from '.';
import { manageInvoiceRecord } from '../../utils/invoiceUtils';
const listUrl = '/invoice/list/all';

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: invoiceConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_RECORDS_ERROR, error, message };
  }
}
function loadStatusTimelineRecordsInvoice(id) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadStatusTimelineRecordsInvoice(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_STATUS_TIMELINE_RECORDS };
  }
  function success(statusTimeline) {
    return { type: invoiceConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS, statusTimeline };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    invoiceService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(id) {
    return { type: invoiceConstants.LOAD_RECORD, id };
  }
  function success(records) {
    return { type: invoiceConstants.LOAD_RECORD_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function loadTotalPendingAmount(params) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadTotalTransactions(params).then(
      (res) => {
        const { error, message, totalPending } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(totalPending));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_TOTAL_PENDING };
  }
  function success(totalPending) {
    return { type: invoiceConstants.LOAD_TOTAL_PENDING_SUCCESS, totalPending };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_TOTAL_PENDING_ERROR, error, message };
  }
}

function loadTotalRevenue(params) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadTotalTransactions(params).then(
      (res) => {
        const { error, message, totalPaid } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(totalPaid));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_TOTAL_PAID };
  }
  function success(totalPaid) {
    return { type: invoiceConstants.LOAD_TOTAL_PAID_SUCCESS, totalPaid };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_TOTAL_PAID_ERROR, error, message };
  }
}

function syncInvoiceTally(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    invoiceService.syncInvoiceTally(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.SYNC_INVOICE_TALLY };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.SYNC_INVOICE_TALLY_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.SYNC_INVOICE_TALLY_ERROR, error, message };
  }
}

function loadExceedRecord(pageSize = 10, page = 0) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadExceedRecord(pageSize, page).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_EXCEED_RECORD };
  }
  function success(exceedRecord, total) {
    return { type: invoiceConstants.LOAD_EXCEED_RECORD_SUCCESS, exceedRecord, total };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_EXCEED_RECORD_ERROR, error, message };
  }
}

function loadDashboardInvoiceRecords(params) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.loadDashboardInvoiceRecords(params).then(
      (res) => {
        const { error, message, response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_INVOICE_DASHBOARD_RECORDS };
  }
  function success(records) {
    return { type: invoiceConstants.LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_INVOICE_DASHBOARD_RECORDS_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(invoiceConstants.error(false));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: invoiceConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: invoiceConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    invoiceService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: invoiceConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadInvoicePaymentRecords(id) {
  return (dispatch) => {
    dispatch(request(id));
    dispatch(loaderActions.start());
    invoiceService.loadInvoicePaymentRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: invoiceConstants.LOAD_INVOICE_PAYMENT_RECORDS, id };
  }
  function success(invoicePaymentRecords) {
    return { type: invoiceConstants.LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS, invoicePaymentRecords };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_INVOICE_PAYMENT_RECORDS_ERROR, error, message };
  }
}

function createInvoicePaymentRecords(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    invoiceService.createInvoicePaymentRecords(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(-1);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.CREATE_INVOICE_PAYMENT_RECORDS };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.CREATE_INVOICE_PAYMENT_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    invoiceService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success({ ...response, invoiceId: record.invoiceId }, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function success(record, message, error) {
    return { type: invoiceConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadLastTransactionRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    invoiceService.loadLastTransactionRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: invoiceConstants.LOAD_LAST_TRANSACTIONS_RECORDS };
  }
  function success(lastTransactions, total, page) {
    return { type: invoiceConstants.LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS, lastTransactions, total, page };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_LAST_TRANSACTIONS_RECORDS_ERROR, error, message };
  }
}



function loadQrRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    invoiceService.loadQrRecord(id).then(
      (res) => {
        const { error,message,response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(id) {
    return { type: invoiceConstants.LOAD_QR_RECORD, id };
  }
  function success(records) {
    return { type: invoiceConstants.LOAD_QR_RECORD_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: invoiceConstants.LOAD_QR_RECORD_ERROR, error, message };
  }
}

function createConsolidatedInvoice(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    invoiceService.createConsolidatedInvoice(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString())); 
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: invoiceConstants.CREATE_CONSOLIDATED_INVOICE, record };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.CREATE_CONSOLIDATED_INVOICE_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.CREATE_CONSOLIDATED_INVOICE_ERROR, error, message };
  }
}

function createInvoiceviaOrder(record) {
  return (dispatch) => {
      dispatch(loaderActions.start());
    dispatch(request());
    invoiceService.createInvoiceviaOrder(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          getLimitKey();
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_ORDER, record };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_ORDER_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_ORDER_ERROR, error, message };
  }
}

function createInvoiceviaTrip(record) {
  return (dispatch) => {
      dispatch(loaderActions.start());
    dispatch(request());
    invoiceService.createInvoiceviaTrip(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          getLimitKey();
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_TRIP, record };
  }
  function success(record, message, error) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_TRIP_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: invoiceConstants.CREATE_INVOICE_VIA_TRIP_ERROR, error, message };
  }
}

function updateInvoiceRecord(action,invoiceRecord,index) {
  return (dispatch,getState) => {
    const {invoiceRecord:prevInvoiceRecord} = getState().invoice
    invoiceRecord = manageInvoiceRecord(invoiceRecord,prevInvoiceRecord,action,index)
    dispatch(success(invoiceRecord)); 
  }; 
  function success(invoiceRecord) {
    return { type: invoiceConstants.UPDATE_INVOICE_RECORD, invoiceRecord };
  }
}

function clear() {
  return (dispatch) => {
    dispatch(success()); 
  }; 
  function success() {
    return { type: invoiceConstants.CLEAR };
  }
}


export const invoiceActions = {
  loadRecords,
  loadRecord,
  loadTotalRevenue,
  loadTotalPendingAmount,
  loadExceedRecord,
  syncInvoiceTally,
  loadDashboardInvoiceRecords,
  deleteRecord,
  createRecord,
  updateRecord,
  loadInvoicePaymentRecords,
  createInvoicePaymentRecords,
  loadLastTransactionRecords,
  loadQrRecord,
  createInvoiceviaTrip,
  createConsolidatedInvoice,
  createInvoiceviaOrder,
  loadStatusTimelineRecordsInvoice,
  updateInvoiceRecord,
  clear,
  
};
