/*
 *
 *  remotes
 *
 */

import { getDefaultParamswithoutlimitkey } from 'helpers/tools'
import { API } from "aws-amplify";
const constantName = "permission"

function loadRecords() {
    return API.get("tennat-service", `${constantName}s`, getDefaultParamswithoutlimitkey()).then((res) => res).catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {string} metaData 
 * @returns 
 */
function loadRecordsMetaData(metaData) {
    return API.get("tennat-service", metaData, getDefaultParamswithoutlimitkey()).then((response) => response.data).catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {integer} id 
 * @returns 
 */
function loadRecord(id) {
    return API.get("tennat-service", `${constantName}`, getDefaultParamswithoutlimitkey()).then((response) => response.response).catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {object} record 
 * @returns 
 */
function createRecord(record) {
    return API.post("tennat-service", constantName, getDefaultParamswithoutlimitkey({body: record}))
    .then((response) => {
        if (response.error) {
            let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
            throw new Error(err_msg);
        }
        return response;
    })
    .catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {object} record 
 * @returns 
 */
function updateRecord(record) {
    const { api_access } = record || {}
    return API.put("tennat-service", `${constantName}`, getDefaultParamswithoutlimitkey({body: Object.assign({}, {api_access})})).then((response) => response).catch((error) => Promise.reject(error))
}

/**
 * 
 * @param {integer} id 
 * @returns 
 */
function deleteRecord(id) {
    return API.del("tennat-service", `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error))
}


export const permissionsService = {
    loadRecords,
    loadRecordsMetaData,
    loadRecord,
    createRecord,
    updateRecord,
    deleteRecord
} 