/*
 *
 *  remotes
 *
 */

import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';
const constantName = 'user';

function loadRecords() {
  return API.get('admin-service', `${constantName}s`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} metaData
 * @returns
 */
function loadRecordsMetaData(metaData) {
  return API.get('admin-service', metaData, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function loadRecord(id) {
  return API.get('admin-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createRecord(record) {
  return API.post('admin-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  const { userName, userPhone, userEmail, userRole } = record || {};
  return API.put(
    'admin-service',
    `${constantName}/${userName}`,
    getDefaultParamswithoutlimitkey({ body: Object.assign({}, { userPhone, userEmail, userRole }) })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function deleteRecord(userName) {
  debugger;
  return API.del('admin-service', `${constantName}/${userName.userName}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
async function uploadProfilePhoto(record) {
  const fileReaderPromise = () => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(record.image);
      fileReader.onload = () => {
        let srcData = fileReader.result;
        record.userPicture = srcData;
        srcData = srcData.substring(srcData.indexOf(',') + 1, srcData.length);
        resolve(srcData);
      };
    });
  };
  let queryParams = { module: 'user', userName: record.userName };
  const srcData = await fileReaderPromise();
  return API.post(
    'tennat-service',
    `${'companyprofile'}/${'uploadimage'}`,
    getDefaultParamswithoutlimitkey(null, { body: srcData, queryStringParameters: queryParams })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function resendMsg(record) {
  const queryParams = { username: record };
  return API.post('tennat-service', `${'companyprofile'}/resendpassword`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function uploadUsers(records) {
  return API.post('admin-service', `${constantName}/upload`, getDefaultParamswithoutlimitkey({ body: records }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const usersService = {
  loadRecords,
  loadRecordsMetaData,
  loadRecord,
  createRecord,
  updateRecord,
  deleteRecord,
  uploadProfilePhoto,
  resendMsg,
  uploadUsers
};
