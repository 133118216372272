import { createContext, useState } from 'react';  
import useConfig from 'hooks/useConfig';
const TableContext = createContext(null);


export const TableContextProvider = ({ children }) => {
  const { rowsPage } = useConfig();
  const tableInitialState = {
    quote: {
      showCalendarFilter: true,
      showCalendar: false,
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    order: {
      showCalendarFilter: true,
      showCalendar: false,
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    trip: {
      showCalendarFilter: true,
      showCalendar: false,
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    driver: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    vehicle: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    products: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    stock: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    stockgroup: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    adjustment: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    users: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {}, globalFilterValue: '', globalAutoFocus: false, columnFilters: {} },
    invoiceAll: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false,
      showCalendar: false,
      showCalendarFilter: true
    },
    invoicePaid: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false,
      showCalendar: false,
      showCalendarFilter: true
    },
    invoiceUnpaid: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false,
      showCalendar: false,
      showCalendarFilter: true
    },
    invoiceOverdue: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false,
      showCalendar: false,
      showCalendarFilter: true
    },
    einvoice: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false,
      showCalendar: false,
      showCalendarFilter: false
    },
    exceedLimit: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    contractor: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    pendingOrders: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    todayOrders: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    upcomingOrders: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    yesterdayOrders: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    yesterdayTrips: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    liveTrips: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    todayTrips: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    completedTrips: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    upcomingTrips: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    drivingLicenseExpiry: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    permitExpiry: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    insuranceExpiry: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    fcExpiry: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    pollutionExpiry: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    lastTransaction: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    topPendingCustomers: { pageSize: rowsPage, page: 0, queryParams: {}, filters: {} },
    default: {
      pageSize: rowsPage,
      page: 0,
      queryParams: {},
      filters: {},
      globalFilterValue: '',
      globalAutoFocus: false,
      columnFilters: {},
      showEachFilter: false
    },
    selectedInvoiceTab: 0
  };
  const [tableConfig,setTableConfig] = useState(tableInitialState)  
  const onChangeCalendar = (contextKey,showCalendar) => {
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],showCalendar:showCalendar,currentDate:null}}})
  };
  const onChangePagination = (contextKey,params) => {
      let {pageSize,page,filters,queryParams,globalFilterValue,rowsPage,globalAutoFocus,columnFilters,showEachFilter=false,showCalendar=false} = params
      let config = { 
          ...{[contextKey]:{
              ...tableConfig[contextKey],
              pageSize,
              page,
              filters,
              queryParams,
              globalFilterValue,
              globalAutoFocus,
              rowsPage,
              columnFilters,
              showEachFilter,
              showCalendar
          }}}  
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
      
  }
  const onChangePageSize = (contextKey,params) => {
      const {pageSize} = params
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],pageSize}}})
  }
  const onChangeGlobalFilterValue = (contextKey,globalFilterValue) => { 
       let config = { 
         ...{[contextKey]:{...tableConfig[contextKey],globalFilterValue}}
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onChangeInvoiceTab = (invoiceTab) => { 
       let config = { 
         selectedInvoiceTab:invoiceTab
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onChangeColumnFilterValue = (contextKey,id,value) => { 
       let config = { 
         ...{[contextKey]:{...tableConfig[contextKey],...{["columnFilters"]:{...tableConfig[contextKey].columnFilters,[id]:value}}}}
       }
       setTableConfig(prevTableConfig => {
            return {...prevTableConfig, ...config}
        });
  }
  const onResetContext = () => {
      setTableConfig({...tableInitialState})
      return tableInitialState
  };
  const onChangeCalendarDate = (contextKey,currentDate) => {
      setTableConfig({...tableConfig,...{[contextKey]:{...tableConfig[contextKey],currentDate:currentDate}}})
  }; 
  return (
    <TableContext.Provider value={{tableConfig,onChangeCalendar,onResetContext,onChangeCalendarDate,onChangePagination,onChangePageSize,onChangeGlobalFilterValue,onChangeColumnFilterValue,onChangeInvoiceTab}}>
      {children}
    </TableContext.Provider>
  );
};

export default TableContext;
