import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';
import config from 'aws-config';
//constant key
const constantName = 'stock';

//Load stock records
function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadGroupRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s/group`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function loadStockAdjustments(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s/inventery`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  return API.put(
    'tennat-service',
    `${constantName}/${record.ID}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign({}, { ...record })
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
/**
 *
 * @param {object} record
 * @returns
 */
function updateStockGroups(record) {
  return API.put(
    'tennat-service',
    `${constantName}/group/${record.ID}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign({}, { ...record })
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
/**
 *
 * @param {object} record
 * @returns
 */
function updateStockAdjustment(record) {
  return API.put('tennat-service', `${constantName}/adjustment/${record.ID}`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function createStockGroups(record) {
  return API.post('tennat-service', `${constantName}/group`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function createStockAdjustment(record) {
  return API.post('tennat-service', `${constantName}/adjustment`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function uploadStockAdjustmentInfo(record) {
  const { id, files } = record;
  return API.put('tennat-service', `${constantName}/adjustment/fileupload/${id}`, getDefaultParamswithoutlimitkey({ body: { files: files } }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(record) {
  const { stockId } = record;
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${stockId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function deleteStockGroups(record) {
  const { stockId } = record;
  let queryParams = {};
  return API.del('tennat-service', `${constantName}/group/${stockId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function deleteAdjustment(record) {
  console.log(record);
  const { adjustmentId } = record;
  let queryParams = {};
  return API.del('tennat-service', `${constantName}/adjustment/${adjustmentId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

async function loadOptions() {
  const response = await fetch(config.STOCK_OPTIONS_URL);
  const options = await response.json();
  return options.BodyType;
}

function loadStocksbyGroupId(id) {
  return API.get('tennat-service', `${constantName}/group/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadAdjustmentbyId(id) {
  return API.get('tennat-service', `${constantName}/adjustment/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteAdjustmentFiles(record) {
  const { id, name, columnID } = record;
  return API.del('tennat-service', `${constantName}/adjustment/fileupload/${id}`, getDefaultParamswithoutlimitkey({ body: { name: name, columnID: columnID } }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}


function deleteAdjustmentStock(record) {
  const { adjustmentId, ID } = record;
  let queryParams = { adjustmentId: adjustmentId };
  return API.del('tennat-service', `${constantName}/adjustment/history/${ID}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const stockService = {
  loadRecords,
  updateRecord,
  createRecord,
  loadRecord,
  deleteRecord,
  loadGroupRecords,
  createStockGroups,
  updateStockGroups,
  deleteStockGroups,
  loadStockAdjustments,
  loadOptions,
  loadStocksbyGroupId,
  loadAdjustmentbyId,
  createStockAdjustment,
  deleteAdjustment,
  updateStockAdjustment,
  uploadStockAdjustmentInfo,
  deleteAdjustmentFiles,
  deleteAdjustmentStock
};
