import { Link } from 'react-router-dom';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //
import { useSelector } from 'store';
const LogoIcon = ({ src, navigate = '/dashboard/general' }: { src?: string; navigate?: string }) => {
  const { logoImage } = useSelector((state) => state.companyprofile);

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <Link to={navigate}>
      {logoImage ? (
        <img alt="Logo" src={src} style={{ width: '80px', padding: '8px', borderRadius: '50%' }} />
      ) : (
        <img alt="Logo" src={src} style={{ width: '80px', padding: '8px' }} />
      )}
    </Link>
  );
};

export default LogoIcon;
