import { useEffect,useState,useMemo } from 'react';

// material-ui
import { Alert } from '@mui/material';
import { useSelector } from 'store';
// assets
import { Timer  } from 'iconsax-react'; 

const Maintenance = () => {
  const { generalnotification } = useSelector((state) => state.notification);
  const [showMaintanenceWindow, setShowMaintanenceWindow] = useState(false);
  const [maintainenceMessage, setMaintainenceMessage] = useState("");
  const MemoMaintenanceComponent = useMemo(
    () => (
       <Alert color="error" sx={{ width: '100%',"fontSize":"12px"} } variant="filled" icon={<Timer variant="Bold" />}> {maintainenceMessage} </Alert>
    ),
    [showMaintanenceWindow,generalnotification,maintainenceMessage]
  );
  useEffect(()=>{ 
      if(generalnotification){
          let data = generalnotification || {}
          if(generalnotification && typeof generalnotification=='string'){
              data = JSON.parse(generalnotification).maintainence
          }
          const {enabled,message} = data 
          if(enabled){
              setShowMaintanenceWindow(true)
              setMaintainenceMessage(message)
          }
      }
  },[generalnotification])
  return <>
    { showMaintanenceWindow ? MemoMaintenanceComponent :""}
  </>
}


  

export default Maintenance;
