import { userProfileService } from '../services';
import { userProfileConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';

function loadRecords(pageSize = 5, page = 0) {
  return (dispatch) => {
    dispatch(request());
    userProfileService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message,total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response,total,page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: userProfileConstants.LOAD_RECORDS };
  }
  function success(records,total,page) {
    return { type: userProfileConstants.LOAD_RECORDS_SUCCESS, records,total,page };
  }
  function failure(error, message) {
    return { type: userProfileConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start())
    userProfileService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end())
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end())
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end())
      }
    );
  };
  function request(record) {
    return { type: userProfileConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: userProfileConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: userProfileConstants.UPDATE_RECORD_ERROR, error, message };
  }
} 

function createRecord(record) {
  return (dispatch) => {
    dispatch(loaderActions.start())
    dispatch(request());
    userProfileService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message)); 
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end())
        } else {
          dispatch(success(response, message, error)); 
          dispatch(snackbarActions(false, message));          
          dispatch(loaderActions.end())
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end())
      }
    );
  };
  function request(record) {
    return { type: userProfileConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: userProfileConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: userProfileConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    userProfileService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: userProfileConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: userProfileConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: userProfileConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record) {
  return (dispatch) => {
    dispatch(request());
    userProfileService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false))
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false))
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: userProfileConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: userProfileConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: userProfileConstants.DELETE_RECORD_ERROR, error, message };
  }
}


export const userProfileActions = {
  loadRecords,
  updateRecord,
  createRecord,
  loadRecord,
  deleteRecord
};
