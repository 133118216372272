import { stockService } from '../services';
import { stockConstants } from '../_constants';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/stock/list';
const groupListUrl = '/stock/groups';
const adjustmentListUrl = '/stock/adjustments';
function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    stockService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: stockConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: stockConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadGroupRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    stockService.loadGroupRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: stockConstants.LOAD_GROUPS_RECORD };
  }
  function success(groups, total, page) {
    return { type: stockConstants.LOAD_GROUPS_RECORD_SUCCESS, groups, total, page };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_GROUPS_RECORD_ERROR, error, message };
  }
}

function loadStockAdjustments(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    stockService.loadStockAdjustments(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: stockConstants.LOAD_ADJUSTMENTS };
  }
  function success(adjacementRecords, total, page) {
    return { type: stockConstants.LOAD_ADJUSTMENTS_SUCCESS, adjacementRecords, total, page };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_ADJUSTMENTS_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    stockService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(success(response));
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response[0]));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function updateStockGroups(record, navigate) {
  let { viewStockGroups, ...filterRecord } = record;
  return (dispatch) => {
    dispatch(request(filterRecord));
    dispatch(loaderActions.start());
    stockService.updateStockGroups(filterRecord).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(success(response));
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response[0]));
          dispatch(loaderActions.end());
          if (viewStockGroups) {
            navigate(`/stock/groups/view/${record.ID}`, { state: record });
          } else {
            navigate(groupListUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.UPDATE_GROUPS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.UPDATE_GROUPS_ERROR, error, message };
  }
}

function updateStockAdjustment(record, navigate) {
  let { files, ...filterRecord } = record;
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    stockService.updateStockAdjustment(filterRecord, navigate).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(success(response));
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response[0], message, error));
          dispatch(loaderActions.end());
          navigate(adjustmentListUrl);
          let data = { id: response[0]?.ID, files: files };
          dispatch(files.length && uploadStockAdjustmentInfo(data, navigate));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(adjustmentListUrl);
        } else {
          dispatch(snackbarActions(true, error));
          navigate(adjustmentListUrl);
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.UPDATE_ADJUSTMENTS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.UPDATE_ADJUSTMENTS_ERROR, error, message };
  }
}

function createStockAdjustment(record, navigate) {
  let { files, ...filterRecord } = record;
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    stockService.createStockAdjustment(filterRecord, navigate).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
          navigate(adjustmentListUrl, { state: { status: CREATE_SUCCESS } });
          let data = {id:response[0]?.ID,files:files}
          dispatch(files.length && uploadStockAdjustmentInfo(data,navigate))
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(adjustmentListUrl);
        } else {
          dispatch(snackbarActions(true, error));
          navigate(adjustmentListUrl);
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.CREATE_ADJUSTMENTS, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.CREATE_ADJUSTMENTS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.CREATE_ADJUSTMENTS_ERROR, error, message };
  }
}

function uploadStockAdjustmentInfo(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    stockService.uploadStockAdjustmentInfo(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(adjustmentListUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(adjustmentListUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.UPLOAD_ADJUSTMENTS_FILE, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.UPLOAD_ADJUSTMENTS_FILE_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.UPLOAD_ADJUSTMENTS_FILE_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    stockService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function createStockGroups(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    stockService.createStockGroups(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(groupListUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: stockConstants.CREATE_GROUPS, record };
  }
  function success(record, message, error) {
    return { type: stockConstants.CREATE_GROUPS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: stockConstants.CREATE_GROUPS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    stockService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: stockConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: stockConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    stockService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: stockConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: stockConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: stockConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function deleteAdjustmentStock(record) {
  return (dispatch) => {
    dispatch(request());
    stockService.deleteAdjustmentStock(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: stockConstants.DELETE_ADJUSTMENTSTOCK };
  }
  function success(record) {
    return { type: stockConstants.DELETE_ADJUSTMENTSTOCK_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: stockConstants.DELETE_ADJUSTMENTSTOCK_ERROR, error, message };
  }
}
function deleteStockGroups(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    stockService.deleteStockGroups(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(groupListUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: stockConstants.DELETE_RECORD };
  }
  function success(record) {
    return { type: stockConstants.DELETE_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: stockConstants.DELETE_RECORD_ERROR, error, message };
  }
}
function deleteAdjustment(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    stockService.deleteAdjustment(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(adjustmentListUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: stockConstants.DELETE_ADJUSTMENT };
  }
  function success(record) {
    return { type: stockConstants.DELETE_ADJUSTMENT_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: stockConstants.DELETE_ADJUSTMENT_ERROR, error, message };
  }
}

function loadOptions() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    stockService.loadOptions().then(
      (res) => {
        if (!res) {
          dispatch(failure(true));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(res));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: stockConstants.LOAD_OPTIONS };
  }
  function success(records) {
    return { type: stockConstants.LOAD_OPTIONS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_OPTIONS_ERROR, error, message };
  }
}
function loadStocksbyGroupId(groupId) {
  return (dispatch) => {
    dispatch(request(groupId));
    stockService.loadStocksbyGroupId(groupId).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          // dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, groupId));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(groupId) {
    return { type: stockConstants.LOAD_STOCK_BYGROUPID, groupId };
  }
  function success(records, groupId) {
    return { type: stockConstants.LOAD_STOCK_BYGROUPID_SUCCESS, records, groupId };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_STOCK_BYGROUPID_ERROR, error, message };
  }
}

function loadAdjustmentbyId(id) {
  return (dispatch) => {
    dispatch(request(id));
    stockService.loadAdjustmentbyId(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          // dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: stockConstants.LOAD_ADJUSTMENT_BYID, id };
  }
  function success(records) {
    return { type: stockConstants.LOAD_ADJUSTMENT_BYID_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: stockConstants.LOAD_ADJUSTMENT_BYID_ERROR, error, message };
  }
}

function deleteAdjustmentFiles(record) {
  return (dispatch) => {
    dispatch(request());
    stockService.deleteAdjustmentFiles(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: stockConstants.DELETE_ADJUSTMENT_FILES };
  }
  function success(record, error, message) {
    return { type: stockConstants.DELETE_ADJUSTMENT_FILES_SUCCESS, record, error, message };
  }
  function failure(error, message) {
    return { type: stockConstants.DELETE_ADJUSTMENT_FILES_ERROR, error, message };
  }
}

export const stockActions = {
  loadRecords,
  updateRecord,
  createRecord,
  loadRecord,
  deleteRecord,
  loadGroupRecords,
  createStockGroups,
  updateStockGroups,
  deleteStockGroups,
  loadStockAdjustments,
  loadOptions,
  loadStocksbyGroupId,
  createStockAdjustment,
  deleteAdjustment,
  updateStockAdjustment,
  loadAdjustmentbyId,
  uploadStockAdjustmentInfo,
  deleteAdjustmentFiles,
  deleteAdjustmentStock
};
