import { plantService } from '../services';
import { plantConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/settings/plant';
function loadRecords(pageSize = 10, page = 0) {
  return (dispatch) => {
    dispatch(request());
    plantService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: plantConstants.LOAD_RECORDS };
  }
  function success(records) {
    return { type: plantConstants.LOAD_RECORDS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: plantConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updatePlantStatus(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.plantId));
    plantService.updatePlantStatus(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: plantConstants.UPDATE_AVAILABILITY_RECORD };
  }
  function success(record, message, error) {
    return { type: plantConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: plantConstants.UPDATE_AVAILABILITY_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    plantService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: plantConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: plantConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: plantConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    plantService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: plantConstants.CREATE_RECORD };
  }
  function success(record, message, error) {
    return { type: plantConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: plantConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    plantService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: plantConstants.LOAD_RECORD, id };
  }
  function success(record) {
    return { type: plantConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: plantConstants.LOAD_RECORD_ERROR, error, message };
  }
}

export const plantActions = {
  loadRecords,
  deleteRecord,
  createRecord,
  updatePlantStatus,
  loadRecord
};
