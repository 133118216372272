/*
 *
 *  remotes
 *
 */

import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';
const constantName = 'companyprofile';

function loadRecords() {
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} metaData
 * @returns
 */
function loadRecordsMetaData(metaData) {
  return API.get('tennat-service', metaData, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function removeImage(record) {
  const { module } = record;
  let queryParams = { module };
  return API.del('tennat-service', `${constantName}/deleteimage`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  let userName = record.userName;
  record.hasOwnProperty('image') && delete record.image;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${constantName}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function deleteRecord(id) {
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error));
}

function loadPrefixRecords() {
  return API.get('tennat-service', `settings`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function updatePrefixRecords(record) {
  const { tenantId } = record;
  delete record.tenantId;
  return API.put('tennat-service', `setting/${tenantId}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadsProfile(username) {
  return API.get('tennat-service', `userprofile/${username}`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateProfile(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${'userprofile'}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateAdvancedRecord(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `userprofile/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function uploadLogoImage(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${constantName}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function uploadBannerImage(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${constantName}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
async function uploadImage(record) {
  const fileReaderPromise = () => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(record.image);
      fileReader.onload = () => {
        let srcData = fileReader.result;
        record[record.module] = srcData;
        srcData = srcData.substring(srcData.indexOf(',') + 1, srcData.length);
        resolve(srcData);
      };
    });
  };
  let queryParams = { module: record.module };
  const srcData = await fileReaderPromise();
  return API.post(
    'tennat-service',
    `${'companyprofile'}/${'uploadimage'}`,
    getDefaultParamswithoutlimitkey(null, { body: srcData, queryStringParameters: queryParams })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
async function uploadAdminProfilePhoto(record) {
  const fileReaderPromise = () => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(record.image);
      fileReader.onload = () => {
        let srcData = fileReader.result;
        record.userImg = srcData;
        srcData = srcData.substring(srcData.indexOf(',') + 1, srcData.length);
        resolve(srcData);
      };
    });
  };
  let queryParams = { module: 'user', userName: record.userName };
  const srcData = await fileReaderPromise();
  return API.post(
    'tennat-service',
    `${'companyprofile'}/${'uploadimage'}`,
    getDefaultParamswithoutlimitkey(null, { body: srcData, queryStringParameters: queryParams })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function displayRecords() {
  return API.get('tennat-service', `limit/tenantId`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
export const companyProfileService = {
  loadRecords,
  loadRecordsMetaData,
  loadRecord,
  createRecord,
  updateRecord,
  deleteRecord,
  loadPrefixRecords,
  updatePrefixRecords,
  loadsProfile,
  updateAdvancedRecord,
  updateProfile,
  uploadBannerImage,
  uploadLogoImage,
  uploadImage,
  removeImage,
  displayRecords,
  uploadAdminProfilePhoto
};
