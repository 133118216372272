import { Stack } from '@mui/material';
import { Box } from '@mui/material';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const PlantViewSkeleton = () => {
  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} sm={5} sx={{ mt: 1 }}>
        <Grid container display="flex" spacing={2}>
          <Grid item xs={12} sm={4}>
            <Skeleton variant={'rounded'} height={80} animation={'wave'} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={1} alignItems="center">
              <Skeleton variant={'text'} height={20} width={'100%'} animation={'wave'} />
              <Skeleton variant={'text'} height={20} animation={'wave'} width={'70%'} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={1} alignItems="center">
              <Skeleton variant={'text'} height={20} animation={'wave'} width={'80%'} />
              <Skeleton variant={'text'} height={20} animation={'wave'} width={'60%'} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ pt: 2, pb: 3 }}>
            <Stack spacing={1} alignItems="center">
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ pt: 2, pb: 3 }}>
            <Stack spacing={1} alignItems="center">
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ pt: 2, pb: 3 }}>
            <Stack spacing={1} alignItems="center">
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
              <Skeleton variant={'text'} height={20} width={'90%'} animation={'wave'} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ pt: 2, pb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ margin: 1 }}>
                <Skeleton variant={'circular'} width={60} height={60} animation={'wave'} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton variant={'text'} height={30} width={'95%'} animation={'wave'} />
                <Skeleton variant={'text'} height={25} width={'60%'} animation={'wave'} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Stack spacing={1}>
          <Skeleton variant={'rounded'} height={400} animation={'wave'} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ margin: 1 }}>
                <Skeleton variant={'circular'} width={60} height={60} animation={'wave'} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton variant={'text'} height={30} width={'95%'} animation={'wave'} />
                <Skeleton variant={'text'} height={25} width={'60%'} animation={'wave'} />
              </Box>
            </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PlantViewSkeleton;
