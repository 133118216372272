import { contractorService } from '../services';
import { contractorConstants } from '../_constants';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/contractor/list';
const transactionUrl = '/contractor/transactions';

function setFilterMonth(record) {
  return (dispatch) => {
    dispatch(request(record));
  };
  function request(record) {
    return { type: contractorConstants.SET_FILTERMONTH, record };
  }
}
function clearTallyCompanyData() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: contractorConstants.CLEAR_TALLY_RECORD };
  }
}
function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    contractorService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.LOAD_RECORDS };
  }
  function success(records, total) {
    return { type: contractorConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    dispatch(loaderActions.start());
    contractorService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          // dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response[0]));
        }
        dispatch(loaderActions.end());
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(id) {
    return { type: contractorConstants.LOAD_RECORD, id };
  }
  function success(record) {
    return { type: contractorConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    contractorService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.UPDATE_RECORD };
  }
  function success(record, message, error) {
    return { type: contractorConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function syncContractorTally(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    contractorService.syncContractorTally(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.SYNC_CONTRACTOR_TALLY };
  }
  function success(record, message, error) {
    return { type: contractorConstants.SYNC_CONTRACTOR_TALLY_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.SYNC_CONTRACTOR_TALLY_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    contractorService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (navigate) {
            navigate(listUrl, { state: { status: CREATE_SUCCESS } });
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.CREATE_RECORD };
  }
  function success(record, message, error) {
    return { type: contractorConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function uploadData(records, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    contractorService.uploadContractors(records).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.UPLOAD_CONTRACTORS };
  }
  function success(records, message, error) {
    return { type: contractorConstants.UPLOAD_CONTRACTORS_SUCCESS, records, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.UPLOAD_CONTRACTORS_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    contractorService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: contractorConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: contractorConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.DELETE_RECORD_ERROR, error, message };
  }
}
function createAddress(address, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    contractorService.createAddress(address).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(`/contractor/managesite/${response.contractorId}`);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.CREATE_ADDRESS };
  }
  function success(address, message, error) {
    return { type: contractorConstants.CREATE_ADDRESS_SUCCESS, address, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.CREATE_ADDRESS_ERROR, error, message };
  }
}

function deleteAddress(id, navigate) {
  return (dispatch) => {
    dispatch(request());
    contractorService.deleteAddress(id).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(id, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: contractorConstants.DELETE_ADDRESS };
  }
  function success(id, message, error) {
    return { type: contractorConstants.DELETE_ADDRESS_SUCCESS, id, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.DELETE_ADDRESS_ERROR, error, message };
  }
}

function getContractorAddress(id) {
  return (dispatch) => {
    dispatch(request(id));
    contractorService.getContractorAddress(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request(id) {
    return { type: contractorConstants.GET_ADDRESS_BY_CONTRACTOR, id };
  }
  function success(address) {
    return { type: contractorConstants.GET_ADDRESS_BY_CONTRACTOR_SUCCESS, address };
  }
  function failure(error, message) {
    return { type: contractorConstants.GET_ADDRESS_BY_CONTRACTOR_ERROR, error, message };
  }
}

function loadPaymentRecords(id) {
  return (dispatch) => {
    dispatch(request(id));
    dispatch(loaderActions.start());
    contractorService.loadPaymentRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: contractorConstants.LOAD_PAYMENT_RECORDS, id };
  }
  function success(paymentRecords) {
    return { type: contractorConstants.LOAD_PAYMENT_RECORDS_SUCCESS, paymentRecords };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_PAYMENT_RECORDS_ERROR, error, message };
  }
}

function createPaymentRecords(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());

    dispatch(request());
    contractorService.createPaymentRecords(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(`/contractor/details/${response?.contractorId}`);
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.CREATE_PAYMENT_RECORDS };
  }
  function success(record, message, error) {
    return { type: contractorConstants.CREATE_PAYMENT_RECORDS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.CREATE_PAYMENT_RECORDS_ERROR, error, message };
  }
}

function loadTransactionRecords(record) {
  const { id, month } = record;
  return (dispatch) => {
    dispatch(request(id, month));
    dispatch(loaderActions.start());
    contractorService.loadTransactionRecords(id, month).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id, month) {
    return { type: contractorConstants.LOAD_TRANSACTION_RECORDS, id, month };
  }
  function success(transactionRecords) {
    return { type: contractorConstants.LOAD_TRANSACTION_RECORDS_SUCCESS, transactionRecords };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_TRANSACTION_RECORDS_ERROR, error, message };
  }
}

function orderCountsByContractor(id) {
  return (dispatch) => {
    dispatch(request());
    contractorService.orderCountsByContractor(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: contractorConstants.ORDERS_COUNT_BY_CONTRACTOR };
  }
  function success(orderCounts) {
    return { type: contractorConstants.ORDERS_COUNT_BY_CONTRACTOR_SUCCESS, orderCounts };
  }
  function failure(error, message) {
    return { type: contractorConstants.ORDERS_COUNT_BY_CONTRACTOR_ERROR, error, message };
  }
}

function loadRecordsCount(params) {
  return (dispatch) => {
    dispatch(request());
    contractorService.loadRecordsCount(params).then(
      (res) => {
        const { error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.LOAD_RECORDS_COUNT };
  }
  function success(counts) {
    return { type: contractorConstants.LOAD_RECORDS_COUNT_SUCCESS, counts };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_RECORDS_COUNT_ERROR, error, message };
  }
}

function updateContractorStatus(record) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.contractorId));
    contractorService.updateContractorStatus(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.UPDATE_AVAILABILITY_RECORD };
  }
  function success(record, message, error) {
    return { type: contractorConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.UPDATE_AVAILABILITY_RECORD_ERROR, error, message };
  }
}

function loadAutoFillAddress(record) {
  return (dispatch) => {
    dispatch(request());
    contractorService.loadAutoFillAddress(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: contractorConstants.LOAD_AUTOFILL_ADDRESS };
  }
  function success(mapRecord) {
    return { type: contractorConstants.LOAD_AUTOFILL_ADDRESS_SUCCESS, mapRecord };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_AUTOFILL_ADDRESS_ERROR, error, message };
  }
}

function resetMapLocation() {
  return {
    type: contractorConstants.RESET_MAP_LOCATION
  };
}

function loadTopPendingContractorsRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    contractorService.loadTopPendingContractorsRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS };
  }
  function success(topPendingContractors, total, page) {
    return { type: contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS_SUCCESS, topPendingContractors, total, page };
  }
  function failure(error, message) {
    return { type: contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS_ERROR, error, message };
  }
}
function updatePaymentStatus(record, navigate) {
  console.log(record);
  let { contractorId, ...filterRecord } = record;
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start(record.contractorId));
    contractorService.updatePaymentStatus(filterRecord).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(`${transactionUrl}/${contractorId}`, { state: record });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: contractorConstants.UPDATE_PAYMENT_RECORDS };
  }
  function success(record, message, error) {
    return { type: contractorConstants.UPDATE_PAYMENT_RECORDS_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.UPDATE_PAYMENT_RECORDS_ERROR, error, message };
  }
}
function deleteRecordPayment(id) {
  return (dispatch) => {
    dispatch(request());
    contractorService.deleteRecordPayment(id).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(id, message, error));
          dispatch(dialogActions.success(false));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: contractorConstants.DELETE_PAYMENT_RECORDS };
  }
  function success(id, message, error) {
    return { type: contractorConstants.DELETE_PAYMENT_RECORDS_SUCCESS, id, message, error };
  }
  function failure(error, message) {
    return { type: contractorConstants.DELETE_PAYMENT_RECORDS_ERROR, error, message };
  }
}

function clearPayment() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: contractorConstants.RESET_DELETE_PAYMENT_RECORD };
  }
}

export const contractorActions = {
  updateContractorStatus,
  loadRecords,
  updateRecord,
  syncContractorTally,
  loadRecord,
  deleteRecord,
  createRecord,
  getContractorAddress,
  deleteAddress,
  createAddress,
  loadPaymentRecords,
  createPaymentRecords,
  loadTransactionRecords,
  orderCountsByContractor,
  loadRecordsCount,
  uploadData,
  loadAutoFillAddress,
  resetMapLocation,
  loadTopPendingContractorsRecords,
  updatePaymentStatus,
  deleteRecordPayment,
  clearPayment,
  clearTallyCompanyData,
  setFilterMonth
};
