import { useMemo, useState, useEffect } from 'react';

// material-ui
import { FormControl, MenuItem, OutlinedInput, OutlinedInputProps, Select, Slider, Stack, TextField, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import { useAsyncDebounce, Row, TableState, MetaBase } from 'react-table';
import { matchSorter } from 'match-sorter';
import { format } from 'date-fns';

// project-imports
import IconButton from 'components/@extended/IconButton';
// assets
import { Add, Minus, SearchNormal1, CloseCircle, ArrowCircleRight2 } from 'iconsax-react';
import useTableContenxt from 'hooks/useTableContext';
// ==============================|| CUSTOM FUNCTION - REACT TABLE ||============================== //

interface GlobalFilterProps extends OutlinedInputProps {
  preGlobalFilteredRows: number;
  globalFilter?: string;
  setGlobalFilter: (value: string) => void;
  setValue: any;
  value: any;
  allColumns?: any;
  loading?: any;
  contextKey?: any;
  handleTableContext?: any;
  extraGlobalFilterColumns?: any;
}

export function GlobalFilter({
  preGlobalFilteredRows,
  handleTableContext,
  globalFilter,
  allColumns,
  setGlobalFilter,
  value,
  setValue,
  loading,
  contextKey,
  extraGlobalFilterColumns,
  ...other
}: GlobalFilterProps) {
  const { tableConfig, onChangeGlobalFilterValue }: any = useTableContenxt();
  const skipedTypes: any = ['date'];
  const _buildFilterQuery = (value: string) => {
    let filterQuery: any = {};
    if (value) {
      allColumns.forEach((_column: any) => {
        if (_column.canFilter && _column.dataType && !skipedTypes.includes(_column.dataType)) {
          filterQuery[_column.id] = value;
        }
      });
      if (extraGlobalFilterColumns && extraGlobalFilterColumns.length) {
        extraGlobalFilterColumns.forEach((_column: any) => {
          filterQuery[_column.id] = value;
        });
      }
    }
    return filterQuery;
  };
  const onChange = useAsyncDebounce((value, globalAutoFocus) => {
    let data = allColumns?.filter((item: any) => item.customField).map((val: any) => val.id);
    let params = {
      filters: _buildFilterQuery(value),
      globalFilterValue: value,
      customField: data,
      page: 0,
      globalAutoFocus: globalAutoFocus
    };
    handleTableContext(params);
    setValue(value);
  }, 200);
  return (
    <OutlinedInput
      value={tableConfig[contextKey]?.globalFilterValue ? tableConfig[contextKey]?.globalFilterValue : value || ''}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onChange(value, true);
        }
      }}
      onChange={(e) => {
        const trimmedValue = e.target.value.trim();
        setValue(trimmedValue);
        onChangeGlobalFilterValue(contextKey, trimmedValue);
        if (!trimmedValue && tableConfig[contextKey]?.filters && Object.keys(tableConfig[contextKey]?.filters).length) {
          onChange('', false);
        }
      }}
      placeholder={loading ? `Loading...` : `Search ${preGlobalFilteredRows} records...`}
      id="start-adornment-email"
      autoFocus={tableConfig[contextKey]?.globalAutoFocus}
      startAdornment={<SearchNormal1 size={18} />}
      endAdornment={
        tableConfig[contextKey]?.globalFilterValue || value || '' ? (
          <>
            <ArrowCircleRight2
              size={36}
              style={{ cursor: 'hand' }}
              onClick={() => {
                onChange(value, true);
              }}
            />{' '}
            <CloseCircle
              size={36}
              style={{ cursor: 'hand' }}
              onClick={() => {
                onChange('', false);
              }}
            />
          </>
        ) : (
          ''
        )
      }
      {...other}
    />
  );
}

export function DefaultColumnFilter({ column: { filterValue, Header, setFilter, id }, props: { contextKey, handleTableContext } }: any) {
  const { tableConfig, onChangeColumnFilterValue }: any = useTableContenxt();
  const [value, setValue] = useState('');
  const isPaymentStatus = Header === 'Payment Status';
  useEffect(() => {}, []);
  const _buildColumnFilterQuery = (value: any) => {
    const params = {
      page: 0,
      columnFilters: { ...tableConfig[contextKey].columnFilters, ...{ [id]: value } }
    };
    handleTableContext(params);
  };
  const onChange = (value: any) => {
    setFilter(value || undefined);
    _buildColumnFilterQuery(value);
  };

  if (isPaymentStatus) {
    return (
      <>
        <Select
          fullWidth
          id={id}
          value={tableConfig[contextKey]?.columnFilters?.[id] || value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Select a value' }}
          size="small"
        >
          <MenuItem value="" disabled>
            <em>Status</em>
          </MenuItem>
          <MenuItem value="Draft">Draft</MenuItem>
          <MenuItem value="Published">Published</MenuItem>
          {value && <MenuItem value="">All</MenuItem>}
        </Select>
      </>
    );
  } else {
    return (
      <OutlinedInput
        fullWidth
        id={id}
        value={tableConfig[contextKey]?.columnFilters?.[id] ? tableConfig[contextKey]?.columnFilters?.[id] : filterValue || value}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onChange(value);
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeColumnFilterValue(contextKey, id, e.target.value);
          if (!e.target.value) {
            onChange('');
          }
        }}
        placeholder={Header}
        endAdornment={
          tableConfig[contextKey]?.columnFilters?.[id] || value ? (
            <>
              <ArrowCircleRight2
                size={36}
                style={{ cursor: 'hand' }}
                onClick={() => {
                  onChange(value);
                }}
              />
              <CloseCircle
                size={36}
                style={{ cursor: 'hand' }}
                onClick={() => {
                  onChange('');
                  setValue('');
                }}
              />
            </>
          ) : (
            ''
          )
        }
        size="small"
      />
    );
  }
}

export function DateColumnFilter({ column: { filterValue, Header, setFilter } }: any) {
  return (
    <FormControl sx={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={filterValue || null}
          onChange={(newValue) => {
            let formatDateFn = undefined;
            try {
              formatDateFn = format(newValue, 'M/d/yyyy');
            } catch (error) {}
            setFilter(formatDateFn || undefined);
          }}
          renderInput={(params) => <TextField name={Header} {...params} placeholder={`Select ${Header}`} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

interface FilterColumnProps {
  filterValue?: never[];
  preFilteredRows: Row[];
  Header: string;
  setFilter: any;
  id: string;
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: { column: FilterColumnProps }) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: Row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      displayEmpty
      size="small"
    >
      <MenuItem value="">All</MenuItem>
      {options.map((option: any, i: number) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: { column: FilterColumnProps }) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
      <Slider
        value={filterValue || min}
        min={min}
        max={max}
        step={1}
        onChange={(event: Event, newValue: number | number[]) => {
          setFilter(newValue);
        }}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
      <Tooltip title="Reset">
        <IconButton size="small" color="error" onClick={() => setFilter(undefined)}>
          <Add style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }: { column: FilterColumnProps }) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: Row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ minWidth: 168, maxWidth: 250 }}>
      <TextField
        fullWidth
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        size="small"
      />
      <Minus />
      <TextField
        fullWidth
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        size="small"
      />
    </Stack>
  );
}

function fuzzyTextFilterFn(rows: Row[], id: number, filterValue: string) {
  return matchSorter(rows, filterValue, { keys: [(row: Row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val: number) => !val;

export const renderFilterTypes: any = () => ({
  fuzzyText: fuzzyTextFilterFn,
  text: (rows: Row[], id: string, filterValue: string) => {
    rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
    });
  }
});

export function filterGreaterThan(rows: Row[], id: number, filterValue: number) {
  return rows.filter((row: Row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val: number) => typeof val !== 'number';

export function useControlledState(state: TableState<{}>, { instance }: MetaBase<{}>) {
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [...state.hiddenColumns!, ...state.groupBy].filter((d, i, all) => all.indexOf(d) === i)
      };
    }
    return state;
  }, [state]);
}

export function roundedMedian(leafValues: any) {
  let min = leafValues[0] || 0;
  let max = leafValues[0] || 0;

  leafValues.forEach((value: number) => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}
