/*
 *
 *   reducer
 *
 */ 

import {
  mqttConstants
} from '../_constants'

import { v4 as uuidv4 } from 'uuid';


const initialState = { 
  mqttResponse: "",
  mqttMessage: "", 
  mqttConnectorError:false,
  mqttConnectionMessage:"",
  mqttMessageError: false,
  isMqttConnected: false,
  initialConnection:false,
  topic:"",
  isCleared:false,
  subscriptionMessage:false,
  markerPosition:{ lat: 12.21686, lng: 79.65963 },
  routes:[],
  transitionStatus:false
}


const Reducer = (state = initialState, action) => { 
  switch (action.type) {
    case mqttConstants.MQTTCONNECTING: 
      return {
        ...state,
        mqttConnectionMessage: action.mqttConnectionMessage,
        mqttConnectorError: action.mqttConnectorError,
        initialConnection:true,
        isMqttConnected:false,
        mqttMessage:"",
        mqttMessageError:false,
        subscriptionMessage:false
      };
    case mqttConstants.MQTTCONNECTING_SUCCESS:
      return {
        ...state,
        mqttConnectorError: false,
        mqttConnectionMessage: "",
        isMqttConnected: action.isMqttConnected,
        isCleared:false,
        transitionStatus:true
      };
    case mqttConstants.MQTTCONNECTING_ERROR: 
      return {
        ...state,
        mqttConnectorError: action.mqttConnectorError,
        mqttConnectionMessage: action.mqttConnectionMessage,
      };
    case mqttConstants.GET_MESSAGE: 
        return {
          ...state,
          mqttMessage: action.mqttMessage,
          mqttMessageError: action.mqttMessageError,
          subscriptionMessage:false
        };
    case mqttConstants.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        mqttMessageError: false,
        mqttMessage: "",
        mqttResponse: action.mqttResponse,
        routes : [...state.routes, {id:uuidv4(),payload:action.mqttResponse}],
        isMqttConnected: true,
        initialConnection:false,
        subscriptionMessage:true
      };
    case mqttConstants.GET_MESSAGE_ERROR:
      return {
        ...state,
        mqttMessageError: action.mqttMessageError,
        mqttMessage: action.mqttMessage,
        subscriptionMessage:false
      };
    case mqttConstants.LOAD_TOPIC_SUCCESS:
      return {
        ...state,
        topic: action.topic,
      };    
    case mqttConstants.CLEAR:
      return {
        ...initialState,
        isCleared:true
      };
    case mqttConstants.UPDATE_MARKER_POSITION: 
      return {
        ...state,
        routes : [...state.routes, {id:uuidv4(),payload:action.markerPosition}],
        markerPosition: action.markerPosition
      };
    case mqttConstants.TRASISTION_STATUS: 
      return {
        ...state,
        transitionStatus:action.transitionStatus
      };
    case mqttConstants.REMOVE_COMPLETED_MARKER:
      return {
        ...state, 
        routes : state.routes.filter((v) => v.id != action.mqttId)
      };

    default:
      return state;
  }
}; 
export default Reducer