import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'quote';

function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadSearchRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(record) {
  const { quoteId } = record;
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${quoteId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).catch((error) =>
    Promise.reject(error)
  );
}

function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateRecord(record) {
  const {
    deliveryDate,
    contractorName,
    contractorId,
    contractorPhone,
    price,
    grade,
    deliverySecAddress,
    quantity,
    deliveryCity,
    deliveryState,
    deliveryAddress,
    deliveryCountry,
    deliveryPin,
    productId,
    productName,
    quoteStatus,
    quoteStatusChanged,
    marketingPersonName,
    quoteStatusUpdate,
    fullAddress,
    discount,
    tax,
    plantId,
    netAmount,
    taxAmount,
    totalAmount,
    aliasName,
    productUnit,
    taxType,
    customFields
  } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${record.quoteId}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign(
        {},
        {
          deliveryDate,
          discount,
          quantity,
          contractorName,
          productId,
          price,
          plantId,
          contractorPhone,
          contractorId,
          deliveryAddress,
          deliveryState,
          deliveryCountry,
          deliveryPin,
          grade,
          deliverySecAddress,
          deliveryCity,
          productName,
          quoteStatus,
          quoteStatusChanged,
          marketingPersonName,
          quoteStatusUpdate,
          fullAddress,
          aliasName,
          tax,
          taxType,
          netAmount,
          taxAmount,
          totalAmount,
          productUnit,
          customFields
        }
      )
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadStatusTimelineRecords(id) {
  return API.get('tennat-service', `quote/timeline/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export const quoteService = {
  loadRecords,
  deleteRecord,
  createRecord,
  loadRecord,
  updateRecord,
  loadStatusTimelineRecords,
  loadSearchRecords
};
