import { useRoutes } from 'react-router-dom';
import React, { lazy,useEffect } from 'react';

// project-imports
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { get_all_authorization, get_module_authorization } from 'helpers/tools';
import { userActions } from 'store/actions';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';
import usePermissionContext from 'hooks/usePermissionContext';
// ==============================|| ROUTES RENDER ||============================== //
const AccessError = Loadable(lazy(() => import('components/AccessError/index')));

export default function ThemeRoutes() {
  const { loginCompleted } = useAuth();
  const {  handlerPermissions } = usePermissionContext();
  let routes = MainRoutes;
  if (loginCompleted) {
    const authorizationByRoles = get_all_authorization();
    handlerPermissions(authorizationByRoles?.permission?authorizationByRoles?.permission:{})
    const isAuthorizeRoute = (route) => {
      if (route) {
        if (authorizationByRoles && authorizationByRoles.permission) {
          if (authorizationByRoles.permission[route]) {
            if (authorizationByRoles.permission[route].get === false) {
              return false;
            }
          }
        } else {
          return true;
        }
      }
      return true;
    };

    const check_permission = (route, is403, path) => {
      if (
        route.path == 'list' ||
        route.path == 'driver' ||
        route.path == 'vehicle' 
      ) {
        let modulePermission  =  get_module_authorization(authorizationByRoles.permission, path)
        if (!is403) {
          if (!route.actualElement) {
            route.actualElement = React.cloneElement(route.element, { permission: modulePermission });
          }

          route.element = <AccessError />;
        } else { 
          if (route.actualElement) {
            route.element = React.cloneElement(route.actualElement, { permission: modulePermission });
          }
          route.element = React.cloneElement(route.element, { permission: modulePermission });
          if(path == "invoice" && route.children){
               route.children.forEach((_invoiceChild)=>{
                  _invoiceChild.element = React.cloneElement(_invoiceChild.element, { permission: modulePermission });
               })
          }
          
        }
      }
    };
    if (routes && routes.children) {
      let _routes = routes.children;
      _routes.forEach((route) => {
        if (route.children) {
          let subRoute = route.children;
          subRoute.forEach((_route) => {
            let path = _route.path;
            let is403 = isAuthorizeRoute(path);
            if (_route.children) {
              _route.children.forEach((_childRoute) => {
                if (
                  _childRoute.path == 'driver' ||
                  _childRoute.path == 'vehicle' 
                ) {
                  path = _childRoute.path;
                  is403 = isAuthorizeRoute(path);
                }
                check_permission(_childRoute, is403, path);
              });
            }
          });
         
        }
      });
    }
    
  }
useEffect(()=>{
    if (loginCompleted) {
      dispatch(userActions.resetLogin());
    }
},[loginCompleted])
  return useRoutes([LoginRoutes, routes]);
}
