/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/trips`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const LOAD_SEARCH = `${url}/LOAD_SEARCH`;
const LOAD_SEARCH_SUCCESS = `${url}/LOAD_SEARCH_SUCCESS`;
const LOAD_SEARCH_ERROR = `${url}/LOAD_SEARCH_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const LOAD_RECORDS_COUNT = `${url}/LOAD_RECORDS_COUNT`;
const LOAD_RECORDS_COUNT_SUCCESS = `${url}/LOAD_RECORDS_COUNT_SUCCESS`;
const LOAD_RECORDS_COUNT_ERROR = `${url}/LOAD_RECORDS_COUNT_ERROR`;
const LOAD_TODAY_RECORDS = `${url}/LOAD_TODAY_RECORDS`;
const LOAD_TODAY_RECORDS_SUCCESS = `${url}/LOAD_TODAY_RECORDS_SUCCESS`;
const LOAD_TODAY_RECORDS_ERROR = `${url}/LOAD_TODAY_RECORDS_ERROR`;
const LOAD_COMPLETED_RECORDS = `${url}/LOAD_COMPLETED_RECORDS`;
const LOAD_COMPLETED_RECORDS_SUCCESS = `${url}/LOAD_COMPLETED_RECORDS_SUCCESS`;
const LOAD_COMPLETED_RECORDS_ERROR = `${url}/LOAD_COMPLETED_RECORDS_ERROR`;
const LOAD_LIVE_RECORDS = `${url}/LOAD_LIVE_RECORDS`;
const LOAD_LIVE_RECORDS_SUCCESS = `${url}/LOAD_LIVE_RECORDS_SUCCESS`;
const LOAD_LIVE_RECORDS_ERROR = `${url}/LOAD_LIVE_RECORDS_ERROR`;
const LOAD_UPCOMING_RECORDS = `${url}/LOAD_UPCOMING_RECORDS`;
const LOAD_UPCOMING_RECORDS_SUCCESS = `${url}/LOAD_UPCOMING_RECORDS_SUCCESS`;
const LOAD_UPCOMING_RECORDS_ERROR = `${url}/LOAD_UPCOMING_RECORDS_ERROR`;
const LOAD_TRIP_RECORDS = `${url}/LOAD_TRIP_RECORDS`;
const LOAD_TRIP_RECORDS_SUCCESS = `${url}/LOAD_TRIP_RECORDS_SUCCESS`;
const LOAD_TRIP_RECORDS_ERROR = `${url}/LOAD_TRIP_RECORDS_ERROR`;
const CREATE_TRIPSHEET = `${url}/CREATE_TRIPSHEET`;
const CREATE_TRIPSHEET_SUCCESS = `${url}/CREATE_TRIPSHEET_SUCCESS`;
const CREATE_TRIPSHEET_ERROR = `${url}/CREATE_TRIPSHEET_ERROR`;
const LOAD_TRIPSBYORDER_RECORDS = `${url}/LOAD_TRIPSBYORDER_RECORDS`;
const LOAD_TRIPSBYORDER_RECORDS_SUCCESS = `${url}/LOAD_TRIPSBYORDER_RECORDS_SUCCESS`;
const LOAD_TRIPSBYORDER_RECORDS_ERROR = `${url}/LOAD_TRIPSBYORDER_RECORDS_ERROR`;
const ORDERTRIPCOUNTS = `${url}/ORDERTRIPCOUNTS`;
const LOAD_STATUS_TIMELINE_RECORDS = `${url}/LOAD_STATUS_TIMELINE_RECORDS`;
const LOAD_STATUS_TIMELINE_RECORDS_SUCCESS = `${url}/LOAD_STATUS_TIMELINE_RECORDS_SUCCESS`;
const LOAD_STATUS_TIMELINE_RECORDS_ERROR = `${url}/LOAD_STATUS_TIMELINE_RECORDS_ERROR`;
const VIEW_TRIPSBYORDER = `${url}VIEW_TRIPSBYORDER`;
const VIEW_TRIPSBYORDER_SUCCESS = `${url}VIEW_TRIPSBYORDER_SUCCESS`;
const VIEW_TRIPSBYORDER_ERROR = `${url}VIEW_TRIPSBYORDER_ERROR`;
const CLEAR = `${url}/CLEAR`;

export const tripConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  LOAD_SEARCH,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_TRIPSBYORDER_RECORDS,
  LOAD_TRIPSBYORDER_RECORDS_SUCCESS,
  LOAD_TRIPSBYORDER_RECORDS_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  LOAD_RECORDS_COUNT,
  LOAD_RECORDS_COUNT_SUCCESS,
  LOAD_RECORDS_COUNT_ERROR,
  LOAD_TODAY_RECORDS,
  LOAD_TODAY_RECORDS_SUCCESS,
  LOAD_TODAY_RECORDS_ERROR,
  LOAD_COMPLETED_RECORDS,
  LOAD_COMPLETED_RECORDS_SUCCESS,
  LOAD_COMPLETED_RECORDS_ERROR,
  LOAD_LIVE_RECORDS,
  LOAD_LIVE_RECORDS_SUCCESS,
  LOAD_LIVE_RECORDS_ERROR,
  LOAD_UPCOMING_RECORDS,
  LOAD_UPCOMING_RECORDS_SUCCESS,
  LOAD_UPCOMING_RECORDS_ERROR,
  LOAD_TRIP_RECORDS,
  LOAD_TRIP_RECORDS_SUCCESS,
  LOAD_TRIP_RECORDS_ERROR,
  CREATE_TRIPSHEET,
  CREATE_TRIPSHEET_SUCCESS,
  CREATE_TRIPSHEET_ERROR,
  ORDERTRIPCOUNTS,
  LOAD_STATUS_TIMELINE_RECORDS,
  LOAD_STATUS_TIMELINE_RECORDS_SUCCESS,
  LOAD_STATUS_TIMELINE_RECORDS_ERROR,
  VIEW_TRIPSBYORDER,
  VIEW_TRIPSBYORDER_SUCCESS,
  VIEW_TRIPSBYORDER_ERROR,
  CLEAR
};
