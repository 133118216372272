/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/invoice`;

export const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
export const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
export const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
export const LOAD_RECORD = `${url}/LOAD_RECORD`;
export const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
export const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
export const CREATE_RECORD = `${url}/CREATE_RECORD`;
export const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
export const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
export const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
export const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
export const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
export const DELETE_RECORD = `${url}/DELETE_RECORD`;
export const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
export const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
export const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
export const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
export const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
export const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
export const CREATE_ADDRESS = `${url}/CREATE_ADDRESS`;
export const CREATE_ADDRESS_SUCCESS = `${url}/CREATE_ADDRESS_SUCCESS`;
export const CREATE_ADDRESS_ERROR = `${url}/CREATE_ADDRESS_ERROR`;
export const GET_ADDRESS = `${url}/GET_ADDRESS`;
export const GET_ADDRESS_SUCCESS = `${url}/GET_ADDRESS_SUCCESS`;
export const GET_ADDRESS_ERROR = `${url}/GET_ADDRESS_ERROR`;
export const UPDATE_ADDRESS = `${url}/UPDATE_ADDRESS`;
export const UPDATE_ADDRESS_SUCCESS = `${url}/UPDATE_ADDRESS_SUCCESS`;
export const UPDATE_ADDRESS_ERROR = `${url}/UPDATE_ADDRESS_ERROR`;
export const DELETE_ADDRESS = `${url}/DELETE_ADDRESS`;
export const DELETE_ADDRESS_SUCCESS = `${url}/DELETE_ADDRESS_SUCCESS`;
export const DELETE_ADDRESS_ERROR = `${url}/DELETE_ADDRESS_ERROR`;
export const GET_ALL_ADDRESS = `${url}/GET_ALL_ADDRESS`;
export const GET_ALL_ADDRESS_SUCCESS = `${url}/GET_ALL_ADDRESS_SUCCESS`;
export const GET_ALL_ADDRESS_ERROR = `${url}/GET_ALL_ADDRESS_ERROR`;
export const GET_ADDRESS_BY_CONTRACTOR = `${url}/GET_ADDRESS_BY_CONTRACTOR`;
export const GET_ADDRESS_BY_CONTRACTOR_SUCCESS = `${url}/GET_ADDRESS_BY_CONTRACTOR_SUCCESS`;
export const GET_ADDRESS_BY_CONTRACTOR_ERROR = `${url}/GET_ADDRESS_BY_CONTRACTOR_ERROR`;
export const LOAD_TOTAL_PENDING = `${url}/LOAD_TOTAL_PENDING`;
export const LOAD_TOTAL_PENDING_SUCCESS = `${url}/LOAD_TOTAL_PENDING_SUCCESS`;
export const LOAD_TOTAL_PENDING_ERROR = `${url}/LOAD_TOTAL_PENDING_ERROR`;
export const LOAD_TOTAL_PAID = `${url}/LOAD_TOTAL_PAID`;
export const LOAD_TOTAL_PAID_SUCCESS = `${url}/LOAD_TOTAL_PAID_SUCCESS`;
export const LOAD_TOTAL_PAID_ERROR = `${url}/LOAD_TOTAL_PAID_ERROR`;
export const LOAD_EXCEED_RECORD = `${url}/LOAD_EXCEED_RECORD`;
export const LOAD_EXCEED_RECORD_SUCCESS = `${url}/LOAD_EXCEED_RECORD_SUCCESS`;
export const LOAD_EXCEED_RECORD_ERROR = `${url}/LOAD_EXCEED_RECORD_ERROR`;
export const LOAD_INVOICE_PAYMENT_RECORDS = `${url}/LOAD_INVOICE_PAYMENT_RECORDS`;
export const LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS = `${url}/LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS`;
export const LOAD_INVOICE_PAYMENT_RECORDS_ERROR = `${url}/LOAD_INVOICE_PAYMENT_RECORDS_ERROR`;
export const SYNC_INVOICE_TALLY = `${url}/SYNC_INVOICE_TALLY`;
export const SYNC_INVOICE_TALLY_SUCCESS = `${url}/SYNC_INVOICE_TALLY_SUCCESS`;
export const SYNC_INVOICE_TALLY_ERROR = `${url}/SYNC_INVOICE_TALLY_ERROR`;
export const CREATE_INVOICE_PAYMENT_RECORDS = `${url}/CREATE_INVOICE_PAYMENT_RECORDS`;
export const CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS = `${url}/CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS`;
export const CREATE_INVOICE_PAYMENT_RECORDS_ERROR = `${url}/CREATE_INVOICE_PAYMENT_RECORDS_ERROR`;
export const LOAD_SEARCH_RECORDS = `${url}/LOAD_SEARCH_RECORDS`;
export const LOAD_SEARCH_RECORDS_SUCCESS = `${url}/LOAD_SEARCH_RECORDS_SUCCESS`;
export const LOAD_SEARCH_RECORDS_ERROR = `${url}/LOAD_SEARCH_RECORDS_ERROR`;
export const LOAD_INVOICE_DASHBOARD_RECORDS = `${url}/LOAD_INVOICE_DASHBOARD_RECORDS`;
export const LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS = `${url}/LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS`;
export const LOAD_INVOICE_DASHBOARD_RECORDS_ERROR = `${url}/LOAD_INVOICE_DASHBOARD_RECORDS_ERROR`;
export const LOAD_LAST_TRANSACTIONS_RECORDS = `${url}/LOAD_LAST_TRANSACTIONS_RECORDS`;
export const LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS = `${url}/LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS`;
export const LOAD_LAST_TRANSACTIONS_RECORDS_ERROR = `${url}/LOAD_LAST_TRANSACTIONS_RECORDS_ERROR`;
export const LOAD_QR_RECORD=`${url}/LOAD_QR_RECORD`;
export const LOAD_QR_RECORD_SUCCESS=`${url}/LOAD_QR_RECORD_SUCCESS`;
export const LOAD_QR_RECORD_ERROR=`${url}/LOAD_QR_RECORD_ERROR`;
export const CLEAR = `${url}/CLEAR`;
export const CREATE_CONSOLIDATED_INVOICE = `${url}/CREATE_CONSOLIDATED_INVOICE`;
export const CREATE_CONSOLIDATED_INVOICE_SUCCESS = `${url}/CREATE_CONSOLIDATED_INVOICE_SUCCESS`;
export const CREATE_CONSOLIDATED_INVOICE_ERROR = `${url}/CREATE_CONSOLIDATED_INVOICE_ERROR`;
export const CREATE_INVOICE_VIA_ORDER = `${url}/CREATE_INVOICE_VIA_ORDER`;
export const CREATE_INVOICE_VIA_ORDER_SUCCESS = `${url}/CREATE_INVOICE_VIA_ORDER_SUCCESS`;
export const CREATE_INVOICE_VIA_ORDER_ERROR = `${url}/CREATE_INVOICE_VIA_ORDER_ERROR`;
export const CREATE_INVOICE_VIA_TRIP = `${url}/CREATE_INVOICE_VIA_TRIP`;
export const CREATE_INVOICE_VIA_TRIP_SUCCESS = `${url}/CREATE_INVOICE_VIA_TRIP_SUCCESS`;
export const CREATE_INVOICE_VIA_TRIP_ERROR = `${url}/CREATE_INVOICE_VIA_TRIP_ERROR`;
export const LOAD_STATUS_TIMELINE_RECORDS = `${url}/LOAD_STATUS_TIMELINE_RECORDS`;
export const LOAD_STATUS_TIMELINE_RECORDS_SUCCESS = `${url}/LOAD_STATUS_TIMELINE_RECORDS_SUCCESS`;
export const LOAD_STATUS_TIMELINE_RECORDS_ERROR = `${url}/LOAD_STATUS_TIMELINE_RECORDS_ERROR`;
export const UPDATE_INVOICE_RECORD = `${url}/UPDATE_INVOICE_RECORD`;
export const invoiceConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  CREATE_ADDRESS,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_ERROR,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_ERROR,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_ERROR,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  GET_ALL_ADDRESS,
  GET_ALL_ADDRESS_SUCCESS,
  GET_ALL_ADDRESS_ERROR,
  GET_ADDRESS_BY_CONTRACTOR,
  GET_ADDRESS_BY_CONTRACTOR_SUCCESS,
  GET_ADDRESS_BY_CONTRACTOR_ERROR,
  LOAD_TOTAL_PENDING,
  LOAD_TOTAL_PENDING_SUCCESS,
  LOAD_TOTAL_PENDING_ERROR,
  LOAD_TOTAL_PAID,
  LOAD_TOTAL_PAID_SUCCESS,
  LOAD_TOTAL_PAID_ERROR,
  LOAD_EXCEED_RECORD,
  LOAD_EXCEED_RECORD_SUCCESS,
  LOAD_EXCEED_RECORD_ERROR,
  LOAD_INVOICE_PAYMENT_RECORDS,
  LOAD_INVOICE_PAYMENT_RECORDS_SUCCESS,
  LOAD_INVOICE_PAYMENT_RECORDS_ERROR,
  SYNC_INVOICE_TALLY,SYNC_INVOICE_TALLY_SUCCESS,
  SYNC_INVOICE_TALLY_ERROR,
  CREATE_INVOICE_PAYMENT_RECORDS,
  CREATE_INVOICE_PAYMENT_RECORDS_SUCCESS,
  CREATE_INVOICE_PAYMENT_RECORDS_ERROR,
  LOAD_SEARCH_RECORDS,
  LOAD_SEARCH_RECORDS_SUCCESS,
  LOAD_SEARCH_RECORDS_ERROR,
  LOAD_INVOICE_DASHBOARD_RECORDS,
  LOAD_INVOICE_DASHBOARD_RECORDS_SUCCESS,
  LOAD_INVOICE_DASHBOARD_RECORDS_ERROR,
  LOAD_LAST_TRANSACTIONS_RECORDS,
  LOAD_LAST_TRANSACTIONS_RECORDS_SUCCESS,
  LOAD_LAST_TRANSACTIONS_RECORDS_ERROR,
  LOAD_QR_RECORD,
  LOAD_QR_RECORD_SUCCESS,
  LOAD_QR_RECORD_ERROR,
  CREATE_INVOICE_VIA_ORDER,
  CREATE_INVOICE_VIA_ORDER_SUCCESS,
  CREATE_INVOICE_VIA_ORDER_ERROR,
  CREATE_INVOICE_VIA_TRIP,
  CREATE_INVOICE_VIA_TRIP_SUCCESS,
  CREATE_INVOICE_VIA_TRIP_ERROR,
  CLEAR,
  CREATE_CONSOLIDATED_INVOICE,
  CREATE_CONSOLIDATED_INVOICE_SUCCESS,
  CREATE_CONSOLIDATED_INVOICE_ERROR,
  LOAD_STATUS_TIMELINE_RECORDS_ERROR,
  LOAD_STATUS_TIMELINE_RECORDS_SUCCESS,
  LOAD_STATUS_TIMELINE_RECORDS,
  UPDATE_INVOICE_RECORD,
  
};
