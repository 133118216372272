/*
 *
 *   driver
 *
 */
import produce from 'immer';

import { driverConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  pageSize: 5,
  page: 0,
  total: null,
  tripRecords: [],
  filters: null,
  tripData: null,
  external: null,
  conditional_operator: null,
  statusLoading: false,
  tripCounts: {},
  deleteSuccess: false,
  imageLoader: false
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, recordsMetaData = {}, error, message, page, total, tripRecords, filters, tripData, external, tripCounts } = props;

  return produce(state, (draft) => {
    switch (type) {
      case driverConstants.REQUST_VALID_CACHE:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.LOAD_RECORDS:
      case driverConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.driverId === v.driverId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.searchRecords = [];
        draft.deleteSuccess = false;
        break;
      case driverConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.filters = filters;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.filters = filters;
        break;
      case driverConstants.VIEW_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.VIEW_RECORDS_SUCCESS:
        draft.tripRecords = tripRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case driverConstants.VIEW_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.filters = filters;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case driverConstants.UPDATE_RECORD_SUCCESS:
        const ID = record.driverId || record.ID;
        draft.records = state.records.find((r) => ID === r.driverId)
          ? state.records.map((r) => (ID === r.driverId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.message = message;
        draft.success = false;
        break;
      case driverConstants.DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.driverId.toString() !== record.driverId.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case driverConstants.LOAD_RECORDS_ERROR:
      case driverConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.UPDATE_AVAILABILITY_RECORD:
        draft.statusLoading = true;
        break;
      case driverConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.driverId === r.driverId)
          ? state.records.map((r) => (record.driverId === r.driverId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.statusLoading = false;
        break;
      case driverConstants.UPDATE_AVAILABILITY_RECORD_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case driverConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case driverConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case driverConstants.DRIVER_UPDATE_BYTRIP:
        draft.records = state.records.map((r) => {
          return tripData.driverId === r.driverId ? { ...r, driverTripStatus: external } : r;
        });
        break;
      case driverConstants.TRIPS_COUNT_BY_DRIVER:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        draft.tripCounts = {};
        break;
      case driverConstants.TRIPS_COUNT_BY_DRIVER_SUCCESS:
        draft.tripCounts = tripCounts;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.TRIPS_COUNT_BY_DRIVER_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;

      case driverConstants.RESEND_MSG:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case driverConstants.RESEND_MSG_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.RESEND_MSG_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case driverConstants.UPLOAD_IMAGE:
        draft.imageLoader = true;
        draft.loading = true;
        const loadingItem = { ...record, ...{ imageLoader: true } };
        const unique_identifier = loadingItem.driverId || record.ID;
        draft.records = state.records.find((r) => unique_identifier === r.driverId)
          ? state.records.map((r) => (unique_identifier === r.driverId ? Object.assign({}, r, loadingItem) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, loadingItem)]);
        break;
      case driverConstants.UPLOAD_IMAGE_SUCCESS:
        draft.imageLoader = false;
        draft.loading = false;
        const item = { ...record, ...{ imageLoader: false } };
        const identifier = item.driverId || record.ID;
        draft.records = state.records.find((r) => identifier === r.driverId)
          ? state.records.map((r) => (identifier === r.driverId ? Object.assign({}, r, item) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, item)]);
        break;
      case driverConstants.UPLOAD_IMAGE_ERROR:
        draft.imageLoader = false;
        draft.loading = false;

        break;
      default:
        break;
    }
  });
}
