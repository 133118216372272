import { createContext, useState } from 'react';
const TableRowsSelectionContext = createContext(null);

export const TableRowsSelectionProvider = ({ children }) => {
  const initialRowSelections = [];

  const [rowSelections, setRowSelections] = useState(initialRowSelections);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState({});

  const onChangeRowsSelections = (tableRowsSelections) => {
    setRowSelections((prevSelections) => {
      return { ...tableRowsSelections };
    });
    setSelectedRows((prevSelections) => {
      return [...Object.keys(tableRowsSelections)];
    });
  };
  const clearRowSelections = () => {
    setRowSelections({});
    setSelectedRows([]);
  };

  return (
    <TableRowsSelectionContext.Provider
      value={{
        setRowSelections,
        rowSelections,
        onChangeRowsSelections,
        clearRowSelections,
        setSelectedRows,
        selectedRows,
        selectedContractor,
        setSelectedContractor
      }}
    >
      {children}
    </TableRowsSelectionContext.Provider>
  );
};

export default TableRowsSelectionContext;
