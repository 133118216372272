import { useMemo, useState, useEffect, useRef } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Slider,
  IconButton,
  Skeleton,
  Divider,
  Tooltip
} from '@mui/material';

// third-party
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import useTableContenxt from 'hooks/useTableContext';
import useTableRowsSelectionsContext from 'hooks/useTableRowsSelectionsContext';

import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  Column,
  HeaderGroup,
  Row,
  Cell,
  HeaderProps
} from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
// import Avatar from 'components/@extended/Avatar';
import ScrollX from 'components/ScrollX';
import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';
import TablePagination from './Pagination';
import { dispatch } from 'store';
import {
  DraggableHeader,
  DragPreview,
  HidingSelect,
  HeaderSort,
  IndeterminateCheckbox,
  //TableRowSelection,
  CSVExport,
  EmptyTable,
  Item
} from 'components/third-party/ReactTable';

import { renderFilterTypes, GlobalFilter, DefaultColumnFilter } from 'utils/react-table';

// assets
import { ArrowDown2, ArrowRight2, LayoutMaximize, Maximize1, Filter, Calendar, ArrowLeft2 } from 'iconsax-react';
import DateField from 'components/DateField';

// const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

interface EditableRowProps {
  value: any;
  row: Row;
  column: any;
  updateData: (index: number, id: string, value: string) => void;
  editableRowIndex: number;
}

const EditableRow = ({ value: initialValue, row: { index }, column: { id, dataType }, updateData, editableRowIndex }: EditableRowProps) => {
  const [value, setValue] = useState(initialValue);
  const theme = useTheme();
  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateData(index, id!, value);
  };

  const ShowStatus = (value: string) => {
    switch (value) {
      case 'Created':
        return <Chip color="error" label="Created" size="small" variant="light" />;
      case 'Convert to Order':
        return <Chip color="success" label="Convert to Order" size="small" variant="light" />;
      case 'Single':
      default:
        return <Chip color="info" label="Single" size="small" variant="light" />;
    }
  };

  let element;
  let userInfoSchema;

  switch (id) {
    case 'email':
      userInfoSchema = Yup.object().shape({
        userInfo: Yup.string().email('Enter valid email ').required('Email is a required field')
      });
      break;
    case 'age':
      userInfoSchema = Yup.object().shape({
        userInfo: Yup.number().required('Age is required').min(18, 'You must be at least 18 years').max(100, 'You must be at most 60 years')
      });
      break;
    case 'visits':
      userInfoSchema = Yup.object().shape({
        userInfo: Yup.number().required('Required')
      });
      break;
    default:
      userInfoSchema = Yup.object().shape({
        userInfo: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is Required')
      });
      break;
  }
  let IsEditAble = index === editableRowIndex;

  switch (dataType) {
    case 'text':
      element = (
        <>
          {IsEditAble ? (
            <>
              <Formik
                initialValues={{
                  userInfo: value
                }}
                enableReinitialize
                validationSchema={userInfoSchema}
                onSubmit={() => { }}
              >
                {({ values, handleChange, handleBlur, errors, touched }) => (
                  <Form>
                    <TextField
                      value={values.userInfo}
                      id={`${index}-${id}`}
                      name="userInfo"
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      onBlur={handleBlur}
                      error={touched.userInfo && Boolean(errors.userInfo)}
                      helperText={touched.userInfo && errors.userInfo && (errors.userInfo as string)}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          py: 0.75,
                          px: 1,
                          backgroundColor: theme.palette.mode === 'light' ? 'common.white' : 'inherit'
                        }
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            value
          )}
        </>
      );
      break;
    case 'select':
      element = (
        <>
          {IsEditAble ? (
            <Select
              labelId="demo-simple-select-label"
              sx={{
                '& .MuiOutlinedInput-input': {
                  py: 0.75,
                  px: 1,
                  backgroundColor: theme.palette.mode === 'light' ? 'common.white' : 'inherit'
                }
              }}
              id="demo-simple-select"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            >
              <MenuItem value={'Created'}>
                <Chip color="error" label="Created" size="small" variant="light" />
              </MenuItem>
              <MenuItem value={'Convert to Order'}>
                <Chip color="success" label="Convert to Order" size="small" variant="light" />
              </MenuItem>
              <MenuItem value={'Single'}>
                <Chip color="info" label="Single" size="small" variant="light" />
              </MenuItem>
            </Select>
          ) : (
            ShowStatus(value)
          )}
        </>
      );
      break;
    case 'progress':
      element = (
        <>
          {IsEditAble ? (
            <>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
                <Slider
                  value={value}
                  min={0}
                  max={100}
                  step={1}
                  onBlur={onBlur}
                  onChange={(event: Event, newValue: number | number[]) => {
                    setValue(newValue);
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="non-linear-slider"
                />
              </Stack>
            </>
          ) : (
            <div>
              <LinearWithLabel value={value} sx={{ minWidth: 75 }} />
            </div>
          )}
        </>
      );
      break;
    default:
      element = <span>{value}</span>;
      break;
  }
  return element;
};

function ReactTable({
  columns,
  data,
  showCSV,
  showFilter,
  showPagination,
  showFooter,
  showEach,
  showDateField,
  multiSelect,
  showCalendar,
  setShowCalendar,
  loading,
  showColumnFilter,
  showFilterIcon,
  showRow,
  total,
  actions,
  //rowsPage,
  queryParameters,
  //setRowsPage,
  contextKey = 'driver',
  filtersParams = {},
  extraGlobalFilterColumns = [],
  extraCSVColumns = [],
  getCustomRowId,
  renderRowSubComponent,
  isSubComponent,
  skeletonRows=[0, 1, 2, 3, 4, 5, 6, 7]
}: {
  columns: Column[];
  data: any[];
  showCSV: boolean;
  showFilter: boolean;
  showPagination: boolean;
  showFooter: boolean;
  showEach: boolean;
  showDateField: boolean;
  showFilterIcon: boolean;
  multiSelect: boolean;
  loading: boolean;
  showColumnFilter: boolean;
  setShowCalendar: any;
  showCalendar: boolean;
  showRow: boolean;
  total: any;
  actions: any;
  //rowsPage: any;
  queryParameters: any;
  //setRowsPage:any;
  contextKey: any;
  filtersParams?: any;
  extraGlobalFilterColumns?: any;
  extraCSVColumns?: any;
  getCustomRowId?: any;
  renderRowSubComponent?:any;
  isSubComponent?:any;
  skeletonRows?:any
}) {
  const theme = useTheme();
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.50';
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [showCSVDownload] = useState(showCSV);
  const [showGlobalFilter] = useState(showFilter);
  const [showTablePagination] = useState(showPagination);
  const [showMultiSelect] = useState(multiSelect);
  const [showTableFooter] = useState(showFooter);
  const [startFilter, setStartFilter] = useState('');
  const [endFilter, setEndFilter] = useState('');
  const [showDate] = useState(showDateField);
  const { tableConfig, onChangePageSize, onChangePagination }: any = useTableContenxt();
  const [showEachFilter, setShowEachFilter] = useState(tableConfig[contextKey]?.showEachFilter || showEach);
  const [rowsPage] = useState(tableConfig[contextKey]?.pageSize);
  let initialDate = new Date().toISOString().split('T')[0];
  const containerRef = useRef(null);
  const [showScrollArrows, setShowScrollArrows] = useState(false);
  const { rowSelections, onChangeRowsSelections }: any = useTableRowsSelectionsContext();

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      Cell: EditableRow
    }),
    []
  );
  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement;
      setShowScrollArrows(container.scrollWidth > container.clientWidth);
    }
  };

  const slideLeft = () => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement;
      container.scrollLeft -= 500;
    }
  };

  const slideRight = () => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement;
      container.scrollLeft += 500;
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current as HTMLDivElement;
      const resizeObserver = new ResizeObserver(() => {
        setShowScrollArrows(container.scrollWidth > container.clientWidth);
      });
      resizeObserver.observe(container);
      container.addEventListener('scroll', handleScroll);
      return () => {
        if (container) {
          resizeObserver.unobserve(container);
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [containerRef.current]);

  const handleShow = () => {
    setShowEachFilter(!showEachFilter);
    let params: any = {
      globalAutoFocus: '',
      globalFilterValue: '',
      filters: {},
      columnFilters: {},
      page: 0,
      showEachFilter: !showEachFilter
    };
    if (showEachFilter) {
      setAllFilters([]);
    } else {
      setGlobalFilter('');
      setValue('');
    }
    handleTableContext(params);
  };
  const initialState = useMemo(
    () => ({
      queryParameters: [{ id: 'status', value: '' }],
      hiddenColumns: ['id', 'role', 'contact', 'country', 'fatherName'],
      pageSize: rowsPage,
      selectedRowIds: rowSelections
    }),
    [rowsPage, rowSelections]
  );
  const getDefaultRowId = (row: any, relativeIndex: any, parent: any) => {
    return row && row.ID ? row.ID : relativeIndex;
  };
  const getRowId = getCustomRowId ? getCustomRowId : getDefaultRowId;
  const [rowTotal, setRowTotal] = useState(total);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    setColumnOrder,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    allColumns,
    state: { filters, globalFilter, hiddenColumns, pageSize, pageIndex, columnOrder, selectedRowIds },
    setGlobalFilter,
    selectedFlatRows,
    setAllFilters
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getRowId,
      autoResetPage: false,
      initialState,
      filterTypes,
      pageCount: Math.ceil(rowTotal / rowsPage),
      editableRowIndex,
      setEditableRowIndex
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (showMultiSelect) {
        hooks.allColumns.push((columns) => [
          {
            title: 'Row Selection',
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }: HeaderProps<{}>) => (
                <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />
            ),
            accessor: 'selection',
            groupByBoundary: true,
            Cell: ({ row }: any) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),

            disableSortBy: true,
            className: 'cell-right',
            disableFilters: true,
            disableGroupBy: true,
            Aggregated: () => null
          },
          ...columns
        ]);
      }
      hooks.allColumns.push((columns) => [...columns]);
    }
  );
  const handleTableContext = (params: any) => {
    const { page } = params;
    params = { ...tableConfig[contextKey], ...params };
    onChangePagination(contextKey, params);
    if (contextKey === 'pendingOrders') {
      dispatch(actions.loadPendingRecords(params));
    } else if (contextKey === 'exceedLimit') {
      dispatch(actions.loadExceedRecord(params));
    } else if (contextKey === 'topPendingCustomers') {
      dispatch(actions.loadTopPendingContractorsRecords(params));
    } else if (contextKey === 'stockgroup') {
      dispatch(actions.loadGroupRecords(params));
    } else if (contextKey === 'adjustment') {
      dispatch(actions.loadStockAdjustments(params));
    } else {
      dispatch(actions.loadRecords(params));
    }

    if (page === 0) {
      gotoPage(0);
    }
  };
  useEffect(() => {
    onChangeRowsSelections(selectedRowIds);
  }, [selectedRowIds]);
  const [value, setValue] = useState(globalFilter);
  useEffect(() => {
    if (rowsPage !== pageSize) {
      onChangePageSize(contextKey, { pageSize: pageSize });
    }
  }, [pageSize]);

  useEffect(() => {
    setRowTotal(total);
  }, [value, total, rows, filters]);

  //Removed this for global search next page duplication in table

  // useEffect(() => {
  //   if (value || (filters && filters.length)) {
  //     gotoPage(0);
  //   }
  // }, [value, filters]);
  //console.log(rowsPage)

  useEffect(() => {
    setPageSize(tableConfig[contextKey]?.pageSize);
  }, [tableConfig[contextKey]?.pageSize]);

  useEffect(() => {
    gotoPage(tableConfig[contextKey]?.page);
  }, [tableConfig[contextKey]?.page]);

  const navigatePage = (value: number) => {
    gotoPage(value);
  };
  const reorder = (item: Item, newIndex: number) => {
    const { index: currentIndex } = item;

    const dragRecord = columnOrder[currentIndex];
    setColumnOrder(
      update(columnOrder, {
        $splice: [
          [currentIndex, 1],
          [newIndex, 0, dragRecord]
        ]
      })
    );
  };
  let headers: LabelKeyObject[] = [];
  allColumns.map((item) => {
    if (!hiddenColumns?.includes(item.id) && item.id !== 'selection' && item.id !== 'edit') {
      headers.push({ label: typeof item.Header === 'string' ? item.Header : '#', key: item.id });
    }
    return item;
  });
  if (extraCSVColumns && extraCSVColumns.length) {
    if (contextKey === 'order') {
      const newArray = [...headers];
      extraCSVColumns.forEach((newElement: any) => {
        const isDuplicate = newArray.some((existingElement: any) => {
          return existingElement.key === newElement.key;
        });
        if (!isDuplicate) {
          if (newElement.index !== undefined) {
            newArray.splice(newElement.index, 0, newElement);
          } else {
            newArray.push(newElement);
          }
        }
      });
      headers = newArray;
    } else {
      headers = [...headers, ...extraCSVColumns];
    }
  }

  return (
    <>
      <Stack spacing={!isSubComponent ? 2 : 0}>
        {showRow ? (
          <Stack direction="row" justifyContent={showCSV && !showFilter ? 'flex-end' : !showEachFilter ? 'space-between' : 'flex-end'} sx={{ p: 2, pb: 0 }}>
            {showGlobalFilter && !showEachFilter ? (
              <GlobalFilter
                handleTableContext={handleTableContext}
                contextKey={contextKey}
                allColumns={allColumns}
                loading={loading}
                setValue={setValue}
                value={value}
                preGlobalFilteredRows={total}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                extraGlobalFilterColumns={extraGlobalFilterColumns}
                size="small"
              />
            ) : (
              ''
            )}
            <Stack direction="row" spacing={2}>
              {showDate || !showColumnFilter ? (
                ''
              ) : (
                <HidingSelect contextKey={contextKey} hiddenColumns={hiddenColumns!} setHiddenColumns={setHiddenColumns} allColumns={allColumns} />
              )}
              {showDate ? (
                <>
                  <DateField
                    label={''}
                    fieldValue={startFilter}
                    setFieldValue={setStartFilter}
                    required={false}
                    disableFuture={false}
                    disablePast={false}
                    disabled={false}
                    error={false}
                  />
                  <DateField
                    label={''}
                    fieldValue={endFilter}
                    setFieldValue={setEndFilter}
                    required={false}
                    disableFuture={false}
                    disablePast={false}
                    disabled={false}
                    error={false}
                  />
                </>
              ) : (
                ''
              )}

              {showFilterIcon ? (
                <IconButton onClick={handleShow} sx={{ color: 'secondary.main', bgcolor: showEachFilter ? iconBackColorOpen : iconBackColor }}>
                  <Filter />
                </IconButton>
              ) : (
                ''
              )}
              {tableConfig[contextKey] && tableConfig[contextKey]['showCalendarFilter'] ? (
                <IconButton
                  sx={{ color: 'secondary.main', bgcolor: tableConfig[contextKey]['showCalendar'] ? iconBackColorOpen : iconBackColor }}
                  onClick={() => {
                    let showCalendar = tableConfig[contextKey]['showCalendar'];
                    let queryParams = tableConfig[contextKey]['queryParams'];
                    //onChangeCalendar(contextKey, !showCalendar);
                    gotoPage(0);
                    setShowCalendar(!showCalendar);
                    setValue('');
                    setGlobalFilter('');
                    let params: any = {
                      showCalendar: !showCalendar,
                      page: 0,
                      queryParams: !showCalendar
                        ? {
                            ...queryParams,
                            delivery_date: tableConfig[contextKey].queryParams?.delivery_date ? tableConfig[contextKey].queryParams?.delivery_date : initialDate
                          }
                        : {
                            ...(queryParams && queryParams.hasOwnProperty('delivery_date') && delete queryParams.delivery_date)
                          }
                    };
                    if (!showCalendar) {
                      params.queryParams = {
                        ...params.queryParams,
                        delivery_date: tableConfig[contextKey].queryParams?.delivery_date ? tableConfig[contextKey].queryParams?.delivery_date : initialDate
                      };
                    } else {
                      params.queryParams = tableConfig[contextKey].queryParams;
                    }
                    handleTableContext(params);
                  }}
                >
                  <Calendar />
                </IconButton>
              ) : (
                ''
              )}
              {showCSVDownload ? (
                <IconButton>
                  <CSVExport
                    data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d: Row) => d.original) : data}
                    filename={'records.csv'}
                    headers={headers}
                  />
                </IconButton>
              ) : (
                ''
              )}
              {showScrollArrows && (
                <>
                  <Tooltip title="Scroll Left">
                    <IconButton onClick={slideLeft}>
                      <ArrowLeft2 size="28" color="#b4b4b4" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Scroll Right">
                    <IconButton onClick={slideRight}>
                      <ArrowRight2 size="28" color="#b4b4b4" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
        ) : (
          ''
        )}

        <Box sx={{ width: '100%', overflowX: 'auto', display: 'block' }}>
          <div ref={containerRef} style={{ overflowX: 'auto' }}>
            <Table {...getTableProps()}>
              <TableHead sx={{ borderTopWidth: 2 }}>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: HeaderGroup<{}>, index: number) => {
                      const groupIcon = column.isGrouped ? <Maximize1 size={18} /> : <LayoutMaximize size={18} />;
                      return (
                        <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                          <DraggableHeader reorder={reorder} key={column.id} column={column} index={index}>
                            <Stack direction="row" spacing={1.15} alignItems="center" sx={{ display: 'inline-flex' }}>
                              {column.canGroupBy ? (
                                <Box sx={{ color: column.isGrouped ? 'error.main' : 'primary.main', fontSize: '1rem' }} {...column.getGroupByToggleProps()}>
                                  {groupIcon}
                                </Box>
                              ) : null}
                              <HeaderSort column={column} sort />
                            </Stack>
                          </DraggableHeader>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()} className="striped">
                {showEachFilter &&
                  headerGroups.map((group: HeaderGroup<{}>) => (
                    <TableRow {...group.getHeaderGroupProps()}>
                      {group.headers.map((column: HeaderGroup) => (
                        <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                          {column.canFilter ? (
                            column.render('Filter', { props: { contextKey, handleTableContext } })
                          ) : column.Header === 'Actions' ? (
                            <Tooltip title="Close Filter">
                              <IconButton color="error" onClick={handleShow}>
                                X
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {loading ? (
                  skeletonRows.map((rowItem: number, rowIndex: number) => (
                    <TableRow key={rowIndex}>
                      {allColumns.map((col: any, colIndex: any) => (
                        <TableCell key={colIndex}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <>
                    {page.length > 0 ? (
                      page.map((row: Row, i: number) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                          <>
                            <TableRow
                              {...row.getRowProps()}
                              onClick={() => {
                                if (multiSelect) {
                                  row.toggleRowSelected();
                                }
                              }}
                              sx={{
                                '& .MuiTableCell-root': { paddingLeft: '5px !important' },
                                cursor: 'pointer',
                                bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit'
                              }}
                            >
                              {row.cells.map((cell: Cell) => {
                                let bgcolor = 'inherit';
                                if (cell.isGrouped) bgcolor = 'success.lighter';
                                if (cell.isAggregated) bgcolor = 'warning.lighter';
                                if (cell.isPlaceholder) bgcolor = 'error.lighter';
                                if (cell.isPlaceholder) bgcolor = 'error.lighter';
                                if (row.isSelected) bgcolor = alpha(theme.palette.primary.lighter, 0.35);
                                const collapseIcon = row.isExpanded ? <ArrowDown2 /> : <ArrowRight2 />;

                                return (
                                  <TableCell {...cell.getCellProps([{ className: cell.column.className }])} sx={{ bgcolor }}>
                                    {cell.isGrouped ? (
                                      <Stack direction="row" spacing={1} alignItems="center" sx={{ display: 'inline-flex' }}>
                                        <Box
                                          sx={{ pr: 1.25, fontSize: '0.75rem', color: 'text.secondary' }}
                                          onClick={(e: any) => {
                                            row.toggleRowExpanded();
                                            e.stopPropagation();
                                          }}
                                        >
                                          {collapseIcon}
                                        </Box>
                                        {cell.render('Cell')} ({row.subRows.length})
                                      </Stack>
                                    ) : cell.isAggregated ? (
                                      cell.render('Aggregated')
                                    ) : cell.isPlaceholder ? null : cell.value === '' || cell.value === null ? (
                                      '-'
                                    ) : (
                                      cell.render('Cell')
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                            {row.isExpanded && renderRowSubComponent({ row, rowProps })}
                          </>
                        );
                      })
                    ) : (
                      <EmptyTable msg="No Records Found" colSpan={allColumns.length} />
                    )}
                  </>
                )}
              </TableBody>
              {/* footer table */}
              {showTableFooter ? (
                <TableFooter sx={{ borderBottomWidth: 2 }}>
                  {footerGroups.map((group) => (
                    <TableRow {...group.getFooterGroupProps()}>
                      {group.headers.map((column: HeaderGroup) => (
                        <TableCell {...column.getFooterProps([{ className: column.className }])}>{column.render('Footer')}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableFooter>
              ) : (
                ''
              )}
            </Table>
          </div>
        </Box>

        {showTablePagination ? (
          <>
            <Divider sx={{ '&&': { m: 0, mt: 0 } }} />
            <Box sx={{ '&&': { p: 2, py: 1, my: 0 } }}>
              <TablePagination
                actions={actions}
                gotoPage={navigatePage}
                rows={rows}
                setPageSize={setPageSize}
                pageIndex={pageIndex}
                contextKey={contextKey}
                handleTableContext={handleTableContext}
                pageSize={tableConfig[contextKey]?.pageSize}
                total={rowTotal}
                queryParameters={queryParameters}
                filters={filtersParams}
              />
            </Box>
          </>
        ) : (
          ''
        )}
      </Stack>
    </>
  );
}

// ==============================|| REACT TABLE  ||============================== //

const CustomTable = (props: any) => {
  const {
    showCSV,
    showFilter,
    showPagination,
    showFooter,
    rows,
    headcells,
    showEach,
    title = '',
    multiSelect = false,
    loading,
    showDateField,
    showCalendar,
    setShowCalendar,
    showFilterIcon,
    showColumnFilter,
    showRow,
    total,
    actions,
    children,
    queryParameters,
    filterParameters,
    contextKey = 'default',
    extraCSVColumns = [],
    extraGlobalFilterColumns = [],
    getCustomRowId = null,
    renderRowSubComponent=null,
    sx={},
    isSubComponent=false,
    skeletonRows
  } = props;
  const data = useMemo(() => rows, [rows, loading]);
  const columns = useMemo(() => headcells as unknown as Column[], [headcells]);
  return (
    <MainCard title={title} content={false} sx={{...sx}}>
      <ScrollX>
        <DndProvider backend={HTML5Backend}>
          <ReactTable
            columns={columns}
            loading={loading}
            data={data}
            showFilter={showFilter}
            showCSV={showCSV}
            showPagination={showPagination}
            showFooter={showFooter}
            showEach={showEach}
            showDateField={showDateField}
            multiSelect={multiSelect}
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
            showFilterIcon={showFilterIcon}
            showColumnFilter={showColumnFilter}
            showRow={showRow}
            total={total}
            actions={actions}
            queryParameters={queryParameters}
            filtersParams={filterParameters}
            contextKey={contextKey}
            extraGlobalFilterColumns={extraGlobalFilterColumns}
            extraCSVColumns={extraCSVColumns}
            getCustomRowId={getCustomRowId}
            renderRowSubComponent={renderRowSubComponent}
            isSubComponent={isSubComponent}
            skeletonRows={skeletonRows}
          />
          {children ? children : ''}
          <DragPreview />
        </DndProvider>
      </ScrollX>
    </MainCard>
  );
};

export default CustomTable;
