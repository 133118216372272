import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'driver';

//Load driver records
function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  record.hasOwnProperty('image') && delete record.image;
  const { driverName, drivingLicense, expiryDate, mobile, governmentId, governmentIdType, driverStatus, driverTripStatus } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${record.driverId}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign(
        {},
        { driverStatus: String(driverStatus), driverName, drivingLicense, expiryDate, mobile, governmentId, governmentIdType, driverTripStatus }
      )
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  record.hasOwnProperty('image') && delete record.image;
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(record) {
  const { driverCognitoUsername, driverId } = record;
  let queryParams = { softDelete: 'True', driverCognitoUsername };
  return API.del('tennat-service', `${constantName}/${driverId}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function tripsByDriver(id, status) {
  let queryParam = { status: status };
  return API.get('tennat-service', `trips/driver/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParam }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateDriverStatus(record) {
  const { driverStatus, driverTripStatus } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/status/${record.driverId}`,
    getDefaultParamswithoutlimitkey({ body: Object.assign({}, { driverStatus: String(driverStatus), driverTripStatus }) })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function tripsCountByDriver(id) {
  return API.get('tennat-service', `driver/counts/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
async function uploadProfilePhoto(record) {
  const fileReaderPromise = () => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(record.image);
      fileReader.onload = () => {
        let srcData = fileReader.result;
        record.imageUrl = srcData;
        srcData = srcData.substring(srcData.indexOf(',') + 1, srcData.length);
        resolve(srcData);
      };
    });
  };
  let queryParams = { module: 'driver', ID: record.driverId };
  const srcData = await fileReaderPromise();
  return API.post(
    'tennat-service',
    `${'companyprofile'}/${'uploadimage'}`,
    getDefaultParamswithoutlimitkey(null, { body: srcData, queryStringParameters: queryParams })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function resendMsg(record) {
  const queryParams = { mobile: record };
  return API.get('tennat-service', `${constantName}/resendpassword`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export const driverService = {
  loadRecords,
  updateRecord,
  createRecord,
  loadRecord,
  deleteRecord,
  tripsByDriver,
  updateDriverStatus,
  tripsCountByDriver,
  uploadProfilePhoto,
  resendMsg
};
