/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { contractorConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  address: [],
  selectedContractor: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  openModal: false,
  lastUpdate: null,
  message: null,
  pageSize: null,
  page: 0,
  total: null,
  counts: null,
  filters: null,
  orderCounts: {},
  deleteSuccess: false,
  mapRecord: {},
  mapLocation: {},
  tallyCompanyRecords: [],
  tallyCompanyLoading: false,
  filterMonth: null
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    id,
    record,
    records,
    address,
    selectedContractor,
    recordsMetaData = {},
    error,
    message,
    page,
    isModalOpen,
    total,
    counts,
    transactionRecords,
    paymentRecords,
    filters,
    orderCounts,
    mapRecord,
    topPendingContractors
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case contractorConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_RECORDS:
      case contractorConstants.UPLOAD_CONTRACTORS:
      case contractorConstants.LOAD_RECORD:
      case contractorConstants.GET_ADDRESS_BY_CONTRACTOR:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.GET_SELECTED_CONTRACTOR:
        draft.selectedContractor = selectedContractor;
        break;
      case contractorConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.contractorId === v.contractorId) === i);
        draft.tallyCompanyRecords = [];
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.page = page;
        draft.deleteSuccess = false;
        draft.searchRecords = [];
        break;
      case contractorConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.filters = filters;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.deleteSuccess = false;
        draft.filters = filters;
        break;
      case contractorConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.filters = filters;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case contractorConstants.UPLOAD_CONTRACTORS_SUCCESS:
        let allRecord = state.records.concat(records);
        draft.records = allRecord.filter((v, i, a) => a.findIndex((v2) => v2.contractorId === v.contractorId) === i);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + records.length;
        break;
      case contractorConstants.UPLOAD_CONTRACTORS_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        break;

      case contractorConstants.SYNC_CONTRACTOR_TALLY:
        draft.tallyCompanyLoading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case contractorConstants.SYNC_CONTRACTOR_TALLY_SUCCESS:
        draft.tallyCompanyRecords = record;
        draft.tallyCompanyLoading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.SYNC_CONTRACTOR_TALLY_ERROR:
        draft.tallyCompanyLoading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        break;

      case contractorConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.contractorId === r.contractorId)
          ? state.records.map((r) => (record.contractorId === r.contractorId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.contractorId.toString() !== record.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case contractorConstants.LOAD_RECORDS_ERROR:
      case contractorConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.CREATE_ADDRESS_ERROR:
      case contractorConstants.GET_ADDRESS_BY_CONTRACTOR_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.UPDATE_RECORD_ERROR:
      case contractorConstants.DELETE_RECORD_ERROR:
      case contractorConstants.DELETE_ADDRESS_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case contractorConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case contractorConstants.RESET_MAP_LOCATION:
        draft.mapLocation = {};
        break;
      case contractorConstants.CREATE_ADDRESS_SUCCESS:
        draft.address = [address].concat(state.address);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.mapLocation = {};
        break;
      case contractorConstants.GET_ADDRESS_BY_CONTRACTOR_SUCCESS:
        draft.address = address;
        draft.record = '';
        draft.openModal = isModalOpen && true;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.DELETE_ADDRESS_SUCCESS:
        draft.address = error ? state.address : state.address.filter((r) => r.addressId.toString() !== id.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.LOAD_RECORDS_COUNT:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_RECORDS_COUNT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.counts = counts;
        break;
      case contractorConstants.LOAD_RECORDS_COUNT_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.counts = 0;
        break;
      case contractorConstants.OPEN_MODAL:
        draft.openModal = true;
        break;
      case contractorConstants.CLOSE_MODAL:
        draft.openModal = false;
        draft.openPopup = false;
        draft.open = false;
        break;
      case contractorConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_TRANSACTION_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_TRANSACTION_RECORDS_SUCCESS:
        draft.transactionRecords = transactionRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.total = total;
        draft.openPopup = true;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case contractorConstants.LOAD_TRANSACTION_RECORDS_ERROR:
        draft.error = true;
        draft.success = false;
        draft.message = '';
        break;

      case contractorConstants.LOAD_AUTOFILL_ADDRESS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_AUTOFILL_ADDRESS_SUCCESS:
        draft.mapLocation = mapRecord;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case contractorConstants.LOAD_AUTOFILL_ADDRESS_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = '';
        draft.mapLocation = {};
        break;
      case contractorConstants.LOAD_PAYMENT_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_PAYMENT_RECORDS_SUCCESS:
        draft.paymentRecords = paymentRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.open = true;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        break;
      case contractorConstants.LOAD_PAYMENT_RECORDS_ERROR:
        draft.error = true;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.CREATE_PAYMENT_RECORDS_SUCCESS:
        draft.paymentRecords = [record].concat(state.paymentRecords);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.CREATE_PAYMENT_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.ORDERS_COUNT_BY_CONTRACTOR:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        draft.orderCounts = {};
        break;
      case contractorConstants.ORDERS_COUNT_BY_CONTRACTOR_SUCCESS:
        draft.orderCounts = orderCounts;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;

      case contractorConstants.UPDATE_AVAILABILITY_RECORD:
        draft.statusLoading = true;
        break;
      case contractorConstants.UPDATE_AVAILABILITY_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.contractorId === r.contractorId)
          ? state.records.map((r) => (record.contractorId === r.contractorId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.statusLoading = false;
        break;
      case contractorConstants.UPDATE_AVAILABILITY_RECORD_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.ORDERS_COUNT_BY_CONTRACTOR_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS_SUCCESS:
        let allTotalPendingRecords = '';
        page === 0 ? (allTotalPendingRecords = topPendingContractors) : (allTotalPendingRecords = state.topPendingContractors.concat(topPendingContractors));
        draft.topPendingContractors = allTotalPendingRecords.filter((v, i, a) => a.findIndex((v2) => v2.ID === v.ID) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.open = true;
        draft.loading = false;
        draft.total = total;
        draft.error = false;
        draft.success = false;
        break;
      case contractorConstants.LOAD_TOP_PENDING_CONTRACTORS_RECORDS_ERROR:
        draft.error = true;
        draft.success = false;
        draft.message = '';
        draft.loading = false;
        break;
      case contractorConstants.UPDATE_PAYMENT_RECORD:
        draft.loading = true;
        break;
      case contractorConstants.UPDATE_PAYMENT_RECORDS_SUCCESS:
        draft.records = state.records.find((r) => record.contractorId === r.contractorId)
          ? state.records.map((r) => (record.contractorId === r.contractorId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.loading = false;
        break;
      case contractorConstants.UPDATE_PAYMENT_RECORDS_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case contractorConstants.DELETE_PAYMENT_RECORDS_SUCCESS:
        draft.transactionRecords = error ? state.transactionRecords : state.transactionRecords.filter((r) => r.ID.toString() !== id.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case contractorConstants.DELETE_PAYMENT_RECORDS_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.error = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case contractorConstants.RESET_DELETE_PAYMENT_RECORD:
        draft.deleteSuccess = false;
        break;

      case contractorConstants.CLEAR_TALLY_RECORD:
        draft.tallyCompanyRecords = [];
        break;
        
      case contractorConstants.SET_FILTERMONTH:
        draft.filterMonth = record;
        break;

      default:
        break;
    }
  });
}
