import { useState,useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Grid, Stack, Typography,RadioGroup,Radio,FormControlLabel } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
//import MessageCard from 'components/cards/statistics/MessageCard';
import NoticeBoardList from 'components/NoticeBoardCard/list';
import AddNoticeBoard from 'components/NoticeBoardCard/add';
import EditNoticeBoard from 'components/NoticeBoardCard/edit';

// assets
import { Add, NotificationStatus,AddCircle,Refresh } from 'iconsax-react';

import {dispatch,useSelector} from "store";
import {noticeBoardActions,dialogActions} from "store/actions"; 
 import Loader from 'components/LoaderComponent'

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const Customization = () => {
  const theme = useTheme();
  const {user } = useSelector((state) => state.sessionReducer); 
  const {records,loading } = useSelector((state) => state.noticeBoard); 
  const [open, setOpen] = useState(false);
  const [days, setDays] = useState(7);
  const [action, setAction] = useState('list');
  const [selectedRecord, setSelectedRecord] = useState('');
  const [noticeBoardID, setNoticeboardID] = useState(null);
  const handleToggle = () => {
    setOpen(!open);
    setAction('list');
    dispatch(dialogActions.request(false))
  };  
  const categoryOptions = ['Delivery', 'Trip Status', 'Order Status','Leave'];   
  const categoryColor = { 
    'Delivery':'success',
    'Trip Status':'success',
    'Order Status':'success',
    'Leave':'error'
  }
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';
  useEffect(()=>{ 
      dispatch(noticeBoardActions.loadRecords(100,0,days))
  },[days]) 
    
  const openAddDialog = (action,record)=>{
      setAction(action)
      if(record){
        setSelectedRecord(record)  
      }
      
  }
  
  const handleChangeDays = (e)=>{
    setDays(e.target.value)
  }

  const ListComponent = ()=>{
    return <>
      <Box sx={{ p: 2.5 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
        <Typography variant="h5">Notice Board </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <IconButton color="secondary" sx={{ p: 0 }} onClick={()=>{openAddDialog('add');}}>
            <AddCircle
               size="40" 
              />
          </IconButton>
          <IconButton color="secondary" sx={{ p: 0 }} onClick={()=>{dispatch(noticeBoardActions.loadRecords())}}>
            <Refresh
               size="40" 
              />
          </IconButton>
          <IconButton color="secondary" sx={{ p: 0 }} onClick={handleToggle}>
            <Add size={28} style={{ transform: 'rotate(45deg)' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="column" sx={{mt:1}}>
        <RadioGroup row aria-label="color" name="days" value={days} id="days" sx={{"justifyContent":"flex-end"}} onChange={handleChangeDays}>
          <FormControlLabel value="7" control={<Radio />} label="Last 7 days" />
          <FormControlLabel value="30" control={<Radio color="primary" />} label="Last 30 days" />
          <FormControlLabel value="90" control={<Radio color="primary" />} label="Last 3 Months" />
        </RadioGroup>
      </Stack>
      <Grid container spacing={1.5} sx={{"justifyContent":"center",mt: 2}}>
        {!loading && records.map((row,index)=>( <><Grid container  sx={{mt:1,ml:1,background:"#f0f4ec"}}>
            <Grid item xs={12} sm={8} sx={{p:0.5}}>
              <Typography variant="h6" key={`typo-${index}`} sx={{ml:1}}>{row.day}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{p:0.5}}>
              <Typography variant="h6" key={`typo-${index}`} sx={{ml:1}}>Total {row.count}</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12}>
              {
                row.messages && row.messages.map((item,index)=>(
                    <NoticeBoardList
                      openAddDialog={openAddDialog}
                      keyValue={`noticeBoard-${index}`}
                      category={item.label}
                      status={item.status}
                      time={item.creationDate}
                      title={item.title?item.title:`${user?.attributes["custom:userRole"]}-${item.createdBy}`}
                      message={item.message}
                      selectedRecord={item}
                      setNoticeboardID={setNoticeboardID} 
                      noticeBoardID={noticeBoardID}
                    /> 
                  
                ))
              }
              {row.messages.length == 0 && <Typography variant="h6" sx={{pl:1}}>No Records</Typography>}
              
         
        </Grid>   
        </> ))}
        {loading && <Loader sx={{mt:6}}/>}
      </Grid>
    </Box>
    </>
  }
  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          color="secondary"
          variant="light"
          onClick={handleToggle}
          aria-label="settings toggler"
          size="large"
          sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, p: 1 }}
        >
          <NotificationStatus variant="Bulk" />
        </IconButton>
      </Box>
      <Drawer sx={{ zIndex: 2001 }} anchor="right" onClose={handleToggle} open={open} PaperProps={{ sx: { width: { xs: 350, sm: 474 } } }}>
        {open && (
          <MainCard content={false} sx={{ border: 'none', borderRadius: 0, height: '100vh' }}>
            <SimpleBar
              sx={{
                '& .simplebar-content': {
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              {action == 'list'?<ListComponent/>:''}
              {action == 'add'?<AddNoticeBoard categoryColor={categoryColor} categoryOptions={categoryOptions} setAction={setAction} handleToggle={handleToggle} noticeBoardActions={noticeBoardActions}/>:''}
              {action == 'edit'?<EditNoticeBoard categoryColor={categoryColor} categoryOptions={categoryOptions} setAction={setAction} selectedRecord={selectedRecord} handleToggle={handleToggle} noticeBoardActions={noticeBoardActions}/>:''}
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
