/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/order`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
const LOAD_RECORD = `${url}/LOAD_RECORD`;
const LOAD_RECORD_SUCCESS = `${url}/LOAD_RECORD_SUCCESS`;
const LOAD_RECORD_ERROR = `${url}/LOAD_RECORD_ERROR`;
const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const LOAD_RECORDS_COUNT = `${url}/LOAD_RECORDS_COUNT`;
const LOAD_RECORDS_COUNT_SUCCESS = `${url}/LOAD_RECORDS_COUNT_SUCCESS`;
const LOAD_RECORDS_COUNT_ERROR = `${url}/LOAD_RECORDS_COUNT_ERROR`;
const LOAD_RECORDS_PRODUCTS_COUNT = `${url}/LOAD_RECORDS_PRODUCTS_COUNT`;
const LOAD_RECORDS_PRODUCTS_COUNT_SUCCESS = `${url}/LOAD_RECORDS_PRODUCTS_COUNT_SUCCESS`;
const LOAD_RECORDS_PRODUCTS_COUNT_ERROR = `${url}/LOAD_RECORDS_PRODUCTS_COUNT_ERROR`;
const LOAD_UP_COMING_RECORDS = `${url}/LOAD_UP_COMING_RECORDS`;
const LOAD_UP_COMING_RECORDS_SUCCESS = `${url}/LOAD_UP_COMING_RECORDS_SUCCESS`;
const LOAD_UP_COMING_RECORDS_ERROR = `${url}/LOAD_UP_COMING_RECORDS_ERROR`;
const LOAD_PENDING_RECORDS = `${url}/LOAD_PENDING_RECORDS`;
const LOAD_PENDING_RECORDS_SUCCESS = `${url}/LOAD_PENDING_RECORDS_SUCCESS`;
const LOAD_PENDING_RECORDS_ERROR = `${url}/LOAD_PENDING_RECORDS_ERROR`;
const LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS = `${url}/LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS`;
const LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_SUCCESS = `${url}/LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_SUCCESS`;
const LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_ERROR = `${url}/LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_ERROR`;
const LOAD_SEARCH = `${url}/LOAD_SEARCH`;
const LOAD_SEARCH_SUCCESS = `${url}/LOAD_SEARCH_SUCCESS`;
const LOAD_SEARCH_ERROR = `${url}/LOAD_SEARCH_ERROR`;
const LOAD_TODAY_RECORDS = `${url}/LOAD_TODAY_RECORDS`;
const LOAD_TODAY_RECORDS_SUCCESS = `${url}/LOAD_TODAY_RECORDS_SUCCESS`;
const LOAD_TODAY_RECORDS_ERROR = `${url}/LOAD_TODAY_RECORDS_ERROR`;
const LOAD_REVENUE_DAYS_RECORDS = `${url}/LOAD_REVENUE_DAYS_RECORDS`;
const LOAD_REVENUE_DAYS_RECORDS_SUCCESS = `${url}/LOAD_REVENUE_DAYS_RECORDS_SUCCESS`;
const LOAD_REVENUE_DAYS_RECORDS_ERROR = `${url}/LOAD_REVENUE_DAYS_RECORDS_ERROR`;
const LOAD_MARKETING_PERSONNAMES = `${url}/LOAD_MARKETING_PERSONNAMES`;
const LOAD_MARKETING_PERSONNAMES_SUCCESS = `${url}/LOAD_MARKETING_PERSONNAMES_SUCCESS`;
const LOAD_MARKETING_PERSONNAMES_ERROR = `${url}/LOAD_MARKETING_PERSONNAMES_ERROR`;
const LOAD_STATUS_TIMELINE_RECORDS = `${url}/LOAD_STATUS_TIMELINE_RECORDS`;
const LOAD_STATUS_TIMELINE_RECORDS_SUCCESS = `${url}/LOAD_STATUS_TIMELINE_RECORDS_SUCCESS`;
const LOAD_STATUS_TIMELINE_RECORDS_ERROR = `${url}/LOAD_STATUS_TIMELINE_RECORDS_ERROR`;
const RESET = `${url}/RESET`;
const LOAD_RECORDS_QUANTITY_COUNT = `${url}/LOAD_RECORDS_QUANTITY_COUNT`;
const LOAD_RECORDS_QUANTITY_COUNT_SUCCESS = `${url}/LOAD_RECORDS_QUANTITY_COUNT_SUCCESS`;
const LOAD_RECORDS_QUANTITY_COUNT_ERROR = `${url}/LOAD_RECORDS_QUANTITY_COUNT_ERROR`;

export const orderConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  LOAD_RECORDS_COUNT,
  LOAD_RECORDS_COUNT_SUCCESS,
  LOAD_RECORDS_COUNT_ERROR,
  LOAD_RECORDS_PRODUCTS_COUNT,
  LOAD_RECORDS_PRODUCTS_COUNT_SUCCESS,
  LOAD_RECORDS_PRODUCTS_COUNT_ERROR,
  LOAD_UP_COMING_RECORDS,
  LOAD_UP_COMING_RECORDS_SUCCESS,
  LOAD_UP_COMING_RECORDS_ERROR,
  LOAD_PENDING_RECORDS,
  LOAD_PENDING_RECORDS_SUCCESS,
  LOAD_PENDING_RECORDS_ERROR,
  LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS,
  LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_SUCCESS,
  LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_ERROR,
  LOAD_SEARCH,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  LOAD_TODAY_RECORDS,
  LOAD_TODAY_RECORDS_SUCCESS,
  LOAD_TODAY_RECORDS_ERROR,
  LOAD_REVENUE_DAYS_RECORDS,
  LOAD_REVENUE_DAYS_RECORDS_SUCCESS,
  LOAD_REVENUE_DAYS_RECORDS_ERROR,
  LOAD_MARKETING_PERSONNAMES,
  LOAD_MARKETING_PERSONNAMES_SUCCESS,
  LOAD_MARKETING_PERSONNAMES_ERROR,
  LOAD_STATUS_TIMELINE_RECORDS,
  LOAD_STATUS_TIMELINE_RECORDS_SUCCESS,
  LOAD_STATUS_TIMELINE_RECORDS_ERROR,
  RESET,
  LOAD_RECORDS_QUANTITY_COUNT,
  LOAD_RECORDS_QUANTITY_COUNT_SUCCESS,
  LOAD_RECORDS_QUANTITY_COUNT_ERROR
};
