/*
 *
 *  remotes
 *
 */

import { getDefaultParamswithoutlimitkey } from '../../helpers/tools'
import { API } from "aws-amplify";
const constantName = 'userprofile';

 function loadRecords() {
    return API.get("tennat-service", `${constantName}s`, getDefaultParamswithoutlimitkey()).then((res) => res.response).catch((error) => Promise.reject(error))
}


 function loadRecordsMetaData(metaData) {
    return API.get("tennat-service", metaData, getDefaultParamswithoutlimitkey()).then((response) => response.data).catch((error) => Promise.reject(error))
}


 function loadRecord(id) {
    return API.get("tennat-service", `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).then((response) => response.response).catch((error) => Promise.reject(error))
}


 function createRecord(record) {
    return API.post("tennat-service", constantName, getDefaultParamswithoutlimitkey({body: record}))
    .then((response) => {
        if (response.error) {
            let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
            throw new Error(err_msg);
        }
        return response;
    })
    .catch((error) => Promise.reject(error))
}


 function updateRecord(record) { 
    let userName = record.userName;
    delete record.userName
    delete record.type
    return API.put("tennat-service", `${constantName}/${userName}`, getDefaultParamswithoutlimitkey({body: Object.assign({}, {...record})})).then((response) =>  response).catch((error) =>  Promise.reject(error))
}


 function deleteRecord(id) {
    return API.del("tennat-service", `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error))
}

export const userProfileService =  {
    loadRecords,
    loadRecordsMetaData,
    loadRecord,
    createRecord,
    updateRecord,
    deleteRecord
}



