/*
 *
 *   reducer
 *
 */
import produce from "immer";

import {
  notificationConstants
} from "../_constants";

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  success: false,
  openModal: false,
  lastUpdate: null,
  message: null,
  pageSize: null,
  page: 0,
  total: null,
  filters: null,
  notify: null,
  generalnotification:null
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    records,
    record,
    recordsMetaData = {},
    error,
    message,
    total,
    page,
    
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case notificationConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = "";
        break;
      case notificationConstants.LOAD_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = "";
        break;
      case notificationConstants.LOAD_RECORDS_SUCCESS:
         let allRecords = ''; 
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.ID === v.ID) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000); 
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = ''; 
        draft.total = total; 
        draft.page = page; 
        break;
      case notificationConstants.NOTIFY_RECORDS:
        let notifyRecords = state.records.concat(record);
        draft.records = notifyRecords;
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = "";
        draft.total = state.total ? state.total + 1 : 0;
        draft.notify = true;
        break;
      case notificationConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign(
          {},
          state.recordsMetaData,
          recordsMetaData
        );
        draft.error = false;
        draft.success = false;
        draft.message = "";
        break;
      case notificationConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break; 
      case notificationConstants.CLEAR:
        draft.error = false;
        draft.message = "";
        draft.notify = false;
        draft.generalnotification =null
        break
      case notificationConstants.GENERAL_NOTIFICATION:
        draft.generalnotification = record
        break;
      default:
        break;
    }
  });
}
