import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import * as store from 'store';
import {
  driverActions,
  vehicleActions,
  productActions,
  contractorActions,
  orderActions,
  tripActions,
  quoteActions,
  dialogActions,
  plantActions,
  invoiceActions,
  permissionsActions,
  companyProfileActions,
  preferencesActions,
  stockActions,
  tallyActions,
  einvoiceActions
} from 'store/actions';
import { usersActions } from 'store/actions/users.actions';
import { userProfileActions } from 'store/actions/userprofile.actions';
import { ConsoliDateInvoice } from 'pages/apps/invoice/cosolidateInvoice';
// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardGeneral = Loadable(lazy(() => import('pages/apps/dashboard/General/index')));
const DashboardStatistics = Loadable(lazy(() => import('pages/apps/dashboard/Statistics/index')));

//stock
const StockList = Loadable(lazy(() => import('pages/apps/stock/list')));
const AddStock = Loadable(lazy(() => import('pages/apps/stock/add')));
const StockGroups = Loadable(lazy(() => import('pages/apps/stock/groups')));
const StockAdjustments = Loadable(lazy(() => import('pages/apps/stock/stockAdjustment/adjustments')));
const AddStockAdjustments = Loadable(lazy(() => import('pages/apps/stock/stockAdjustment/adjustmentsadd')));
const ViewStockAdjustments = Loadable(lazy(() => import('pages/apps/stock/stockAdjustment/adjustmentView')));
const EditStockAdjustments = Loadable(lazy(() => import('pages/apps/stock/stockAdjustment/adjustmentsedit')));
const EditStock = Loadable(lazy(() => import('pages/apps/stock/edit')));
const AddStockGroup = Loadable(lazy(() => import('pages/apps/stock/groupadd')));
const EditStockGroup = Loadable(lazy(() => import('pages/apps/stock/groupedit')));
const ViewStockGroup = Loadable(lazy(() => import('pages/apps/stock/groupview.js')));
//contractor
const ContractorList = Loadable(lazy(() => import('pages/apps/contractor/list')));
const AddContractor = Loadable(lazy(() => import('pages/apps/contractor/add')));
const EditContractor = Loadable(lazy(() => import('pages/apps/contractor/edit')));
const ViewContractor = Loadable(lazy(() => import('pages/apps/contractor/view')));
const TransactionForm = Loadable(lazy(() => import('pages/apps/contractor/Transaction')));
const RecordPayment = Loadable(lazy(() => import('pages/apps/contractor/RecordPayment')));
const ManageSite = Loadable(lazy(() => import('pages/apps/contractor/Managesite')));
const Refund = Loadable(lazy(() => import('pages/apps/contractor/Refund')));
const EditRecordPayment = Loadable(lazy(() => import('pages/apps/contractor/editPayment')));
const TallySyncProcess = Loadable(lazy(() => import('pages/apps/contractor/synctally')));

//plant
const PlantList = Loadable(lazy(() => import('pages/apps/plant/index')));
const AddPlant = Loadable(lazy(() => import('pages/apps/plant/add')));
const ViewPlant = Loadable(lazy(() => import('pages/apps/plant/View')));

//order
const OrderList = Loadable(lazy(() => import('pages/apps/order/list')));
const EditOrder = Loadable(lazy(() => import('pages/apps/order/Edit')));
const ViewOrder = Loadable(lazy(() => import('pages/apps/order/ViewLocation')));
const AddOrder = Loadable(lazy(() => import('pages/apps/order/Add')));

//quote
const QuoteList = Loadable(lazy(() => import('pages/apps/quote/list')));
const EditQuote = Loadable(lazy(() => import('pages/apps/quote/Edit')));
const AddQuote = Loadable(lazy(() => import('pages/apps/quote/Add')));
const ViewQuote = Loadable(lazy(() => import('pages/apps/quote/View')));

//trip
const TripList = Loadable(lazy(() => import('pages/apps/trip/list')));
const AddTrip = Loadable(lazy(() => import('pages/apps/trip/Add')));
const EditTrip = Loadable(lazy(() => import('pages/apps/trip/Edit')));
const ViewTrip = Loadable(lazy(() => import('pages/apps/trip/View')));

//invoice

const AddInvoice = Loadable(lazy(() => import('pages/apps/invoice/create')));
const AddInvoiceTab = Loadable(lazy(() => import('pages/apps/invoice/createTab')));
const EditInvoiceTab = Loadable(lazy(() => import('pages/apps/invoice/editTab')));
const AddInvoiceManual = Loadable(lazy(() => import('pages/apps/invoice/createmanual')));
const EditInvoice = Loadable(lazy(() => import('pages/apps/invoice/edit')));
const EditInvoiceManual = Loadable(lazy(() => import('pages/apps/invoice/editmanual')));
const InvoiceRecordPayment = Loadable(lazy(() => import('pages/apps/invoice/payment')));
const ViewInvoice = Loadable(lazy(() => import('pages/apps/invoice/viewInvoice')));
const PreviewInvoice = Loadable(lazy(() => import('pages/apps/invoice/previewInvoice')));
const InvoiceTab = Loadable(lazy(() => import('pages/apps/invoice/list/index')));
const InvoiceListAll = Loadable(lazy(() => import('pages/apps/invoice/list/all')));
const InvoiceListPaid = Loadable(lazy(() => import('pages/apps/invoice/list/paid')));
const InvoiceListUnpaid = Loadable(lazy(() => import('pages/apps/invoice/list/unpaid')));
const InvoiceListOverdue = Loadable(lazy(() => import('pages/apps/invoice/list/overdue')));
const InvoiceListEinvoiceList = Loadable(lazy(() => import('pages/apps/invoice/list/einvoiceList')));
const TallySyncInvoiceProcess = Loadable(lazy(() => import('pages/apps/invoice/list/synctallyInvoice')));

//product-> add
const ProductList = Loadable(lazy(() => import('pages/apps/products/list')));
const AddProduct = Loadable(lazy(() => import('pages/apps/products/add')));
const EditProduct = Loadable(lazy(() => import('pages/apps/products/edit')));
const ViewProduct = Loadable(lazy(() => import('pages/apps/products/viewProduct')));

//logistics->driver
const DriverList = Loadable(lazy(() => import('pages/apps/driver/list')));
const EditDriver = Loadable(lazy(() => import('pages/apps/driver/edit')));
const AddDriver = Loadable(lazy(() => import('pages/apps/driver/add')));
//const ViewDriverLocation = Loadable(lazy(() => import('pages/apps/driver/location')));
const ViewDriver = Loadable(lazy(() => import('pages/apps/driver/view')));

//logistics->vehicle
const VehicleList = Loadable(lazy(() => import('pages/apps/vehicle/list')));
const EditVehicle = Loadable(lazy(() => import('pages/apps/vehicle/edit')));
const AddVehicle = Loadable(lazy(() => import('pages/apps/vehicle/add')));
const ViewVehicleLocation = Loadable(lazy(() => import('pages/apps/vehicle/viewLocation')));
const ViewVehicle = Loadable(lazy(() => import('pages/apps/vehicle/viewDetails')));

//company Details -> UserProfile
const SettingUserProfile = Loadable(lazy(() => import('pages/apps/settings/userProfile/index')));

//companyDetails -> Users and Permissions
const SettingUsersPermissions = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/index')));
const Users = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/users/list')));
const RolePermissionsList = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/permissions/index')));
const PermissionsView = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/managePermissions/index')));
const PermissionsEdit = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/permissions/edit')));
const PermissionsViewEach = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/permissions/view')));
const UserAdd = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/users/add')));
const UserEdit = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/users/edit')));
const UserView = Loadable(lazy(() => import('pages/apps/settings/usersandPermissions/users/userDetails')));

//company-Details -> Company Profile
const SettingCompanyProfile = Loadable(lazy(() => import('pages/apps/settings/companyProfile/index')));

//company-details -> Advanced
const Advanced = Loadable(lazy(() => import('pages/apps/settings/advanced/index')));

//company-details -> Preferences
const Preferences = Loadable(lazy(() => import('pages/apps/settings/preferences/index')));
const Branding = Loadable(lazy(() => import('pages/apps/settings/preferences/Branding/index')));
const GeneralPreference = Loadable(lazy(() => import('pages/apps/settings/preferences/General/index')));

//company-details -> Billing & Plans
const BillingAndPlans = Loadable(lazy(() => import('pages/apps/settings/billingandPlans/index')));

const AccessError = Loadable(lazy(() => import('components/AccessError/index')));
const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),

      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'general',
              element: (
                <DashboardGeneral
                  orderActions={orderActions}
                  invoiceActions={invoiceActions}
                  contractorActions={contractorActions}
                  dialogActions={dialogActions}
                  {...store}
                />
              )
            },
            {
              path: 'statistics',
              element: (
                <DashboardStatistics
                  driverActions={driverActions}
                  vehicleActions={vehicleActions}
                  orderActions={orderActions}
                  tripActions={tripActions}
                  invoiceActions={invoiceActions}
                  dialogActions={dialogActions}
                  {...store}
                />
              )
            }
          ]
        },
        {
          path: 'apps',
          children: []
        },
        {
          path: '403',
          element: <AccessError />
        },
        {
          path: 'order',
          children: [
            {
              path: 'edit/:id',
              element: (
                <EditOrder
                  orderActions={orderActions}
                  productActions={productActions}
                  plantActions={plantActions}
                  contractorActions={contractorActions}
                  tripActions={tripActions}
                  {...store}
                />
              )
            },
            {
              path: 'list',
              element: <OrderList orderActions={orderActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'view/:id',
              element: (
                <ViewOrder orderActions={orderActions} {...store} dialogActions={dialogActions} tripActions={tripActions} invoiceActions={invoiceActions} />
              )
            },
            {
              path: 'add',
              element: (
                <AddOrder
                  orderActions={orderActions}
                  productActions={productActions}
                  plantActions={plantActions}
                  contractorActions={contractorActions}
                  dialogActions={dialogActions}
                  {...store}
                />
              )
            }
          ]
        },
        {
          path: 'trip',
          children: [
            {
              path: 'edit/:id',
              element: (
                <EditTrip tripActions={tripActions} driverActions={driverActions} {...store} orderActions={orderActions} vehicleActions={vehicleActions} />
              )
            },
            {
              path: 'list',
              element: <TripList tripActions={tripActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'view/:id',
              element: <ViewTrip tripActions={tripActions} preferencesActions={preferencesActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'add',
              element: (
                <AddTrip tripActions={tripActions} driverActions={driverActions} {...store} orderActions={orderActions} vehicleActions={vehicleActions} />
              )
            }
          ]
        },
        {
          path: 'quote',
          children: [
            {
              path: 'edit/:id',
              element: (
                <EditQuote
                  quoteActions={quoteActions}
                  contractorActions={contractorActions}
                  productActions={productActions}
                  plantActions={plantActions}
                  orderActions={orderActions}
                  {...store}
                />
              )
            },
            {
              path: 'list',
              element: <QuoteList quoteActions={quoteActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'add',
              element: (
                <AddQuote
                  orderActions={orderActions}
                  productActions={productActions}
                  quoteActions={quoteActions}
                  plantActions={plantActions}
                  contractorActions={contractorActions}
                  dialogActions={dialogActions}
                  {...store}
                />
              )
            },
            {
              path: 'view/:id',
              element: (
                <ViewQuote
                  quoteActions={quoteActions}
                  preferencesActions={preferencesActions}
                  orderActions={orderActions}
                  dialogActions={dialogActions}
                  {...store}
                />
              )
            }
          ]
        },
        {
          path: 'product',
          children: [
            {
              path: 'list',
              element: <ProductList productActions={productActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'add',
              element: <AddProduct productActions={productActions} {...store} />
            },
            {
              path: 'edit/:id',
              element: <EditProduct productActions={productActions} {...store} />
            },
            {
              path: 'view/:id',
              element: <ViewProduct productActions={productActions} dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
          path: 'stock',
          children: [
            {
              path: 'list',
              element: <StockList stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'add',
              element: <AddStock stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'groups',
              element: <StockGroups stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'adjustments',
              element: <StockAdjustments stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'adjustments/add',
              element: <AddStockAdjustments stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'adjustments/edit/:id',
              element: <EditStockAdjustments stockActions={stockActions} {...store} />
            },
            {
              path: 'adjustments/view/:id',
              element: <ViewStockAdjustments stockActions={stockActions} {...store} />
            },
            {
              path: 'edit/:id',
              element: <EditStock stockActions={stockActions} {...store} />
            },
            {
              path: 'groups/add',
              element: <AddStockGroup stockActions={stockActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'groups/edit/:id',
              element: <EditStockGroup stockActions={stockActions} {...store} />
            },
            {
              path: 'groups/view/:id',
              element: <ViewStockGroup stockActions={stockActions} dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
          path: 'contractor',
          children: [
            {
              path: 'list',
              element: <ContractorList contractorActions={contractorActions} tallyActions={tallyActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'details/:id',
              element: <ViewContractor contractorActions={contractorActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'managesite/:id',
              element: <ManageSite contractorActions={contractorActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'transactions/:id',
              element: <TransactionForm contractorActions={contractorActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'payment/:id',
              element: <RecordPayment contractorActions={contractorActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'refund/:id',
              element: <Refund contractorActions={contractorActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'add',
              element: <AddContractor contractorActions={contractorActions} {...store} />
            },
            {
              path: 'edit/:id',
              element: <EditContractor contractorActions={contractorActions} {...store} />
            },
            {
              path: 'editpayment/:id',
              element: <EditRecordPayment contractorActions={contractorActions} {...store} />
            },
            {
              path: 'synctally',
              element: <TallySyncProcess tallyActions={tallyActions} dialogActions={dialogActions} {...store} />
            }
          ]
        },
        {
          path: 'logistics',
          children: [
            {
              path: 'driver',
              element: <DriverList driverActions={driverActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'driver/edit/:id',
              element: <EditDriver driverActions={driverActions} {...store} />
            },
            {
              path: 'driver/add',
              element: <AddDriver driverActions={driverActions} {...store} />
            },
            {
              path: 'driver/view/:id',
              element: <ViewDriver driverActions={driverActions} dialogActions={dialogActions} {...store} />
            },
            // {
            //   path: 'driver/view-location/:id',
            //   element: <ViewDriverLocation driverActions={driverActions} {...store} />
            // },

            {
              path: 'vehicle',
              element: <VehicleList vehicleActions={vehicleActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'vehicle/edit/:id',
              element: <EditVehicle vehicleActions={vehicleActions} {...store} />
            },
            {
              path: 'vehicle/add',
              element: <AddVehicle vehicleActions={vehicleActions} {...store} />
            },
            {
              path: 'vehicle/view/:id',
              element: <ViewVehicle vehicleActions={vehicleActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'vehicle/view-location/:id',
              element: <ViewVehicleLocation vehicleActions={vehicleActions} {...store} />
            }
          ]
        },
        {
          path: 'invoice',
          children: [
            {
              path: 'synctallyInvoice',
              element: <TallySyncInvoiceProcess invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'payment/:id',
              element: <InvoiceRecordPayment invoiceActions={invoiceActions} {...store} />
            },
            {
              path: 'view/:id',
              element: <ViewInvoice invoiceActions={invoiceActions} {...store} />
            },
            {
              path: 'preview',
              element: <PreviewInvoice />
            },
            {
              path: 'consolidate',
              element: <ConsoliDateInvoice invoiceActions={invoiceActions} {...store} />
            },

            {
              path: 'add',
              element: <AddInvoiceTab />,
              children: [
                {
                  path: 'auto',
                  element: (
                    <AddInvoice
                      orderActions={orderActions}
                      invoiceActions={invoiceActions}
                      tripActions={tripActions}
                      contractorActions={contractorActions}
                      productActions={productActions}
                      companyProfileActions={companyProfileActions}
                      preferencesActions={preferencesActions}
                      {...store}
                    />
                  )
                },
                {
                  path: 'manual',
                  element: (
                    <AddInvoiceManual
                      orderActions={orderActions}
                      invoiceActions={invoiceActions}
                      tripActions={tripActions}
                      contractorActions={contractorActions}
                      productActions={productActions}
                      companyProfileActions={companyProfileActions}
                      preferencesActions={preferencesActions}
                      {...store}
                    />
                  )
                }
              ]
            },
            {
              path: 'edit',
              element: <EditInvoiceTab />,
              children: [
                {
                  path: 'auto/:id',
                  element: (
                    <EditInvoice
                      orderActions={orderActions}
                      invoiceActions={invoiceActions}
                      tripActions={tripActions}
                      contractorActions={contractorActions}
                      productActions={productActions}
                      companyProfileActions={companyProfileActions}
                      {...store}
                    />
                  )
                },
                {
                  path: 'manual/:id',
                  element: (
                    <EditInvoiceManual
                      orderActions={orderActions}
                      invoiceActions={invoiceActions}
                      tripActions={tripActions}
                      contractorActions={contractorActions}
                      productActions={productActions}
                      companyProfileActions={companyProfileActions}
                      {...store}
                    />
                  )
                }
              ]
            },
            {
              path: 'list',
              element: <InvoiceTab {...store} invoiceActions={invoiceActions} />,
              children: [
                {
                  path: 'all',
                  element: <InvoiceListAll invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'paid',
                  element: <InvoiceListPaid invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'unpaid',
                  element: <InvoiceListUnpaid invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'overdue',
                  element: <InvoiceListOverdue invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'einvoiceList',
                  element: (
                    <InvoiceListEinvoiceList einvoiceActions={einvoiceActions} invoiceActions={invoiceActions} {...store} dialogActions={dialogActions} />
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'users',
          element: <SettingUsersPermissions />,
          children: [
            {
              path: 'list',
              element: <Users usersActions={usersActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'add',
              element: <UserAdd usersActions={usersActions} {...store} />
            },
            {
              path: 'edit/:id',
              element: <UserEdit usersActions={usersActions} {...store} />
            },
            {
              path: 'view/:id',
              element: <UserView usersActions={usersActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'permissions',
              children: [
                {
                  path: 'view',
                  element: <PermissionsView permissionsActions={permissionsActions} {...store} />
                },
                {
                  path: 'list',
                  element: <RolePermissionsList usersActions={usersActions} permissionsActions={permissionsActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'edit/:id',
                  element: <PermissionsEdit permissionsActions={permissionsActions} {...store} />
                },
                {
                  path: 'views/:id',
                  element: <PermissionsViewEach permissionsActions={permissionsActions} {...store} />
                }
              ]
            }
          ]
        },
        {
          path: 'preferences',
          element: <Preferences preferencesActions={preferencesActions} {...store} dialogActions={dialogActions} />,
          children: [
            {
              path: 'branding',
              element: <Branding usersActions={usersActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'general-preference',
              element: <GeneralPreference usersActions={usersActions} {...store} />
            },
            {
              path: 'edit/:id',
              element: <UserEdit usersActions={usersActions} {...store} />
            },
            {
              path: 'view/:id',
              element: <UserView usersActions={usersActions} {...store} dialogActions={dialogActions} />
            },
            {
              path: 'permissions',
              children: [
                {
                  path: 'view',
                  element: <PermissionsView permissionsActions={permissionsActions} {...store} />
                },
                {
                  path: 'list',
                  element: <RolePermissionsList usersActions={usersActions} permissionsActions={permissionsActions} {...store} dialogActions={dialogActions} />
                },
                {
                  path: 'edit/:id',
                  element: <PermissionsEdit permissionsActions={permissionsActions} {...store} />
                },
                {
                  path: 'views/:id',
                  element: <PermissionsViewEach permissionsActions={permissionsActions} {...store} />
                }
              ]
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'profile',
              element: <SettingUserProfile {...store} companyProfileActions={companyProfileActions} userProfileActions={userProfileActions} />
            },

            {
              path: 'company-profile',
              element: <SettingCompanyProfile {...store} companyProfileActions={companyProfileActions} />
            },
            {
              path: 'advanced',
              element: <Advanced {...store} companyProfileActions={companyProfileActions} />
            },
            // {
            //   path: 'plan',
            //   element: <Plan {...store} companyProfileActions={companyProfileActions} />
            // },
            {
              path: 'billing-and-plans',
              element: <BillingAndPlans {...store} companyProfileActions={companyProfileActions} />
            },
            {
              path: 'plant/add',
              element: <AddPlant plantActions={plantActions} {...store} />
            },
            {
              path: 'plant',
              element: <PlantList plantActions={plantActions} dialogActions={dialogActions} {...store} />
            },
            {
              path: 'plant/view/:id',
              element: <ViewPlant plantActions={plantActions} dialogActions={dialogActions} {...store} />
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
