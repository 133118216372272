import { createContext } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
const TableColumnContext = createContext(null);

export const TableColumnContextProvider = ({ children }) => {
  const defaultHiddenColumns = {
    order: ['plantname', 'marketingPersonName'],
    quote: ['quoteId'],
    trip: ['contractorName'],
    invoiceAll: ['dateDue', 'issueDate'],
    invoicePaid: ['dateDue', 'issueDate'],
    invoiceUnpaid: ['dateDue', 'issueDate'],
    invoiceOverdue: ['dateDue', 'issueDate'],
    contractor: ['gstDetails', 'gstNumber', 'creditLimit']
  };

  const initialTableColumnSettings = {
    order: { hiddenColumns: defaultHiddenColumns.order },
    quote: { hiddenColumns: defaultHiddenColumns.quote },
    trip: { hiddenColumns: defaultHiddenColumns.trip },
    invoiceAll: { hiddenColumns: defaultHiddenColumns.invoiceAll },
    invoicePaid: { hiddenColumns: defaultHiddenColumns.invoicePaid },
    invoiceUnpaid: { hiddenColumns: defaultHiddenColumns.invoiceUnpaid },
    invoiceOverdue: { hiddenColumns: defaultHiddenColumns.invoiceOverdue },
    contractor: { hiddenColumns: defaultHiddenColumns.contractor }
  };

  const [tableColumnSettings, setTableColumnSettings] = useLocalStorage('plant356-table-column-settings', initialTableColumnSettings);

  const onChangeTableColumnSelection = (contextKey, tableColumnSelection) => {
    let config = {
      ...tableColumnSettings,
      [contextKey]: tableColumnSelection[contextKey]
    };
    setTableColumnSettings((prevTableColumnSettings) => {
      return { ...prevTableColumnSettings, ...config };
    });
  };

  return (
    <TableColumnContext.Provider value={{ setTableColumnSettings, tableColumnSettings, onChangeTableColumnSelection }}>{children}</TableColumnContext.Provider>
  );
};

export default TableColumnContext;
