/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { quoteConstants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  message: null,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  pageSize: null,
  page: 0,
  total: null,
  filters: null,
  statusTimeline: [],
  deleteSuccess:false
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, statusTimeline, recordsMetaData = {}, message, error, total, page, filters, delivery_date, pageSize } = props;
  return produce(state, (draft) => {
    switch (type) {
      case quoteConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.LOAD_RECORDS:
      case quoteConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.quoteId === v.quoteId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.pageSize = pageSize;
        draft.delivery_date = delivery_date;
        draft.deleteSuccess = false;
        draft.searchRecords = [];
        draft.searchFilters = [];
        draft.searchTotals = [];
        break;
      case quoteConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.CREATE_RECORD_SUCCESS:
        draft.records = record.concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case quoteConstants.UPDATE_RECORD:
        draft.records = state.records.find((r) => record.quoteId === r.quoteId)
          ? state.records.map((r) => (record.quoteId === r.quoteId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.status_changed = state.records.find((r) => record.quoteId === r.quoteId && record.quoteStatus !== r.quoteStatus) ? true : false;
        break;
      case quoteConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record[0].quoteId === r.quoteId)
          ? state.records.map((r) => (record.quoteId === r.quoteId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.updatedStatus = record;
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case quoteConstants.DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.quoteId.toString() !== record.quoteId.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case quoteConstants.LOAD_RECORDS_ERROR:
      case quoteConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case quoteConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case quoteConstants.UPDATE_RECORD_ERROR:
      case quoteConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case quoteConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case quoteConstants.LOAD_STATUS_TIMELINE_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS:
        draft.statusTimeline = statusTimeline;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case quoteConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.statusTimeline = [];
        break;
      case quoteConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.searchFilters = filters;
        draft.success = false;
        draft.message = '';
        draft.searchTotals = total;
        break;
      case quoteConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.searchFilters = filters;
        draft.searchTotals = total;
        break;
      case quoteConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.searchFilters = filters;
        draft.message = message;
        draft.searchTotals = total;
        break;
      case quoteConstants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      default:
        break;
    }
  });
}
