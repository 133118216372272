import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import { APP_DEFAULT_PATH } from 'config';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { dispatch, useSelector } from 'store';
import { preferencesActions } from 'store/actions';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, isPermissions, } = useAuth();
  const { userRecords }: any = useSelector((state) => state.preferences);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch(preferencesActions.loadUserSettingRecords());
  }, []);
  const { defaultHomePage }: any = useConfig();
  useEffect(() => {
    if (isLoggedIn && isPermissions ) {
      let userDefaultPath = defaultHomePage;
      if (userRecords) {
        userDefaultPath = userRecords?.settingValue?.defaultHomePage
          ? userRecords?.settingValue?.defaultHomePage
          : localStorage.getItem('DefaultRoute') === null
          ? defaultHomePage
          : localStorage.getItem('DefaultRoute');
      }
      localStorage.setItem('DefaultRoute', userDefaultPath);
      navigate(userDefaultPath ? userDefaultPath : location?.state?.from ? location?.state?.from : defaultHomePage ? defaultHomePage : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location, isPermissions, userRecords]);

  return children;
};

export default GuestGuard;
