import {useEffect, useState } from 'react'; 

// material-ui
import { Dialog, useMediaQuery, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme,Button,CircularProgress  } from '@mui/material';
import {useSelector,dispatch} from 'store'
import {dialogActions} from "store/actions"

const CustomDialog = ({message="Do you want to delete this record?",title='',callback,sx={}}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md')); 
  const [loading, setLoading] = useState(false);  
  const { progress } = useSelector((state) => state.dialog);    
  useEffect(()=>{
    if(!progress){
      setLoading(progress)
    }    
  },[progress]) 
  return (
    <>  
        {progress ? (
          <Dialog
            fullScreen={fullScreen}
            open={progress}
            style={{ background: 'rgba(0,0,0,0.2)' }}
            BackdropProps={{ invisible: true }}
            sx={{...sx}}
            onClose={() => dispatch(dialogActions.clear())}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">Delete {title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              {!loading?<Button autoFocus onClick={() => dispatch(dialogActions.clear())}>Close</Button>:""}
              {!loading ? <Button onClick={() => {setLoading(true);callback()}} autoFocus>
                Confirm
              </Button>:<CircularProgress size={25} color="secondary"  />}
            </DialogActions>
          </Dialog>
        ) : null} 
    </>
  );
};

export default CustomDialog;
