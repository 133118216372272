// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  Truck,
  Kanban,
  Profile2User,
  MoneyRecive,
  ShoppingBag,
  TruckFast,
  // Setting2,
  DollarSquare,
  Courthouse,
  Buildings2,
  TableDocument,
  UserSquare,
  Layer,
  Routing,
  QuoteUpSquare,
  ProfileCircle,
  Setting2,
  People,
  SmartCar,
  ShoppingCart,
  Additem,
  ArchiveBox,
  TransactionMinus
} from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  truck: Truck,
  kanban: Kanban,
  contractor: People,
  product: Layer,
  invoice: MoneyRecive,
  setting: Setting2,
  logistics: SmartCar,
  ecommerce: ShoppingBag,
  driver: ProfileCircle,
  usersmanagement: Profile2User,
  billingandplans: DollarSquare,
  companyprofile: Courthouse,
  plantlocations: Buildings2,
  advanced: TableDocument,
  userprofile: UserSquare,
  trip: Routing,
  order: ShoppingCart,
  quote: QuoteUpSquare,
  stock: ArchiveBox,
  item: TableDocument,
  adjustment: TransactionMinus
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const applications: NavItemType = {
  type: 'group',
  children: [
    {
      id: 'quote',
      title: <FormattedMessage id="quote" />,
      type: 'item',
      icon: icons.quote,
      url: '/quote/list'
    },
    {
      id: 'order',
      icon: icons.order,
      title: <FormattedMessage id="order" />,
      type: 'item',
      url: '/order/list'
    },
    {
      id: 'trip',
      icon: icons.trip,
      title: <FormattedMessage id="trip" />,
      type: 'item',
      url: '/trip/list'
    },
    {
      id: 'invoice',
      title: <FormattedMessage id="invoice" />,
      url: '/invoice/list/all',
      type: 'item',
      icon: icons.invoice
    },
    {
      id: 'contractor',
      title: <FormattedMessage id="contractor" />,
      type: 'item',
      url: '/contractor/list',
      icon: icons.contractor
    },
    {
      id: 'product',
      title: <FormattedMessage id="product" />,
      type: 'item',
      url: '/product/list',
      icon: icons.product
    },
    {
      id: 'stock',
      title: <FormattedMessage id="stock" />,
      type: 'collapse',
      icon: icons.stock,
      children: [
        {
          id: 'item-list',
          parent: 'stock',
          icon: icons.item,
          title: <FormattedMessage id="item-list" />,
          type: 'item',
          url: '/stock/list'
        },
        {
          id: 'item-groups',
          parent: 'stock',
          title: <FormattedMessage id="item-groups" />,
          type: 'item',
          icon: Additem,
          url: '/stock/groups'
        },
        {
          id: 'item-adjustments',
          parent: 'stock',
          title: <FormattedMessage id="item-adjustments" />,
          type: 'item',
          icon: icons.adjustment,
          url: '/stock/adjustments'
        }
      ]
    },
    {
      id: 'logistics',
      title: <FormattedMessage id="logistics" />,
      type: 'collapse',
      icon: icons.logistics,
      children: [
        {
          id: 'driver',
          parent: 'logistics',
          icon: icons.driver,
          title: <FormattedMessage id="driver" />,
          type: 'item',
          url: '/logistics/driver'
        },
        {
          id: 'vehicle',
          parent: 'logistics',
          title: <FormattedMessage id="vehicle" />,
          type: 'item',
          icon: TruckFast,
          url: '/logistics/vehicle'
        }
      ]
    },
    {
      id: 'settings',
      title: <FormattedMessage id="company-details" />,
      type: 'collapse',
      icon: icons.setting,
      children: [
        {
          id: 'company-profile',
          parent: 'settings',
          title: <FormattedMessage id="company-profile" />,
          icon: icons.companyprofile,
          type: 'item',
          url: '/settings/company-profile'
        },
        {
          id: 'plant',
          parent: 'settings',
          title: <FormattedMessage id="plant" />,
          icon: icons.plantlocations,
          type: 'item',
          url: '/settings/plant'
        },
        {
          id: 'users-management',
          parent: 'settings',
          title: <FormattedMessage id="users-management" />,
          icon: icons.usersmanagement,
          type: 'item',
          url: '/users/list'
        },
        {
          id: 'preferences',
          parent: 'settings',
          title: <FormattedMessage id="preferences" />,
          icon: icons.usersmanagement,
          type: 'item',
          url: '/preferences'
        },
        {
          id: 'billing-and-plans',
          parent: 'settings',
          title: <FormattedMessage id="billing-and-plans" />,
          icon: icons.billingandplans,
          type: 'item',
          url: '/settings/billing-and-plans'
        },
        {
          id: 'advanced',
          parent: 'settings',
          title: <FormattedMessage id="advanced" />,
          icon: icons.advanced,
          type: 'item',
          url: '/settings/advanced'
        },
        {
          id: 'user-profile',
          parent: 'settings',
          title: <FormattedMessage id="user-profile" />,
          icon: icons.userprofile,
          type: 'item',
          url: '/settings/profile'
        }
      ]
    }
  ]
};

export default applications;
