import { Link } from 'react-router-dom';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //
const StaticLogo = ({src,navigate="/dashboard/general", auth = false}:{ src?: string,navigate?: string, auth?: boolean }) => {   
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <Link to={navigate}> 
      <img alt="Logo"  src={src} style={{ width: auth ? "110px" : "60px", padding: "8px" }} />
    </Link>
  );
};

export default StaticLogo;
