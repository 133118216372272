import { useContext } from 'react';

// table provider 
import TableRowsSelectionContext from 'contexts/TableRowsSelectionContext'; 

// ==============================|| HOOKS - AUTH ||============================== //

const useTableRowsSelectionsContext = () => {
  const context = useContext(TableRowsSelectionContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useTableRowsSelectionsContext;
