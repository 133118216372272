import { isEmpty } from "helpers/tools"

export const calculateInclusiveTax = (amount, tax, discount = 0) => {
    if (Number(discount) > 0) {
        amount = calculatedTotalwithDiscount(amount, discount)
    }
    let taxAmount = Number((amount - (amount / (1 + Number(tax) / 100))).toFixed(2)) || 0
    let totalAmount = Number((amount - taxAmount).toFixed(2)) || 0
    return { totalAmount, taxAmount, productTotal: amount, discount, tax: tax }
}

export const calculateExclusiveTax = (amount, tax, discount = 0) => {
    if (Number(discount) > 0) {
        amount = calculatedTotalwithDiscount(amount, discount)
    }
    let taxAmount = Number(Number(amount) * (tax / 100)) || 0
    return { taxAmount, totalAmount: Number(amount),  productTotal: amount,discount, tax: tax }
}

export const calculateTax = (amount, tax, taxType = "exclusive", discount = 0) => {
    if ("exclusive" === taxType) {
        return calculateExclusiveTax(amount, tax, discount)
    } else if ("inclusive" === taxType) {
        return calculateInclusiveTax(amount, tax, discount)
    }
}

export const calculatedTotalwithDiscount = (total, discount) => {
    const discountedTotal = (total - discount);
    return discountedTotal
}


export const manageInvoiceRecord = (invoiceRecord, prevRecord, action = "init", index) => {

    if (invoiceRecord) {
        if (isEmpty(prevRecord)) {
            prevRecord = []
        }
        const { price,
             quantity, 
             productName: product,
              extra = false, tax, taxType, discount, HSNSAC: hsnsac, grade, products = {},error=false } = invoiceRecord
        switch (action) {
            case "init":
                return [{
                    taxType,
                    product,
                    price,
                    tax,
                    discount,
                    hsnsac,
                    grade,
                    quantity,
                    taxAmount: 0.0,
                    totalAmount: 0.0
                }]
            case "onTripChange":
                const currentCost = price * quantity
                const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax ?? prevRecord[0].tax, taxType ?? prevRecord[0].taxType, discount ?? prevRecord[0].discount);
                if (extra) {
                    return [...prevRecord, ...[{
                        price,
                        quantity,
                        taxAmount,
                        tax,
                        totalAmount,
                        product: product ? product : invoiceRecord.product,
                        productTotal
                    }]]
                }
                return prevRecord?.map((item) => {
                    if (!item.hsnsac) return item
                    return {
                        ...item,
                        ...{
                            price,
                            quantity,
                            taxAmount,
                            totalAmount,
                            productTotal
                        }
                    };
                });
            case "onProductList":
                {let discountByProduct = discount
                const filterByHsn = products?.filter((_f)=>_f.hsnsac)
                if(filterByHsn && filterByHsn.length>1){
                    discountByProduct = discountByProduct/filterByHsn.length
                }
                return products?.map((item) => {
                    if(!item.hsnsac) return item
                    const { price, quantity, tax } = item;
                    const currentCost = price * quantity
                    const discountValue = discountByProduct??item.discount
                    const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax, taxType, discountValue);
                    return {
                        ...item,
                        ...{
                            taxType,
                            taxAmount,
                            totalAmount,
                            discount: discountByProduct,
                            productTotal
                        }
                    };
                });}
            case "onTaxTypeChange":
                return prevRecord?.map((item) => {
                    const { price, quantity, tax } = item;
                    const currentCost = price * quantity
                    const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax, taxType, discount ?? item.discount);
                    return {
                        ...item,
                        ...{
                            taxType,
                            taxAmount,
                            totalAmount,
                            productTotal
                        }
                    };
                });
            case "onHSNChange":
                return prevRecord?.map((item,itemIndex) => {
                    if (itemIndex === index) {
                        const { price, quantity, tax, taxType } = item;
                        const currentCost = price * quantity
                        const taxValue = hsnsac?tax:0
                        const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, taxValue, taxType, discount ?? item.discount);
                        return {
                            ...item,
                            ...{
                                taxType,
                                taxAmount,
                                totalAmount,
                                hsnsac,
                                tax:taxValue,
                                productTotal
                            }
                        };
                    }else{
                        return item
                    }

                });
            case "onTaxChange":
                return prevRecord?.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        const { price, quantity } = item;
                        const currentCost = price * quantity
                        const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax, taxType ?? item.taxType, discount ?? item.discount);
                        return {
                            ...item,
                            ...{
                                tax,
                                taxAmount,
                                totalAmount,
                                productTotal
                            }
                        };
                    } else {
                        return item
                    }

                });
            case "onPriceChange":
                return prevRecord?.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        const { quantity } = item;
                        const currentCost = price * quantity
                        const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax ?? item.tax, taxType ?? item.taxType, discount ?? item.discount);
                        return {
                            ...item,
                            ...{
                                price,
                                taxAmount,
                                totalAmount,
                                productTotal
                            }
                        };
                    } else {
                        return item
                    }

                });
            case "onQtyChange":
                return prevRecord?.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        const { price } = item;
                        const currentCost = price * quantity;
                        const calculatedTax = tax ?? item.tax;
                        const calculatedTaxType = taxType ?? item.taxType;
                        const calculatedDiscount = discount ?? item.discount;
                        const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, calculatedTax, calculatedTaxType, calculatedDiscount);
                        return {
                            ...item,
                            ...{
                                tax: calculatedTax,
                                quantity,
                                taxAmount,
                                totalAmount,
                                productTotal
                            }
                        };
                    } else {
                        return item;
                    }
                });

            case "onProductChange":
                return prevRecord?.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            ...{
                                product
                            }
                        };
                    } else {
                        return item
                    }

                });
            case "onDiscountChange":
                let discountByProduct = discount
                if (error) return prevRecord
                const filterByHsn = prevRecord?.filter((_f)=>_f.hsnsac)
                if(filterByHsn && filterByHsn.length>1){
                    discountByProduct = discountByProduct/filterByHsn.length
                }
                return prevRecord?.map((item) => {
                    if (!item.hsnsac) {
                        return {
                            ...item,
                            ...{
                                discount:0
                            }
                        }
                    }
                    const { price, quantity, tax, taxType } = item;
                    const currentCost = price * quantity
                    const { taxAmount, totalAmount,productTotal } = calculateTax(currentCost, tax, taxType, discountByProduct);
                    return {
                        ...item,
                        ...{
                            price,
                            quantity,
                            tax,
                            taxType,
                            taxAmount,
                            totalAmount,
                            discount:discountByProduct,
                            productTotal
                        }
                    };
                });
            case "onAddNew":
                return [...prevRecord, invoiceRecord]
            case "onRemove":
                const newList = [...prevRecord];
                newList.splice(index, 1);
                return newList
            default:
                break;
        }
    }
}