import { Box, CircularProgress, Typography, Grid } from '@mui/material';
const LoaderComponent = ({ label, size, color,sx={} }) => {
  return (
    <Grid display="flex" alignItems="center" justifyContent="center" sx={{...sx}}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress size={size} value={label} color={color} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="div" color="text.secondary">
            {label ? label : ''}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default LoaderComponent;
