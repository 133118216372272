/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * InvoiceView Page
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Grid, Typography, Box, Card, Paper, TableContainer, Table, TableBody, TableRow, TableHead, Button, Stack } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { ArrowLeft, DocumentDownload } from 'iconsax-react';
import { useSelector } from 'react-redux';
import InvoiceAddSkeleton from 'components/Skeleton/invoice/add';
import { dispatch } from 'store';
import { invoiceActions, preferencesActions } from 'store/actions';
import config from 'aws-config';
import { snackbarActions } from 'helpers/tools';
import convertRupeesIntoWords from 'convert-rupees-into-words'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1D3F71',
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export const PreviewInvoice = ({ record, handleSubmitInvoice, setOpenPreview, handleCloseRateConfirmationDialog, openPreview, isReportView = false }) => {
  // const [count, setcount] = useState(0);
  const container = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [numericalvalue, setNumericalvalue] = useState('');
  const [gstTax, setGstTax] = useState(0);
  const [error, setError] = useState(false);
  const { logoImage } = useSelector((state) => state.companyprofile);
  const { loading } = useSelector((state) => state.invoice);
  const { inprogress } = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(preferencesActions.loadGeneralSettingRecords({ module: 'general' }));
  }, []);
  useEffect(() => {
    if (error) {
      dispatch(snackbarActions(true, error));
    }
  }, [error]);

  const { generalRecords } = useSelector((state) => state.preferences);
  const {
    ID,
    auto,
    issueDate,
    // dateDue,
    orderId,
    tripId,
    deliveryAddress,
    fullAddress,
    headOfficeAddress,
    gstNumber,
    companyName,
    tagLine,
    gstIN,
    contractorName,
    company,
    products,
    paymentTerms,
    notes,
    invoiceSubTotal,
    discount,
    taxAmount,
    invoiceTotal,
    roundOff,
    roundedTotal,
    gstType = '',
    // billToAddress,
    // shipToAddress,
    vehicles,
    mobile,
    mobile1,
    mobile2,
    phone,
    alternativePhone1,
    alternativePhone2,
    invoiceId,
    status,
    invoiceStatus,
    selectedOrder,
    selectedContractor,
    irn,
    ackNumber,
    ackDate,
    qrCodeImage,
    taxType
  } = record;
  const handleDownloadPDF = useReactToPrint({
    content: () => container.current,
    documentTitle: `${invoiceId}`
  });
  const handleSubmit = () => {
    const convertedIssueDate = issueDate.replace(/-/g, '/');
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0].replace(/-/g, '/');
    if (convertedIssueDate <= currentDateString) {
      const {
        addressChange,
        addressName,
        year,
        month,
        contractorStatus,
        creditLimit,
        email,
        gstDetails,
        createdBy,
        createdRole,
        gstNumber,
        invoiceStatus,
        pending,
        productId,
        updatedBy,
        statusChange,
        updatedDate,
        updatedRole,
        ...rest
      } = record;

      let invoiceData = {
        ...rest,
        status: 'Published'
      };
      delete invoiceData?.openingBalance;
      delete invoiceData?.qrCodeImage;
      delete invoiceData?.irn;
      delete invoiceData?.ackNumber;
      delete invoiceData?.ackDate;
      dispatch(invoiceActions.updateRecord(invoiceData, navigate));
    } else {
      setError('Invoice can not be Published with a Future Date');
      return;
    }
  };

  const selectedTrips = tripId?.split(',') || [];
  const selectedVehicle = vehicles?.split(',') || [];

  useEffect(() => {
    let productsArr = products;
    try {
      let prods = JSON.parse(products);
      productsArr = prods;
      setInvoiceProducts(prods);
    } catch {
      setInvoiceProducts(products);
    }
    if(auto){
      if (productsArr && productsArr.length > 0) {
        setGstTax(productsArr[0].tax);
      } else {
        setGstTax(0);
      }  
    }else{
      if (productsArr && productsArr.length > 0 && (productsArr.length === 1 || productsArr.every((product) => product?.tax === product[0]?.tax))) {
        setGstTax(productsArr[0].tax);
      } else {
        setGstTax(0);
      }
    }
    
  }, [products]);

  useEffect(() => {
    if (invoiceTotal && invoiceProducts) {
      const formattedTotal = roundedTotal.replace(/,/g, '');
      const TotalAmount = parseFloat(formattedTotal);
      if (!isNaN(TotalAmount)) {
        const num = `${convertRupeesIntoWords(TotalAmount)} only`;
        setNumericalvalue(num);
      }
    }
  }, [invoiceTotal, invoiceProducts, roundedTotal]);

  if (inprogress || loading) {
    return (
      <>
        <InvoiceAddSkeleton />
      </>
    );
  }
  return (
    <Box>
      <Grid container spacing={2} root spacing-xs={12}>
        <Grid
          item
          xs={12}
          xl={12}
          md={12}
          display="flex"
          justifyContent="center"
          id="Invoice"
          sx={{
            m: 2,
            flexGrow: 1,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <Card
            sx={{
              p: 3,
              width: '1240px',
              maxWidth: '100%',
              borderRadius: 0,
              position: 'relative',
              margin: 0
            }}
            ref={container}
          >
            <Grid display="flex" justifyContent="center" sx={{ pb: 1 }}>
              <Typography color="inherit" variant="h4">
                {!isReportView ? 'Tax Invoice' : ``}
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Paper sx={{ m: 2 }} elevation={0}>
                  <Grid container spacing={0}>
                    <Grid xs={12} lg={12} sm={12} md={12} p={0}>
                      <Box display="flex">
                        {logoImage ? (
                          <img
                            alt="Logo"
                            src={logoImage}
                            style={{
                              width: '110px',
                              padding: '8px',
                              marginLeft: '-10px',
                              objectFit: 'contain'
                            }}
                          />
                        ) : (
                          ''
                        )}
                        <Stack sx={{ m: 1 }}>
                          <Typography variant="h4" mr={1}>
                            {companyName}
                          </Typography>
                          <Typography mr={1}>{tagLine}</Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>Supplier Address: {headOfficeAddress}</Typography>
                      <Typography>GSTIN Number: {gstIN}</Typography>
                      <Typography>
                        Mobile: {`${phone ? phone : mobile ? mobile : ''}, ${alternativePhone1 ? alternativePhone1 : mobile1 ? mobile1 : ''}`}
                      </Typography>
                      <Typography>{` ${alternativePhone2 ? alternativePhone2 : mobile2 ? mobile2 : ''}`}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Stack direction={'row'} spacing={5} justifyContent={'space-between'} width={'98%'} sx={{ m: 2 }}>
                  <Stack width={'70%'}>
                    <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                      <Typography variant="subtitle1">Date:</Typography>
                      <Typography>{issueDate}</Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                      <Typography variant="subtitle1">Invoice No:</Typography>
                      <Typography>{invoiceId}</Typography>
                    </Stack>
                    {auto && (
                      <>
                        <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                          <Typography variant="subtitle1">Order No:</Typography>
                          <Typography>{orderId ? orderId : selectedOrder ? selectedOrder.label : ''}</Typography>
                        </Stack>

                        <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                          <Typography variant="subtitle1">Trip No:</Typography>
                          <Typography>{selectedTrips.length > 1 ? `${selectedTrips[0]} + ${selectedTrips.length - 1}` : selectedTrips[0]}</Typography>
                        </Stack>
                      </>
                    )}
                    {vehicles && (
                      <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                        <Typography variant="subtitle1">Vehicle No:</Typography>
                        <Typography>{selectedVehicle.length > 1 ? `${selectedVehicle[0]} + ${selectedVehicle.length - 1}` : selectedVehicle[0]}</Typography>
                      </Stack>
                    )}
                  </Stack>
                  {status && qrCodeImage ? (
                    <Stack style={{ marginTop: '-20px', marginRight: '13px' }}>
                      <Typography sx={{ textAlign: 'center' }}>eInvoice QR</Typography>
                      <Typography>
                        <img src={qrCodeImage} alt="einvoiceQrCode" style={{ width: '120px', height: '120px' }} />
                      </Typography>
                    </Stack>
                  ) : status ? (
                    <Typography sx={{ m: 3, pr: 3 }}>
                      <QRCode size={80} value={config?.QR_CODE_URL ? `${config.QR_CODE_URL}QR/${ID}` : ''} />
                    </Typography>
                  ) : (
                    ''
                  )}
                </Stack>
                {qrCodeImage ? (
                  <Stack width={'98%'} sx={{ m: 2 }}>
                    <Stack direction={'row'} justifyContent={'space-between'} mr={'13px'}>
                      <Typography variant="subtitle1">IRN:</Typography>
                      <div>
                        <Typography>{irn?.slice(0, irn.length / 2)}</Typography>
                        <Typography>{irn?.slice(irn.length / 2, irn.length)}</Typography>
                      </div>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} mr={'13px'}>
                      <Typography variant="subtitle1">Ack No:</Typography>
                      <Typography>{ackNumber}</Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} mr={'13px'}>
                      <Typography variant="subtitle1">Ack Date:</Typography>
                      <Typography>{ackDate}</Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5" mr={1} mb={1} backgroundColor="#1D3F71" color="white" pl={1}>
                  Bill To
                </Typography>
                <Typography>{contractorName}</Typography>
                <Typography>{company}</Typography>
                <Typography>{fullAddress}</Typography>
                <Typography>{mobile}</Typography>
                <Typography>{selectedContractor?.gstNumber ? selectedContractor?.gstNumber : gstNumber ? gstNumber : ''}</Typography>
              </Grid>

              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5" mr={0} mb={1} backgroundColor="#1D3F71" color="white" pl={1}>
                  Ship To
                </Typography>
                <Typography>{contractorName}</Typography>
                <Typography>{company}</Typography>
                <Typography>{deliveryAddress}</Typography>
                <Typography>{mobile}</Typography>
                <Typography>{selectedContractor?.gstNumber ? selectedContractor?.gstNumber : gstNumber ? gstNumber : ''}</Typography>
              </Grid>
            </Grid>

            <Paper sx={{ mt: 2, mb: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ITEM DETAILS</StyledTableCell>
                      <StyledTableCell align="right">HSN/SAC</StyledTableCell>
                      <StyledTableCell align="right">RATE</StyledTableCell>
                      <StyledTableCell align="right">QTY</StyledTableCell>
                      <StyledTableCell align="right">TAX</StyledTableCell>
                      <StyledTableCell align="right">DISCOUNT</StyledTableCell>
                      <StyledTableCell align="right">AMOUNT</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceProducts &&
                      invoiceProducts.map((row, index) => (
                        <StyledTableRow
                          key={row.product}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.product} {row.grade ? `- ${row.grade}` : ''}
                          </TableCell>
                          <TableCell align="right">{row.hsnsac ? row.hsnsac : '-'}</TableCell>
                          <TableCell align="right">{row.price ? `₹ ${Number(row.price).toLocaleString('en-IN')}` : '-'}</TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{row.tax}%</TableCell>
                          <TableCell align="right">{row.discount ? `₹ ${row.discount}` : 0}</TableCell>
                          <TableCell align="right">
                            {row?.totalAmount ? `₹ ${(Number(row.totalAmount)).toLocaleString('en-IN')}` : '-'}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Grid container spacing={2} sx={{ px: 1 }}>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5">BANK DETAILS</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{notes}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Sub Total</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography>{invoiceSubTotal ? `₹ ${(Number(invoiceSubTotal)).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  {/* {!auto && discount > 0 && (
                    <>
                      <Grid item xs={6}>
                        <Typography>Discount</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>{discount ? `- ₹ ${Number(discount).toLocaleString('en-IN')}` : '-'}</Typography>
                      </Grid>
                    </>
                  )} */}
                  {gstType !== 'inter-state' ? (
                    <>
                      <Grid item xs={6}>
                        <Typography>{`CGST ${(gstTax > 0 && `@ ${gstTax / 2} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>₹ {Number(taxAmount / 2).toFixed(2)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{`SGST ${(gstTax > 0 && `@ ${gstTax / 2} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>₹ {Number(taxAmount / 2).toFixed(2)}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <Typography>{`IGST ${(gstTax > 0 && `@ ${gstTax} %`) || ''}`}</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>{taxAmount ? `₹ ${Number(taxAmount).toLocaleString('en-IN')}` : '-'}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <hr />
                { }
                <Grid container sx={{ pb: 2 }}>
                  <Grid item xs={6}>
                    <Typography>Grand Total</Typography>
                    <Typography variant='subtitle2' sx={{ textTransform: 'capitalize' }}>({taxType} Tax)</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="end">
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{invoiceTotal ? `₹ ${Number(invoiceTotal).toLocaleString('en-IN')}` : '-'}</Typography>
                  </Grid>
                  {(roundOff > 0 && location.pathname.includes('view')) || (!location.pathname.includes('view') && generalRecords?.settingValue?.roundingOff && JSON.parse(generalRecords?.settingValue?.roundingOff) && roundOff >0) ? (
                    <>
                      <Grid item xs={6}>
                        <Typography>Round Off:</Typography>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Typography>{roundOff ? `₹ ${Number(roundOff)}` : '₹0'}</Typography>
                      </Grid>

                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography>Rounded Total</Typography>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{roundedTotal ? `₹ ${Number(roundedTotal).toLocaleString('en-IN')}` : '-'}</Typography>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
                <hr />
                { }
                <Grid container sx={{ width: '100%' }}>
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', gap: '0' }}>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                      <Typography>In Words: (INR)</Typography>
                      <Typography style={{ fontWeight: 'bold' }}>{numericalvalue}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr />
            {/* <hr /> */}
            <Grid container spacing={2} display="flex">
              <Grid item xs={6} lg={6} sm={6} md={6}>
                <Typography variant="h5">Terms & Conditions</Typography>
                {/* <hr /> */}
                <Grid item xs={6}>
                  <Typography sx={{ width: '150%' }}>{paymentTerms}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6} sm={6} md={6} justifyContent="flex-end">
                <Typography variant="h5" sx={{ marginBottom: '50px' }}>
                  for {companyName}
                </Typography>
                {status && qrCodeImage ? (
                  <Typography>
                    <QRCode size={80} value={config?.QR_CODE_URL ? `${config.QR_CODE_URL}QR/${ID}` : ''} />
                  </Typography>
                ) : (
                  ''
                )}
                <Typography variant="p">Authorised Signatory</Typography>
              </Grid>
            </Grid>
            <hr />

            <Stack spacing={1} direction="row" justifyContent={'space-between'} sx={{ margin: '5px' }}>
              <Typography variant="p">This is a computer generated invoice</Typography>
              {generalRecords?.settingValue?.branding ? (
                <>
                  <Typography display={'flex'} variant="subtitle2" variant="p" fontWeight={600} component="span">
                    © 2023 All rights reserved |{' '}
                    <img navigate="" alt="icon" isIcon={true} height={25} src={`${config.S3.poweredBy}`} />
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Stack>
          </Card>
          {/* </Card> */}
        </Grid>
      </Grid>
      {!isReportView && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2
          }}
        >
          <>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ ml: 2 }}
              startIcon={<ArrowLeft />}
              onClick={() => {
                if (openPreview) {
                  setOpenPreview(false);
                  handleCloseRateConfirmationDialog();
                } else {
                  navigate(-1);
                }
              }}
            >
              Back
            </Button>
          </>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Button variant="contained" onClick={handleDownloadPDF}>
              <DocumentDownload size={22} />{' '}
              <Typography sx={{ ml: 1 }} variant="subtitle1">
                Download Invoice
              </Typography>
            </Button>

            {location.pathname.includes('view') && status === 'Draft' && (
              <Button sx={{ ml: 2 }} variant="contained" onClick={handleSubmit}>
                Publish
              </Button>
            )}
          </Box>

          <>
            {!location.pathname.includes('view') ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {invoiceStatus ? (
                  <Button variant="contained" onClick={(e) => handleSubmitInvoice(e, 'Published')}>
                    {invoiceStatus === 'Published' ? 'Update' : 'Published'}
                  </Button>
                ) : (
                  <>
                    <Button variant="outlined" color="inherit" sx={{ mr: 2 }} onClick={(e) => handleSubmitInvoice(e, 'Draft')}>
                      Draft
                    </Button>
                    <Button variant="contained" onClick={(e) => handleSubmitInvoice(e, 'Published')}>
                      Published
                    </Button>
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
          </>
        </Box>
      )}
    </Box>
  );
};

function InvoiceView({ invoiceActions, dispatch, useSelector }) {
  const params = useParams();
  const data = useSelector((store) => store.invoice);
  useEffect(() => {
    dispatch(invoiceActions.loadRecord(params.id));
  }, []);
  return <PreviewInvoice record={data?.record} />;
}

export default InvoiceView;

