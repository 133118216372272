/*
 *
 *  remotes
 *
 */

import {  getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';
const constantName = 'setting';

function loadRecords() {
  return API.get('tennat-service', `${constantName}`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {string} metaData
 * @returns
 */
function loadRecordsMetaData(metaData) {
  return API.get('tennat-service', metaData, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

//general preference
function loadGeneralSettingRecords(params) {
  const queryParams = params;
  return API.get('tennat-service', `${constantName}/generalsetting`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//module preference
function loadModuleSettingRecords(params) {
  const queryParams = params;
  return API.get('tennat-service', `${constantName}/modulesetting`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//all module prefix in advance

function loadAllModuleSettingRecords() {
  return API.get('tennat-service', `${constantName}/modulesetting`, getDefaultParamswithoutlimitkey(null))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
//user preference
function loadUserSettingRecords() {
  return API.get('tennat-service', `${constantName}/usersetting`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//general preference
function loadCustomFieldRecords(params) {
  const queryParams = params;
  return API.get('tennat-service', `${constantName}/generalsetting`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//update general setting
function updateGeneralSettingRecords(record) {
  return API.put('tennat-service', `${constantName}/generalsetting/general`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//update custom fields setting
function updateCustomFields(record) {
  return API.put('tennat-service', `${constantName}/generalsetting/customfields`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//update module setting
function updateModuleSetting(record, queryParams) {
  const { module } = queryParams;
  return API.put('tennat-service', `${constantName}/modulesetting/${module}`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

//update user setting
function updateUserSetting(userPayload, pathParams) {
  const { username } = pathParams;
  return API.put('tennat-service', `${constantName}/usersetting/${username}`, getDefaultParamswithoutlimitkey({ body: userPayload }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateRecord(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${constantName}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {integer} id
 * @returns
 */
function deleteRecord(id) {
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error));
}

function loadPrefixRecords() {
  return API.get('tennat-service', `settings`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadsProfile(username) {
  return API.get('tennat-service', `userprofile/${username}`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function updateProfile(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `${'userprofile'}/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateAdvancedRecord(record) {
  let userName = record.userName;
  delete record.userName;
  delete record.type;
  return API.put('tennat-service', `userprofile/${userName}`, getDefaultParamswithoutlimitkey({ body: Object.assign({}, { ...record }) }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function connectTally(record) {
  return API.post('tally-instance', 'connect', getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadConnectTally() {
  return API.get('tally-instance', 'url', getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function testTallyConnection(url) {
  return API.post('tally-instance', `verify`,getDefaultParamswithoutlimitkey({ body: url }))
      .then((res) => res)
      .catch((error) => Promise.reject(error));
}

export const preferencesService = {
  loadRecords,
  loadRecordsMetaData,
  loadGeneralSettingRecords,
  loadModuleSettingRecords,
  loadUserSettingRecords,
  loadCustomFieldRecords,
  updateGeneralSettingRecords,
  updateCustomFields,
  updateModuleSetting,
  updateUserSetting,
  createRecord,
  updateRecord,
  deleteRecord,
  loadPrefixRecords,
  loadsProfile,
  updateAdvancedRecord,
  updateProfile,
  loadAllModuleSettingRecords,
  connectTally,
  loadConnectTally,
  testTallyConnection
};
