/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { productContants } from '../_constants';

const initialState = {
  records: [],
  record: {},
  products: [],
  recordsMetaData: {},
  loading: false,
  error: false,
  message: null,
  page: 0,
  updateError: false,
  success: false,
  lastUpdate: null
};

export default function Reducer(state = initialState, props) {
  const { type, record, records, recordsMetaData = {}, products, message, error, total, page } = props;

  return produce(state, (draft) => {
    switch (type) {
      case productContants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.LOAD_RECORDS:
      case productContants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.LOAD_RECORDS_SUCCESS:
        let allRecords = [];
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.productId === v.productId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.total = total;
        draft.page = page;
        draft.message = '';
        break;
      case productContants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.CREATE_RECORD_SUCCESS:
        draft.records = [record].concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.productId === r.productId)
          ? state.records.map((r) => (record.productId === r.productId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.DELETE_RECORD_SUCCESS:
        draft.records = error ? state.records : state.records.filter((r) => r.productId.toString() !== record.toString());
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.total = state.total - 1;
        break;
      case productContants.LOAD_RECORDS_ERROR:
      case productContants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.UPDATE_RECORD_ERROR:
      case productContants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case productContants.LOAD_PRODUCTS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.LOAD_PRODUCTS_SUCCESS:
        draft.products = products;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case productContants.LOAD_PRODUCTS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;

      case productContants.UPDATE_AVAILABILITY_RECORD:
        draft.statusLoading = true;
        break;
      case productContants.UPDATE_AVAILABILITY_RECORD_SUCCESS:
        draft.records = state.records.find((r) => record.productId === r.productId)
          ? state.records.map((r) => (record.productId === r.productId ? Object.assign({}, r, record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({}, record)]);
        draft.error = error;
        draft.message = message;
        draft.success = false;
        draft.statusLoading = false;
        break;
      case productContants.UPDATE_AVAILABILITY_RECORD_ERROR:
        draft.loading = false;
        draft.statusLoading = false;
        draft.updateError = error;
        draft.success = false;
        draft.message = message;
        break;
      case productContants.CLEAR:
        draft.error = false;
        draft.message = '';
        break;
      default:
        break;
    }
  });
}
