/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { orderConstants } from '../_constants';

const initialState = {
  records: [],
  marketingpersons: [],
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  pageSize: null,
  page: 0,
  total: null,
  filters: null,
  fields: null,
  delivery_date: null,
  counts: null,
  orderCounts: null,
  upcomingRecords: [],
  contractorCounts: null,
  todayRecords: [],
  revenueDays: null,
  statusTimeline: [],
  pendingRecords:[],
  deleteSuccess:false,
  productPerformanceLoading:false,
  revenueLoading:false,
  contractorCountLoading:false,
  quantityCounts:null
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    record,
    records,
    counts,
    orderCounts,
    quantityCounts,
    todayRecords,
    revenueDays,
    contractorCounts,
    recordsMetaData = {},
    error,
    message,
    total,
    delivery_date,
    filters,
    page,
    upcomingRecords,
    marketingpersons,
    pendingRecords,
    statusTimeline
  } = props;
  return produce(state, (draft) => {
    switch (type) {
      case orderConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_RECORDS:
      case orderConstants.LOAD_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_SEARCH:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.searchFilters = filters;
        draft.success = false;
        draft.message = '';
        draft.searchTotals = total;
        break;
      case orderConstants.LOAD_SEARCH_SUCCESS:
        draft.searchRecords = records;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.searchFilters = filters;
        draft.searchTotals = total;
        break;
      case orderConstants.LOAD_SEARCH_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.searchFilters = filters;
        draft.message = message;
        draft.searchTotals = total;
        break;
      case orderConstants.LOAD_RECORDS_SUCCESS:
        let allRecords = '';
        page === 0 ? (allRecords = records) : (allRecords = state.records.concat(records));
        draft.records = allRecords.filter((v, i, a) => a.findIndex((v2) => v2.orderId === v.orderId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        draft.deleteSuccess = false;
        draft.searchRecords = [];
        draft.searchFilters = [];
        draft.searchTotals = [];
        break;
      case orderConstants.LOAD_RECORD_SUCCESS:
        draft.record = record;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.CREATE_RECORD_SUCCESS:
        draft.records = record.concat(state.records);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.total = state.total + 1;
        break;
      case orderConstants.UPDATE_RECORD_SUCCESS:
        draft.records = state.records.find((r) =>record.orderId === r.orderId)
          ? state.records.map((r) => (record.orderId === r.orderId ? Object.assign({}, r,record) : Object.assign({}, r)))
          : state.records.concat([Object.assign({},record)]);
        draft.searchRecords = state.searchRecords
          ? state.searchRecords.find((r) =>record.orderId === r.orderId)
            ? state.searchRecords.map((r) => (record.orderId === r.orderId ? Object.assign({}, r,record) : Object.assign({}, r)))
            : state.searchRecords.concat([Object.assign({},record)])
          : state.searchRecords;
        draft.updatedStatus = record;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.UPDATE_RECORD:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.status_changed = state.records.find((r) => record.orderId === r.orderId && record.orderStatus !== r.orderStatus) ? true : false;
        break;
      case orderConstants.LOAD_MARKETING_PERSONNAMES:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_MARKETING_PERSONNAMES_SUCCESS:
        draft.marketingpersons = marketingpersons;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_MARKETING_PERSONNAMES_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = '';
        break;

      case orderConstants.DELETE_RECORD_SUCCESS:
        draft.records = state.records.filter((r) => r.orderId.toString() !== record.toString());
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        draft.deleteSuccess = true;
        draft.total = state.total - 1;
        break;
      case orderConstants.LOAD_RECORDS_ERROR:
      case orderConstants.LOAD_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.CREATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.UPDATE_RECORD_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.DELETE_RECORD_ERROR:
        draft.loading = false;
        draft.updateError = error;
        draft.success = false;
        draft.error = true;
        draft.deleteSuccess = false;
        draft.message = message;
        break;
      case orderConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        break;
      case orderConstants.LOAD_RECORDS_COUNT:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_RECORDS_COUNT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.counts = counts;
        break;
      case orderConstants.LOAD_RECORDS_COUNT_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.counts = 0;
        break;
      case orderConstants.LOAD_RECORDS_PRODUCTS_COUNT:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.productPerformanceLoading = true;
        draft.message = '';
        break;
      case orderConstants.LOAD_RECORDS_PRODUCTS_COUNT_SUCCESS:
        draft.orderCounts = orderCounts;
        draft.loading = false;
        draft.error = false;
        draft.productPerformanceLoading = false;
        draft.success = false;
        break;
      case orderConstants.LOAD_RECORDS_PRODUCTS_COUNT_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.productPerformanceLoading = false;
        draft.orderCounts = 0;
        break;
      case orderConstants.RESET:
        draft.error = false;
        draft.message = '';
        draft.loading = false;
        break;
      case orderConstants.LOAD_UP_COMING_RECORDS:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.LOAD_PENDING_RECORDS:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.LOAD_UP_COMING_RECORDS_SUCCESS:
        let items = [];
        page === 0 ? (items = upcomingRecords) : (items = state.upcomingRecords.concat(upcomingRecords));
        draft.upcomingRecords = items.filter((v, i, a) => a.findIndex((v2) => v2.orderId === v.orderId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.upComingTotal = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case orderConstants.LOAD_PENDING_RECORDS_SUCCESS:
        let fullRecords = '';
        page === 0 ? (fullRecords = pendingRecords) : (fullRecords = state.pendingRecords.concat(pendingRecords));
        draft.pendingRecords = fullRecords.filter((v, i, a) => a.findIndex((v2) => v2.orderId === v.orderId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.pendingTotal = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case orderConstants.LOAD_UP_COMING_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.upcomingRecords = [];
        break;
      case orderConstants.LOAD_PENDING_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.pendingRecords = [];
        break;
      case orderConstants.LOAD_TODAY_RECORDS:
        draft.loading = true;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case orderConstants.LOAD_TODAY_RECORDS_SUCCESS:
        let item = [];
        page === 0 ? (item = todayRecords) : (item = state.todayRecords.concat(todayRecords));
        draft.todayRecords = item.filter((v, i, a) => a.findIndex((v2) => v2.orderId === v.orderId) === i);
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        draft.total = total;
        draft.filters = filters;
        draft.page = page;
        draft.delivery_date = delivery_date;
        break;
      case orderConstants.LOAD_TODAY_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.todayRecords = [];
        break;
      case orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.contractorCountLoading = true;
        draft.message = '';
        break;
      case orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_SUCCESS:
        draft.contractorCounts = contractorCounts;
        draft.loading = false;
        draft.error = false;
        draft.contractorCountLoading = false;
        draft.success = false;
        break;
      case orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.contractorCountLoading = false;
        draft.contractorCounts = 0;
        break;
      case orderConstants.LOAD_REVENUE_DAYS_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.revenueLoading = true;
        draft.message = '';
        break;
      case orderConstants.LOAD_REVENUE_DAYS_RECORDS_SUCCESS:
        draft.revenueDays = revenueDays;
        // draft.lastUpdate = Math.floor(Date.now() / 1000)
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.revenueLoading = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_REVENUE_DAYS_RECORDS_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.revenueLoading = false;
        draft.message = message;
        break;
      case orderConstants.LOAD_STATUS_TIMELINE_RECORDS:
        draft.loading = false;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS:
        draft.statusTimeline = statusTimeline;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case orderConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        draft.statusTimeline = [];
        break;
        case orderConstants.LOAD_RECORDS_QUANTITY_COUNT:
          draft.loading = true;
          draft.error = false;
          draft.updateError = false;
          draft.success = false;
          draft.productPerformanceLoading = true;
          draft.message = '';
          break;
        case orderConstants.LOAD_RECORDS_QUANTITY_COUNT_SUCCESS:
          draft.quantityCounts = quantityCounts;
          draft.loading = false;
          draft.error = false;
          draft.productPerformanceLoading = false;
          draft.success = false;
          break;
          case orderConstants.LOAD_RECORDS_QUANTITY_COUNT_ERROR:
            draft.loading = false;
            draft.error = true;
            draft.success = false;
            draft.message = message;
            draft.productPerformanceLoading = false;
            draft.quantityCounts = 0;
            break;
      default:
        break;
    }
  });
}
