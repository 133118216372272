import { Auth } from 'aws-amplify';

const TENNAT_BASE_URL = process.env.REACT_APP_TENNAT_BASE_URL;
const TENNAT_ADMIN_USER_BASE_URL = process.env.REACT_APP_TENNAT_ADMIN_USER_BASE_URL;
let stageEnv = process.env.REACT_APP_STAGE;
const devPool = {
  tennat: {
    userPoolId: 'ap-south-1_U62KFRdgF',
    region: 'ap-south-1',
    userPoolWebClientId: '3ckamsitq8kl7lr7aulcjm4jj'
  }
};
const demoPool = {
  tennat: {
    userPoolId: 'ap-south-1_DQTSDnFne',
    region: 'ap-south-1',
    userPoolWebClientId: '11ab4beji9qbmsclgcna1voacl'
  }
};
const prodPool = {
  tennat: {
    userPoolId: 'ap-south-1_ilbYTXoAh',
    region: 'ap-south-1',
    userPoolWebClientId: '26vnofrhbfnj64itk2ahtkpnq'
  }
};
let pool = {};
if (stageEnv === 'dev') {
  pool = { ...devPool };
} else if (stageEnv === 'demo') {
  pool = { ...demoPool };
} else if (stageEnv === 'prod') {
  pool = { ...prodPool };
} else {
  pool = { ...devPool };
}
export const cognitoPool = pool;
export const dev = {
  Auth: {
    userPoolId: 'ap-south-1_eop5H7nHm',
    region: 'ap-south-1',
    userPoolWebClientId: '5sf4r1j5ume9eegpcmo98ep8dq'
  },
  Razorpay: {
    API_KEY: 'rzp_test_MjX6WFeZT9TftZ'
  },
  MQTT: {
    host: 'wss://mqtt.dev1.plant365.in',
    mqttOption: {
      username: 'plant365-driver-app',
      password: 'Meyicloudpvt#plant365',
      port: 8081
    }
  },
  QR_CODE_URL: 'https://app.dev1.plant365.in/',
  STOCK_OPTIONS_URL: 'https://api.dev1.plant365.in/mock/sp/stock',
  PRODUCTS_URL: 'https://api.dev1.plant365.in/mock/sp/product',
  WS_URL: {
    NOTIFICATION: 'wss://socket.dev1.plant365.in/'
  },
  APP_CONFIG: {
    pageSize: 50,
    WS_INTERVAL: 60000
  },
  AWS: {
    accessKeyId: 'AKIAR72AIT7XFQZZGOGA',
    secretAccessKey: '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'
  },
  API: {
    endpoints: [
      {
        name: 'tennat-service-no-auth',
        endpoint: TENNAT_BASE_URL ? TENNAT_BASE_URL : 'https://api.dev1.plant365.in/supplier/'
      },
      {
        name: 'google-api',
        endpoint: 'https://maps.googleapis.com/maps/api/directions/json?origin=12.7968835,77.3838303&destination=11.9531185,79.7999574&mode=driving&key=AIzaSyAHEzryVvNy0QQ2StZTm-LEb5P_XRezqsw'
      },
      {
        name: 'einvoice',
        endpoint: 'https://api.dev1.plant365.in/eInvoice/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'tennat-service',
        endpoint: TENNAT_BASE_URL ? TENNAT_BASE_URL : 'https://api.dev1.plant365.in/supplier/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'admin-service-no-auth',
        endpoint: TENNAT_ADMIN_USER_BASE_URL ? TENNAT_ADMIN_USER_BASE_URL : 'https://api.dev1.plant365.in/admin/'
      },
      {
        name: 'admin-service',
        endpoint: TENNAT_ADMIN_USER_BASE_URL ? TENNAT_ADMIN_USER_BASE_URL : 'https://api.dev1.plant365.in/admin/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'tally-instance',
        endpoint: 'https://api.dev1.plant365.in/tally/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'vehicle-input',
        endpoint: 'https://8ekr3oajcd.execute-api.ap-south-1.amazonaws.com/v1/sp/'
      },
      {
        name: 'gstIn-service',
        endpoint: 'https://sheet.gstincheck.co.in/check/57def4bd5c979f8a3cbc7bf8b53f745b/'
      }
    ]
  },
  S3: {
    cdnDomain: 'cdn.dev1.plant365.in',
    poweredBy:`https://cdn.dev1.plant365.in/common/img/logo/Plant365.png`,
    tennatassets: 'assets-dev-meyi-io',
    tennatassetsURL: 'https://assets-dev-meyi-io.s3.amazonaws.com',
    region: 'us-east-1'
  }
};

export const prod = {
  Auth: {
    userPoolId: 'ap-south-1_ilbYTXoAh',
    region: 'ap-south-1',
    userPoolWebClientId: '26vnofrhbfnj64itk2ahtkpnq'
  },
  Razorpay: {
    API_KEY: 'rzp_test_MjX6WFeZT9TftZ'
  },
  MQTT: {
    host: 'wss://mqtt.dev1.plant365.in',
    mqttOption: {
      username: 'plant365-driver-app',
      password: 'Meyicloudpvt#plant365',
      port: 8081
    }
  },
  QR_CODE_URL: 'https://app.plant365.in/',
  STOCK_OPTIONS_URL: 'https://api.plant365.in/mock/sp/stock',
  PRODUCTS_URL: 'https://api.plant365.in/mock/sp/product',
  WS_URL: {
    NOTIFICATION: 'wss://socket.plant365.in/'
  },
  APP_CONFIG: {
    pageSize: 50,
    WS_INTERVAL: 60000
  },
  AWS: {
    accessKeyId: 'AKIAR72AIT7XFQZZGOGA',
    secretAccessKey: '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'
  },
  API: {
    endpoints: [
      {
        name: 'tennat-service-no-auth',
        endpoint: 'https://api.plant365.in/supplier/'
      },
      {
        name: 'tennat-service',
        endpoint: 'https://api.plant365.in/supplier/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'admin-service-no-auth',
        endpoint: 'https://api.plant365.in/admin/'
      },
      {
        name: 'admin-service',
        endpoint: 'https://api.plant365.in/admin/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'tally-instance',
        endpoint: 'https://api.plant365.in/tally/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'vehicle-input',
        endpoint: 'https://8ekr3oajcd.execute-api.ap-south-1.amazonaws.com/v1/sp/'
      },
      {
        name: 'gstIn-service',
        endpoint: 'https://sheet.gstincheck.co.in/check/57def4bd5c979f8a3cbc7bf8b53f745b/'
      },
      {
        name: 'einvoice',
        endpoint: 'https://api.plant365.in/eInvoice/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      }
    ]
  },
  S3: {
    cdnDomain: 'cdn.plant365.in',
    poweredBy:`https://cdn.plant365.in/common/img/logo/Plant365.png`,
    tennatassets: 'assets-dev-meyi-io',
    tennatassetsURL: 'https://assets-dev-meyi-io.s3.amazonaws.com',
    region: 'us-east-1'
  }
};

export const demo = {
  Auth: {
    userPoolId: 'ap-south-1_DQTSDnFne',
    region: 'ap-south-1',
    userPoolWebClientId: '11ab4beji9qbmsclgcna1voacl'
  },
  Razorpay: {
    API_KEY: 'rzp_test_MjX6WFeZT9TftZ'
  },
  QR_CODE_URL: 'https://demo.plant365.in/',
  STOCK_OPTIONS_URL: 'https://api.plant365.in/supplier/mock/sp/stock',
  MQTT: {
    host: 'wss://mqtt.dev.meyi.in',
    mqttOption: {
      username: 'meyi-driver-app',
      password: 'MeyicLOUD#0608',
      port: 8081
    }
  },
  AWS: {
    accessKeyId: 'AKIAR72AIT7XFQZZGOGA',
    secretAccessKey: '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'
  },
  API: {
    endpoints: [
      {
        name: 'tennat-service-no-auth',
        endpoint: TENNAT_BASE_URL ? TENNAT_BASE_URL : 'https://api.demo.plant365.in/supplier/'
      },
      {
        name: 'tennat-service',
        endpoint: TENNAT_BASE_URL ? TENNAT_BASE_URL : 'https://api.demo.plant365.in/supplier/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'admin-service-no-auth',
        endpoint: TENNAT_ADMIN_USER_BASE_URL ? TENNAT_ADMIN_USER_BASE_URL : 'https://api.demo.plant365.in/admin/'
      },
      {
        name: 'admin-service',
        endpoint: TENNAT_ADMIN_USER_BASE_URL ? TENNAT_ADMIN_USER_BASE_URL : 'https://api.demo.plant365.in/admin/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'tally-instance',
        endpoint: 'https://api.demo.plant365.in/tally/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'gstIn-service',
        endpoint: 'https://sheet.gstincheck.co.in/check/57def4bd5c979f8a3cbc7bf8b53f745b/'
      }
    ]
  },
  S3: {
    tennatassets: 'assets-demo-meyi-in',
    tennatassetsURL: 'https://assets-demo-meyi-in.s3.amazonaws.com',
    region: 'ap-south-1'
  }
};

export const stage = {
  Auth: {
    userPoolId: 'ap-south-1_DQTSDnFne',
    region: 'ap-south-1',
    userPoolWebClientId: '11ab4beji9qbmsclgcna1voacl'
  },
  Razorpay: {
    API_KEY: 'rzp_test_MjX6WFeZT9TftZ'
  },
  MQTT: {
    host: 'wss://mqtt.dev.meyi.in',
    mqttOption: {
      username: 'meyi-driver-app',
      password: 'MeyicLOUD#0608',
      port: 8081
    }
  },
  AWS: {
    accessKeyId: 'AKIAR72AIT7XFQZZGOGA',
    secretAccessKey: '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'
  },
  API: {
    endpoints: [
      {
        name: 'tennat-service',
        endpoint: 'https://api.demo.meyi.in/supplier/'
      },
      {
        name: 'admin-service',
        endpoint: 'https://api.demo.meyi.in/admin/'
      },
      {
        name: 'gstIn-service',
        endpoint: 'https://sheet.gstincheck.co.in/check/57def4bd5c979f8a3cbc7bf8b53f745b/'
      }
    ]
  },
  S3: {
    tennatassets: 'assets-demo-meyi-in',
    tennatassetsURL: 'https://assets-demo-meyi-in.s3.amazonaws.com',
    region: 'ap-south-1'
  }
};
