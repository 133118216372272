import { notificationService } from '../services';
import { notificationConstants } from '../_constants'; 
function loadRecords(pageSize = 5, page = 0) {
  return (dispatch) => {
    dispatch(request());
    notificationService.loadRecords(pageSize, page).then(
      (res) => {
        const { response, error, message,total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response,total,page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: notificationConstants.LOAD_RECORDS };
  }
  function success(records,total,page) {
    return { type: notificationConstants.LOAD_RECORDS_SUCCESS, records,total,page };
  }
  function failure(error, message) {
    return { type: notificationConstants.LOAD_RECORDS_ERROR, error, message };
  }
} 

function notifyRecords(record) {
  return (dispatch) => {
    dispatch(request(record));
  };
  function request(record) {
    return { type: notificationConstants.NOTIFY_RECORDS,record };
  }
} 

function generalNotification(record) {
  return (dispatch) => {
    dispatch(request(record));
  };
  function request(record) {
    return { type: notificationConstants.GENERAL_NOTIFICATION,record };
  }
} 

function clear() {
  return (dispatch) => {
    dispatch(request()); 
  };
  function request() {
    return { type: notificationConstants.CLEAR };
  }
} 

export const notificationActions = {
  loadRecords,
  clear,
  notifyRecords,
  generalNotification
};
