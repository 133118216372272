import { getDefaultParamswithoutlimitkey, buildQuery } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'contractor';

//Load contractor records
function loadRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function deleteAddress(id) {
  return API.del('tennat-service', `address/${id}`, getDefaultParamswithoutlimitkey()).catch((error) => Promise.reject(error));
}

function updateRecord(record) {
  const {
    contractorName,
    company,
    email,
    mobile,
    locations,
    address,
    apartment,
    city,
    addressState,
    addressId,
    country,
    pincode,
    gstDetails,
    gstNumber,
    creditLimit,
    latitute,
    longitude,
    addressName,
    gstUpdate,
    openingBalance
  } = record || {};
  return API.put(
    'tennat-service',
    `${constantName}/${record.contractorId}`,
    getDefaultParamswithoutlimitkey({
      body: Object.assign(
        {},
        {
          contractorName,
          company,
          email,
          mobile,
          locations,
          address,
          apartment,
          city,
          addressState,
          country,
          addressId,
          pincode,
          gstDetails,
          gstNumber,
          creditLimit,
          latitute,
          longitude,
          addressName,
          gstUpdate,
          openingBalance
        }
      )
    })
  )
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteRecord(id) {
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).catch((error) =>
    Promise.reject(error)
  );
}
function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getContractorAddress(id) {
  return API.get('tennat-service', `address/contractor/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createAddress(address) {
  return API.post('tennat-service', 'address', getDefaultParamswithoutlimitkey({ body: address }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function loadPaymentRecords(id) {
  return API.get('tennat-service', `${'invoice'}s/contractor/${id}?unpaid=true`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

/**
 *
 * @param {object} record
 * @returns
 */
function createPaymentRecords(record) {
  return API.post('tennat-service', `invoices/contractor`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadTransactionRecords(id, month) {
  let queryParams = { filterby: month };
  return API.get('tennat-service', `${'invoice'}s/contractor/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function orderCountsByContractor(id) {
  return API.get('tennat-service', `contractor/ordercount/${id}`, getDefaultParamswithoutlimitkey(null))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRecordsCount(params) {
  return API.get('tennat-service', `${constantName}s/counts`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: params }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function uploadContractors(records) {
  return API.post('tennat-service', `${constantName}/upload`, getDefaultParamswithoutlimitkey({ body: records }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateContractorStatus(record) {
  let { contractorId, ...filterRecord } = record;
  return API.put('tennat-service', `${constantName}/status/${contractorId}`, getDefaultParamswithoutlimitkey({ body: filterRecord }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadAutoFillAddress(record) {
  let queryParams = { url: record };
  return API.get('tennat-service', `${constantName}/location/url`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function syncContractorTally(record) {
  return API.post('tally-instance', `company`, getDefaultParamswithoutlimitkey({ body: record }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function loadTopPendingContractorsRecords(params) {
  const queryParams = buildQuery(params);
  return API.get('tennat-service', `contractors/pendingamount`,getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
function updatePaymentStatus(record) {
  let { transactionId, ...filterRecord } = record;
  return API.put('tennat-service', `invoices/${constantName}/${transactionId}`, getDefaultParamswithoutlimitkey({ body: filterRecord }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function deleteRecordPayment(record) {
  let queryParams = {};
  return API.del('tennat-service', `invoices/${constantName}/${record}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).catch((error) =>
    Promise.reject(error)
  );
}
export const contractorService = {
  updateContractorStatus,
  loadRecords,
  updateRecord,
  syncContractorTally,
  loadRecord,
  deleteRecord,
  createRecord,
  getContractorAddress,
  deleteAddress,
  createAddress,
  loadPaymentRecords,
  createPaymentRecords,
  loadTransactionRecords,
  orderCountsByContractor,
  loadRecordsCount,
  uploadContractors,
  loadAutoFillAddress,
  loadTopPendingContractorsRecords,
  updatePaymentStatus,
  deleteRecordPayment
};
