import LogoIcon from 'components/logo';
// material-ui
import {  Stack } from '@mui/material';
import Maintenance from "./Header/HeaderContent/Maintenance";
import config from 'aws-config';
// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}> 
    <Stack spacing={1.5} direction="row" justifyContent="center" alignItems="center">
       © 2023 All rights reserved  | <LogoIcon isIcon={true} src={`${config.S3.poweredBy}`}/>
    </Stack>
    <Maintenance/>
  </Stack>
);

export default Footer;