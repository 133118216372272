/**
 *
 * @param {string} name
 * @returns
 */

const url = `app/preferences`;

const LOAD_RECORDS = `${url}/LOAD_RECORDS`;
const LOAD_RECORDS_SUCCESS = `${url}/LOAD_RECORDS_SUCCESS`;
const LOAD_RECORDS_ERROR = `${url}/LOAD_RECORDS_ERROR`;
//general setting
const LOAD_GENERALSETTING_RECORDS = `${url}/LOAD_GENERALSETTING_RECORDS`;
const LOAD_GENERALSETTING_RECORDS_SUCCESS = `${url}/LOAD_GENERALSETTING_RECORDS_SUCCESS`;
const LOAD_GENERALSETTING_RECORDS_ERROR = `${url}/LOAD_GENERALSETTING_RECORDS_ERROR`;
const LOAD_CUSTOM_FIELD_RECORDS = `${url}/LOAD_CUSTOM_FIELDS_RECORDS`;
const LOAD_CUSTOM_FIELD_RECORDS_SUCCESS = `${url}/LOAD_CUSTOM_FIELDS_RECORDS_SUCCESS`;
const LOAD_CUSTOM_FIELD_RECORDS_ERROR = `${url}/LOAD_CUSTOM_FIELDS_RECORDS_ERROR`;
const UPDATE_GENERALSETTING_RECORDS = `${url}/UPDATE_GENERALSETTING_RECORDS`;
const UPDATE_GENERALSETTING_RECORDS_SUCCESS = `${url}/UPDATE_GENERALSETTING_RECORDS_SUCCESS`;
const UPDATE_GENERALSETTING_RECORDS_ERROR = `${url}/UPDATE_GENERALSETTING_RECORDS_ERROR`;

//update custom fields
const UPDATE_CUSTOM_FIELDS = `${url}/UPDATE_CUSTOM_FIELDS`;
const UPDATE_CUSTOM_FIELDS_SUCCESS = `${url}/UPDATE_CUSTOM_FIELDS_SUCCESS`;
const UPDATE_CUSTOM_FIELDS_ERROR = `${url}/UPDATE_CUSTOM_FIELDS_ERROR`;


//update module setting
const UPDATE_MODULE_SETTING = `${url}/UPDATE_MODULE_SETTING`;
const UPDATE_MODULE_SETTING_SUCCESS = `${url}/UPDATE_MODULE_SETTING_SUCCESS`;
const UPDATE_MODULE_SETTING_ERROR = `${url}/UPDATE_MODULE_SETTING_ERROR`;

//update user setting
const UPDATE_USER_SETTING = `${url}/UPDATE_USER_SETTING`;
const UPDATE_USER_SETTING_SUCCESS = `${url}/UPDATE_USER_SETTING_SUCCESS`;
const UPDATE_USER_SETTING_ERROR = `${url}/UPDATE_USER_SETTING_ERROR`;

//module setting
const LOAD_MODULESETTING_RECORDS = `${url}/LOAD_MODULESETTING_RECORDS`;
const LOAD_MODULESETTING_RECORDS_SUCCESS = `${url}/LOAD_MODULESETTING_RECORDS_SUCCESS`;
const LOAD_MODULESETTING_RECORDS_ERROR = `${url}/LOAD_MODULESETTING_RECORDS_ERROR`;

//user setting
const LOAD_USERSETTING_RECORDS = `${url}/LOAD_USERSETTING_RECORDS`;
const LOAD_USERSETTING_RECORDS_SUCCESS = `${url}/LOAD_USERSETTING_RECORDS_SUCCESS`;
const LOAD_USERSETTING_RECORDS_ERROR = `${url}/LOAD_USERSETTING_RECORDS_ERROR`;

const CREATE_RECORD = `${url}/CREATE_RECORD`;
const CREATE_RECORD_SUCCESS = `${url}/CREATE_RECORD_SUCCESS`;
const CREATE_RECORD_ERROR = `${url}/CREATE_RECORD_ERROR`;
const UPDATE_RECORD = `${url}/UPDATE_RECORD`;
const UPDATE_RECORD_SUCCESS = `${url}/UPDATE_RECORD_SUCCESS`;
const UPDATE_RECORD_ERROR = `${url}/UPDATE_RECORD_ERROR`;
const DELETE_RECORD = `${url}/DELETE_RECORD`;
const DELETE_RECORD_SUCCESS = `${url}/DELETE_RECORD_SUCCESS`;
const DELETE_RECORD_ERROR = `${url}/DELETE_RECORD_ERROR`;
const LOAD_RECORDS_META_DATA = `${url}/LOAD_RECORDS_META_DATA`;
const LOAD_RECORDS_META_DATA_SUCCESS = `${url}/LOAD_RECORDS_META_DATA_SUCCESS`;
const LOAD_RECORDS_META_DATA_ERROR = `${url}/LOAD_RECORDS_META_DATA_ERROR`;
const LOAD_RECORDS_VALID_CACHE = `${url}/LOAD_RECORDS_VALID_CACHE`;
const LOAD_PREFIX_RECORDS = `${url}/LOAD_PREFIX_RECORDS`;
const LOAD_PREFIX_RECORDS_SUCCESS = `${url}/LOAD_PREFIX_RECORDS_SUCCESS`;
const LOAD_PREFIX_RECORDS_ERROR = `${url}/LOAD_PREFIX_RECORDS_ERROR`;
const UPDATE_PREFIX_RECORDS = `${url}/UPDATE_PREFIX_RECORDS`;
const UPDATE_PREFIX_RECORDS_SUCCESS = `${url}/UPDATE_PREFIX_RECORDS_SUCCESS`;
const UPDATE_PREFIX_RECORDS_ERROR = `${url}/UPDATE_PREFIX_RECORDS_ERROR`;
const RESET = `${url}/RESET`;
const LOAD_PROFILE_RECORDS = `${url}/LOAD_PROFILE_RECORDS`;
const LOAD_PROFILE_RECORDS_SUCCESS = `${url}/LOAD_PROFILE_RECORDS_SUCCESS`;
const LOAD_PROFILE_RECORDS_ERROR = `${url}/LOAD_PROFILE_RECORDS_ERROR`;
const UPDATE_ADVANCED_RECORD = `${url}/UPDATE_ADVANCED_RECORD`;
const UPDATE_ADVANCED_RECORD_SUCCESS = `${url}/UPDATE_ADVANCED_RECORD_SUCCESS`;
const UPDATE_ADVANCED_RECORD_ERROR = `${url}/UPDATE_ADVANCED_RECORD_ERROR`;
const CONNECT_TALLY = `${url}/CONNECT_TALLY`;
const CONNECT_TALLY_SUCCESS = `${url}/CONNECT_TALLY_SUCCESS`;
const CONNECT_TALLY_ERROR = `${url}/CONNECT_TALLY_ERROR`;
const LOAD_TALLY_CONNECT = `${url}/LOAD_TALLY_CONNECT`;
const LOAD_TALLY_CONNECT_SUCCESS = `${url}/LOAD_TALLY_CONNECT_SUCCESS`;
const LOAD_TALLY_CONNECT_ERROR = `${url}/LOAD_TALLY_CONNECT_ERROR`;
const TALLY_TEST_CONNECTION = `${url}/TALLY_TEST_CONNECTION`;
const TALLY_TEST_CONNECTION_SUCCESS = `${url}/TALLY_TEST_CONNECTION_SUCCESS`;
const TALLY_TEST_CONNECTION_ERROR = `${url}/TALLY_TEST_CONNECTION_ERROR`;
export const preferencesConstants = {
  LOAD_RECORDS,
  LOAD_RECORDS_SUCCESS,
  LOAD_RECORDS_ERROR,
  LOAD_GENERALSETTING_RECORDS,
  LOAD_GENERALSETTING_RECORDS_SUCCESS,
  LOAD_GENERALSETTING_RECORDS_ERROR,
  LOAD_CUSTOM_FIELD_RECORDS,
  LOAD_CUSTOM_FIELD_RECORDS_SUCCESS,
  LOAD_CUSTOM_FIELD_RECORDS_ERROR,
  LOAD_MODULESETTING_RECORDS,
  LOAD_MODULESETTING_RECORDS_SUCCESS,
  LOAD_MODULESETTING_RECORDS_ERROR,
  LOAD_USERSETTING_RECORDS,
  LOAD_USERSETTING_RECORDS_SUCCESS,
  LOAD_USERSETTING_RECORDS_ERROR,
  UPDATE_GENERALSETTING_RECORDS,
  UPDATE_GENERALSETTING_RECORDS_SUCCESS,
  UPDATE_GENERALSETTING_RECORDS_ERROR,
  UPDATE_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_ERROR,
  UPDATE_MODULE_SETTING,
  UPDATE_MODULE_SETTING_SUCCESS,
  UPDATE_MODULE_SETTING_ERROR,
  UPDATE_USER_SETTING,
  UPDATE_USER_SETTING_SUCCESS,
  UPDATE_USER_SETTING_ERROR,
  CREATE_RECORD,
  CREATE_RECORD_SUCCESS,
  CREATE_RECORD_ERROR,
  UPDATE_RECORD,
  UPDATE_RECORD_SUCCESS,
  UPDATE_RECORD_ERROR,
  DELETE_RECORD,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  LOAD_RECORDS_VALID_CACHE,
  LOAD_RECORDS_META_DATA,
  LOAD_RECORDS_META_DATA_SUCCESS,
  LOAD_RECORDS_META_DATA_ERROR,
  LOAD_PREFIX_RECORDS,
  LOAD_PREFIX_RECORDS_SUCCESS,
  LOAD_PREFIX_RECORDS_ERROR,
  UPDATE_PREFIX_RECORDS,
  UPDATE_PREFIX_RECORDS_SUCCESS,
  UPDATE_PREFIX_RECORDS_ERROR,
  RESET,
  LOAD_PROFILE_RECORDS,
  LOAD_PROFILE_RECORDS_SUCCESS,
  LOAD_PROFILE_RECORDS_ERROR,
  UPDATE_ADVANCED_RECORD,
  UPDATE_ADVANCED_RECORD_SUCCESS,
  UPDATE_ADVANCED_RECORD_ERROR,
  CONNECT_TALLY,
  CONNECT_TALLY_SUCCESS,
  CONNECT_TALLY_ERROR,
  LOAD_TALLY_CONNECT,
  LOAD_TALLY_CONNECT_SUCCESS,
  LOAD_TALLY_CONNECT_ERROR,
  TALLY_TEST_CONNECTION,
  TALLY_TEST_CONNECTION_SUCCESS,
  TALLY_TEST_CONNECTION_ERROR,
};
