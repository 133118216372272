/*
 *
 *  remotes
 *
 */

import { getDefaultParamswithoutlimitkey } from "helpers/tools";
import { API } from "aws-amplify";
const constantName = "notification"

function loadRecords(pageSize, page ) {
  let queryParams = {
    pageSize: pageSize,
    page: page
  };
  return API.get(
    "tennat-service",
    `${constantName}s`,
    getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })
  )
    .then((res) => res)
    .catch((error) => Promise.reject(error));
} 
/**
 *
 * @param {string} metaData
 * @returns
 */
function loadRecordsMetaData(metaData) {
  return API.get("tennat-service", metaData, getDefaultParamswithoutlimitkey())
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
} 

export const notificationService = {
  loadRecords,
  loadRecordsMetaData
};

