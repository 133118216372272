/*
 *
 * Session constants
 *
 */
export const sessionConstants = {
  VERIFY_SESSION: 'app/session/VERIFY_SESSION',
  VERIFY_SESSION_SUCCESS: 'app/session/VERIFY_SESSION_SUCCESS',
  VERIFY_SESSION_ERROR: 'app/session/VERIFY_SESSION_ERROR',

  LOG_IN: 'app/session/LOG_IN',
  LOG_IN_SUCCESS: 'app/session/LOG_IN_SUCCESS',
  LOG_IN_ERROR: 'app/session/LOG_IN_ERROR',

  LOG_OUT: 'app/session/LOG_OUT',
  LOG_OUT_SUCCESS: 'app/session/LOG_OUT_SUCCESS',
  LOG_OUT_ERROR: 'app/session/LOG_OUT_ERROR',
  LOG_IN_SUCCESS_EXIST: 'app/session/LOG_IN_SUCCESS_EXIST',

  VERIFY_COMPANY_SUMBIT: 'app/session/VERIFY_COMPANY_SUMBIT',
  VERIFY_COMPANY_SUCCESS: 'app/session/VERIFY_COMPANY_SUCCESS',
  VERIFY_COMPANY_ERROR: 'app/session/VERIFY_COMPANY_ERROR',

  VERIFY_GSTIN_SUMBIT: 'app/session/VERIFY_GSTIN_SUMBIT',
  VERIFY_GSTIN_SUCCESS: 'app/session/VERIFY_GSTIN_SUCCESS',
  VERIFY_GSTIN_ERROR: 'app/session/VERIFY_GSTIN_ERROR',

  FORGOT_PASSWORD: 'app/session/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'app/session/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'app/session/FORGOT_PASSWORD_ERROR',
  NEW_PASSWORD_REQUIRED: 'app/session/NEW_PASSWORD_REQUIRED',
  COMPLETE_NEW_PASSWORD_INIT: 'app/session/COMPLETE_NEW_PASSWORD_INIT',
  COMPLETE_NEW_PASSWORD: 'app/session/COMPLETE_NEW_PASSWORD',
  RESET: 'app/session/RESET',

  UPDATE_USER_INFO: 'app/session/UPDATE_USER_INFO',

  ROUTER: '@@router/LOCATION_CHANGE',

  LOAD_LIMIT_KEY: 'app/session/LOAD_LIMIT_KEY',
  LOAD_LIMIT_KEY_ERROR: 'app/session/LOAD_LIMIT_KEY_ERROR',

  LOAD_ROLES_PERMISSION: 'app/session/LOAD_ROLES_PERMISSION',
  LOAD_ROLES_PERMISSION_ERROR: 'app/session/LOAD_ROLES_PERMISSION_ERROR',
  LOAD_ROLES_PERMISSION_SUCCESS: 'app/session/LOAD_ROLES_PERMISSION_SUCCESS',

  REGISTER: 'app/session/REGISTER',
  REGISTER_SUCCESS: 'app/session/REGISTER_SUCCESS',
  REGISTER_ERROR: 'app/session/REGISTER_ERROR',

  VERIFY_PASSWORD_CODE: 'app/session/VERIFY_PASSWORD_CODE',
  VERIFY_PASSWORD_CODE_SUCCESS: 'app/session/VERIFY_PASSWORD_CODE_SUCCESS',
  VERIFY_PASSWORD_CODE_ERROR: 'app/session/VERIFY_PASSWORD_CODE_ERROR',
  RESET_LOGIN: 'app/session/RESET_LOGIN',
  REQUEST_INVITE: 'app/session/REQUEST_INVITE',
  REQUEST_INVITE_SUCCESS: 'app/session/REQUEST_INVITE_SUCCESS',
  REQUEST_INVITE_ERROR: 'app/session/REQUEST_INVITE_ERROR',

  GET_COMPRESS_AUTH: 'app/session/GET_COMPRESS_AUTH',
  GET_COMPRESS_AUTH_SUCCESS: 'app/session/GET_COMPRESS_AUTH_SUCCESS',
  GET_COMPRESS_AUTH_ERROR: 'app/session/GET_COMPRESS_AUTH_ERROR'
};
