import { plansService } from '../services';
import { plansConstants } from '../_constants';
import { loaderActions } from './loader.actions';

function loadRecords(params) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    plansService.loadRecords(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message)); 
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(loaderActions.end());
        dispatch(failure(true, error.toString())); 
      }
    );
  };
  function request() {
    return { type: plansConstants.LOAD_RECORDS };
  }
  function success(records) {
    return { type: plansConstants.LOAD_RECORDS_SUCCESS, records };
  }
  function failure(error, message) {
    return { type: plansConstants.LOAD_RECORDS_ERROR, error, message };
  }
} 

function loadRecord(params) {
  return (dispatch) => {
    dispatch(request());
    plansService.loadRecord(params).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: plansConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: plansConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: plansConstants.LOAD_RECORD_ERROR, error, message };
  }
}
export const plansActions = {
  loadRecords,
  loadRecord
};
