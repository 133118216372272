/*
 *
 *   reducer
 *
 */
import produce from 'immer'

import {
     permissionsConstants
} from '../_constants'

const initialState = {
    records: [],
    record: {},
    recordsMetaData: {},
    loading: false,
    error: false,
    updateError: false,
    success: false,
    lastUpdate: null,
    message:null
}

export default function Reducer(state = initialState, props) {

    const { type, record, records, recordsMetaData = {}, error,message } = props

    return produce(state, draft => {
        switch (type) {
            case permissionsConstants.LOAD_RECORDS_VALID_CACHE:
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORDS:
            case permissionsConstants.LOAD_RECORD:
                draft.loading = true
                draft.error = false
                draft.updateError = false
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORDS_SUCCESS:
                draft.records = records
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORD_SUCCESS:                
                draft.record = record
                draft.lastUpdate = Math.floor(Date.now() / 1000)
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORDS_META_DATA_SUCCESS:
                draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData)
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.CREATE_RECORD_SUCCESS:
                draft.records = [record].concat(state.records)
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.UPDATE_RECORD_SUCCESS:
                draft.records = record
                draft.loading = false
                draft.error = false
                draft.success = false
                draft.message=message
                break
            case permissionsConstants.DELETE_RECORD_SUCCESS:
                draft.records = state.records
                draft.loading = false
                draft.error = false
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORDS_ERROR:
            case permissionsConstants.LOAD_RECORD_ERROR:
                draft.loading = false
                draft.error = error
                draft.success = false
                break
            case permissionsConstants.CREATE_RECORD_ERROR:
                draft.loading = false
                draft.error = error
                draft.success = false
                break
            case permissionsConstants.UPDATE_RECORD_ERROR:
            case permissionsConstants.DELETE_RECORD_ERROR:
                draft.loading = false
                draft.updateError = error
                draft.success = false
                break
            case permissionsConstants.LOAD_RECORDS_META_DATA_ERROR:
                draft.error = error
                break
            case permissionsConstants.CLEAR:
                draft.error = false
                draft.message = ""
                break
            default:
                break    
        }
    })
}

