import { getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { API } from 'aws-amplify';

//constant key
const constantName = 'plant';

//Load plans records
function loadRecords() {
  return API.get('tennat-service', `${constantName}s`, getDefaultParamswithoutlimitkey())
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

function createRecord(record) {
  return API.post('tennat-service', constantName, getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        let err_msg = response.message.includes('limit') ? 'Limit Exceeded' : response.message;
        throw new Error(err_msg);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}

function deleteRecord(id) {
  let queryParams = { softDelete: 'True' };
  return API.del('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey(null, { queryStringParameters: queryParams })).catch((error) =>
    Promise.reject(error)
  );
}

function updatePlantStatus(record) {
  let { plantId, ...filterRecord } = record;
  return API.put('tennat-service', `${constantName}/status/${plantId}`, getDefaultParamswithoutlimitkey({ body: filterRecord }))
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}
function loadRecord(id) {
  return API.get('tennat-service', `${constantName}/${id}`, getDefaultParamswithoutlimitkey())
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export const plantService = {
  loadRecords,
  loadRecord,
  createRecord,
  deleteRecord,
  updatePlantStatus
};
