import { noticeBoardService } from '../services';
import { noticeBoardConstants } from '../_constants';
import { snackbarActions } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions'; 
function loadRecords(pageSize = 5, page = 0,days=7) {
  return (dispatch) => {
    dispatch(request());
    noticeBoardService.loadRecords(pageSize, page,days).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: noticeBoardConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: noticeBoardConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: noticeBoardConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function updateRecord(record) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    noticeBoardService.updateRecord(record).then(
      (res) => {
        const {  error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(loaderActions.end()); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: noticeBoardConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: noticeBoardConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: noticeBoardConstants.UPDATE_RECORD_ERROR, error, message };
  }
}
 

function createRecord(record) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    noticeBoardService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message)); 
          dispatch(loaderActions.end()); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: noticeBoardConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: noticeBoardConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: noticeBoardConstants.CREATE_RECORD_ERROR, error, message };
  }
}
 

function deleteRecord(ID) {
  return (dispatch) => {
    dispatch(request());
    noticeBoardService.deleteRecord(ID).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(ID, message, error)); 
          dispatch(dialogActions.success(false)); 
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: noticeBoardConstants.DELETE_RECORD };
  }
  function success(ID) {
    return { type: noticeBoardConstants.DELETE_RECORD_SUCCESS, ID };
  }
  function failure(error, message) {
    return { type: noticeBoardConstants.DELETE_RECORD_ERROR, error, message };
  }
} 
export const noticeBoardActions = {
  loadRecords,
  updateRecord, 
  createRecord, 
  deleteRecord
};
