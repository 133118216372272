import { getParams, getDefaultParamswithoutlimitkey } from 'helpers/tools';
import { Amplify, Auth, API } from 'aws-amplify';
import { cognitoPool } from '../../aws-config/aws-config';
Amplify.configure({
  ...cognitoPool.tennat
});

function login(username, password) {
  return Auth.signIn(username, password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function logout() {
  return Auth.signOut({ global: false });
}

function register(record) {
  return API.post('admin-service-no-auth', 'registration', { body: record })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

const isUserExists = async (values) => {
  await Auth.signIn({ username: values.username, password: 'random#123' })
    .then((user) => {
      return Auth.signOut();
    })
    .then((res) => {
      return 'User exists';
    });
};

function forgotPassword(username) {
  return Auth.forgotPassword(username)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

/**
 * @param {string} username
 * @param {string} password
 * @param {string} code
 */
function forgotPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function loadRolesPermissions() {
  let queryParams = {};
  return API.get('tennat-service', `permission?userName=true`, getParams(null, { queryStringParameters: queryParams }))
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

// function loadLimitKey(){
//     let queryParams = {}
//     return API.get("tennat-service",`limit/tenantId`,getParams(null,{queryStringParameters:queryParams}))
//     .then((res)=>res)
//     .catch((error)=> Promise.reject(error))
// }

/**
 * @param {object} cognitoUser
 * @param {string} password
 */
function completeNewPassword(cognitoUser, password) {
  return Auth.completeNewPassword(cognitoUser, password)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function requestInvite() {
  return new Promise((resolve) => {
    resolve({ message: 'Thank you for contacting us', error: false });
  });
}

function getCompressedAuthToken(record) {
  return API.post('tennat-service', 'websocket/authTokenCompress', getDefaultParamswithoutlimitkey({ body: record }))
    .then((response) => {
      if (response.error) {
        return null;
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
}
export const sessionService = {
  login,
  logout,
  register,
  forgotPassword,
  forgotPasswordSubmit,
  loadRolesPermissions,
  // loadLimitKey,
  isUserExists,
  completeNewPassword,
  requestInvite,
  getCompressedAuthToken
};
