import { orderService } from '../services';
import { orderConstants } from '../_constants';
import { snackbarActions,  CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';

const listUrl = '/order/list';

function loadStatusTimelineRecords(id) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadStatusTimelineRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_STATUS_TIMELINE_RECORDS };
  }
  function success(statusTimeline) {
    return { type: orderConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS, statusTimeline };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR, error, message };
  }
}

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    orderService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_RECORDS };
  }
  function success(records, total, page) {
    return { type: orderConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request(id));
    orderService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request(id) {
    return { type: orderConstants.LOAD_RECORD, id };
  }
  function success(record) {
    return { type: orderConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    orderService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (!message.includes('unfulfilled') && !message.includes('force close')) {
            if (message.includes('cannot') || message.includes("can't")) {
              dispatch(snackbarActions(true, message, true));
            } else {
              dispatch(snackbarActions(true, message));
            }
          }
          dispatch(loaderActions.end());
        } else {
          dispatch(success(record, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          if (response[0].orderStatusUpdate) {
            navigate(`/order/view/${response[0].orderId}/#id`);
          } else {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: orderConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: orderConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: orderConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    orderService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot') || message.includes("can't")) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: orderConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: orderConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function loadMarketingPersonnames() {
  return (dispatch) => {
    dispatch(request());
    orderService.loadMarketingPersonnames().then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          if (message.includes('cannot')) {
            dispatch(snackbarActions(true, message, true));
          } else {
            dispatch(snackbarActions(true, message));
          }
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_MARKETING_PERSONNAMES };
  }
  function success(marketingpersons) {
    return { type: orderConstants.LOAD_MARKETING_PERSONNAMES_SUCCESS, marketingpersons };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_MARKETING_PERSONNAMES_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    orderService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        if (error.includes('Limit')) {
          dispatch(snackbarActions(true, error, true));
          navigate(listUrl);
        } else {
          dispatch(snackbarActions(true, error));
        }
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: orderConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: orderConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: orderConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function loadRecordsCount(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadRecordsCount(params).then(
      (res) => {
        const { error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_RECORDS_COUNT };
  }
  function success(counts) {
    return { type: orderConstants.LOAD_RECORDS_COUNT_SUCCESS, counts };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_RECORDS_COUNT_ERROR, error, message };
  }
}

function loadRecordsProductsCount(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadRecordsProductsCount(params).then(
      (res) => {
        const { error, message, response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_RECORDS_PRODUCTS_COUNT };
  }
  function success(orderCounts) {
    return { type: orderConstants.LOAD_RECORDS_PRODUCTS_COUNT_SUCCESS, orderCounts };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_RECORDS_PRODUCTS_COUNT_ERROR, error, message };
  }
}

function loadOrdersCountbyContractors(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadOrdersCountbyContractors(params).then(
      (res) => {
        const { error, message, response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS };
  }
  function success(contractorCounts) {
    return { type: orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_SUCCESS, contractorCounts };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_TOP_ORDERS_COUNT_BY_CONTRACTORS_ERROR, error, message };
  }
}

function loadTodayRecords(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadTodayRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_TODAY_RECORDS };
  }
  function success(todayRecords, total, page) {
    return { type: orderConstants.LOAD_TODAY_RECORDS_SUCCESS, todayRecords, total, page };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_TODAY_RECORDS_ERROR, error, message };
  }
}

function loadUpcomingRecords(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadUpcomingRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_UP_COMING_RECORDS };
  }
  function success(upcomingRecords, total, page) {
    return { type: orderConstants.LOAD_UP_COMING_RECORDS_SUCCESS, upcomingRecords, total, page };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_UP_COMING_RECORDS_ERROR, error, message };
  }
}

function loadPendingRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    orderService.loadPendingRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_PENDING_RECORDS };
  }
  function success(pendingRecords, total, page) {
    return { type: orderConstants.LOAD_PENDING_RECORDS_SUCCESS, pendingRecords, total, page };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_PENDING_RECORDS_ERROR, error, message };
  }
}

function loadRevenueDays(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadRevenueDays(params).then(
      (res) => {
        const { error, message, response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_REVENUE_DAYS_RECORDS };
  }
  function success(revenueDays) {
    return { type: orderConstants.LOAD_REVENUE_DAYS_RECORDS_SUCCESS, revenueDays };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_REVENUE_DAYS_RECORDS_ERROR, error, message };
  }
}
function loadQuantityCounts(params) {
  return (dispatch) => {
    dispatch(request());
    orderService.loadQuantityCounts(params).then(
      (res) => {
        const { error, message, response } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
      }
    );
  };
  function request() {
    return { type: orderConstants.LOAD_RECORDS_QUANTITY_COUNT };
  }
  function success(quantityCounts) {
    return { type: orderConstants.LOAD_RECORDS_QUANTITY_COUNT_SUCCESS, quantityCounts };
  }
  function failure(error, message) {
    return { type: orderConstants.LOAD_RECORDS_QUANTITY_COUNT_ERROR, error, message };
  }
}
export const orderActions = {
  loadRecords,
  updateRecord,
  loadRecord,
  deleteRecord,
  loadMarketingPersonnames,
  createRecord,
  loadRecordsCount,
  loadRecordsProductsCount,
  loadOrdersCountbyContractors,
  loadTodayRecords,
  loadUpcomingRecords,
  loadStatusTimelineRecords,
  loadPendingRecords,
  loadRevenueDays,
  loadQuantityCounts
};
