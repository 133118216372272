// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import kanban from './kanban';
import sessionReducer from './session.reducers';
//import invoice from './invoice';
import notification from "./notification.reducers"
import invoice from './invoice.reducers'
import mqtt from './mqtt.reducers'
import alert from './alert.reducers';
import dialog from './dialog.reducers';
import loader from './loader.reducers';

import plans from './plans.reducers';

//module reducers
import driver from './driver.reducers';
import vehicle from './vehicle.reducers';
import products from './product.reducers';
import contractor from './contractor.reducers';
import order from './order.reducers';
import trip from './trip.reducers';
import quote from './quote.reducers';
import plant from './plant.reducers';
import users from './users.reducers';
import permissions from './permissions.reducers';
import companyprofile from './companyProfile.reducers';
import userprofile from './userprofile.reducers'
import noticeBoard from './noticeBoard.reducers'
import preferences from './preferences.reducers'
import stock from './stock.reducers';
import tally from './tally.reducers';
import einvoice from './einvoice.reducers';
import qualityControl from './qualityControl.reducer';
//Schema
//import schema from "../../schema"

//const additionalReducers = schema.filter(_ => _.visible).reduce((a, el) => el.reducer ? Object.assign({}, a, { [el.name]: el.reducer}) : a,{})

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  sessionReducer,
  kanban,
  invoice,
  mqtt,
  notification,
  products,
  driver,
  vehicle,
  contractor,
  alert,
  order,
  trip,
  quote,
  dialog,
  loader,
  plans,
  plant,
  users,
  permissions,
  companyprofile,
  userprofile,
  noticeBoard,
  preferences,
  stock,
  tally,
  einvoice,
  qualityControl
  //...additionalReducers
});

export default reducers;
