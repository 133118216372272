/**
 * 
 * @param {string} name 
 * @returns 
 */
const url = `app/mqtt`

const MQTTCONNECTING = `${url}/MQTTCONNECTING`
const MQTTCONNECTING_SUCCESS = `${url}/MQTTCONNECTING_SUCCESS`
const MQTTCONNECTING_ERROR = `${url}/MQTTCONNECTING_ERROR`
const GET_MESSAGE = `${url}/GET_MESSAGE`
const GET_MESSAGE_SUCCESS = `${url}/GET_MESSAGE_SUCCESS`
const GET_MESSAGE_ERROR = `${url}/GET_MESSAGE_ERROR`
const LOAD_TOPIC = `${url}/LOAD_TOPIC`
const LOAD_TOPIC_SUCCESS = `${url}/LOAD_TOPIC_SUCCESS`
const LOAD_TOPIC_ERROR = `${url}/LOAD_TOPIC_ERROR`
const UPDATE_MARKER_POSITION = `${url}/UPDATE_MARKER_POSITION`
const CLEAR = `${url}/CLEAR`
const REMOVE_COMPLETED_MARKER = `${url}/REMOVE_COMPLETED_MARKER`
const TRASISTION_STATUS = `${url}/TRASISTION_STATUS`

export const mqttConstants = {
  MQTTCONNECTING,
  MQTTCONNECTING_SUCCESS,
  MQTTCONNECTING_ERROR,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_ERROR,
  LOAD_TOPIC,
  LOAD_TOPIC_SUCCESS,
  LOAD_TOPIC_ERROR,
  CLEAR,
  UPDATE_MARKER_POSITION,
  REMOVE_COMPLETED_MARKER,
  TRASISTION_STATUS
}
