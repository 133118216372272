import { quoteService } from '../services';
import { quoteConstants } from '../_constants';
import { alertActions } from '.';
import { snackbarActions, CREATE_SUCCESS } from 'helpers/tools';
import { dialogActions } from './dialog.actions';
import { loaderActions } from './loader.actions';
const listUrl = '/quote/list';
export const quoteActions = {
  loadRecords,
  deleteRecord,
  createRecord,
  loadRecord,
  updateRecord,
  loadStatusTimelineRecords,
  loadSearchRecords
};

function loadRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    dispatch(loaderActions.start());
    quoteService.loadRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, total, page));
          dispatch(loaderActions.end());
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.end());
      }
    );
  };
  function request() {
    return { type: quoteConstants.LOAD_RECORDS };
  }
  function success(records, total) {
    return { type: quoteConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: quoteConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function loadSearchRecords(params) {
  const { page } = params;
  return (dispatch) => {
    dispatch(request());
    quoteService.loadSearchRecords(params).then(
      (res) => {
        const { response, error, message, total } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(alertActions.error(message));
        } else {
          dispatch(success(response, total, page));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: quoteConstants.LOAD_RECORDS };
  }
  function success(records, total) {
    return { type: quoteConstants.LOAD_RECORDS_SUCCESS, records, total, page };
  }
  function failure(error, message) {
    return { type: quoteConstants.LOAD_RECORDS_ERROR, error, message };
  }
}

function createRecord(record, navigate) {
  return (dispatch) => {
    dispatch(loaderActions.start());
    dispatch(request());
    quoteService.createRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(success(response, message, error));
          dispatch(snackbarActions(false, message));
          dispatch(loaderActions.end());
          navigate(listUrl, { state: { status: CREATE_SUCCESS } });
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: quoteConstants.CREATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: quoteConstants.CREATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: quoteConstants.CREATE_RECORD_ERROR, error, message };
  }
}

function deleteRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request());
    quoteService.deleteRecord(record).then(
      (res) => {
        const { error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(dialogActions.error(false));
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(record, message, error));
          dispatch(dialogActions.success(false));
          if (navigate) {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error.toString()));
        dispatch(dialogActions.error(false));
      }
    );
  };
  function request() {
    return { type: quoteConstants.DELETE_RECORD };
  }
  function success(record, message, error) {
    return { type: quoteConstants.DELETE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: quoteConstants.DELETE_RECORD_ERROR, error, message };
  }
}

function loadRecord(id) {
  return (dispatch) => {
    dispatch(request());
    quoteService.loadRecord(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response[0]));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: quoteConstants.LOAD_RECORD };
  }
  function success(record) {
    return { type: quoteConstants.LOAD_RECORD_SUCCESS, record };
  }
  function failure(error, message) {
    return { type: quoteConstants.LOAD_RECORD_ERROR, error, message };
  }
}

function updateRecord(record, navigate) {
  return (dispatch) => {
    dispatch(request(record));
    dispatch(loaderActions.start());
    quoteService.updateRecord(record).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
          dispatch(snackbarActions(true, message));
          dispatch(loaderActions.end());
        } else {
          dispatch(snackbarActions(false, message));
          dispatch(success(response, message, error));
          dispatch(loaderActions.end());
          if (response[0].quoteStatusUpdate) {
            navigate(`/quote/view/${response[0].quoteId}/#id`, { state: response });
          } else {
            navigate(listUrl);
          }
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
        dispatch(snackbarActions(true, error));
        dispatch(loaderActions.end());
      }
    );
  };
  function request(record) {
    return { type: quoteConstants.UPDATE_RECORD, record };
  }
  function success(record, message, error) {
    return { type: quoteConstants.UPDATE_RECORD_SUCCESS, record, message, error };
  }
  function failure(error, message) {
    return { type: quoteConstants.UPDATE_RECORD_ERROR, error, message };
  }
}

function loadStatusTimelineRecords(id) {
  return (dispatch) => {
    dispatch(request());
    quoteService.loadStatusTimelineRecords(id).then(
      (res) => {
        const { response, error, message } = res;
        if (error) {
          dispatch(failure(true, message));
        } else {
          dispatch(success(response));
        }
      },
      (error) => {
        if (error && error.message) {
          error = error.message;
        }
        dispatch(failure(true, error.toString()));
      }
    );
  };
  function request() {
    return { type: quoteConstants.LOAD_STATUS_TIMELINE_RECORDS };
  }
  function success(statusTimeline) {
    return { type: quoteConstants.LOAD_STATUS_TIMELINE_RECORDS_SUCCESS, statusTimeline };
  }
  function failure(error, message) {
    return { type: quoteConstants.LOAD_STATUS_TIMELINE_RECORDS_ERROR, error, message };
  }
}
