
import { useState } from 'react';

// material-ui 
import { 
  FormControl,
  Grid, 
  Select,   
  MenuItem,
  Stack,  
  Typography
} from '@mui/material';

import useConfig from 'hooks/useConfig';

const PageSettings = () => { 
    const { rowsPage, onChangeRowsPage } = useConfig();
    const [open, setOpen] = useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
    return (
      <Grid container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption" color="secondary">
                Row per page
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={rowsPage}
                  onChange={(e) => {  
                    onChangeRowsPage(parseInt(e.target.value))
                  }}
                  size="small"
                  sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 }}}
                > 
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select> 
              </FormControl>
            </Stack> 
          </Stack>
        </Grid> 
      </Grid>
    );
  };

  export default PageSettings