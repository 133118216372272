/**
 *
 * @param {string} name
 * @returns
 */
const url = `app/tally`;

const LOAD_QC_ORDER_DATA = `${url}/LOAD_QC_ORDER_DATA`;
const LOAD_QC_ORDER_DATA_SUCCESS = `${url}/LOAD_QC_ORDER_DATA_SUCCESS`;
const LOAD_QC_ORDER_DATA_ERROR = `${url}/LOAD_QC_ORDER_DATA_ERROR`;

const UPDATE_QC_ORDER_DATA = `${url}/LOAD_QC_ORDER_DATA`;
const UPDATE_QC_ORDER_DATA_SUCCESS = `${url}/LOAD_QC_ORDER_DATA_SUCCESS`;
const UPDATE_QC_ORDER_DATA_ERROR = `${url}/LOAD_QC_ORDER_DATA_ERROR`;

const LOAD_QC_QUOTE_DATA = `${url}/LOAD_QC_QUOTE_DATA`;
const LOAD_QC_QUOTE_DATA_SUCCESS = `${url}/LOAD_QC_QUOTE_DATA_SUCCESS`;
const LOAD_QC_QUOTE_DATA_ERROR = `${url}/LOAD_QC_QUOTE_DATA_ERROR`;

const UPDATE_QC_QUOTE_DATA = `${url}/LOAD_QC_QUOTE_DATA`;
const UPDATE_QC_QUOTE_DATA_SUCCESS = `${url}/LOAD_QC_QUOTE_DATA_SUCCESS`;
const UPDATE_QC_QUOTE_DATA_ERROR = `${url}/LOAD_QC_QUOTE_DATA_ERROR`;

const LOAD_QC_TRIP_DATA = `${url}/LOAD_QC_TRIP_DATA`;
const LOAD_QC_TRIP_DATA_SUCCESS = `${url}/LOAD_QC_TRIP_DATA_SUCCESS`;
const LOAD_QC_TRIP_DATA_ERROR = `${url}/LOAD_QC_TRIP_DATA_ERROR`;

const UPDATE_QC_TRIP_DATA = `${url}/LOAD_QC_TRIP_DATA`;
const UPDATE_QC_TRIP_DATA_SUCCESS = `${url}/LOAD_QC_TRIP_DATA_SUCCESS`;
const UPDATE_QC_TRIP_DATA_ERROR = `${url}/LOAD_QC_TRIP_DATA_ERROR`;

const CREATE_QC_DATA = `${url}/CREATE_QC_DATA`;
const CREATE_QC_DATA_SUCCESS = `${url}/CREATE_QC_DATA_SUCCESS`;
const CREATE_QC_DATA_ERROR = `${url}/CREATE_QC_DATA_ERROR`;

const GET_QC_DATA = `${url}/GET_QC_DATA`;
const GET_QC_DATA_SUCCESS = `${url}/GET_QC_DATA_SUCCESS`;
const GET_QC_DATA_ERROR = `${url}/GET_QC_DATA_ERROR`;

const UPDATE_QC_DATA = `${url}/UPDATE_QC_DATA`;
const UPDATE_QC_DATA_SUCCESS = `${url}/UPDATE_QC_DATA_SUCCESS`;
const UPDATE_QC_DATA_ERROR = `${url}/UPDATE_QC_DATA_ERROR`;

const DELETE_QC_DATA = `${url}/DELETE_QC_DATA`;
const DELETE_QC_DATA_SUCCESS = `${url}/DELETE_QC_DATA_SUCCESS`;
const DELETE_QC_DATA_ERROR = `${url}/DELETE_QC_DATA_ERROR`;

const STORE_PRODUCT_QC_RECORD = `${url}/STORE_PRODUCT_QC_RECORD`;
const STORE_DEFULT_PRODUCT_QC_RECORD = `${url}/STORE_DEFULT_PRODUCT_QC_RECORD`;
export const qcConstants = {
    LOAD_QC_ORDER_DATA,
    LOAD_QC_ORDER_DATA_SUCCESS,
    LOAD_QC_ORDER_DATA_ERROR,

    UPDATE_QC_ORDER_DATA,
    UPDATE_QC_ORDER_DATA_SUCCESS,
    UPDATE_QC_ORDER_DATA_ERROR,

    LOAD_QC_QUOTE_DATA,
    LOAD_QC_QUOTE_DATA_SUCCESS,
    LOAD_QC_QUOTE_DATA_ERROR,

    UPDATE_QC_QUOTE_DATA,
    UPDATE_QC_QUOTE_DATA_SUCCESS,
    UPDATE_QC_QUOTE_DATA_ERROR,

    LOAD_QC_TRIP_DATA,
    LOAD_QC_TRIP_DATA_SUCCESS,
    LOAD_QC_TRIP_DATA_ERROR,

    UPDATE_QC_TRIP_DATA,
    UPDATE_QC_TRIP_DATA_SUCCESS,
    UPDATE_QC_TRIP_DATA_ERROR,

    CREATE_QC_DATA,
    CREATE_QC_DATA_SUCCESS,
    CREATE_QC_DATA_ERROR,

    GET_QC_DATA,
    GET_QC_DATA_SUCCESS,
    GET_QC_DATA_ERROR,

    UPDATE_QC_DATA,
    UPDATE_QC_DATA_SUCCESS,
    UPDATE_QC_DATA_ERROR,

    DELETE_QC_DATA,
    DELETE_QC_DATA_SUCCESS,
    DELETE_QC_DATA_ERROR,

    STORE_PRODUCT_QC_RECORD,
    STORE_DEFULT_PRODUCT_QC_RECORD
};