/*
 *
 *   reducer
 *
 */
import produce from 'immer';

import { preferencesConstants } from '../_constants';
import logoImg from 'assets/images/logo.png';
const initialState = {
  records: [],
  generalValue: {},
  customFieldRecords: {},
  moduleRecords: {},
  productRecords: {},
  userRecords: null,
  record: {},
  recordsMetaData: {},
  loading: false,
  error: false,
  updateError: false,
  success: false,
  lastUpdate: null,
  message: null,
  profileLoading: false,
  logoImage: logoImg,
  bannerImage: '',
  domainURL: '',
  userprofiles: [],
  loader: false,
  images: [],
  allModuleSettings: [],
  prefixRecords: []
};

export default function Reducer(state = initialState, props) {
  const {
    type,
    recordsMetaData = {},
    error,
    message,
    generalValue,
    moduleRecords,
    userRecords,
    customFieldRecords,
    field,
    modulePayload,
    prefixRecords,
    records
  } = props;

  return produce(state, (draft) => {
    switch (type) {
      case preferencesConstants.LOAD_RECORDS_VALID_CACHE:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_USERSETTING_RECORDS:
      case preferencesConstants.LOAD_MODULESETTING_RECORDS:
        draft.moduleRecords = {};
        draft.productRecords = {};
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_PREFIX_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.updateError = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_GENERALSETTING_RECORDS:
        draft.generalRecords = {};
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS:
        draft.customFieldRecords = {};
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_GENERALSETTING_RECORDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        break;
      case preferencesConstants.LOAD_GENERALSETTING_RECORDS_SUCCESS:
        draft.generalRecords = generalValue;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS_SUCCESS:
        draft.customFieldRecords = customFieldRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_MODULESETTING_RECORDS_SUCCESS:
        draft.productRecords = moduleRecords?.settingName === "product" ? moduleRecords : {}
        draft.moduleRecords = moduleRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_USERSETTING_RECORDS_SUCCESS:
        draft.userRecords = userRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_RECORDS_META_DATA_SUCCESS:
        draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData);
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_GENERALSETTING_RECORDS_SUCCESS:
        draft.generalRecords = generalValue;
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = message;
        break;
      case preferencesConstants.LOAD_CUSTOM_FIELD_RECORDS_ERROR:
      case preferencesConstants.LOAD_GENERALSETTING_RECORDS_ERROR:
      case preferencesConstants.LOAD_MODULESETTING_RECORDS_ERROR:
      case preferencesConstants.LOAD_USERSETTING_RECORDS_ERROR:
      case preferencesConstants.LOAD_PREFIX_RECORDS_ERROR:
      case preferencesConstants.CONNECT_TALLY_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      case preferencesConstants.LOAD_PREFIX_RECORDS_SUCCESS:
        draft.prefixRecords = prefixRecords;
        draft.lastUpdate = Math.floor(Date.now() / 1000);
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.CONNECT_TALLY:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.CONNECT_TALLY_SUCCESS:
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = message;
        break;
      case preferencesConstants.LOAD_TALLY_CONNECT:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_TALLY_CONNECT_SUCCESS:
        draft.records = records;
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = '';
        break;
      case preferencesConstants.LOAD_TALLY_CONNECT_ERROR:
        draft.records = records;
        draft.error = error;
        draft.loading = false;
        draft.message = message;
        break;
      case preferencesConstants.LOAD_RECORDS_META_DATA_ERROR:
        draft.error = error;
        draft.message = message;
        break;
      case preferencesConstants.RESET:
        draft.error = false;
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_CUSTOM_FIELDS:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_CUSTOM_FIELDS_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = message;
        draft.customFieldRecords = field;
        break;
      case preferencesConstants.UPDATE_CUSTOM_FIELDS_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        break;
      case preferencesConstants.UPDATE_MODULE_SETTING:
        draft.loading = true;
        draft.error = false;
        draft.success = '';
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_MODULE_SETTING_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = message;
        draft.modulePayload = modulePayload;
        break;
      case preferencesConstants.UPDATE_MODULE_SETTING_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        break;
      case preferencesConstants.UPDATE_USER_SETTING:
        draft.loading = true;
        draft.error = false;
        draft.success = '';
        draft.message = '';
        break;
      case preferencesConstants.UPDATE_USER_SETTING_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.success = true;
        draft.message = message;
        draft.userRecords = userRecords;
        break;
      case preferencesConstants.UPDATE_USER_SETTING_ERROR:
        draft.loading = false;
        draft.error = true;
        draft.success = false;
        draft.message = message;
        draft.userRecords = null;
        break;
      case preferencesConstants.TALLY_TEST_CONNECTION:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.message = '';
        break;
      case preferencesConstants.TALLY_TEST_CONNECTION_SUCCESS:
        draft.loading = false;
        draft.error = error;
        draft.success = true;
        draft.message = message;
        break;
      case preferencesConstants.TALLY_TEST_CONNECTION_ERROR:
        draft.loading = false;
        draft.error = error;
        draft.success = false;
        draft.message = message;
        break;
      default:
        break;
    }
  });
}
