/**
 *
 * @param {string} name
 * @returns
 */
const url = `app/tally`;

const SYNC_CONTRACTOR_TALLY = `${url}/SYNC_CONTRACTOR_TALLY`;
const SYNC_CONTRACTOR_TALLY_SUCCESS = `${url}/SYNC_CONTRACTOR_TALLY_SUCCESS`;
const SYNC_CONTRACTOR_TALLY_ERROR = `${url}/SYNC_CONTRACTOR_TALLY_ERROR`;

const FETCH_TALLY_COMPANIES = `${url}/FETCH_TALLY_COMPANIES`;
const FETCH_TALLY_COMPANIES_SUCCESS = `${url}/FETCH_TALLY_COMPANIES_SUCCESS`;
const FETCH_TALLY_COMPANIES_ERROR = `${url}/FETCH_TALLY_COMPANIES_ERROR`; 

const IMPORT_CONTRACTOR_TALLY = `${url}/IMPORT_CONTRACTOR_TALLY`;
const IMPORT_CONTRACTOR_TALLY_SUCCESS = `${url}/IMPORT_CONTRACTOR_TALLY_SUCCESS`;
const IMPORT_CONTRACTOR_TALLY_ERROR = `${url}/IMPORT_CONTRACTOR_TALLY_ERROR`;

const UPDATE_CONTRACTOR_TALLY = `${url}/UPDATE_CONTRACTOR_TALLY`;
const UPDATE_CONTRACTOR_TALLY_SUCCESS = `${url}/UPDATE_CONTRACTOR_TALLY_SUCCESS`;
const UPDATE_CONTRACTOR_TALLY_ERROR = `${url}/UPDATE_CONTRACTOR_TALLY_ERROR`;

const CLEAR_TALLY_RECORD = `${url}/CLEAR_TALLY_RECORD`; 

const SYNC_INVOICE_TALLY = `${url}/SYNC_INVOICE_TALLY`;
const SYNC_INVOICE_TALLY_SUCCESS = `${url}/SYNC_INVOICE_TALLY_SUCCESS`;
const SYNC_INVOICE_TALLY_ERROR = `${url}/SYNC_INVOICE_TALLY_ERROR`;

const FETCH_TALLY_INVOICE = `${url}/FETCH_TALLY_INVOICE`;
const FETCH_TALLY_INVOICE_SUCCESS = `${url}/FETCH_TALLY_INVOICE_SUCCESS`;
const FETCH_TALLY_INVOICE_ERROR = `${url}/FETCH_TALLY_INVOICE_ERROR`; 

const IMPORT_INVOICE_TALLY = `${url}/IMPORT_INVOICE_TALLY`;
const IMPORT_INVOICE_TALLY_SUCCESS = `${url}/IMPORT_INVOICE_TALLY_SUCCESS`;
const IMPORT_INVOICE_TALLY_ERROR = `${url}/UPDATE_INVOICE_TALLY_ERROR`;

const UPDATE_INVOICE_TALLY = `${url}/UPDATE_INVOICE_TALLY`;
const UPDATE_INVOICE_TALLY_SUCCESS = `${url}/UPDATE_INVOICE_TALLY_SUCCESS`;
const UPDATE_INVOICE_TALLY_ERROR = `${url}/UPDATE_INVOICE_TALLY_ERROR`;

export const tallyConstants = { 
  SYNC_CONTRACTOR_TALLY,
  SYNC_CONTRACTOR_TALLY_SUCCESS,
  SYNC_CONTRACTOR_TALLY_ERROR,
  FETCH_TALLY_COMPANIES,
  FETCH_TALLY_COMPANIES_SUCCESS,
  FETCH_TALLY_COMPANIES_ERROR,
  IMPORT_CONTRACTOR_TALLY,
  IMPORT_CONTRACTOR_TALLY_SUCCESS,
  IMPORT_CONTRACTOR_TALLY_ERROR,
  UPDATE_CONTRACTOR_TALLY,
  UPDATE_CONTRACTOR_TALLY_SUCCESS,
  UPDATE_CONTRACTOR_TALLY_ERROR,
  CLEAR_TALLY_RECORD,
  SYNC_INVOICE_TALLY,
  SYNC_INVOICE_TALLY_SUCCESS,
  SYNC_INVOICE_TALLY_ERROR,
  IMPORT_INVOICE_TALLY,
  IMPORT_INVOICE_TALLY_SUCCESS,
  IMPORT_INVOICE_TALLY_ERROR,
  FETCH_TALLY_INVOICE,
  FETCH_TALLY_INVOICE_SUCCESS,
  FETCH_TALLY_INVOICE_ERROR,
  UPDATE_INVOICE_TALLY,
  UPDATE_INVOICE_TALLY_SUCCESS,
  UPDATE_INVOICE_TALLY_ERROR
};
