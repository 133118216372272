// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Stack, Skeleton } from '@mui/material';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';

import Logo from 'components/logo';
import { useSelector } from 'store';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'config';
import useConfig from 'hooks/useConfig';
import logoImg from 'assets/images/logo.png';

// types
import { LAYOUT_CONST } from 'types/config';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const { logoImage, loading } = useSelector((state) => state.companyprofile);
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT,
        width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
      sx={{ mt: 1 }}
    >
      <Stack direction="row" justifyContent="space-evenly">
        {loading ? (
          <Skeleton variant="circular" width={60} height={60} />
        ) : (
          <Logo isIcon={!open} sx={{ width: open ? 'auto' : 52, height: 'auto' }} src={logoImage ? logoImage : logoImg} />
        )}
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
